import { getters } from "./getters.js";
export default {
    state() {
        return {
            is_feed_alert: false,
            alert_data: {}
        };
    },
    getters,
    mutations: {
        setFeedAlertData(state, data) {
            state.alert_data = data;

            // get session id
            let pod_sess_id = "";
            if(typeof localStorage.pod_sess_data !== 'undefined') {
                pod_sess_id = (JSON.parse(localStorage.pod_sess_data)).session_id_key;
            }

            if(Object.prototype.hasOwnProperty.call(data, 'content')) {
                if(typeof localStorage.rss_feed_viewed !== 'undefined') {
                    let viewed_feeds = JSON.parse(localStorage.rss_feed_viewed);
                    if(Object.prototype.hasOwnProperty.call(viewed_feeds, data.content)) {
                        let pod_sess_id_stored = viewed_feeds[data.content];
                        if(pod_sess_id !== pod_sess_id_stored) {
                            state.is_feed_alert = true;
                            state.alert_data = data;
                        }
                        else {
                            state.is_feed_alert = false;
                            state.alert_data = {};
                        }
                    }
                    else {
                        state.is_feed_alert = true;
                        state.alert_data = data;
                    }
                }
                else {
                    state.is_feed_alert = true;
                    state.alert_data = data;
                }
            }
            else {
                state.is_feed_alert = false;
            }
        },
    },
};
