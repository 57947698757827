const axios = require('axios');
import { cloud_api_routes } from '../routes/cloud_api_routes.js';
import { handleError, checkIfNotTokenIssue } from "../services/Guard";
let cloudAPI = process.env.VUE_APP_CLOUD_API;

export async function getGamesList(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.PLAYER_GAMES_LIST}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        handleError(error);
        return error.response;
    });
}

export async function updateStreamingFlag(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.PLAYER_STREAM_FLAG}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        handleError(error);
        return error.response;
    });
}

export async function checkSessionId(session_id_key) {
    return await axios.get(`${cloudAPI}${cloud_api_routes.KIOSK_CHECK_SESSION_ID}`+"?session="+session_id_key)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        handleError(error);
        return error.response;
    });
}

export async function getPodDetails(pod_id, params) {
    let url = cloudAPI + cloud_api_routes.PLAYER_POD + "/" + pod_id;
    return await axios.get(`${url}`, { params })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        handleError(error);
        return error.response;
    });
}

export async function setPodVolume(params) {
    let url = cloudAPI + cloud_api_routes.PLAYER_POD_SET_VOLUME;
    return await axios.post(`${url}`, params)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        handleError(error);
        return error.response;
    });
}

export async function getThemeLogoDetails(params) {
    let url = cloudAPI + cloud_api_routes.FAC_PLAYER_THEME_LOGO;
    return await axios.post(`${url}`, params)
    .then(response => response.data)
    .catch(error => {
        return error.response;
    });
}

export async function setPodLockout(params) {
    let url = cloudAPI + cloud_api_routes.PLAYER_POD_SET_LOCKOUT;
    return await axios.post(`${url}`, params)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        handleError(error);
        return error.response;
    });
}

export async function getOffersList(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.PLAYER_OFFERS_LIST}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        handleError(error);
        return error.response;
    });
}

export async function getPaymentIntent(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.PLAYER_GET_PAYMENT_INTENT}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        handleError(error);
        return error.response;
    });
}

export async function confirmPaymentIntent(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.PLAYER_CONFIRM_PAYMENT_INTENT}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        handleError(error);
        return error.response;
    });
}

export async function getPaymentMethods(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.PLAYER_GET_PAYMENT_METHODS}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        handleError(error);
        return error.response;
    });
}

export async function updateSaveCard(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.PLAYER_UPDATE_SAVE_CARD}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        handleError(error);
        return error.response;
    });
}

export async function deletePaymentMethod(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.PLAYER_DELETE_PAYMENT_METHOD}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        handleError(error);
        return error.response;
    });
}

export async function getPlayerRemainingTime(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.PLAYER_GET_REMAINING_TIME}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        handleError(error);
        return error.response;
    });
}

export async function getPaymentMethodId(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.PLAYER_GET_PAYMENT_METHOD_ID}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        handleError(error);
        return error.response;
    });
}

export async function getSurveyGamesList(params) {
    return await axios.get(`${cloudAPI}${cloud_api_routes.SURVEY_GAMES_LIST}`, {params})
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        handleError(error);
        return error.response;
    });
}

export async function saveSurvey(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.SURVEY_SAVE}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        handleError(error);
        return error.response;
    });
}