<template>
    <div class="main-layout-section admin-portal" :class="[user_data.role_id == 4 ? '' : 'secondary']">
        <AdminHeader @setPodData="setPodData($event)" v-if="user_data.role_id == 3" />
        <SuperAdminHeader v-if="user_data.role_id == 4" />

        <div class="w-logo mb-3">
            <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="logo_file !== '' && logo_file !== null && user_data.role_id !== 4"/>
            <img src="../../assets/images/gamelab-logo.svg" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="user_data.role_id == 4"/>
        </div>

        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeInUp animate__delay-1s">User Detail</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">

                    <div class="row">

                        <div class="admin-general-section-buttons col-md-12">
                            <router-link :to="`/common/users/edit/${edit_user_id}`" class="admin-general-section-button">
                                Edit User
                            </router-link>

                            <router-link to="/common/users" class="admin-general-section-button">
                                Back To Users Management
                            </router-link>
                        </div>
                    </div>

                    <div class="col-sm-12" v-if="is_valid_user">
                        <div class="d-flex col-md-12 m-auto p-0 my-3">
                            <h4 class="page-heading animate__animated animate__fadeInUp animate__delay-1s text-left">User</h4>
                        </div>
                        
                        <div class="overflow-auto p-0 m-0">

                            <table class="table b-table table-striped" id="admin-client-table">
                                <tbody>
                                    <tr>
                                        <th width="20%">Email</th>
                                        <td width="80%">{{ user.user.email }}</td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Role</th>
                                        <td width="80%">{{ user.user.role.role_name }}</td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Active</th>
                                        <td width="80%">
                                            <div class="d-flex flex-column switch-container">
                                                <switches 
                                                class="active-switch" 
                                                v-model="is_active"
                                                theme="bootstrap" 
                                                color="primary"
                                                type-bold="true"
                                                >
                                                </switches>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>

                    <div class="col-sm-12" v-if="is_valid_user && user.user.user_role_id == 1">
                        <div class="d-flex col-md-12 m-auto p-0 my-3">
                            <h4 class="page-heading animate__animated animate__fadeInUp animate__delay-1s text-left">Linked Player</h4>
                        </div>
                        
                        <div class="overflow-auto p-0 m-0">

                            <table class="table b-table table-striped" id="admin-client-table">
                                <tbody>
                                    <tr>
                                        <th width="20%">Name</th>
                                        <td width="80%" colspan="3">{{ user.player.first_name }}</td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Email</th>
                                        <td width="30%">{{ user.player.email }}</td>
                                        <th width="20%">Phone#</th>
                                        <td width="30%">{{ user.player.phone }}</td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Date of Birth</th>
                                        <td width="30%">{{ user.player.dob }}</td>
                                        <th width="20%">Time Comp</th>
                                        <td width="30%">{{ formatTimeString(user.player.time_comp) }}</td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Time Paid</th>
                                        <td width="30%">{{ formatTimeString(user.player.time_paid) }}</td>
                                        <th width="20%">Total Playing Time</th>
                                        <td width="30%">{{ formatTimeString(user.player.time_play) }}</td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Remaining Time</th>
                                        <td width="30%">{{ formatTimeString(user.player.remaining_time) }}</td>
                                        <th width="20%">Paid Playing Time</th>
                                        <td width="30%">{{ formatTimeString(user.player.paid_play_time) }}</td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Stream</th>
                                        <td width="30%">
                                            <div class="d-flex flex-column switch-container">
                                                <switches 
                                                class="active-switch" 
                                                v-model="stream_default"
                                                theme="bootstrap" 
                                                color="primary"
                                                type-bold="true"
                                                >
                                                </switches>
                                            </div>
                                        </td>
                                        <th width="20%">Pro</th>
                                        <td width="30%">
                                            <div class="d-flex flex-column switch-container">
                                                <switches 
                                                class="active-switch" 
                                                v-model="pro"
                                                theme="bootstrap" 
                                                color="primary"
                                                type-bold="false"
                                                >
                                                </switches>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <h3 class="page-heading mb-3 animate__animated animate__fadeInUp animate__delay-1s mt-2 text-left">Comp Time</h3>
                        <div class="row m-0 p-0 col-md-12 col-sm-12 m-auto">
                            
                            <b-form inline @submit.stop.prevent class="m-0 p-0">
                                <!-- client and labe name field -->
                                
                                <div class="row mac-search mt-3">
                                    <div class="d-flex mb-1 col-md-6 flex-column">
                                        <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100 overflow-visible" :class="{ 'is_invalid': $v.time_form.minutes.$error }">
                                            <span><i class="fa-solid fa-clock"></i></span>
                                            <input type="text" name="date" value="" v-mask="'########'" v-model="time_form.minutes" class="form-control" :class="{ 'is-invalid': $v.time_form.minutes
                                            .$error }" placeholder="# of minutes" />
                                        </div>
                                    </div>
                                    <div class="d-flex mb-1 col-md-2 flex-column">
                                        <div
                                        class="
                                        w-form-control
                                        animate__animated animate__fadeInUp animate__delay-3s m-0 p-0
                                        "
                                        >
                                            <a
                                            href="javascript:void(0)"
                                            class="flex-fill m-0" 
                                            @click="handleUserTimeCompReq"
                                            >ADD</a
                                            >
                                        </div>
                                    </div>
                                </div>

                            </b-form>

                        </div>

                        <!-- start of lab schedule override table -->
                        <div class="col-sm-12 animate__animated animate__fadeInUp animate__delay-1s mt-2">
                            <div class="overflow-auto p-0 m-0">
                                <b-table
                                id="admin-general-table"
                                :striped="true"
                                :hover="false"
                                :dark="false"
                                :items="user.timecomps"
                                :fields="fields"
                                show-empty
                                :emptyText="emptyText"
                                >
                                    <template #cell(action)="data">
                                        <div class="icon-action-buttons-container d-flex flex-row">
                                            <!-- delete button -->
                                            <button 
                                            class="btn-danger btn-sm" 
                                            :class="data.value"
                                            @click="confirmDeleteTimeComp(data.item.id)"
                                            >
                                            <b-icon class="icon" icon="trash2-fill"></b-icon>
                                            Delete
                                            </button>
                                        </div>
                                    </template>
                                    <template #empty="scope">
                                    <p class="text-center p-0 m-0">{{ scope.emptyText }}</p>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                        <!-- end of lab schedule override table -->
                        
                        <!-- start of offer payments section -->
                        <h3 class="page-heading mb-3 animate__animated animate__fadeInUp animate__delay-1s mt-2 text-left flex-rangepicker-heading">
                            Offer Payments
                            <div class="date-range-picker" v-if="!payments_api_state">
                                <date-range-picker
                                    ref="payments_picker" 
                                    :date-range="payments_date" 
                                    :ranges="false" 
                                    @update="paymentsDateApplied" 
                                    :locale-data="default_locale" 
                                    opens="left" 
                                    v-model="payments_date"
                                >
                                <template v-slot:input="picker">
                                    <div class="form-control reportrange-text">
                                        <i class="glyphicon glyphicon-calendar fa fa-calendar"></i>
                                        <span>{{ (picker.startDate == "" || picker.startDate == null || picker.endDate == "" || picker.endDate == null) ? "Select Date Range" : moment(picker.startDate).format('MM/DD/YYYY')+ " - " + moment(picker.endDate).format('MM/DD/YYYY') }}</span>
                                        <b class="caret"></b>
                                    </div>
                                </template>
                                </date-range-picker>
                                <button v-if="payments_date.startDate != '' && payments_date.endDate != ''" class="daterange-clear-btn" @click="clearPaymentDateSelection">Clear</button>
                            </div>
                            <div class="date-range-picker-loader" v-if="payments_api_state">
                                <span class="spinner-border"></span> Loading...
                            </div>
                        </h3>
                        <!-- end of offer payments section -->

                        <div class="col-sm-12">
                            <div class="overflow-auto p-0 m-0">
                                <b-table
                                id="admin-general-table"
                                :striped="true"
                                :hover="false"
                                :dark="false"
                                :items="payments"
                                :fields="paymentsFields"
                                show-empty
                                :emptyText="paymentsEmptyText" 
                                >
                                    <template #empty="scope">
                                        <p class="text-center p-0 m-0">{{ scope.emptyText }}</p>
                                    </template>
                                </b-table>
                            </div>
                            <b-pagination
                            v-model="payments_current_page"
                            :total-rows="payments_total_rows"
                            :per-page="payments_per_page" 
                            limit="3"
                            aria-controls="admin-general-table" 
                            align="right"
                            ></b-pagination>
                            <p class="admin-general-table-status-text">{{ payments_status_text }}</p>
                        </div>

                        <!-- start of playing activities section -->
                        <h3 class="page-heading mb-3 animate__animated animate__fadeInUp animate__delay-1s mt-2 text-left flex-rangepicker-heading">
                            Game Playing Time 
                            <div class="date-range-picker" v-if="!playing_api_state">
                                <date-range-picker
                                    ref="playing_picker" 
                                    :date-range="playing_date" 
                                    :ranges="false" 
                                    @update="playingDateApplied" 
                                    :locale-data="default_locale" 
                                    opens="left" 
                                    v-model="playing_date"
                                >
                                <template v-slot:input="picker">
                                    <div class="form-control reportrange-text">
                                        <i class="glyphicon glyphicon-calendar fa fa-calendar"></i>
                                        <span>{{ (picker.startDate == "" || picker.startDate == null || picker.endDate == "" || picker.endDate == null) ? "Select Date Range" : moment(picker.startDate).format('MM/DD/YYYY')+ " - " + moment(picker.endDate).format('MM/DD/YYYY') }}</span>
                                        <b class="caret"></b>
                                    </div>
                                </template>
                                </date-range-picker>
                                <button v-if="playing_date.startDate != '' && playing_date.endDate != ''" class="daterange-clear-btn" @click="clearPlayingDateSelection">Clear</button>
                            </div>
                            <div class="date-range-picker-loader" v-if="playing_api_state">
                                <span class="spinner-border"></span> Loading...
                            </div>
                        </h3>
                        
                        <div class="col-sm-12">
                            <div class="overflow-auto p-0 m-0">
                                <b-table
                                id="admin-general-table"
                                :striped="true"
                                :hover="false"
                                :dark="false"
                                :items="playing_activities"
                                :fields="playingFields"
                                show-empty
                                :emptyText="playingEmptyText" 
                                >
                                    <template #cell(play_time)="data">
                                        {{ parseFloat(data.item.play_time).toFixed(2).replace(/[.,]00$/, "") }}
                                    </template>
                                    <template #cell(pod_id_key)="data">
                                        {{ (Object.prototype.hasOwnProperty.call(data.item, 'session') && Object.prototype.hasOwnProperty.call(data.item.session, 'pod') && Object.prototype.hasOwnProperty.call(data.item.session.pod, 'podnav') && Object.prototype.hasOwnProperty.call(data.item.session.pod.podnav, 'license')) ? data.item.session.pod.podnav.license : '' }}
                                    </template>
                                    <template #empty="scope">
                                        <p class="text-center p-0 m-0">{{ scope.emptyText }}</p>
                                    </template>
                                </b-table>
                            </div>
                            <b-pagination
                            v-model="playing_current_page"
                            :total-rows="playing_total_rows"
                            :per-page="playing_per_page" 
                            limit="3"
                            aria-controls="admin-general-table" 
                            align="right"
                            ></b-pagination>
                            <p class="admin-general-table-status-text">{{ playing_status_text }}</p>
                        </div>
                        <!-- end of playing activities section -->

                    </div>

                    <div class="col-sm-12" v-if="is_valid_user && (user.user.user_role_id == 3 || user.user.user_role_id == 1 || user.user.user_role_id == 2 || user.user.user_role_id == 5) && Object.prototype.hasOwnProperty.call(user.client, 'first_name')">
                        <div class="d-flex col-md-12 m-auto p-0 my-3">
                            <h4 class="page-heading animate__animated animate__fadeInUp animate__delay-1s text-left">Linked Client</h4>
                        </div>
                        
                        <div class="overflow-auto p-0 m-0">

                            <table class="table b-table table-striped" id="admin-client-table">
                                <tbody>
                                    <tr>
                                        <th width="20%">First Name</th>
                                        <td width="30%">{{ user.client.first_name }}</td>
                                        <th width="20%">Last Name</th>
                                        <td width="30%">{{ user.client.last_name }}</td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Email</th>
                                        <td width="30%">{{ user.client.email }}</td>
                                        <th width="20%">Phone#</th>
                                        <td width="30%">{{ user.client.phone }}</td>
                                    </tr>
                                    <tr>
                                        <th width="20%">City</th>
                                        <td width="30%">{{ user.client.city }}</td>
                                        <th width="20%">State</th>
                                        <td width="30%">{{ user.client.state }}</td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Address 1</th>
                                        <td width="30%">{{ user.client.address1 }}</td>
                                        <th width="20%">Address 2</th>
                                        <td width="30%">{{ user.client.address2 }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>

                    <!-- start of edit user section -->
                    <h3 class="page-heading mb-3 animate__animated animate__fadeInUp animate__delay-1s mt-2 text-left">Edit User</h3>
                    <div class="col-md-12 col-sm-12 m-auto">
                    
                        <b-form inline @submit.stop.prevent class="m-0 p-0">
                            <!-- client and labe name field -->
                            
                            <div class="row mt-3">
                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Name</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100"  :class="{ 'is_invalid': $v.user_form.name.$error }">
                                        <span><i class="fa-solid fa-file"></i></span>
                                        <b-form-input
                                            v-model="user_form.name"
                                            id="name"
                                            class=""
                                            :class="{ 'is-invalid': $v.user_form.name.$error }"
                                            placeholder="name"
                                        ></b-form-input>
                                    </div>
                                </div>
                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Email</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100"  :class="{ 'is_invalid': $v.user_form.email.$error }">
                                        <span><i class="fa-solid fa-user"></i></span>
                                        <b-form-input
                                            v-model="user_form.email"
                                            id="email"
                                            class=""
                                            :class="{ 'is-invalid': $v.user_form.email.$error }"
                                            placeholder="email@domain.com"
                                        ></b-form-input>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Mobile Number</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.user_form.phone.$error }">
                                        <span><i class="fa-solid fa-phone"></i></span>
                                        <b-form-input
                                            v-model="user_form.phone"
                                            id="dob"
                                            v-mask="phone_mask" 
                                            class="mb-2 mr-sm-2 mb-sm-0 user-name bg-lightBlack border-0 h-53"
                                            :class="{ 'is-invalid': $v.user_form.phone.$error }"
                                            placeholder="555-555-5555"
                                        ></b-form-input>
                                    </div>
                                </div>

                                <!-- phone field -->
                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Date of Birth</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.user_form.dob.$error }">
                                        <span><i class="fa-solid fa-calendar"></i></span>
                                        <b-form-input
                                            v-model="user_form.dob"
                                            id="dob"
                                            v-mask="date_mask" 
                                            class="mb-2 mr-sm-2 mb-sm-0 user-name bg-lightBlack border-0 h-53"
                                            :class="{ 'is-invalid': $v.user_form.dob.$error }"
                                            placeholder="mm/dd/yyyy"
                                        ></b-form-input>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Select Role</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100"  :class="{ 'is_invalid': $v.user_form.user_role.$error }">
                                        <span><i class="fa-solid fa-file"></i></span>
                                        <b-form-select
                                            v-model="user_form.user_role"
                                            :options="roles"
                                            text-field="value"
                                            value-field="id"
                                            id="user_role"
                                            class=""
                                            :class="[ $v.user_form.user_role.$error ? 'is-invalid' : '', user_form.user_role == '' ? 'text-grey' : '' ]"
                                            placeholder="Select a role"
                                        ></b-form-select>
                                    </div>
                                </div>
                                <div class="d-flex mb-1 col-md-6 flex-column" v-if="user_data.role_id == 4">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Select Client</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100"  :class="{ 'is_invalid': $v.user_form.client_id.$error }">
                                        <span><i class="fa-solid fa-user"></i></span>
                                        <b-form-select
                                            v-model="user_form.client_id"
                                            :options="clients"
                                            text-field="value"
                                            value-field="id"
                                            id="client_id"
                                            class=""
                                            :class="[ $v.user_form.client_id.$error ? 'is-invalid' : '', user_form.client_id == '' ? 'text-grey' : '' ]"
                                            placeholder="Select a client"
                                        ></b-form-select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="d-flex mb-1 mt-4 col-md-12 flex-column">
                                    <h3 class="page-subheading mb-3 animate__animated animate__fadeInUp animate__delay-2s text-left">Fill in the password if you want to change, leave empty otherwise</h3>
                                </div>
                            </div>
                            <div class="row">
                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">New Password</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.user_form.password.$error }">
                                        <span><i class="fa-solid fa-lock"></i></span>
                                        <b-form-input
                                            type="password" 
                                            v-model="user_form.password"
                                            id="password" 
                                            class=""
                                            :class="{ 'is-invalid': $v.user_form.password.$error }"
                                            placeholder="s3cretp@ssword"
                                        ></b-form-input>
                                    </div>
                                </div>

                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">New Password Again</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.user_form.password_again.$error }">
                                        <span><i class="fa-solid fa-lock"></i></span>
                                        <b-form-input
                                            type="password" 
                                            v-model="user_form.password_again"
                                            id="password_again" 
                                            class=""
                                            :class="{ 'is-invalid': $v.user_form.password_again.$error }"
                                            placeholder="s3cretp@ssword"
                                        ></b-form-input>
                                    </div>
                                </div>

                            </div>

                            <div
                            class="
                            w-form-control
                            animate__animated animate__fadeInUp animate__delay-3s m-0 p-0
                            "
                            >
                                <a
                                href="javascript:void(0)"
                                @click="handleUserReq"
                                class="flex-fill" 
                                :disabled="!is_valid_user"
                                >Update</a
                                >
                            </div>

                        </b-form>

                    </div>

                </div>

            </div>

        </div>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <AdminFooter type="inner" v-if="user_data.role_id == 3" />
            <SuperAdminFooter type="inner" v-if="user_data.role_id == 4" />
        </div>
        <!-- end of footer section -->

        <!-- start of delete confirm modal -->
        <b-modal id="delete-timecomp-modal" dialog-class="admin-portal-modal" header-class="admin-portal-bg secondary px-3 py-2" hide-footer>
            <template #modal-title>
                Confirm
                <b-icon 
                class="icon" 
                icon="question-circle-fill"
                >
                </b-icon> 
            </template>
            <b-overlay :show="false" rounded="lg">
                <div class="d-flex">
                <p>Are you sure you want to delete selected time entry ?</p>
                </div>
                <div class="d-flex text-center">
                <button
                    type="button"
                    class="modal-button px-5 py-2"
                    @click="deleteTimeComp"
                >
                    Yes
                </button>
                <button
                    type="button"
                    class="modal-button px-5 py-2 mx-2"
                    @click="$bvModal.hide('delete-timecomp-modal')"
                >
                    No
                </button>
                </div>
            </b-overlay>
        </b-modal>

    </div>
</template>

<script>
import AdminHeader from "@/views/admin/AdminHeader.vue";
import { adminEditUser, adminUserSwitches, adminSaveUserCompTime, adminDeleteCompTime, adminUserPayments, adminPlayerPlayingActivities, adminGetClientsList, adminSaveUSer, adminGetRolesList } from "../../services/AdminService";
import AdminFooter from "@/views/admin/AdminFooter.vue";
import SuperAdminHeader from "@/views/superadmin/SuperAdminHeader.vue";
import SuperAdminFooter from "@/views/superadmin/SuperAdminFooter.vue";
import Switches from 'vue-switches';
import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import { required, minLength, requiredIf, sameAs } from 'vuelidate/lib/validators';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';

export default {
    name: "ViewUser-view",
    components: { AdminHeader, AdminFooter, SuperAdminHeader, SuperAdminFooter, Switches, DateRangePicker },
    data() {
        return {
            first_load: true, 
            stream_default: null,
            pro: null,
            user_data: null,
            user: [],
            edit_user_id: this.$route.params.user_id,
            is_valid_user: false,
            time_form: {
                minutes:'',
            },
            emptyText:"No comp times found at the moment!",
            emptyHtml:"No comp times found at the moment!",
            fields: [
                {'key' : 'id', 'label' : 'ID'}, 
                {'key' : 'comp_minutes', 'label' : 'Minutes'},
                {'key' : 'FormattedCreatedAt', 'label' : 'Created At'},
                {'key' : 'note', 'label' : 'Note'},
                {'key' : 'action', 'label' : 'Action'}
            ],
            is_active: null,
            payments: [],
            payments_status_text: '',
            payments_per_page: 10,
            payments_current_page: 1,
            payments_total_rows: 0,
            paymentsEmptyText:"No offers found at the moment!",
            paymentsEmptyHtml:"No offers found at the moment!",
            paymentsFields: [
                {'key' : 'payment_intent', 'label' : 'Payment ID'},
                {'key' : 'offer_time', 'label' : 'Offer Time(mins)'}, 
                {'key' : 'offer_cost', 'label' : 'Offer Cost($)'},
                {'key' : 'FormattedCreatedAt', 'label' : 'Created At'}
            ],
            payments_date : {
                startDate: "",
                endDate: ""
            },
            default_locale: {
                format: 'mm/dd/yyyy'
            },
            payments_api_state: false,
            moment: moment,
            playing_activities: [],
            playing_status_text: '',
            playing_per_page: 10,
            playing_current_page: 1,
            playing_total_rows: 0,
            playingEmptyText:"No activities found at the moment!",
            playingEmptyHtml:"No activities found at the moment!",
            playingFields: [
                {'key' : 'pod_id_key', 'label' : 'Nav ID'}, 
                {'key' : 'PlatformName', 'label' : 'Platform'},
                {'key' : 'game.game_title', 'label' : 'Game Name'},
                {'key' : 'StartAt', 'label' : 'Start At'},
                {'key' : 'EndAt', 'label' : 'End At'},
                {'key' : 'play_time', 'label' : 'Playing Time(mins)'},
            ],
            playing_date : {
                startDate: "",
                endDate: ""
            },
            playing_api_state: false,
            phone_mask: '###-###-####',
            date_mask: '##/##/####',
            clients:[],
            roles: [],
            user_form: {
                edit_id:'',
                client_id:'',
                user_role:'',
                name:'',
                email:'',
                dob:'',
                phone: '',
                password: '',
                password_again: '',
            }
        };
    },

    validations: {
        time_form: {
            minutes:{required}
        },
        user_form : {
            client_id:{
                required: requiredIf(function (params) {
                    params;
                    if(this.user_form.user_role == 4 && this.user_data.role_id == 4) {
                        return false;
                    }
                    return true;  
                })
            },
            user_role:{required},
            name:{
                required: requiredIf(function (params) {
                    params;
                    if((this.user_form.user_role == 4 && this.user_data.role_id == 4) 
                    || (this.user_form.user_role == 2 || this.user_form.user_role == 5)) {
                        return false;
                    }
                    return true;  
                })
            },
            email: {required},
            dob: {
                required: requiredIf(function (params) {
                    params;
                    if((this.user_form.user_role == 4 && this.user_data.role_id == 4) 
                    || (this.user_form.user_role == 2 || this.user_form.user_role == 5)) {
                        return false;
                    }
                    return true;  
                }), 
                minLength: minLength(10)
            },
            phone: {
                required: requiredIf(function (params) {
                    params;
                    if((this.user_form.user_role == 4 && this.user_data.role_id == 4) 
                    || (this.user_form.user_role == 2 || this.user_form.user_role == 5)) {
                        return false;
                    }
                    return true;  
                }), 
                minLength: minLength(12)
            },
            password: {required: false, minLength: minLength(8)},
            password_again: {sameAsPassword: sameAs('password')},
        }
    },

    computed: {
        logo_file() {
            return this.$store.getters.getThemeLogo().logo;
        }
    },

    watch: {
        stream_default: {
            handler: function(value, old) {
                if(old !== null && !this.first_load) {
                    this.updateUserSwitches('stream_default', 'player');
                }
            }
        },
        pro: {
            handler: function(value, old) {
                if(old !== null && !this.first_load) {
                    this.updateUserSwitches('pro', 'player');
                }
            }
        },
        is_active: {
            handler: function(value, old) {
                if(old !== null && !this.first_load) {
                    this.updateUserSwitches('is_active', 'user');
                }
            }
        },
        payments_current_page: {
            handler: function(value) {
                this.payments_current_page = value;
                this.getUserPayments();
            }
        },
        playing_current_page: {
            handler: function(value) {
                this.playing_current_page = value;
                this.getUserPlayingActivities();
            }
        }
    },

    methods: {
        getUserDetails() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                user_id: this.edit_user_id
            };
            adminEditUser(params).then(response => {
                if(response.status) {
                    this.is_valid_user = true;
                    this.user = response.data;
                    this.is_active = this.user.user.is_active;
                    if(this.user.user.user_role_id == 1) {
                        this.stream_default = this.user.player.stream_default;
                        this.pro = this.user.player.pro;
                        this.first_load = false;
                        this.getUserPayments();
                        this.getUserPlayingActivities();
                    }
                    // fill in the edit form
                    let user_details = response.data.extra;
                    let self = this;
                    Object.keys(user_details).forEach(function(key,index) {
                        if(Object.prototype.hasOwnProperty.call(self.user_form, key) && user_details[key] !== "" && user_details[key] !== null) {
                            self.user_form[key] = user_details[key];
                            index;
                        }
                    });
                }
            });
        },
        updateUserSwitches(type, tbl) {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                user_id: this.edit_user_id,
                type : type,
                value: this[type],
                tbl: tbl
            };
            adminUserSwitches(params).then(response => {
                if(response.status){
                    this.user = response.data;
                    this.first_load = true;
                    this.is_active = this.user.user.is_active;
                    if(this.user.user.user_role_id == 1) {
                        this.stream_default = this.user.player.stream_default;
                        this.pro = this.user.player.pro;
                    }
                    setTimeout(() => {
                        this.first_load = false;
                    }, 1000);
                    this.makeToast("success", response.message);
                } 
                else {
                    this.makeToast("error", response.message);
                }
            });
        },
        handleUserTimeCompReq() {
            this.$v.time_form.$touch();
            if (this.$v.time_form.$invalid) {
                this.makeToast("error", "Please fill all required fields");
            } 
            else {
                let user_data = JSON.parse(localStorage.user_sess_data);
                let data = this.time_form;
                data.token = user_data.token;
                data.user_id = this.edit_user_id;
                adminSaveUserCompTime(data).then(response => {
                    if(response.status){
                        this.makeToast("success", response.message);
                        this.$v.time_form.$reset();
                        this.time_form.minutes = '';
                        this.user = response.data;
                    } 
                    else {
                        this.makeToast("error", response.message);
                    }
                }); 
            }
        },
        confirmDeleteTimeComp(id) {
            this.delete_timecomp_id = id;
            this.$bvModal.show('delete-timecomp-modal');
        },
        deleteTimeComp() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                id: this.delete_timecomp_id,
                user_id: this.edit_user_id
            };
            adminDeleteCompTime(params).then(response => {
                this.$bvModal.hide('delete-timecomp-modal');
                if(response.status) {
                    this.$bvModal.hide('delete-timecomp-modal');
                    this.makeToast("success", response.message, 'override');
                    this.user = response.data;
                }
                else {
                    this.makeToast("error", response.message, 'override');
                }
            });
        },
        makeToast(variant = "default", message) {
            this.$root.$emit("toast_message", {'type' : variant, 'message' : message});
        },
        setPodData(obj) {
            this[obj.key] = obj.value;
        },
        setUserData() {
            this.user_data = JSON.parse(localStorage.user_sess_data);
            if(!Object.prototype.hasOwnProperty.call(this.user_data, 'role_id')) {
                this.$router.push("/admin/login");
            }
        }, 
        formatTimeString(time) {
            if(typeof time == 'undefined' || time == 'null' || time == '') {
                return '0m';
            }
            else {
                // convert into hours and minutes
                let hours = Math.floor(time / 60);
                let minutes = Math.ceil(time % 60); 
                if(hours > 0) {
                    return hours + "h " + minutes + "m";
                }
                else {
                    return minutes + "m";
                }
                
            }
        },
        getUserPayments() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                player_id: this.user.player.player_id,
                length: this.payments_per_page,
                page: this.payments_current_page,
                start_date: this.payments_date.startDate,
                end_date: this.payments_date.endDate
            };
            this.payments_api_state = true;
            adminUserPayments(params).then(response => {
                this.payments_api_state = false;
                if(response.status) {
                    this.payments = response.data.payments;
                    this.payments_current_page = response.data.current_page;
                    this.payments_total_rows = response.data.total_rows;
                    this.payments_status_text = response.data.status_text;
                }
            });
        },
        paymentsDateApplied($event) {
            this.payments_date = {
                startDate: moment($event.startDate),
                endDate: moment($event.endDate),
            };
            this.payments_current_page = 1;
            this.payments_total_rows = 0;
            this.getUserPayments();
        },
        clearPaymentDateSelection() {
            this.payments_date = {
                startDate: "",
                endDate: ""
            };
            this.$refs.payments_picker.start = "";
            this.$refs.payments_picker.end = "";
            this.payments_current_page = 1;
            this.payments_total_rows = 0;
            this.getUserPayments();
        },
        getUserPlayingActivities() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                user_id: this.edit_user_id,
                length: this.playing_per_page,
                page: this.playing_current_page,
                start_date: this.playing_date.startDate,
                end_date: this.playing_date.endDate
            };
            this.playing_api_state = true;
            adminPlayerPlayingActivities(params).then(response => {
                this.playing_api_state = false;
                if(response.status) {
                    this.playing_activities = response.data.activities;
                    this.playing_current_page = response.data.current_page;
                    this.playing_total_rows = response.data.total_rows;
                    this.playing_status_text = response.data.status_text;
                }
            });
        },
        playingDateApplied($event) {

            this.playing_date = {
                startDate: moment($event.startDate),
                endDate: moment($event.endDate),
            };
            this.playing_current_page = 1;
            this.playing_total_rows = 0;
            this.getUserPlayingActivities();
        },
        clearPlayingDateSelection() {
            
            this.playing_date = {
                startDate: "",
                endDate: ""
            };
            this.$refs.playing_picker.start = "";
            this.$refs.playing_picker.end = "";
            this.playing_current_page = 1;
            this.playing_total_rows = 0;
            this.getUserPlayingActivities();
        },
        clientsList() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token
            };
            adminGetClientsList(params).then(response => {
                this.clients = response.data.clients;
                // add empty option as placeholder
                this.clients.splice(0, 0, {'id' : '', 'value' : 'Select a client'});
            });
        },
        rolesList() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token
            };
            adminGetRolesList(params).then(response => {
                this.roles = response.data.roles;
                // add empty option as placeholder
                this.roles.splice(0, 0, {'id' : '', 'value' : 'Select a role'});
            });
        },
        handleUserReq() {
            // fill in client id if user is not superadmin
            if(this.user_data.role_id !== 4) {
                this.user_form.client_id = this.user_data.client_id;
            }
            this.$v.user_form.$touch();
            if (this.$v.user_form.$invalid) {
                this.makeToast("error", "Please fill all required fields");
            } 
            else {
                let user_data = JSON.parse(localStorage.user_sess_data);
                let data = this.user_form;
                data.token = user_data.token;
                data.edit_id = this.edit_user_id;
                adminSaveUSer(data).then(response => {
                    if(response.status){
                        this.user_form.password = "";
                        this.user_form.password_again = "";
                        this.makeToast("success", response.message);
                        this.getUserDetails();
                    } 
                    else {
                        this.makeToast("error", response.message);
                    }
                }); 
            }
        },
    },

    mounted() {
        this.getUserDetails();
        this.clientsList();
        this.rolesList();
    },
    created() {
        this.setUserData();
    }
};
</script>
<style scoped>
.flex-rangepicker-heading {
    display: flex;
}
.flex-rangepicker-heading .date-range-picker {
    margin-left: auto;
    display: flex;
}
.flex-rangepicker-heading .date-range-picker .daterange-clear-btn {
    width: auto;
    font-size: 14px;
    border: unset;
    border-radius: 8px;
    margin-left: 5px;
    padding: 0px 10px;
    color: #d8d8d8;
    border: 1px solid var(--client-primary);
    background: transparent;
}
.flex-rangepicker-heading .date-range-picker .daterange-clear-btn:hover {
    border-color: var(--client-primary);
    background: var(--client-primary);
}
.flex-rangepicker-heading .date-range-picker-loader {
    margin-left: auto;
    font-size: 15px;
    display: flex;
    align-items: center;
}
.flex-rangepicker-heading .date-range-picker-loader .spinner-border {
    margin-right: 10px;
}
.admin-portal #admin-general-table {
    user-select: text !important;
}
</style>