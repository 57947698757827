<template>
    <div class="main-layout-section admin-portal secondary">
        <AdminHeader @setPodData="setPodData($event)" />
        
        <div class="w-logo mb-3">
            <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeIn animate__fast" v-if="logo_file !== '' && logo_file !== null"/>
        </div>

        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeIn animate__fast">Manage Your Games</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">

                    <div class="admin-general-section-buttons">
                        <router-link to="/admin/dashboard" class="admin-general-section-button">
                            Back To Dashboard
                        </router-link>

                        <button
                        type="button"
                        class="admin-general-section-button"
                        @click="openGamesModal"
                        >
                        Assign Games
                        </button>
                    </div>

                    <div class="col-sm-12">
                        <div class="overflow-auto p-0 m-0">
                            <b-table
                            id="admin-general-table"
                            :striped="true"
                            :hover="false"
                            :dark="false"
                            :items="assigned_games"
                            :fields="fields"
                            show-empty
                            :emptyText="emptyText"
                            >
                                <template #cell(action)="data">
                                    <button 
                                    class=" btn-sm icon-button"
                                    v-if="data.item.GameType.toLowerCase() == 'local'"
                                    @click="assignGames('del', data.item.GameId)"
                                    >
                                        <b-icon class="icon" :icon="data.value"></b-icon>
                                        Delete
                                    </button>
                                </template>

                                <template #empty="scope">
                                    <p class="text-center p-0 m-0">{{ scope.emptyText }}</p>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <AdminFooter type="inner" />
        </div>
        <!-- end of footer section -->
        
        <!-- start of games modal -->
        <b-modal id="games-modal"  dialog-class="admin-portal-modal" header-class="admin-portal-bg secondary px-3 py-2" size="xl" hide-footer>
            <template #modal-title>
            Assign Games
            </template>
            <b-overlay :show="api_state" rounded="lg">
                <div class="modal-games-search col-md-12 ml-auto">
                    <b-form-input
                    type="text"
                    placeholder="Type the name of the game"
                    class="search-box h-53"
                    autocomplete="off"
                    v-model="gameSearchQuery"
                    ></b-form-input>

                    <b-form-select
                    v-model="selected_lab_id"
                    :options="labs"
                    text-field="value"
                    value-field="id"
                    id="lab_id"
                    class=""
                    placeholder="Select a lab"
                    ></b-form-select>
                </div>

                <div class="modal-games-list row m-0 p-0 col-md-12 m-auto" v-if="filteredGames.length > 0">
                    <div class="modal-game-list-item col-lg-4 col-md-4 col-sm-12 mb-4" v-for="game in filteredGames" :key="game.GameId">
                        <div class="modal-game-list-item-container">
                            <div class="modal-game-list-item-title d-flex">
                                {{ game.GameName }} <b style="margin-left: 5px;">
                                    <small>
                                        <span v-for="(pt, index) in game.PlatformsFE" :key="index">
                                            {{ (pt !== "" && Object.prototype.hasOwnProperty.call(xds_icons, pt)) ? xds_icons[pt].text.split(" ")[1] : "" }}
                                        </span>
                                    </small>
                                </b>
                                <div class="modal-game-list-item-title-check" v-if="assigned_games_ids.indexOf(game.GameId) == -1 && game.GameType.toLowerCase() == 'local'">
                                    <i 
                                    class="fas fa-circle-check fa-2xl icon icon-checked" 
                                    @click="selectGame(game)"
                                    v-if="selected_games.indexOf(game.GameId) !== -1"
                                    >
                                    </i>
                                    <i 
                                    class="fas fa-circle-check fa-2xl icon" 
                                    @click="selectGame(game)"
                                    v-if="selected_games.indexOf(game.GameId) == -1"
                                    >
                                    </i>
                                </div>
                                <div class="modal-game-list-item-title-check" v-else-if="game.GameType.toLowerCase() !== 'local'">
                                    <i class="fa-solid fa-lock fa-2xl icon"></i>
                                </div>
                                <div class="modal-game-list-item-title-check" v-else-if="assigned_games_ids.indexOf(game.GameId) !== -1">
                                    <i 
                                    class="fas fa-circle-check fa-2xl icon icon-checked" 
                                    >
                                    </i>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="games-list row m-0 p-0 px-1 col-sm-12 m-auto" v-if="filteredGames.length <= 0">
                    <p class="text-left h5 no-results">No games found at the moment!</p>
                </div>
                
                <div class="modal-games-buttons col-md-12 ml-auto mt-3" v-if="filteredGames.length > 0 && selected_games.length > 0">
                    <button
                    type="button"
                    class="mr-3"
                    @click="assignGames('add')"
                    >
                    Add Game
                    </button>
                </div>

            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
import AdminHeader from "@/views/admin/AdminHeader.vue";
import { adminGetGamesList, adminSaveGameIds, adminGetLabsList } from "../../services/AdminService";
import AdminFooter from "@/views/admin/AdminFooter.vue";
import { global_config } from "@/config/config.js";

export default {
  name: "AdminGames-view",
  components: { AdminHeader, AdminFooter },
  data() {
    return {
        emptyText:"No assigned games found at the moment!",
        fields: [
            {'key' : 'GameId', 'label' : 'ID'}, 
            {'key' : 'GameKey', 'label' : 'Key'},
            {'key' : 'GameName', 'label' : 'Name'}, 
            {'key' : 'GameGenre', 'label' : 'Genre'}, 
            {'key' : 'GamePlatform', 'label' : 'Platform'}, 
            {'key' : 'action', 'label' : 'Action'}
        ],
        games: [],
        assigned_games: [],
        assigned_games_ids: [],
        gameSearchQuery:'',
        selected_games:[],
        api_state: false,
        labs: [],
        selected_lab_id: '',
        xds_icons: global_config.XD_ICONS,
    };
  },

  computed: {
    filteredGames () {
        if(this.gameSearchQuery) {
            return this.games.filter(item => {
                return item.GameName.toLowerCase().includes(this.gameSearchQuery.toLowerCase())
            });
        }
        else {
            return this.games;
        }
    },
    logo_file() {
        return this.$store.getters.getThemeLogo().logo;
    }
  },

  methods: {
    gamesList(type="assigned") {
        let user_data = JSON.parse(localStorage.user_sess_data);
        if(type !== "assigned") {
            let params = {
                token: user_data.token,
                client_id: 0
            };
            adminGetGamesList(params).then(response => {
                this.games = response.data.games;
            }); 
        }
        else {
            let params = {
                token: user_data.token,
                client_id: user_data.client_id
            };
            adminGetGamesList(params).then(response => {
                this.assigned_games = response.data.games;
                this.assigned_games_ids = [];
                if(this.assigned_games.length > 0) {
                let self = this;
                this.assigned_games.map(function(cl) {
                    self.assigned_games_ids.push(cl.GameId);
                    return cl.action='trash-fill';
                });
                }
            });
        }
    },

    openGamesModal() {
        this.$bvModal.show('games-modal');
    },

    selectGame(game) {
        if(this.selected_games.indexOf(game.GameId) !== -1) {
            this.selected_games.splice(this.selected_games.indexOf(game.GameId), 1);
        }
        else {
            this.selected_games.push(game.GameId);
        }
    },

    assignGames(type, game_id = "") {
        this.api_state = true;
        if(game_id !== "") {
            this.selected_games = [game_id];
        }
        let user_data = JSON.parse(localStorage.user_sess_data);
        let params = {
            token: user_data.token,
            client_id: user_data.client_id,
            lab_id: this.selected_lab_id,
            pod_id: '',
            game_ids: this.selected_games
        };
        adminSaveGameIds(params, type).then(response => {
            this.api_state = false;
            if(response.status) {
                this.makeToast("success", response.message);
                this.selected_games = [];
                this.$bvModal.hide('games-modal');
                this.gamesList();
            }
            else {
                this.mute_state = !this.mute_state;
                this.makeToast("error", response.message);
            }
        })
    },

    makeToast(variant = "default", message) {
        // this.$toast.open({
        //     message: message,
        //     type: variant,
        //     position: 'top-right',
        //     duration: 3000,
        //     dismissible: true
        // });
        this.$root.$emit("toast_message", {'type' : variant, 'message' : message});
    },
    setPodData(obj) {
        this[obj.key] = obj.value;
    },
    labsList() {
        let user_data = JSON.parse(localStorage.user_sess_data);
        let params = {
            token: user_data.token,
            client_id: user_data.client_id
        };
        adminGetLabsList(params).then(response => {
            this.labs = response.data.labs;
            // add empty option as placeholder
            this.labs.splice(0, 0, {'id' : '', 'value' : 'All Labs'});
        });
    },
  },

  mounted() {
    this.gamesList();
    this.gamesList('all');
    this.labsList();
  }

};
</script>