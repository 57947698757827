import { render, staticRenderFns } from "./MainKeyboard.vue?vue&type=template&id=383b4187&scoped=true&"
import script from "./MainKeyboard.vue?vue&type=script&lang=js&"
export * from "./MainKeyboard.vue?vue&type=script&lang=js&"
import style0 from "./MainKeyboard.vue?vue&type=style&index=0&id=383b4187&prod&scoped=true&lang=css&"
import style1 from "./MainKeyboard.vue?vue&type=style&index=1&id=383b4187&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "383b4187",
  null
  
)

export default component.exports