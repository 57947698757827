<template>
    <div></div>
</template>
<script>
    import { playFlaskGame, getFlaskGameStarted, getPodId, setFlaskRebootPod, setFlaskLED1, setFlaskLED2, getPodLogoutReason, getPodLogoutAcknowledge } from "../../services/FlaskService";
    import { global_config } from "@/config/config.js";
    import store from "@/store";

    export default {
        name: "AdminPollingAndSockets-view",
        data() {
            return {
                game_init: false,
                game_startup_duration: global_config.GAME_STARTUP_DURATION,
                game_polling: null,
                logout_polling : null,
            };
        },
        methods: {
            gameStartInitiated() {
                this.game_init = true;
                this.game_startup_duration = 0;
                clearTimeout(this.game_polling);
                this.game_polling = setInterval(() => {
                    getFlaskGameStarted().then(gs => {
                        if(gs.success && gs.data !== undefined && typeof gs.data.game_started !== undefined) {
                            if(!gs.data.game_started) {
                                if(this.game_startup_duration == global_config.GAME_STARTUP_DURATION) {
                                    this.$root.$emit("game_message", {'type' : 'fail'});
                                }
                            }
                            else {
                                clearInterval(this.game_polling);
                                this.game_init = false;
                                this.$root.$emit("game_message", {'type' : 'success'});
                            }
                        }
                        else {
                            if(this.game_startup_duration == global_config.GAME_STARTUP_DURATION) {
                                this.$root.$emit("game_message", {'type' : 'fail'});
                            }
                        }
                    });
                    this.game_startup_duration += 3;
                }, process.env.VUE_APP_POLLING_TIME_POD_GAME_STARTED_CHECK);
            },

            playGame(gid, search, platform, install=null) {
                let params = {
                    platform: platform,
                    select_type: install,
                    search_title: search
                };
                playFlaskGame(params).then(response => {
                    response;
                    this.$root.$emit("game_message", {'type' : 'init'});
                    params['game_id'] = gid;
                    this.gameStartInitiated();
                })
            },
            pollFlaskLogout () {
                this.logout_polling = setInterval(() => {
                    // get logout status
                    getPodLogoutReason().then(logout_reason => {
                        if(logout_reason.data.logout !== undefined && logout_reason.data.logout == 1 
                        && logout_reason.data.logout_reason !== undefined && logout_reason.data.logout_reason.length > 0) {
                            getPodLogoutAcknowledge().then(logout_acknowledge => {
                                if(logout_acknowledge.success !== undefined && logout_acknowledge.success) {
                                    let reason = "";
                                    if(logout_reason.data.logout_reason.toLowerCase() == 'button') {
                                        reason = "You pushed the physical logout button";
                                        this.$root.$emit("admin_logout", {'poll_logout': { 'reason' : reason}});
                                    }
                                    else if(logout_reason.data.logout_reason.toLowerCase() == 'seat') {
                                        reason = "You left your seat for more than 30 seconds";
                                        this.$root.$emit("admin_logout", {'poll_logout': { 'reason' : reason}});
                                    }
                                }
                            });
                        }
                    });
                }, process.env.VUE_APP_POLLING_TIME_POD_LOGOUT_REASON_CHECK);
            }
        },
        computed: {
            getMessage() {
                return store.getters.getMessage;
            },
        },
        mounted() {
            // check if channel already subscribed
            if(!Object.prototype.hasOwnProperty.call(window.Echo.connector.channels, "admin-pod-command-loggedin")) {
                // listen for pod schedule event
                window.Echo.channel('admin-pod-command-loggedin').listen('AdminPodCommandLoggedinEvent', (e) => {
                    

                    getPodId().then((pod) => {
                        if (
                            Object.prototype.hasOwnProperty.call(pod, "success") &&
                            pod.success &&
                            Object.prototype.hasOwnProperty.call(pod, "data") &&
                            Object.prototype.hasOwnProperty.call(pod.data, "pod_id") &&
                            pod.data.pod_id.length > 0
                        ) {
                            if(
                            e.message.data.pod_ids.indexOf(pod.data.pod_id) !== -1 && 
                            e.message.server_info == process.env.VUE_APP_WS_ENV
                            ) {
                                let command_type = e.message.data.command_type;
                                if(typeof localStorage.pod_sess_data !== 'undefined') {
                                    if(typeof localStorage.user_sess_data !== 'undefined') {
                                        let pod_data = JSON.parse(localStorage.pod_sess_data);
                                        if(command_type == 'admin_play_game') {
                                            e.message.data['command_pod'] = pod_data.pod_id_key;
                                            if(this.$route.name !== 'Admin Pod Games') {
                                                this.$router.push({ name: "Admin Pod Games", params: {'admin_websocket': e.message.data, 'xd' : e.message.data.game_platform}});
                                            }
                                            else {
                                                this.$root.$emit("admin_play_game", {'admin_websocket': e.message.data, 'xd' : e.message.data.game_platform});
                                            }
                                        }
                                        else if(command_type == 'save_seat') {
                                            if(this.$route.name !== 'Admin Dashboard') {
                                                this.$router.push({ name: "Admin Dashboard", params: {'admin_websocket': e.message.data}});
                                            }
                                            else {
                                                this.$root.$emit("admin_save_seat", {'admin_websocket': e.message.data});
                                            }
                                        }
                                        else if(command_type == 'reboot') {
                                            setFlaskRebootPod().then(reb => {
                                                console.log("REBOOT POD API IS CALLED : ", reb);
                                            });
                                            this.$root.$emit("admin_logout", {'admin_websocket': e.message.data});
                                        }
                                        else if(command_type == 'logout') {
                                            this.$root.$emit("admin_logout", {'admin_websocket': e.message.data});
                                        }
                                        else if(command_type == 'led1_lock' || command_type == 'led2_lock') {
                                            if(command_type == 'led1_lock') {
                                                let params1 = {
                                                    led1_lock: e.message.data.command_value
                                                };
                                                setFlaskLED1(params1, 'lock').then(lc => {
                                                    console.log("LED1 CHANGE FLASK API CALLED : ", lc);
                                                });
                                            }
                                            else if(command_type == 'led2_lock') {
                                                let params1 = {
                                                    led2_lock: e.message.data.command_value
                                                };
                                                setFlaskLED2(params1, 'lock').then(lc => {
                                                    console.log("LED2 CHANGE FLASK API CALLED : ", lc);
                                                });
                                            }
                                        }
                                        else if (command_type == 'logout_fac') {
                                            localStorage.removeItem('host_websocket');
                                            localStorage.removeItem('host_websocket_command');
                                            let command_data = e.message.data;
                                            command_data.logout_message = "Host forced the logout from kioske";
                                            this.$root.$emit("admin_logout", {'admin_websocket': command_data});
                                        }
                                        else if (
                                            command_type == 'login_fac' || 
                                            command_type == 'save_seat_fac' || 
                                            command_type == 'fac_play_game'
                                        ) {
                                            localStorage.setItem('host_websocket', JSON.stringify(e.message.data));
                                            if(command_type == 'login_fac') {
                                                localStorage.removeItem('host_websocket_command');
                                            }
                                            else {
                                                localStorage.setItem('host_websocket_command', JSON.stringify(e.message.data));
                                            }
                                            let command_data = e.message.data;
                                            command_data.command_type = "logout_fac";
                                            command_data.logout_message = "Host forced the logout from kioske";
                                            this.$root.$emit("admin_logout", {'admin_websocket': command_data});
                                        }
                                    }
                                }
                            }
                        }
                    });
                });
            }
        },
        created () {
            clearInterval(this.logout_polling);
            this.pollFlaskLogout();
        },
        beforeDestroy () {
            clearInterval(this.logout_polling);
        },
};
</script>