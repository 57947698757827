<template>
    <div class="main-layout-section admin-portal secondary">
        <AdminHeader @setPodData="setPodData($event)" />
        
        <div class="w-logo mb-3">
            <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeIn animate__fast" v-if="logo_file !== '' && logo_file !== null"/>
        </div>

        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeIn animate__fast">Manage Your Reservations</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">

                    <div class="admin-general-section-buttons">
                        <router-link to="/admin/dashboard" class="admin-general-section-button">
                            Back To Dashboard
                        </router-link>
                        <router-link :to="`/admin/reservations/create`" class="admin-general-section-button">
                            Create New Reservation
                        </router-link>
                    </div>

                    <div class="col-sm-12">
                        <div class="overflow-auto p-0 m-0">
                            <b-table
                            id="admin-general-table"
                            :striped="true"
                            :hover="false"
                            :dark="false"
                            :items="reservations"
                            :fields="fields"
                            show-empty
                            :emptyText="emptyText"
                            >
                                <template #cell(start_time)="data">
                                    {{ data.item.reservation_start.date + " " + data.item.reservation_start.time }}
                                </template>
                                <template #cell(stop_time)="data">
                                    {{ data.item.reservation_end.date + " " + data.item.reservation_end.time }}
                                </template>
                                <template #cell(action)="data">
                                    <div class="icon-action-buttons-container d-flex flex-row">

                                        <!-- edit button -->
                                        <router-link :to="`/admin/reservations/edit/${data.item.id}`" :class="data.value" class="btn-sm">
                                            <b-icon class="icon" icon="pencil-fill"></b-icon>
                                            Edit
                                        </router-link>
                                        
                                        <!-- delete button -->
                                        <button 
                                        class="btn-danger btn-sm" 
                                        :class="data.value"
                                        @click="confirmDelete(data.item.id)"
                                        >
                                        <b-icon class="icon" icon="trash2-fill"></b-icon>
                                        Delete
                                        </button>
                                    </div>
                                </template>
                                <template #empty="scope">
                                    <p class="text-center p-0 m-0">{{ scope.emptyText }}</p>
                                </template>
                            </b-table>
                        </div>
                        <b-pagination
                        v-model="current_page"
                        :total-rows="total_rows"
                        :per-page="per_page" 
                        limit="3"
                        aria-controls="admin-general-table" 
                        align="right"
                        ></b-pagination>
                        <p class="admin-general-table-status-text">{{ status_text }}</p>
                    </div>
                </div>

            </div>

        </div>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <AdminFooter type="inner" />
        </div>
        <!-- end of footer section -->
        
        <!-- start of delete confirm modal -->
        <b-modal id="delete-modal" dialog-class="admin-portal-modal" header-class="admin-portal-bg secondary px-3 py-2" hide-footer>
            <template #modal-title>
                Confirm
                <b-icon 
                class="icon" 
                icon="question-circle-fill"
                >
                </b-icon> 
            </template>
            <b-overlay :show="false" rounded="lg">
                <div class="d-flex">
                <p>Are you sure you want to permanently delete this Reservation?</p>
                </div>
                <div class="d-flex text-center">
                <button
                    type="button"
                    class="modal-button px-5 py-2"
                    @click="deleteReservation"
                >
                    Yes
                </button>
                <button
                    type="button"
                    class="modal-button px-5 py-2 mx-2"
                    @click="$bvModal.hide('delete-modal')"
                >
                    No
                </button>
                </div>
            </b-overlay>
        </b-modal>

    </div>
</template>

<script>
import AdminHeader from "@/views/admin/AdminHeader.vue";
import { adminGetReservationsList, adminDeleteReservation } from "../../services/AdminService";
import AdminFooter from "@/views/admin/AdminFooter.vue";

export default {
    name: "AdminReservations-view",
    components: { AdminHeader, AdminFooter },
    data() {
        return {
            emptyText:"No reservations found at the moment!",
            emptyHtml:"No reservations found at the moment!",
            fields: [
                {'key' : 'linkedlab.lab_name', 'label' : 'Lab'},
                {'key' : 'start_time', 'label' : 'Start Time'}, 
                {'key' : 'stop_time', 'label' : 'Stop Time'},
                {'key' : 'pod_navs', 'label' : 'Navs'}, 
                {'key' : 'emails_list', 'label' : 'Emails'},
                {'key' : 'group_name', 'label' : 'Group Name'},
                {'key' : 'action', 'label' : 'Action'}
            ],
            reservations: [],
            delete_reservation_id: "",
            status_text: '',
            per_page: 10,
            current_page: 1,
            total_rows: 0,
        };
    },

    computed: {
        logo_file() {
            return this.$store.getters.getThemeLogo().logo;
        }
    },

    watch: {
        current_page: {
            handler: function(value) {
                this.current_page = value;
                this.reservationsList();
            }
        }
    },

    methods: {
        reservationsList() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                client_id: user_data.client_id,
                length: this.per_page,
                page: this.current_page,
            };
            adminGetReservationsList(params).then(response => {
                this.reservations = response.data.reservations;
                this.current_page = response.data.current_page;
                this.total_rows = response.data.total_rows;
                this.status_text = response.data.status_text;
                if(this.reservations.length > 0) {
                    this.reservations.map(function(lb){
                        return lb.action='icon-button-actions';
                    });
                }
            });
        },

        confirmDelete(res_id) {
            this.delete_reservation_id = res_id;
            this.$bvModal.show('delete-modal');
        },

        deleteReservation() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                reservation_id: this.delete_reservation_id
            };
            adminDeleteReservation(params).then(response => {
                if(response.status) {
                    this.$bvModal.hide('delete-modal');
                    this.makeToast("success", response.message);
                    this.reservationsList();
                }
                else {
                    this.makeToast("error", response.message);
                }
            });
        },

        makeToast(variant = "default", message) {
            this.$root.$emit("toast_message", {'type' : variant, 'message' : message});
        },

        setPodData(obj) {
            this[obj.key] = obj.value;
        },
    },

    mounted() {
        this.reservationsList();
        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            if(modalId == "delete-modal") {
                this.delete_reservation_id = "";
            }
        })
    }

};
</script>
<style scoped>
.admin-portal #admin-general-table {
    user-select: text !important;
}
.admin-portal #admin-general-table .pod-key-container {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    align-items: center;
}
</style>