<template>
    <div class="main-contenttt">
        <div class="overlay"></div>

        <div class="rss-feed-content">
            <h4 class="page-heading animate__animated animate__fadeIn">Upcoming reservation!</h4>
            <h6 class="page-subheading mb-1 animate__animated animate__fadeIn">You will be logged out automatically at {{ reservation_data.start_time }}</h6>

            <div class="general-buttons-container animate__animated animate__fadeIn">
                <div class="general-buttons animate__animated animate__fadeIn mt-4">
                    <button class="general-button flex-fill" @click="handleUnderstand">
                        I Understand
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "ReservationWidget-view",
    data() {
        return {
            reservation_data: this.reservation
        };
    },
    props: ['reservation'],
    methods: {
        handleUnderstand() {
            this.$emit('reservationAcknowledged', true);
        }
    },
};
</script>
<style scoped>
.main-contenttt {
    width: 100%;
    height: 100vh;
    z-index: 1000;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    backdrop-filter: blur(30px);
    background: transparent;
}
.main-contenttt .rss-feed-content {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.main-contenttt .overlay {
    position: absolute;
    background: black;
    width: 100%;
    left: 0;
    height: 100%;
    opacity: 0.55;
}
.main-contenttt h4,
.main-contenttt h6 {
    color: #d8d8d8;
}
.main-contenttt .general-buttons-container {
    width: max-content;
}
.main-contenttt .general-buttons-container .general-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 25px;
    row-gap: 15px;
}
.main-contenttt .general-buttons .general-button {
    border: 2px solid var(--client-primary);
    background-color: transparent;
    background: transparent;
    padding: 15px 10px;
    border-radius: 8px;
    display: inline-block;
    text-decoration: none;
    color: #fff;
    font-family: sans-serif;
    font-size: 16px;
    flex: calc(100%/2 - 25px);
    outline: none;
}
.main-contenttt .general-buttons .general-button:hover,
.main-contenttt .general-buttons .general-button:focus,
.main-contenttt .general-buttons .general-button:active {
  border: 2px solid var(--client-primary);
  background: var(--client-primary);
}
div.timer {
    position: relative;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    /* overflow: hidden; */
    color: #d8d8d8;
    border-radius: 50%;
    font-weight: 700;
    font-family: 'Open Sans';
    animation: 60s 0.875s cubic-bezier(0.9, 0, 0.1);
}

div.timer_with_kb {
    width: 150px;
    height: 150px;
    font-size: 70px;
    border: 2px solid var(--client-primary);
    margin-top: 25px;
}

@keyframes timer_beat {
    40%,
    80% {
        transform: none; }
    50% {
        transform: scale(1.125);
    }
}
div.timer:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -100;
    width: 100%;
    height: 100%;
    animation: 60s 1s linear forwards timer_indicator;
}

@keyframes timer_indicator {
    100% {
        transform: translateY(100%);
    }
}
</style>