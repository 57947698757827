<template>
  <!-- new view -->
  <section class="blue register-page">  
    <AllTheme />
    <MainKeyboard :main_element="current_component" />

    <div class="smaller-screen-header">
        <div class="smaller-screen-header-logo">
            <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s"
                v-if="logo_file !== '' && logo_file !== null"
            />
        </div>
        <div v-if="nav_name !== ''" class="w-form-control pod-info-nav animate__animated animate__fadeInUp animate__delay-1s smaller-screen-header-nav">
            <span class="pod-info-nav-name">
                {{ nav_name }}
            </span>
        </div>
    </div>

    <div class="w-logo">
        <img :src="logo_file" alt="logo" class="larger-screen-header w-logo w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="logo_file !== '' && logo_file !== null"/>
        
        <h2 class="page-heading mt-3 animate__animated animate__fadeInUp animate__delay-2s" v-if="!is_registered1 || !is_registered">Sign-Up</h2>
        <h3 class="page-subheading animate__animated animate__fadeInUp animate__delay-2s" v-if="!is_registered1 || !is_registered">Complete each field to continue.</h3>
        <h2 class="page-heading mt-5 animate__animated animate__fadeIn animate__delay-2s" v-if="is_registered1 && is_registered">Thanks for signing up!</h2>
        <h3 class="page-subheading mb-1 animate__animated animate__fadeIn animate__delay-2s" v-if="is_registered1 && is_registered">Redirecting you to the login...</h3>
    </div>
    <div class="w-form-block mb-0 mt-5" v-if="!is_registered1 || !is_registered" :class="[is_registered ? 'animate__animated animate__fadeOutDown' : '']">

        <!-- flas error section -->
        <div class="w-login-show animate__animated animate__fadeInUp animate__delay-3s" v-if="!ip_verified && !falsk_server">
          <div class="notification-block error">
            <div class="notification-message">
                <h5>{{ messages.flask_error }}</h5>
                <p>{{ messages.flask_error }}</p>
            </div>                
            <div class="icon-error"><i class="fa-solid fa-times-circle"></i></div>
          </div>
        </div>

        <!-- pod deployment section -->
        <div class="main-action-box" v-if="!ip_verified && falsk_server">
          <div class="w-form-control animate__animated animate__fadeInUp animate__delay-3s">
              <p v-if="!flask_pod_need_deployment" v-html="messages.ip_not_allowed"></p>
              <p v-if="flask_pod_need_deployment" v-html="messages.pod_need_deployment"></p>
          </div>
          <div class="w-form-control animate__animated animate__fadeInUp animate__delay-3s">
              <router-link to="/admin/login">Go to Deployment</router-link>
          </div>
        </div>

        <!-- login form section -->
        <form action="" class="" v-if="ip_verified && step1 && !step2">
            <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Email</label>
            <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s mb-3" :class="{ 'is_invalid': $v.form1.user_name.$error }">
                <span><i class="fa-solid fa-user"></i></span>
                <input type="text" name="email" ref="emailinput" value="" v-model="form1.user_name" class="form-control" :class="{ 'is-invalid': $v.form1.user_name.$error }"
            placeholder="email@domain.com" autofocus @focus="setFocusedElement($event, 'user_name', 'form1')" />
            </div>

            <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Password</label>
            <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s mb-3" :class="{ 'is_invalid': $v.form1.password.$error }">
                <span><i class="fa-solid fa-lock"></i></span>
                <input type="password" name="password" ref="passinput" value="" v-model="form1.password" placeholder="s3cretp@ssword" class="form-control" :class="{ 'is-invalid': $v.form1.password.$error }" @focus="setFocusedElement($event, 'password', 'form1')" />
            </div>
            <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Password(Again)</label>
            <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s mb-3" :class="{ 'is_invalid': $v.form1.password_again.$error }">
                <span><i class="fa-solid fa-lock"></i></span>
                <input type="password" name="password_again" ref="conpassinput" value="" v-model="form1.password_again" placeholder="s3cretp@ssword" class="form-control" :class="{ 'is-invalid': $v.form1.password_again.$error }" 
                @keyup.enter="handleEnterPress('continue')" 
                @focus="setFocusedElement($event, 'password_again', 'form1')"
                />
            </div>
            <div class="main-action-box w-login-hide">
            <div class="w-form-control animate__animated animate__fadeInUp animate__delay-3s" :class="remote_id !== '' ? 'forgot-page' : ''">
                
                <template v-if="remote_id !== ''">
                    <router-link :to="`/mobile-login/${remote_id}`" class="flex-fill" v-if="remote_id !== ''">Back</router-link>
                    <a href="javascript:void(0)" @click="handleFirstStep" class="flex-fill" style="min-width: 156.47px;max-width: 156.47px;width: 156.47px;" v-if="!step1_checking && remote_verified">Continue</a>
                    <button class="flex-fill" disabled="disabled" style="min-width: 156.47px;max-width: 156.47px;width: 156.47px;" v-if="!step1_checking && !remote_verified">Continue</button>
                </template>
                <template v-else>
                    <router-link to="/" class="flex-fill" v-if="remote_id == ''">Back</router-link>
                    <a href="javascript:void(0)" @click="handleFirstStep" class="flex-fill" style="min-width: 156.47px;max-width: 156.47px;width: 156.47px;" v-if="!step1_checking">Continue</a>
                </template>
                <a href="javascript:void(0)" class="flex-fill" style="min-width: 156.47px;max-width: 156.47px;width: 156.47px;" v-if="step1_checking">Processing...</a>
            </div>
            </div>
            <div class="w-login-show">
                <div class="notification-block">
                </div>
            </div>
        </form>

        <form action="" class="" v-if="ip_verified && step1 && step2">
            <label for="" class="form-main-label animate__animated animate__fadeInUp animate__faster w-100">First Name</label>
            <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__faster mb-3" :class="{ 'is_invalid': $v.form2.first_name.$error }">
                <span><i class="fa-solid fa-user"></i></span>
                <input type="text" name="first_name" ref="nameinput" value="" v-model="form2.first_name" class="form-control" :class="{ 'is-invalid': $v.form2.first_name.$error }"
                placeholder="name" @focus="setFocusedElement($event, 'first_name', 'form2')" />
            </div>

            <label for="" class="form-main-label animate__animated animate__fadeInUp animate__faster w-100">Mobile Number</label>
            <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__faster mb-3" :class="{ 'is_invalid': $v.form2.phone.$error }">
                <span><i class="fa-solid fa-phone"></i></span>
                <input type="text" name="phone" value="" v-mask="phone_mask" v-model="form2.phone" class="form-control" :class="{ 'is-invalid': $v.form2.phone.$error }"
                placeholder="555-555-5555" @focus="setFocusedElement($event, 'phone', 'form2')" />
            </div>

            <label for="" class="form-main-label animate__animated animate__fadeInUp animate__faster w-100">Date of Birth</label>
            <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__faster overflow-visible mb-3" :class="{ 'is_invalid': $v.form2.dob.$error }">
                <span><i class="fa-solid fa-calendar"></i></span>
                <input type="text" name="phone" value="" v-mask="date_mask" v-model="form2.dob" class="form-control" :class="{ 'is-invalid': $v.form2.dob.$error }"
                placeholder="mm/dd/yyyy" @keyup.enter="handleEnterPress('signup')" @focus="setFocusedElement($event, 'dob', 'form2')" />
                <!-- <b-form-datepicker
                v-model="form2.dob"
                id="dob"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                :class="[ $v.form2.dob.$error ? 'is-invalid' : '', form2.dob == '' ? 'text-grey' : '' ]"
                placeholder="Please select DOB"
                ></b-form-datepicker> -->
            </div>
            <div class="main-action-box w-login-hide">
                <div class="w-form-control animate__animated animate__fadeInUp animate__fast">
                    <a href="javascript:void(0)" @click="handleBackStep" class="flex-fill">Back</a>
                    <a href="javascript:void(0)" @click="handleRegisterReq" class="flex-fill" style="min-width: 156.47px;max-width: 156.47px;width: 156.47px;" v-if="!step2_checking">Sign-Up</a>
                    <a href="javascript:void(0)" class="flex-fill" style="min-width: 156.47px;max-width: 156.47px;width: 156.47px;" v-if="step2_checking">Processing...</a>
                </div>
            </div>
            <div class="w-login-show">
                <div class="notification-block">
                </div>
            </div>
        </form>
    </div>

    <!-- register success section -->
    <div class="main-content register-complete mt-5" style="min-height: 410px;" v-if="is_registered1 && is_registered">
        <!-- start of buttons section -->
        <div class="dashboard-buttons-container col-md-12">
                <div class="logout-loading-image mx-auto">
                    <object type="image/svg+xml" :data="require(`../../assets/images/gamelab-bits.svg`)" class="animate__animated animate__fadeIn animate__delay-2s"></object>
                </div>
        </div>
        <!-- end of buttons section -->
    </div>

    <!-- error container -->
    <div class="w-login-show mb-5 notification-container w-320 move-to-header">
        <div class="notification-block error animate__animated animate__fadeInUp w-320" v-if="($v.form2.first_name.$error && !$v.form2.first_name.required) || ($v.form2.last_name.$error && !$v.form2.last_name.required) || ($v.form1.user_name.$error && !$v.form1.user_name.required) || ($v.form1.password.$error && !$v.form1.password.required) || ($v.form2.phone.$error && !$v.form2.phone.required && $v.form2.phone.minLength) || ($v.form2.dob.$error && !$v.form2.dob.required && $v.form2.dob.minLength)">
            <div class="notification-message w-270">
                <h5>{{ messages.required_message }}</h5>
                <p>{{ messages.register_error_message }}</p>
            </div>                
            <div class="icon-error"><i class="fa-solid fa-times-circle"></i></div>
        </div>
        <div class="notification-block animate__animated animate__fadeInUp w-320" :class="noti_class" v-if="noti_class !== '' && noti_message !== ''">
            <div class="notification-message w-270">
                <h5>{{ noti_message }}</h5>
                <p>{{ noti_message1 !== '' ? noti_message1 : noti_message }}</p>
            </div>                
            <div class="icon-success" v-if="noti_class == 'success'"><i class="fa-solid fa-circle-check"></i></div>
            <div class="icon-error" v-if="noti_class == 'error'"><i class="fa-solid fa-times-circle"></i></div>
            <div class="icon-warning" v-if="noti_class == 'warning'"><i class="fa-solid fa-exclamation-circle"></i></div>
        </div>
    </div>
    <PlayerFooter type="" class="animate__animated animate__fadeIn animate__delay-3s outer-footer"/>
  </section>
</template>

<script>
import store from "@/store";
import { validateUseragentAndIP, validateKioskPodId, userRegister, userCheckEmail, validateRemoteQR, sessionActivitySave } from "../../services/HomepageService";
import qr_code from "../../assets/images/qr_code.jpg";
import { getPodId, checkFlaskPodUser } from "../../services/FlaskService";
import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import { required, sameAs, minLength } from 'vuelidate/lib/validators';
import { ToastPlugin } from 'bootstrap-vue';
Vue.use(ToastPlugin);
// import Switches from 'vue-switches';
import { global_config } from "@/config/config.js";
import AllTheme from "@/components/themes/AllTheme.vue";
import PlayerFooter from "@/views/player/PlayerFooter.vue";
import { handlePushMenuPolling } from "../../services/Guard";

export default {
  name: "register-view",
  components: { AllTheme, PlayerFooter },

  data() {
    return {
        form1: {
            user_name: "",
            email: "",
            password: "",
            password_again: "",
        },
        form2: {
            dob:"",
            phone: "",
            first_name: "",
            last_name: "",
            session_id_key: "",
            stream_default : 0,
        },
        hasAccessToKioskPodType: true,
        sessionActive: true,
        with_keyboard: false,
        qr_code: qr_code,
        ip_verified: false,
        pod_verified: false,
        pod_error: "",
        pod_id_key: "",
        falsk_server: true,
        flask_pod_need_deployment: false,
        theme_file: '',
        logo_file:'',
        theme_classes : global_config.THEME_CLASSES,
        messages: global_config.MESSAGES,
        noti_class: "",
        noti_message: "",
        step1: true,
        step2: false,
        phone_mask: '###-###-####',
        date_mask: '##/##/####',
        step1_checking: false,
        is_registered: false,
        is_registered1: false,
        remote_id: "",
        remote_verified: false,
        idle_polling: null,
        menu_polling: null,
        noti_message1: "",
        step2_checking:false,
        current_component: this,
    };
  },

  validations: {
    form1 : {
      user_name: {required},
      password: {required, minLength: minLength(8)},
      password_again: {sameAsPassword: sameAs('password')},
    },
    form2 : {
      dob: {required, minLength: minLength(10)},
      phone: {required, minLength: minLength(12)},
      first_name: {required},
      last_name: {required:false},
      stream_default: {required:false}
    }
  },

  computed: {
    getMessage() {
        return store.getters.getMessage;
    },
    nav_name() {
        return this.$store.getters.getNavName();
    }
  },

  methods: {
    handleFirstStep() {
        this.$v.form1.$touch();
        if (this.$v.form1.$invalid) {
            if(this.$v.form1.password.$error && !this.$v.form1.password.minLength) {
                this.makeToast('register-password-weak');
            }
            else if(this.$v.form1.password_again.$error && !this.$v.form1.password_again.sameAs) {
                this.makeToast('register-password-notmatch');
            }
        } else {
            this.step1_checking = true;
            let session_id = (JSON.parse(localStorage.pod_sess_data)).session_id_key;
            let data = Object.assign({}, this.form1);
            data.session_id_key = session_id;
            data.email = data.user_name;
            data.remote_id = this.remote_id;
            userCheckEmail(data).then(response => {
                this.step1_checking = false;
                if(response.status){
                    this.step2 = true; 
                    setTimeout(() => {
                        this.$refs.nameinput.focus();
                    }, 2000);
                } else {
                    if(response.message == 'domainnotallowed') {
                        this.makeToast('register-domain-notallowed', this.messages.error_class, response.data.message, response.data.message1);
                    }
                    else {
                        this.makeToast(response.message, this.messages.error_class, response.message);
                    }
                }
            });
        }
    },
    handleBackStep() {
        this.step2 = false;
        setTimeout(() => {
            this.$refs.emailinput.focus();
        }, 2000);
    },
    handleRegisterReq() {
      this.$v.form2.$touch();
      if (this.$v.form2.$invalid) {
        if(this.$v.form2.phone.$error && !this.$v.form2.phone.minLength) {
            this.makeToast('register-phone-limit-error');
        }
        else if(this.$v.form2.dob.$error && !this.$v.form2.dob.minLength) {
            this.makeToast('register-dob-format-error');
        }
      } else {
        this.step2_checking = true;
        let session_id = (JSON.parse(localStorage.pod_sess_data)).session_id_key;
        let data = Object.assign({}, this.form1, this.form2);
        data.session_id_key = session_id;
        data.email = data.user_name;
        data.stream_default = data.stream_default ? 1 : 0;
        userRegister(data).then(response => {
          if(response.status){
            // this.makeToast(this.messages.success_class, response.message);
            // this.$v.form1.$reset();
            // this.$v.form2.$reset();
            // this.resetForm();
            // this.step2 = false;
            setTimeout(() => {
                this.is_registered = true;
            }, 2000);
            setTimeout(() => {
                this.is_registered1 = true;
                this.step2_checking = false;
            }, 2100);
            setTimeout(() => {
                if(this.remote_id !== '') {
                    // this.$router.push('/mobile-login/'+this.remote_id);
                    this.$router.push({name: "Mobile Login", params: {qr_code: this.remote_id, username: this.form1.user_name, password: this.form1.password}});
                }
                else {
                    // this.$router.push('/');
                    this.$router.push({name: "Login", params: {username: this.form1.user_name, password: this.form1.password}});
                }
            }, 9000);
          } else {
            this.step2_checking = false;
            this.makeToast(response.message, this.messages.error_class, response.message);
          }
        }); 
      }
    },

    validateKioskPodId() {
      validateKioskPodId(this.pod_id_key).then(response => {
        if(!response.status) {
          this.pod_verified = false;
          this.pod_error = response.message;
        }
        else {
          localStorage.setItem('pod_sess_data', JSON.stringify(response.data));
          this.with_keyboard = response.status;
          this.pod_verified = response.status;
          this.pod_error = "";
          this.$store.commit("setThemeLogo", response.data);
          // set theme file and logo file for pod
          this.logo_file = response.data.logo;
          this.theme_file = response.data.theme;
          this.getThemeFile();
          // call activity record api
          sessionActivitySave({'activity': global_config.ACTIVITIES.UI_SIGNUP}).then((response) => {
            console.log("ACTIVITY API CALLED : ", response);
          });
        }
      })
    },

    validateUseragentAndIP() {
      validateUseragentAndIP({'pod_id' : this.pod_id_key}).then(response => {
        if(response.message == 'Exist') {
          this.ip_verified = true;
          this.validateKioskPodId();
        }
      })
    },

    getPodIdFromFlask() {
      getPodId().then(pod => {
        if(pod.success 
        && Object.prototype.hasOwnProperty.call(pod, 'data') 
        && Object.prototype.hasOwnProperty.call(pod.data, 'pod_id') 
        && pod.data.pod_id.length > 0) {
            this.falsk_server = true;
            this.flask_pod_need_deployment = true;
            this.pod_id_key = pod.data.pod_id;

            checkFlaskPodUser().then((pu) => {
                if (
                    Object.prototype.hasOwnProperty.call(pu, "success") &&
                    pu.success &&
                    Object.prototype.hasOwnProperty.call(pu, "data") &&
                    Object.prototype.hasOwnProperty.call(pu.data, "user_name")
                ) {
                    this.$router.push('/');
                } 
                else {
                    this.validateUseragentAndIP();
                    this.pollPodIdle();
                    this.menu_polling = handlePushMenuPolling(this);
                }
            });
        }
        else if(Object.prototype.hasOwnProperty.call(pod, 'success') && pod.success) {
          this.falsk_server = true;
          this.flask_pod_need_deployment = true;
          // this.makeToast("error", "Please deploy this POD first!");
        }
        else {
          this.falsk_server = false;
          this.flask_pod_need_deployment = true;
          // this.makeToast("error", "Flask API server doesn't work now!");
        }
      });
    },

    makeToast(message_key='', variant = "default", message, message1="") {
        // search for key
        let smessage = this.$store.state.system_messages.list.find(o => o.msg_key === message_key);
        if(smessage !== null && typeof smessage !== 'undefined' && Object.prototype.hasOwnProperty.call(smessage, 'text_head')) {
            this.noti_class = smessage.type == 'failure' ? 'error' : smessage.type;
            this.noti_message = smessage.text_head;
            this.noti_message1 = smessage.text_sub + (message_key == 'register-domain-notallowed' ? ' ' + message1 : '');
        }
        else {
            this.noti_class = variant;
            this.noti_message = message;
            this.noti_message1 = message1;
        }
        // hide error div
        setTimeout(() => {
            this.noti_class = "";
            this.noti_message = "";
            this.noti_message1 = "";
        }, 5000);
    },

    resetForm() {
      var self = this;
      //Iterate through each object field, key is name of the object field`
      Object.keys(this.form1).forEach(function(key) {
        self.form1[key] = '';
      });
      Object.keys(this.form2).forEach(function(key) {
        self.form2[key] = '';
      });
    },

    getThemeFile() {
      // remove existing classes
      for(let i = 0; i < this.theme_classes.length; i++) {
        document.body.classList.remove(this.theme_classes[i]);
      }
      if(this.theme_file !== "" && this.theme_file !== null) {
        let class_name = this.theme_file+'Theme';
        document.body.classList.add(class_name);
      }
    },

    validateQRRemoteId() {
        validateRemoteQR(this.remote_id, 'register').then(response => {
            this.remote_requested = true;
            if(!response.status) {
                this.remote_verified = false;
                this.noti_class = this.messages.error_class;
                this.noti_message = "This remote session has expired.";
                this.noti_message1 = "Please scan the QR code again with your camera.";
            }
            else {
                this.remote_verified = true;
                if(response.message == 'remote_init_reg_forgot') {
                    this.makeToast('', this.messages.success_class, "Starting remote session with "+response.data.nav_name+".", "Please wait while the connection is established...");
                }
                else {
                    this.makeToast(response.message, this.messages.success_class, response.message);
                }
                localStorage.setItem('pod_sess_data', JSON.stringify(response.data));
                // set theme file and logo file for pod
                this.logo_file = response.data.logo;
            }
        })
    },

    pollPodIdle () {
        this.idle_polling = setInterval(() => {
            // // get xd types for buttons
            // checkFlaskPodIdle().then(idle => {
            //     if(idle.success !== 'undefined') {
            //         if(idle.success) {
            //             clearInterval(this.idle_polling);
            //             this.$router.push('/');
            //         }
            //     }
            // });

            let pod_idle = localStorage.getItem('pod_idle');
            // check if idle already recorded
            if(typeof pod_idle !== 'undefined' && pod_idle == 'true') {
                clearInterval(this.idle_polling);
                this.$router.push('/');
            }
        }, 3000);
        this.$store.commit("setIdleFlagUpater", true);
    },

    handleEnterPress(type) {
        if(type == "continue") {
            this.handleFirstStep();
        }
        else if(type == "signup") {
            if(!this.step2_checking) {
                this.handleRegisterReq();
            }
        }
    },
    setFocusedElement(event, ref, form) {
        this.$store.commit("setFocusedElement", ref);
        this.$store.commit("setFocusedElementForm", {'form': form, 'target' : event});
    }
  },

  mounted() {
    if(typeof this.$route.params.qr_code == 'undefined') {
        this.getPodIdFromFlask();
    }
    else {
        this.remote_id = this.$route.params.qr_code;
        this.ip_verified = true;
        this.falsk_server = true;
        this.flask_pod_need_deployment = false;
        this.noti_class = this.messages.warning_class;
        this.noti_message = this.messages.remote_verifying;
        this.validateQRRemoteId();
    }
    setTimeout(() => {
        this.$refs.emailinput.focus();
    }, 4000);
    // check if info recieved from login page
    let params = this.$route.params;
    let is_email_transfer = false;
    let is_pass_transfer = false;
    if(Object.prototype.hasOwnProperty.call(params, "uname") && params.uname !== '') {
        this.form1.user_name = params.uname;
        is_email_transfer = true;
    }
    if(Object.prototype.hasOwnProperty.call(params, "pass") && params.pass !== '') {
        this.form1.password = params.pass;
        is_pass_transfer = true;
    }
    // check if last field needs focused
    if(is_email_transfer || is_pass_transfer) {
        setTimeout(() => {
            if(is_email_transfer && is_pass_transfer) {
                this.$refs.conpassinput.focus();
            }
            else if(is_email_transfer && !is_pass_transfer) {
                this.$refs.passinput.focus();
            }
            else if(!is_email_transfer && is_pass_transfer) {
                this.$refs.emailinput.focus();
            }
        }, 4000);
    }
  },
  beforeDestroy() {
    clearInterval(this.idle_polling);
    clearInterval(this.menu_polling);
  }
};
</script>
<style>
button#dob {
  display: none !important;
}
label#dob__value_ {
  width: 100% !important;
  height: 100%;
  line-height: 43px !important;
  text-align: left !important;
  padding: 0.375rem 0.75rem !important;
  /* color: #fff !important; */
  color: #212529 !important;
}
div#dob__dialog_ {
  transform: unset !important;
  top: unset !important;
  bottom: 53px !important;
  left: -49px !important;
}
.text-grey label#dob__value_ {
  color: grey !important;
}
div#dob__outer_ {
  width: 100% !important;
  text-align: left;
  border: unset;
}
#dob__outer_.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.active-switch {
  margin-top: 15px;
}
.active-switch > span.vue-switcher__label,
.active-switch > div {
  float: left;
}
.active-switch span.vue-switcher__label span {
  color: #fff !important;
  font-size: 16px;
  margin-right: 15px;
}
.active-switch div,
.active-switch.vue-switcher--bold div {
  height: 40px !important;
  width: 80px !important;
  background-color: #1c1f22 !important;
}
.active-switch div:after,
.active-switch.vue-switcher--bold div:after {
  height: 34px !important;
  width: 34px !important;
  background-color: #fff !important;
}
.active-switch.vue-switcher--unchecked div:after,
.active-switch.vue-switcher--bold.vue-switcher--unchecked div:after {
  left: 27px !important;
  background-color: grey !important;
}
.active-switch:not(.vue-switcher--unchecked) div:after,
.active-switch.vue-switcher--bold:not(.vue-switcher--unchecked) div:after {
  margin-left: -37px !important;
}
</style>