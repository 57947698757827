<template>
    <div class="offers-stripe-container">
        <MainKeyboard :main_element="current_component" />

        <template v-if="payment_method_type !== 'existing'">
            <div class="stripe-payment-form">
                <div class="col-sm-12 full-width-element mb-2 position-relative">
                    <label for="stripe-card">Card Number</label>
                    <input type="password" class="stripe-card-number" id="stripe-card" v-mask="card_mask" v-model="stripe_form.card" :class="{ 'stripe_invalid': $v.stripe_form.card.$error }" :placeholder="stripe_form.card.length == 0 ? '4242 4242 4242 4242' : ''" @focus="setFocusedElement($event, 'card', 'stripe_form')" />
                    <div class="stripe-cards-container">
                        <img src="../assets/images/visa.svg" alt="">
                        <img src="../assets/images/mastercard.svg" alt="">
                        <img src="../assets/images/amex.svg" alt="">
                        <img src="../assets/images/discover.svg" alt="">
                    </div>
                    <span class="dot-span" v-if="stripe_form.card.length > 0">{{ convertToDots(stripe_form.card) }}</span>
                </div>
                <div class="col-sm-12 half-width-element mb-2">
                    <div class="half-width-element-child position-relative">
                        <label for="stripe-exp">Expiration</label>
                        <input type="password" class="stripe-expiry" v-mask="exp_mask" v-model="stripe_form.exp" :class="{ 'stripe_invalid': $v.stripe_form.exp.$error }" :placeholder="stripe_form.exp.length == 0 ? 'MM/YY' : ''" id="stripe-exp" @focus="setFocusedElement($event, 'exp', 'stripe_form')" />
                        <span class="dot-span" v-if="stripe_form.exp.length > 0">
                            <div v-html="convertToDots(stripe_form.exp)"></div>
                        </span>
                    </div>
                    <div class="half-width-element-child position-relative">
                        <label for="stripe-cvc">CVC</label>
                        <input type="password" class="stripe-cvc" v-mask="cvc_mask" v-model="stripe_form.cvc" :class="{ 'stripe_invalid': $v.stripe_form.cvc.$error }" :placeholder="stripe_form.cvc.length == 0 ? 'CVC' : ''" id="stripe-cvc" @focus="setFocusedElement($event, 'cvc', 'stripe_form')" />
                        <div class="stripe-cards-container">
                            <img src="../assets/images/cvv.svg" alt="">
                        </div>
                        <span class="dot-span" v-if="stripe_form.cvc.length > 0">{{ convertToDots(stripe_form.cvc) }}</span>
                    </div>
                </div>
                <div class="col-sm-12 full-width-element mb-2">
                    <label for="stripe-country">Country</label>
                    <select v-model="stripe_form.country" id="stripe-country">
                        <option 
                        v-for="(selectOption, indexOpt) in countries_list" 
                        :key="indexOpt"
                        :value="selectOption.id"
                        >
                            {{ selectOption.value }}
                        </option>
                    </select>
                </div>
                <div class="col-sm-12 full-width-element mb-2 position-relative">
                    <label for="stripe-postal">ZIP</label>
                    <input type="password" class="stripe-postal" v-mask="postal_mask" v-model="stripe_form.postal" :placeholder="stripe_form.postal.length == 0 ? '90210' : ''" id="stripe-postal" @focus="setFocusedElement($event, 'postal', 'stripe_form')" />
                    <span class="dot-span" v-if="stripe_form.cvc.length > 0">{{ convertToDots(stripe_form.postal) }}</span>
                </div>
                <!-- <div class="col-sm-12 full-width-element mb-2">
                    <p class="stripe-instructions">
                        By providing your card information you will allow DROP ZONE to charge your card for furure payments in accordance with their terms.
                    </p>
                </div> -->
                <div class="stripe-save-card-container" v-if="clientSecret !== ''" :class="[stripe_loading ? 'zero-opacityy' : '']">
                    <label for="save-card">
                        <input type="checkbox" id="save-card" v-model="save_card" /> Save Card for Future Purchases
                    </label>
                </div>
            </div>
            <div class="offers-section-list" style="width: 100%;padding: 0px;" v-if="clientSecret !== ''">
                <div class="offers-section-list-data mt-1">
                    <div class="offers-section-list-data-play" style="margin-top:15px;">
                        <div class="offers-section-list-data-play-buttons">
                            <a href="javascript:;" class="btn" @click="handleBack">
                                Back
                            </a>
                            <button 
                            class="btn"
                            @click="confirmPayment"
                            v-if="!api_state"
                            >
                            Confirm ${{ offer.usd_cost }} Charge
                            </button>
                            <button 
                            class="btn"
                            disabled="disabled"
                            v-if="api_state"
                            >
                            Processing...
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-if="payment_method_type == 'existing' && payment_methods.length > 0">
            <ul class="stripe-existing-cards-container">
                <li v-for="pam in payment_methods" :key="pam.id">
                    <template v-if="pam.type == 'card'">
                        <div class="stripe-card-name">{{ pam.card.brand + ' - ' + pam.card.last4 }}</div>
                        <div class="stripe-card-actions">
                            <a 
                            class="stripe-card-actions-use"
                            href="javascript:;"
                            @click="useSavedCard(pam.id)" 
                            :class="[selected_payment_method == pam.id ? 'card-active' : '']"
                            >Select Card</a>
                            <a 
                            class="stripe-card-actions-delete"
                            href="javascript:;" 
                            @click="deleteSavedPaymentMethod(pam.id)"
                            ><i class="fas fa-trash"></i></a>
                        </div>
                    </template>
                </li>
            </ul>
            <div class="offers-section-list" style="width: 100%;padding: 0px;" v-if="selected_payment_method == ''">
                <div class="offers-section-list-data mt-1">
                    <div class="offers-section-list-data-play" style="margin-top:15px;">
                        <div class="offers-section-list-data-play-buttons">
                            <a href="javascript:;" class="btn" @click="handleBack">
                                Back
                            </a>
                            <button 
                            class="btn"
                            @click="upadatePaymentMethodType('new')"
                            v-if="!api_state"
                            >
                            Add New Card
                            </button>
                            <button 
                            class="btn"
                            disabled="disabled"
                            v-if="api_state"
                            >
                            Processing...
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="offers-section-list" style="width: 100%;padding: 0px;" v-if="clientSecret !== '' && selected_payment_method !== ''">
                <div class="offers-section-list-data mt-1">
                    <div class="offers-section-list-data-play" style="margin-top:15px;">
                        <div class="offers-section-list-data-play-buttons">
                            <a href="javascript:;" class="btn" @click="handleBack">
                                Back
                            </a>
                            <button 
                            class="btn"
                            @click="confirmPayment"
                            v-if="!api_state"
                            >
                            Confirm ${{ offer.usd_cost }} Charge
                            </button>
                            <button 
                            class="btn"
                            disabled="disabled"
                            v-if="api_state"
                            >
                            Processing...
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { getPaymentIntent, confirmPaymentIntent, getPaymentMethods, updateSaveCard, deletePaymentMethod, getPaymentMethodId } from "../services/PlayerService";
import { global_config } from "@/config/config.js";
import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import { required, minLength } from 'vuelidate/lib/validators';

export default {
    name: "StripePaymentWidget",
    components: { },
    data() {
        return {
            api_state: false,
            stripe: null,
            clientSecret: this.payment_intent,
            pk: global_config.STRIPE_PK_KEY,
            offer_data: this.offer,
            payment_success: false,
            payment_methods: [],
            save_card: false,
            first_load: true,
            selected_payment_method: '',
            payment_method_type: 'existing',
            stripe_loading: true,
            countries_list: [
                {'id': 'US', 'value' : 'United States'}
            ],
            exp_mask: '##/##',
            card_mask: '#### #### #### ####',
            cvc_mask: '###',
            postal_mask: '#####',
            stripe_form: {
                card: '',
                exp: '',
                cvc: '',
                country: 'US',
                postal: '',
            },
            current_component: this,
        };
    },
    props: ['offer', 'payment_intent'],
    watch: {
        save_card: {
            handler: function() {
                this.switchSaveCardForPaymentIntent();
            }
        },
    },
    validations: {
        stripe_form : {
            card: {required},
            exp: {required, minLength: minLength(5)},
            cvc: {required, minLength: minLength(3)},
        }
    },
    methods: {
        generatePaymentIntent() {
            // this.clientSecret = 'pi_3N2swLIuXBp9vsS10Qf9i1k4_secret_1eZHYYhyMwuR6KcqH2PxIKBjc';
            // check if client secret already there meaning intent genenrated already
            if(this.clientSecret == '') {
                let user_data = JSON.parse(localStorage.user_sess_data);
                let pod_data = JSON.parse(localStorage.pod_sess_data);
                let params = {
                    session_id_key : pod_data.session_id_key,
                    token: user_data.token,
                    user_id: user_data.user_id,
                    amount: this.offer.usd_cost
                };
                this.api_state = true;
                getPaymentIntent(params).then(response => {
                    this.api_state = false;
                    if(response.status) {
                        this.clientSecret = response.data.intent.client_secret;
                        this.$emit('setPodData', {'key' : 'payment_intent', 'value' : this.clientSecret});
                        // update amount for intent
                        this.switchSaveCardForPaymentIntent();
                    }
                    else {
                        this.$root.$emit("toast_message", {'type' : 'error', 'message' : response.message});
                    }
                });
            }
            else {
                // update amount for intent
                this.switchSaveCardForPaymentIntent();
            }
        },
        confirmPayment() {
            this.api_state = true;
            let confirm_response = null;
            let client_secret = this.clientSecret;
            // check for payment type
            if(this.payment_method_type == 'existing') {
                let selected_method = this.selected_payment_method;
                confirm_response = this.stripe.confirmPayment({
                    clientSecret: client_secret,
                    confirmParams: {
                        payment_method: selected_method
                    },
                    redirect: 'if_required'
                });
                this.confirmPaymentResponse(confirm_response);
            }
            else {
                this.$v.stripe_form.$touch();
                if (this.$v.stripe_form.$invalid) {
                    this.api_state = false;
                    this.$root.$emit("toast_message", {'type' : 'error', 'message' : 'generic-required-message'});
                } 
                else {
                    let form_data = this.stripe_form;
                    let exp_obj = form_data.exp.split('/');
                    // get payment method id from cloud
                    let user_data = JSON.parse(localStorage.user_sess_data);
                    let pod_data = JSON.parse(localStorage.pod_sess_data);
                    let card_data = {
                        session_id_key : pod_data.session_id_key,
                        token: user_data.token,
                        user_id: user_data.user_id,
                        card : {
                            type: 'card',
                            card: {
                                number: form_data.card,
                                exp_month: parseInt(exp_obj[0]),
                                exp_year: parseInt(exp_obj[1]),
                                cvc: form_data.cvc
                            }
                        }
                    };
                    getPaymentMethodId(card_data).then(cd => {
                        
                        if(cd.status) {
                            confirm_response = this.stripe.confirmPayment({
                                clientSecret: client_secret,
                                confirmParams: {
                                    payment_method: cd.data.pm.id,
                                    shipping: {
                                        address: {
                                            country: form_data.country,
                                            postal_code: form_data.postal
                                        },
                                        name: user_data.name
                                    }
                                },
                                redirect: 'if_required'
                            });
                            this.confirmPaymentResponse(confirm_response);
                        }
                        else {
                            this.api_state = false;
                            this.$root.$emit("toast_message", {'type' : 'error', 'message' : cd.message});
                        }
                    });
                }
            }
        },
        confirmPaymentResponse(confirm_response) {
            // get response from payment confirmation
            confirm_response.then(response => {
                 
                if (Object.prototype.hasOwnProperty.call(response, "paymentIntent")) {
                    if(response.paymentIntent.status == 'succeeded' || response.paymentIntent.status == 'processing') {
                        // call payment save function here
                        let user_data = JSON.parse(localStorage.user_sess_data);
                        let pod_data = JSON.parse(localStorage.pod_sess_data);
                        let payment_data = {
                            session_id_key : pod_data.session_id_key,
                            token: user_data.token,
                            user_id: user_data.user_id,
                            intent : response.paymentIntent,
                            offer : this.offer_data,
                            save_card: this.save_card ? 1 : 0,
                            existing_method: this.selected_payment_method
                        };
                        confirmPaymentIntent(payment_data).then(pd => {
                            pd;
                            this.api_state = false;
                            this.$emit('paymentComplete', true);
                        });
                    }
                    else {
                        this.api_state = false;
                        this.$root.$emit("toast_message", {'type' : 'error', 'message' : 'player-buytime-payment-unsuccessfull-error'});
                    }
                }
                else if(Object.prototype.hasOwnProperty.call(response, "error")) {
                    this.api_state = false;
                    this.$root.$emit("toast_message", {'type' : 'error', 'message' : response.error.message});
                } 
            });
        },
        handleBack() {
            this.$emit('handleBack', true);
        },
        availablePaymentMethods() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                session_id_key : pod_data.session_id_key,
                token: user_data.token,
                user_id: user_data.user_id
            };
            getPaymentMethods(params).then(response => {
                this.payment_methods = response.data.methods;
                if(this.payment_methods.length > 0) {
                    this.payment_method_type = 'existing';
                }
                else {
                    this.payment_method_type = 'new';
                }
                this.generatePaymentIntent();
                
            });
        },
        switchSaveCardForPaymentIntent() {
            if(!this.api_state) {
                this.api_state = true;
                this.stripe.retrievePaymentIntent(this.clientSecret).then(response => {
                    if (Object.prototype.hasOwnProperty.call(response, "paymentIntent")) {
                        let user_data = JSON.parse(localStorage.user_sess_data);
                        let pod_data = JSON.parse(localStorage.pod_sess_data);
                        let params = {
                            session_id_key : pod_data.session_id_key,
                            token: user_data.token,
                            user_id: user_data.user_id,
                            payment_intent: response.paymentIntent.id,
                            save_card: this.save_card ? 1 : 0,
                            amount: this.offer_data.usd_cost
                        };
                        updateSaveCard(params).then(res => {
                            this.api_state = false;
                            res;
                        });
                    }
                    else {
                        this.api_state = false;
                    }
                });
            }
        },
        upadatePaymentMethodType(type) {
            this.payment_method_type = type;
            this.generatePaymentIntent();
        },
        useSavedCard(card_id) {
            this.selected_payment_method = this.selected_payment_method == card_id ? '' : card_id;
        },
        deleteSavedPaymentMethod(card_id) {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                session_id_key : pod_data.session_id_key,
                token: user_data.token,
                user_id: user_data.user_id,
                payment_method: card_id
            };
            this.api_state = true;
            deletePaymentMethod(params).then(response => {
                this.selected_payment_method = this.selected_payment_method == card_id ? '' : this.selected_payment_method;
                this.api_state = false;
                this.$root.$emit("toast_message", {'type' : 'error', 'message' : response.message});
                this.payment_methods = response.data.methods;
                if(this.payment_methods.length > 0) {
                    this.payment_method_type = 'existing';
                }
                else {
                    this.payment_method_type = 'new';
                }
                this.generatePaymentIntent();
            });
        },
        convertToDots: function(val) {
            if (!val) return "";
            val = val.replace(/[0-9]/g, '⬤');
            return val.replace(/\//g, '<span class="dot-span-slash"><span class="dot-span-slash-inner">/</span></span>');
        },
        setFocusedElement(event, ref, form) {
            this.$store.commit("setFocusedElement", ref);
            this.$store.commit("setFocusedElementForm", {'form': form, 'target' : event});
        }
    },
    mounted () {
        this.stripe = window.Stripe(this.pk);
        this.availablePaymentMethods();
    },
}
</script>
<style>
.dot-span {
    width: auto;
    position: absolute;
    left: 8px;
    top: 25px;
    height: 45px;
    line-height: 45px;
    color: #191919;
    font-size: 7px;
    background: #d8d8d8;
}
.dot-span-slash {
    width: 6px;
    display: inline-block;
    text-align: center;
}
.dot-span-slash .dot-span-slash-inner {
    position: absolute;
    top: 1px;
    font-size: 18px;
    left: 15px;
    font-weight: normal;
    font-family: 'Open Sans';
}
</style>