<template>
    <div class="main-layout-section admin-portal">
        <SuperAdminHeader />
        
        <div class="w-logo mb-3">
            <img src="../../assets/images/gamelab-logo.svg" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" />
        </div>

        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeInUp animate__delay-1s">Client Pods</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">

                    <div class="admin-general-section-buttons">
                        <router-link :to="`/superadmin/clients/${client_id}`" class="admin-general-section-button">
                            Back To Client Details
                        </router-link>
                    </div>

                    <div class="col-sm-12">
                        <div class="overflow-auto p-0 m-0">
                            <b-table
                            id="admin-general-table"
                            :striped="true"
                            :hover="false"
                            :dark="false"
                            :items="pods"
                            :fields="podfields"
                            show-empty
                            :emptyText="emptyPods"
                            >
                                <template #empty="scope">
                                    <p class="text-center p-0 m-0">{{ scope.emptyText }}</p>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <SuperAdminFooter type="inner" />
        </div>
        <!-- end of footer section -->
    </div>
</template>

<script>
import SuperAdminHeader from "@/views/superadmin/SuperAdminHeader.vue";
import SuperAdminFooter from "@/views/superadmin/SuperAdminFooter.vue";
import { adminGetPodsList } from "../../services/AdminService";

export default {
  name: "SuperAdminClientsPods-view",
  components: { SuperAdminHeader, SuperAdminFooter },
  data() {
    return {
      client_id: this.$route.params.client_id,
      lab_id: this.$route.params.lab_id,
      emptyPods:"No pods found for requestd lab!",
      podfields: [
        {'key' : 'id', 'label' : 'ID'}, 
        {'key' : 'value', 'label' : 'Key'},
        {'key' : 'nav_id', 'label' : 'Nav ID'}, 
        {'key' : 'seat', 'label' : 'Seat'}, 
        {'key' : 'status', 'label' : 'Status'}
      ],
      pods: [],
    };
  },

  computed: {
  },

  methods: {
    podsList() {
      let user_data = JSON.parse(localStorage.user_sess_data);
      let params = {
        token: user_data.token,
        client_id: this.client_id,
        lab_id: this.lab_id
      };
      adminGetPodsList(params).then(response => {
        this.pods = response.data.pods;
      });
    },

    makeToast(variant = "default", message) {
      this.$toast.open({
        message: message,
        type: variant,
        position: 'top-right',
        duration: 3000,
        dismissible: true
      });
    }
  },

  mounted() {
    this.podsList();
  }

};
</script>