<template>
    <div v-if="is_idle || idle_started">
        <div class="main-contenttt zindex" v-if="idle_started">
            <div class="overlay-black animate__animated animate__delay-1s" :class="idle_started_class"></div>
        </div>
        <div class="main-contenttt" v-if="is_idle" @click="showHomePage">
            <div class="overlay"></div>

            <div class="idle-touch-navid animate__animated animate__fadeInUp animate__delay-1s" :class="is_closed ? 'disabled-pointer' : ''">
                {{ nav_name }}
            </div>
            <div class="idle-touch-container">
                <div class="w-logo">
                    <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="logo_file !== '' && logo_file !== null"/>
                </div>
                
                <a href="javascript:void(0)" class="idle-touch-button animate__animated animate__fadeInUp animate__delay-1s mb-auto mt-5" :disabled="is_closed" :class="is_closed ? 'disabled-pointer' : ''">{{ touch_text }}</a>

                <span class="idle-touch-text animate__animated animate__fadeInUp animate__delay-1s">
                    {{ start_sleep_message }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { checkFlaskPodTouch } from "../services/FlaskService";
import moment from 'moment';
export default {
    name: "IdleWidgetTouch-view",
    data() {
        return {
            start_sleep_message: "",
            nav_name: "",
            touch_text: "",
            sch_polling: null,
            pre_title: "",
            is_closed: false,
            is_idle: false,
            idle_started: false,
            idle_started_class: "",
        };
    },

    computed: {
        pod_idle() {
            return this.$store.getters.getIdleState();
        },
        logo_file() {
            return this.$store.getters.getThemeLogo().logo;
        },
    },

    watch: {
        pod_idle(new_val) {
            if(new_val) {
                this.idle_started = true;
                this.idle_started_class = 'animate__fadeIn';
                setTimeout(() => {
                    this.is_idle = true;
                    this.idle_started_class = 'animate__fadeOut';
                }, 2000);
                setTimeout(() => {
                    this.idle_started_class = '';
                    this.idle_started = false;
                }, 4000);
            }
        }
    },

    methods: {
        showHomePage() {
            // if(!this.is_closed) {
                document.title = this.pre_title;
                this.$store.commit("setIdleFlag", false);
                localStorage.removeItem('pod_idle');
                this.$store.commit("setFocusedElement", null);
                this.$store.commit("setFocusedElementForm", {'form': null, 'target': null});
                this.$emit('getPodIdFromFlask', true);

                // handle animations
                this.idle_started = true;
                this.idle_started_class = 'animate__fadeIn';
                setTimeout(() => {
                    this.is_idle = false;
                    this.idle_started_class = 'animate__fadeOut';
                }, 2000);
                setTimeout(() => {
                    this.idle_started_class = '';
                    this.idle_started = false;
                }, 4000);
            // }
        },
        setStartSleepMessage(data) {
            // var d = new Date();
            // var seconds = d.getSeconds();
            // var minutes = d.getMinutes();
            // var hours = d.getHours();

            // get moment date with timezone
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            var mdate = moment.tz(moment(), pod_data.timezone);
            var hours = mdate.format('HH');
            var minutes = mdate.format('mm');
            var seconds = mdate.format('ss');

            var time_string = hours + ':' + minutes + ':' + seconds;
            if(data.start > time_string) {
                const [hourString, minute] = data.start.split(":");
                const hour = +hourString % 24;
                this.start_sleep_message = "Facility opens today at " + (hour % 12 || 12) + ":" + minute + (hour < 12 ? "am" : "pm") + ".";
                // this.is_closed = true;
            }
            else if(time_string > data.end) {
                try {
                    const [hourString, minute] = data.tomorrow.split(":");
                    const hour = +hourString % 24;
                    this.start_sleep_message = "Facility opens tomorrow at " + (hour % 12 || 12) + ":" + minute + (hour < 12 ? "am" : "pm") + ".";
                    //this.is_closed = true;
                } catch (error) {
                    this.start_sleep_message = "Facility opens tomorrow at.";
                    //this.is_closed = true;
                }
            }
            else {
                try {
                    const [hourString, minute] = data.end.split(":");
                    const hour = +hourString % 24;
                    this.start_sleep_message = "Facility closes today at " + (hour % 12 || 12) + ":" + minute + (hour < 12 ? "am" : "pm") + ".";
                    // this.is_closed = false;
                } catch (error) {
                    this.start_sleep_message = "Facility closes today at.";
                    //this.is_closed = true;
                }
            }
        },
        setTouchText() {
            checkFlaskPodTouch().then(fpi => {
                if (Object.prototype.hasOwnProperty.call(fpi, "success") && fpi.success) {
                    this.touch_text = "Click Here to Begin";
                }
                else {
                    this.touch_text = "Click Here to Begin";
                }
            });
        },
        pollScheduleTime() {
            setInterval(() => {
                if(typeof localStorage.pod_sess_data !== 'undefined') {
                    let pod_data = JSON.parse(localStorage.pod_sess_data);
                    this.setStartSleepMessage({'start' : pod_data.schedule_start, 'end' : pod_data.schedule_end, 'tomorrow' : pod_data.schedule_tomorrow});
                }
            }, 2000);
        },
        checkIdle() {
            if(this.pod_idle) {
                document.title = 'GameLab - Idle';
                this.idle_started = true;
                this.idle_started_class = 'animate__fadeIn';
                setTimeout(() => {
                    this.is_idle = true;
                    this.idle_started_class = 'animate__fadeOut';
                }, 2000);
                setTimeout(() => {
                    this.idle_started_class = '';
                    this.idle_started = false;
                }, 4000);
            }
        }
    },
    mounted() {
        clearInterval(this.sch_polling);
        this.pollScheduleTime();
        this.pre_title = document.title;
        this.$store.commit("setFocusedElement", null);
        this.$store.commit("setFocusedElementForm", {'form': null, 'target': null});
        this.$store.commit("setFocusedElement", null);
        this.$store.commit("setFocusedElementForm", {'form': null, 'target': null});
        // set message for display
        if(typeof localStorage.pod_sess_data !== 'undefined') {
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            this.setStartSleepMessage({'start' : pod_data.schedule_start, 'end' : pod_data.schedule_end, 'tomorrow' : pod_data.schedule_tomorrow});
            this.nav_name = pod_data.nav_name;
            this.setTouchText();
        }

        // capture schedule messages
        this.$root.$on('idle_touch_schedule_message', (message) => {
            this.setStartSleepMessage(message);
        });
        this.checkIdle();
    },
    updated() {
        if(this.pod_idle) {
            document.title = 'GameLab - Idle';
        }
        this.$root.$emit('live-chat-widget-hide', 'show-hide');
        this.$store.commit("setFocusedElement", null);
        this.$store.commit("setFocusedElementForm", {'form': null, 'target': null});
        this.$root.$emit('live-chat-widget-hide', 'show-hide');
        this.$store.commit("setFocusedElement", null);
        this.$store.commit("setFocusedElementForm", {'form': null, 'target': null});
        if(typeof localStorage.pod_sess_data !== 'undefined') {
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            this.setStartSleepMessage({'start' : pod_data.schedule_start, 'end' : pod_data.schedule_end, 'tomorrow' : pod_data.schedule_tomorrow});
            this.nav_name = pod_data.nav_name;
            this.setTouchText();
        }
    },
    beforeDestroy() {
        clearInterval(this.sch_polling);
    }
};
</script>
<style scoped>
.main-contenttt {
    width: 100%;
    height: 100vh;
    z-index: 1000;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    background: transparent;
}
.main-contenttt .overlay {
    position: absolute;
    background: black;
    width: 100%;
    left: 0;
    height: 100%;
    background: linear-gradient(30deg, var(--client-gradiant), var(--client-accent));
    background-image: linear-gradient(30deg, var(--client-gradiant), var(--client-accent));
}
.idle-touch-container {
    width: 475px;
    height: 100vh;
    z-index: 1000;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    background: transparent;
}
.idle-touch-button {
    border: 2px solid var(--client-primary);
    padding: 20px 15px;
    border-radius: 8px;
    display: inline-block;
    text-decoration: none;
    min-width: 90%;
    margin: 10px 0;
    color: #d8d8d8;
    font-size: 18px;
    outline: none;
    z-index: 1000;
    font-weight: 700;
    font-family: 'Open Sans';
    margin-top: auto !important;
}
@media screen and (max-width: 1280px) and (max-height: 720px) {
    .idle-touch-button {
        min-width: 30%;
    }
}
.idle-touch-button:hover {
  background: var(--client-primary);
  color: var(--client-hover);
}
.idle-touch-text {
    min-width: 90%;
    background: var(--client-primary);
    z-index: 1000;
    font-size: 22px;
    font-weight: 600;
    font-family: 'Open Sans';
    color: #d8d8d8;
    padding: 20px 25px;
    border-radius: 8px;
    margin-top: auto;
    margin-bottom: -7px;
}
.w-logo img {
    width: 200px;
}
.idle-touch-navid {
    background: var(--client-primary);
    z-index: 1000;
    font-size: 22px;
    font-weight: 600;
    font-family: 'Open Sans';
    color: #d8d8d8;
    padding: 15px 20px;
    border-radius: 8px;
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
}
.disabled-pointer {
    cursor: not-allowed;
}
/* responsiveness section */
@media screen and (max-width: 475px) {
    .idle-touch-container {
        width: 90%;
    }
    .idle-touch-text {
        font-size: 20px;
    }
    .idle-touch-navid {
        right: 15px;
    }
}
.main-contenttt .overlay-black {
    position: absolute;
    background: black;
    width: 100%;
    left: 0;
    height: 100%;
    background: linear-gradient(30deg, black, black);
    background-image: linear-gradient(30deg, black, black);
    z-index: 100000;
}
.main-contenttt.zindex {
    z-index: 100000;
}
</style>