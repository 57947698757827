<template>
  <div>
    <!-- start of game startup modal -->
    <b-modal id="game-startup-modal" header-class="bg-labPrimary text-white px-3 py-2" hide-footer :hide-header-close="hide_header_close" :no-close-on-backdrop="no_close_bd" :no-close-on-esc="no_close_esc">
      <template #modal-title>
        Alert
        <b-icon 
          class="icon" 
          icon="question-circle-fill"
          >
        </b-icon> 
      </template>
      <b-overlay :show="false" rounded="lg">
        <div class="d-flex">
          <p>{{ game_startup_message }}</p>
        </div>
        <div class="d-flex text-center">
          <button
            type="button"
            class="btn btn-info text-white px-5"
            @click="$bvModal.hide('game-startup-modal')"
            v-if="game_startup_button !== ''"
          >
            {{ game_startup_button }}
          </button>
        </div>
      </b-overlay>
    </b-modal>

  </div>
</template>
<script>
import { getPodId, playFlaskGame, getXdTypes, getFlaskGameStarted, setFlaskLED1, setFlaskLED2 } from "../../services/FlaskService";
import { getPodDetails } from "../../services/PlayerService";
import { global_config } from "@/config/config.js";
import store from "@/store";
export default {
    name: "FacPollingAndSockets-view",
    data() {
        return {
            pod_id_key : "",
            pod_locked: false,
            pod_data: {},
            xds_data: {
              "xd1" : "", 
              "xd2" : "", 
              "xb_disabled": true, 
              "ps_disabled": true, 
              "sw_disabled": true, 
              "pc_disabled": true, 
              "pod_disabled": true
            },
            game_init: false,
            game_startup_duration: global_config.GAME_STARTUP_DURATION,
            game_polling: null,
            game_startup_message: "",
            game_startup_button: "",
            hide_header_close: false,
            no_close_bd: false,
            no_close_esc: false,
        };
    },
    methods: {
        getPodIdFromFlask() {
            getPodId().then(pod => {
                if(pod.data.pod_id !== undefined && pod.data.pod_id.length > 0) {
                    this.pod_id_key = pod.data.pod_id;
                    this.$emit('setPodData', {'key' : 'pod_id_key', 'value' : this.pod_id_key});
                    this.podDetails();
                }
            });
        },
        podDetails() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                session_id_key : pod_data.session_id_key,
                session_id : pod_data.session_id_key,
                token: user_data.token
            };
            getPodDetails(this.pod_id_key, params).then(response => {
                if(response.status) {
                    this.pod_data = response.data.pod;
                    this.pod_locked = response.data.pod.lock_out;
                    this.$emit('setPodData', {'key' : 'pod_locked', 'value' : this.pod_locked});
                    this.$emit('setPodData', {'key' : 'pod_data', 'value' : this.pod_data});
                    this.$emit('setPodVolume', response.data.pod.volume);
                }
            });
        },

        gameStartInitiated() {
            this.game_init = true;
            this.game_startup_duration = 0;
            clearTimeout(this.game_polling);
            this.game_polling = setInterval(() => {
                getFlaskGameStarted().then(gs => {
                    if(gs.success && gs.data !== undefined && typeof gs.data.game_started !== undefined) {
                        if(!gs.data.game_started) {
                            if(this.game_startup_duration == global_config.GAME_STARTUP_DURATION) {
                                this.$root.$emit("game_message", {'type' : 'fail'});
                            }
                        }
                        else {
                            clearInterval(this.game_polling);
                            this.game_init = false;
                            this.$root.$emit("game_message", {'type' : 'success'});
                        }
                    }
                    else {
                        if(this.game_startup_duration == global_config.GAME_STARTUP_DURATION) {
                            this.$root.$emit("game_message", {'type' : 'fail'});
                        }
                    }
                });
                this.game_startup_duration += 3;
            }, process.env.VUE_APP_POLLING_TIME_POD_GAME_STARTED_CHECK);
        },

        playGame(gid, search, platform, install=null) {
            let params = {
                platform: platform,
                select_type: install,
                search_title: search
            };
            playFlaskGame(params).then(response => {
                response;
                this.$root.$emit("game_message", {'type' : 'init'});
                params['game_id'] = gid;
                this.gameStartInitiated();
            })
        },

        getXdsData() {
            // get xd types for buttons
            getXdTypes().then(xd => {
                if(xd.data !== undefined) {
                    this.xds_data = xd.data;
                    this.$emit('setPodData', {'key' : 'xds_data', 'value' : xd.data});
                }
            });
        },

      makeToast(variant = "default", message) {
        this.$root.$emit("toast_message", {'type' : variant, 'message' : message});
      },

    },
    computed: {
      getMessage() {
        return store.getters.getMessage;
      },
    },
    mounted() {
      // listen for modal close
      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        if(modalId == "game-startup-modal") {
          this.game_startup_message = "";
          this.game_startup_button = "";
          this.hide_header_close = false;
          this.no_close_bd = false;
          this.no_close_esc = false;
        }
      });

      // init websocket for admin pod websockets
        // check if channel already subscribed
        if(!Object.prototype.hasOwnProperty.call(window.Echo.connector.channels, "fac-pod-command")) {
            // listen for pod schedule event
            window.Echo.channel('fac-pod-command').listen('FacPodCommandEvent', (e) => {
                if(
                e.message.data.pod_ids.indexOf(this.pod_id_key) !== -1 && 
                e.message.server_info == process.env.VUE_APP_WS_ENV
                ) {
                    let command_type = e.message.data.command_type;
                    let pod_data = JSON.parse(localStorage.pod_sess_data);
                    if(command_type == 'led1' || command_type == 'led2') {
                        if(command_type == 'led1') {
                            let params = {
                                led1_default: e.message.data.command_value
                            };
                            setFlaskLED1(params, 'default').then(lc => {
                                console.log("LED1 CHANGE FLASK API CALLED : ", lc);
                            });
                        }
                        else if(command_type == 'led2') {
                            let params = {
                                led2_default: e.message.data.command_value
                            };
                            setFlaskLED2(params, 'default').then(lc => {
                                console.log("LED2 CHANGE FLASK API CALLED : ", lc);
                            });
                        }
                    }
                    else if(command_type == 'logout_fac') {
                        this.$parent.handleLogout();
                    }
                    else if(command_type == 'save_seat') {
                        // trigger pod logout as user logged in on another location
                        if(this.$route.path !== '/facilitator/dashboard') {
                            this.$router.push({name: "Facility Dashboard", params: {fac_save_seat: true}});
                        }
                        else {
                            this.$root.$emit("fac_save_seat", true);
                        }
                    }
                    else if(command_type == 'fac_play_game') {
                        e.message.data['command_pod'] = pod_data.pod_id_key;
                        if(this.$route.name !== 'Facility Games') {
                            this.$router.push({ name: "Facility Games", params: {'fac_websocket': e.message.data, 'xd' : e.message.data.game_platform}});
                        }
                        else {
                            this.$root.$emit("fac_play_game", {'fac_websocket': e.message.data, 'xd' : e.message.data.game_platform});
                        }
                    }
                }
            });
        }
    },
    beforeDestroy () {
    },
    created () {
      this.getPodIdFromFlask();
      this.getXdsData();
    }
};
</script>