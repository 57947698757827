<template>
    <div class="main-layout-section admin-portal">
        <SuperAdminHeader />
        
        <div class="w-logo mb-3">
            <img src="../../assets/images/gamelab-logo.svg" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" />
        </div>

        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeInUp animate__delay-1s">Edit Game</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">

                    <div class="admin-general-section-buttons">
                        <router-link to="/superadmin/games" class="admin-general-section-button">
                            Back To Games Management
                        </router-link>
                    </div>

                    <div class="row m-0 p-0 col-md-12 col-sm-12 m-auto">
                        <b-form inline @submit.stop.prevent class="m-0 p-0">
                            <!-- client and labe name field -->
                            <div class="row">
                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Title</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.title.$error }">
                                        <span><i class="fa-solid fa-file"></i></span>
                                        <b-form-input
                                            v-model="form.title"
                                            type="text"
                                            placeholder="Enter title"
                                            id="title"
                                            aria-describedby="title-help-block"
                                            class=""
                                            :class="{ 'is-invalid': $v.form.title.$error }"
                                        ></b-form-input>
                                    </div>
                                </div>

                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Search Title</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.search_title.$error }">
                                        <span><i class="fa-solid fa-file"></i></span>
                                        <b-form-input
                                            v-model="form.search_title"
                                            id="search_title"
                                            class=""
                                            :class="{ 'is-invalid': $v.form.search_title.$error }"
                                            placeholder="Enter search title"
                                        ></b-form-input>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Platform</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.platform.$error }">
                                        <span><i class="fa-solid fa-file"></i></span>
                                        <!-- <b-form-select
                                            :options="platforms"
                                            text-field="value"
                                            value-field="id"
                                            v-model="form.platform"
                                            type="text"
                                            placeholder="Enter platform"
                                            id="platform"
                                            aria-describedby="platform-help-block"
                                            class=""
                                            :class="{ 'is-invalid': $v.form.platform.$error }"
                                        ></b-form-select> -->
                                        <Select2 class="gl_select2_multiple" v-model="form.platform" ref="select_platform" :options="platforms" :settings="{placeholder: 'None', multiple: true}" @update:modelValue="changeEvent($event)" />
                                    </div>
                                </div>

                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Esrb</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.esrb.$error }">
                                        <span><i class="fa-solid fa-file"></i></span>
                                        <b-form-select
                                            :options="esrbs"
                                            text-field="value"
                                            value-field="id"
                                            v-model="form.esrb"
                                            id="esrb"
                                            class=""
                                            :class="{ 'is-invalid': $v.form.esrb.$error }"
                                            placeholder="Enter esrb"
                                        ></b-form-select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Release Date</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100 overflow-visible" :class="{ 'is_invalid': $v.form.released.$error }">
                                        <span><i class="fa-solid fa-calendar"></i></span>
                                        <input type="text" name="released" value="" v-mask="'##/##/####'" v-model="form.released" class="form-control" :class="{ 'is-invalid': $v.form.released.$error }"
                                        placeholder="mm/dd/yyyy" />
                                        <!-- <b-form-datepicker
                                            reset-button
                                            v-model="form.released"
                                            id="released" 
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            class="date-picker"
                                            :class="[ $v.form.released.$error ? 'is-invalid' : '', form.released == '' ? 'text-grey' : '' ]"
                                            placeholder="Enter release date"
                                        ></b-form-datepicker> -->
                                    </div>
                                </div>

                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Expire Date</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100 overflow-visible" :class="{ 'is_invalid': $v.form.expired.$error }">
                                        <span><i class="fa-solid fa-calendar"></i></span>
                                        <input type="text" name="expired" value="" v-mask="'##/##/####'" v-model="form.expired" class="form-control" :class="{ 'is-invalid': $v.form.expired.$error }"
                                        placeholder="mm/dd/yyyy" />
                                        <!-- <b-form-datepicker
                                            reset-button
                                            v-model="form.expired"
                                            id="expired" 
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            class="date-picker"
                                            :class="[ $v.form.expired.$error ? 'is-invalid' : '', form.expired == '' ? 'text-grey' : '' ]"
                                            placeholder="Enter expired date"
                                        ></b-form-datepicker> -->
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Genre</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.genre.$error }">
                                        <span><i class="fa-solid fa-file"></i></span>
                                        <b-form-select
                                            :options="genres"
                                            text-field="value"
                                            value-field="id"
                                            v-model="form.genre"
                                            id="genre" 
                                            class=""
                                            :class="{ 'is-invalid': $v.form.genre.$error }"
                                            placeholder="Enter genre"
                                        ></b-form-select>
                                    </div>
                                </div>

                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Select Type</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.type.$error }">
                                        <span><i class="fa-solid fa-file"></i></span>
                                        <b-form-select
                                            v-model="form.type"
                                            :options="types"
                                            text-field="value"
                                            value-field="id"
                                            id="type"
                                            class=""
                                            :class="[ $v.form.type.$error ? 'is-invalid' : '', form.type == '' ? 'text-grey' : '' ]"
                                            placeholder="Select a type"
                                        ></b-form-select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Select Status</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.status.$error }">
                                        <span><i class="fa-solid fa-file"></i></span>
                                        <b-form-select
                                            v-model="form.status"
                                            :options="statuses"
                                            text-field="value"
                                            value-field="id"
                                            id="status"
                                            class=""
                                            :class="[ $v.form.status.$error ? 'is-invalid' : '', form.status == '' ? 'text-grey' : '' ]"
                                            placeholder="Select a status"
                                        ></b-form-select>
                                    </div>
                                </div>

                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Choose Image</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.image.$error }">
                                        <span><i class="fa-solid fa-image"></i></span>
                                        <b-form-file
                                            v-model="form.image"
                                            placeholder="Choose a file"
                                            id="image"
                                            accept="image/*"
                                            aria-describedby="image-help-block"
                                            class=""
                                            :class="[ $v.form.image.$error ? 'is-invalid' : '' ]"
                                        ></b-form-file>
                                        <p class="text-white m-0 p-0 text-left animate__animated animate__fadeInUp animate__delay-2s">Recommended size for image is 256 x 256</p>
                                    </div>
                                </div>

                            </div>

                            <!-- install size field -->
                            <div class="row">
                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Install Size</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.install_size.$error }">
                                        <span><i class="fa-solid fa-file"></i></span>
                                        <b-form-input
                                            :key="install_change"
                                            v-model="form.install_size"
                                            type="text"
                                            placeholder="10GB"
                                            id="install_size"
                                            aria-describedby="title-help-block"
                                            class=""
                                            :class="{ 'is-invalid': $v.form.install_size.$error }" 
                                            v-mask="size_mask"
                                        ></b-form-input>
                                        <span style="border-top-left-radius:unset; border-bottom-left-radius:unset;">GB</span>
                                    </div>
                                </div>

                                <div class="d-flex mb-1 col-md-6 flex-column animate__animated animate__fadeInUp animate__delay-2s" :class="{ 'is_invalid': $v.form.is_survey_only.$error }">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">&nbsp;</label>
                                    <switches 
                                    class="active-switch h-53" 
                                    v-model="form.is_survey_only"
                                    theme="bootstrap" 
                                    color="primary"
                                    type-bold="true"
                                    label="Survey Only"
                                    >
                                    </switches>
                                </div>

                            </div>

                            <div
                            class="
                            w-form-control
                            animate__animated animate__fadeInUp animate__delay-3s m-0 p-0
                            "
                            >
                                <a
                                href="javascript:void(0)"
                                @click="handleGameReq"
                                :disabled="!is_valid_game"
                                class="flex-fill"
                                >Save</a
                                >
                            </div>

                        </b-form>
                    </div>
                </div>

            </div>

        </div>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <SuperAdminFooter type="inner" />
        </div>
        <!-- end of footer section -->
    </div>
</template>

<script>
import SuperAdminHeader from "@/views/superadmin/SuperAdminHeader.vue";
import SuperAdminFooter from "@/views/superadmin/SuperAdminFooter.vue";
import { adminUpdateGame, adminEditGame } from "../../services/AdminService";
import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import { required, minLength } from 'vuelidate/lib/validators';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import Switches from 'vue-switches';
import Select2 from 'vue3-select2-component';

const size_mask = createNumberMask({
    prefix:'',
    suffix:'',
    allowDecimal: true,
    includeThousandsSeparator: false,
    allowNegative: false,
});

export default {
  name: "SuperAdminEditGame-view",
  components: { SuperAdminHeader, SuperAdminFooter, Switches, Select2 },
  data() {
    return {
      edit_game_id: this.$route.params.game_id,
      game_details: [],
      is_valid_game: false,
      form: {
        title : '',
        search_title : '',
        platform : [],
        esrb : '',
        released : '',
        expired : '',
        genre : '',
        type : '',
        status : '',
        image : null,
        install_size: '',
        is_survey_only: 0,
      },
      size_mask,
      install_change: 0,
      types:[
        {'id': '', 'value' : 'Select a type'}, 
        {'id': 'local', 'value' : 'LOCAL'}, 
        {'id': 'cloud', 'value' : 'CLOUD'},
      ],
      statuses:[
        {'id': 'active', 'value' : 'ACTIVE'}, 
        {'id': 'inactive', 'value' : 'INACTIVE'}, 
        {'id': 'down', 'value' : 'DOWN'},
        {'id': 'expired', 'value' : 'EXPIRED'},
      ],
      platforms:[
        {'id': 'none', 'value' : 'None', 'text': 'None'}, 
        {'id': 'xb', 'value' : 'Xbox', 'text': 'Xbox'}, 
        {'id': 'ps', 'value' : 'PS5', 'text': 'PS5'}, 
        {'id': 'sw', 'value' : 'Switch', 'text': 'Switch'},
        {'id': 'pc', 'value' : 'PC', 'text': 'PC'},
      ],
      esrbs:[
        {'id': '', 'value' : 'Select a value'}, 
        {'id': 'Everyone', 'value' : 'Everyone'}, 
        {'id': 'Everyone 10+', 'value' : 'Everyone 10+'}, 
        {'id': 'Teen 13+', 'value' : 'Teen 13+'},
        {'id': 'Mature 17+', 'value' : 'Mature 17+'},
      ],
      genres:[
        {'id': '', 'value' : 'Select a genre'}, 
        {'id': 'Action & adventure', 'value' : 'Action & adventure'}, 
        {'id': 'Fighting', 'value' : 'Fighting'}, 
        {'id': 'Indie', 'value' : 'Indie'},
        {'id': 'Kids & family', 'value' : 'Kids & family'},
        {'id': 'Racing & flying', 'value' : 'Racing & flying'}, 
        {'id': 'Roleplaying', 'value' : 'Roleplaying'}, 
        {'id': 'Shooter', 'value' : 'Shooter'},
        {'id': 'Sports', 'value' : 'Sports'},
        {'id': 'Strategy', 'value' : 'Strategy'},
      ],
    };
  },

  validations: {
    form : {
      title : {required},
      search_title : {required},
      platform : {required},
      esrb : {required},
      released : {required, minLength: minLength(10)},
      expired : {required:false, minLength: minLength(10)},
      genre : {required},
      type : {required},
      status : {required},
      image : {required:false},
      install_size: {required:false},
      is_survey_only:{required}
    }
  },

  computed: {
  },

  methods: {

    handleGameReq() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.makeToast("error", "generic-required-message");
      } else {
        let user_data = JSON.parse(localStorage.user_sess_data);
        let data = this.form;
        data.token = user_data.token;
        data.game_id = this.edit_game_id;
        data.is_survey_only = data.is_survey_only ? 1 : 0;
        data.platform = data.platform.join(',');
        adminUpdateGame(data).then(response => {
          this.form.platform = data.platform.split(',');
          if(response.status){
            this.makeToast("success", response.message);
            this.form.image = null;
            this.getGameDetails();
          } else {
            this.makeToast("error", response.message);
          }
        }); 
      }
    },

    makeToast(variant = "default", message) {
        // this.$toast.open({
        // message: message,
        // type: variant,
        // position: 'top-right',
        // duration: 3000,
        // dismissible: true
        // });
        this.$root.$emit("toast_message", {'type' : variant, 'message' : message});
    },

    getGameDetails() {
      let user_data = JSON.parse(localStorage.user_sess_data);
      let params = {
        token: user_data.token,
        game_id: this.edit_game_id
      };
      adminEditGame(params).then(response => {
        if(response.status) {
          this.is_valid_game = true;
          this.game_details = response.data.game;
          let self = this;
          Object.keys(this.game_details).forEach(function(key,index) {
              if(Object.prototype.hasOwnProperty.call(self.form, key) && self.game_details[key] !== "" && self.game_details[key] !== null) {
                if(key == 'platform') {
                    self.form[key] = self.game_details['MultiplePlatforms'];
                    self.$refs.select_platform.setValue(self.game_details['MultiplePlatforms'])
                }
                else {
                    self.form[key] = self.game_details[key];
                    if(key == 'install_size') {
                        self.install_change = 50;
                    }
                }
                index;
              }
          });
        }
      });
    },
    changeEvent(value){
        this.form['platform'] = value;
    }
  },

  mounted() {
    this.getGameDetails();
  }

};
</script>