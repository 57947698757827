import { postPodLogoutWeb, setFlaskPodreserved } from "../../../services/FlaskService";
import router from "@/router/index";
import { getters } from "./getters.js";
export default {
    state() {
        return {
            is_reservation_start: false,
            is_reservation_end: false,
            reservation_data: {},
            reservation_activity_updater: null,
        };
    },
    getters,
    mutations: {
        setReservationAlertData(state, data) {
            state.reservation_data = data;
            
            if(Object.prototype.hasOwnProperty.call(data, 'to_start') && Object.prototype.hasOwnProperty.call(data, 'to_end')) {
                if(state.reservation_activity_updater != null) {
                    clearInterval(state.reservation_activity_updater);
                }
                localStorage.setItem('reservation_alert', JSON.stringify(data));

                state.reservation_activity_updater = setInterval(() => {
                    if(typeof localStorage.reservation_alert == 'undefined') {
                        clearInterval(state.reservation_activity_updater);
                        return;
                    }

                    let reservation_alert = JSON.parse(localStorage.getItem('reservation_alert'));
                    if(reservation_alert.to_start > 0 && reservation_alert.to_start <= 10800) {
                        state.is_reservation_start = true;
                        state.is_reservation_end = false;
                        reservation_alert.to_start -= 5;
                        localStorage.setItem('reservation_alert', JSON.stringify(reservation_alert));
                    }
                    else if(reservation_alert.to_end > 0 && reservation_alert.to_start < 0) {
                        state.is_reservation_start = true;
                        state.is_reservation_end = true;

                        // trigger pod reserved file creation
                        setFlaskPodreserved().then(reser => {reser});

                        // trigger logout if email not allowed
                        if(typeof localStorage.pod_sess_data !== 'undefined' && typeof localStorage.user_sess_data !== 'undefined' && reservation_alert.to_start <= 0) {
                            let user_data = JSON.parse(localStorage.user_sess_data);
                            let pod_data = JSON.parse(localStorage.pod_sess_data);

                            if(reservation_alert.emails_list.indexOf(user_data.email.toLowerCase()) !== -1) {
                                console.log('No need checking');
                            }
                            else {

                                if(user_data.role_id == 1 || user_data.role_id == 2) {
                                    let params = {
                                        session_id : pod_data.session_id_key,
                                        user_id : user_data.user_id,
                                        pod_id_key: pod_data.pod_id_key,
                                        logout_reason: 'web'
                                    };
                                    postPodLogoutWeb(params).then(log_web => {
                                        console.log("WEB LOGOUT API CALLED", log_web);
                                    });
                                    router.push({ name: "Player Logout", params: { 'reason': 'This Gamepod has a reservation for ' + reservation_alert.start_time }});
                                }
                            }
                        }
                        reservation_alert.to_end -= 5;
                        localStorage.setItem('reservation_alert', JSON.stringify(reservation_alert));
                    }
                    else {
                        if(reservation_alert.to_end <= 0) {
                            state.is_reservation_start = false;
                            state.is_reservation_end = false;
                            state.reservation_data = {};
                            state.reservation_activity_updater = null;
                            localStorage.removeItem('reservation_alert');
                            clearInterval(state.reservation_activity_updater);

                            // trigger logout if not triggered already
                            if(typeof localStorage.pod_sess_data !== 'undefined' && typeof localStorage.user_sess_data !== 'undefined') {
                                let user_data = JSON.parse(localStorage.user_sess_data);
                                let pod_data = JSON.parse(localStorage.pod_sess_data);
                                
                                if(user_data.role_id == 1 || user_data.role_id == 2) {
                                    let params = {
                                        session_id : pod_data.session_id_key,
                                        user_id : user_data.user_id,
                                        pod_id_key: pod_data.pod_id_key,
                                        logout_reason: 'web'
                                    };
                                    postPodLogoutWeb(params).then(log_web => {
                                        console.log("WEB LOGOUT API CALLED", log_web);
                                    });
                                    router.push({ name: "Player Logout", params: {'reason': 'The reservation time has ended.'}});
                                }
                            }
                        }
                        state.is_reservation_start = false;
                        state.is_reservation_end = false;
                        state.reservation_data = {};
                        state.reservation_activity_updater = null;
                        localStorage.removeItem('reservation_alert');
                        clearInterval(state.reservation_activity_updater);
                    }
                }, 5000);
            }
            else {
                state.is_reservation_alert = false;
                state.reservation_data = {};
                state.reservation_activity_updater = null;
                localStorage.removeItem('reservation_alert');
                clearInterval(state.reservation_activity_updater);
            }
        },
    },
};
