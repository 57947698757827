<template>
    <div class="main-layout-section admin-portal" :class="[user_data.role_id == 4 ? '' : 'secondary']">
        <AdminHeader @setPodData="setPodData($event)" v-if="user_data.role_id == 3" />
        <SuperAdminHeader v-if="user_data.role_id == 4" />
        <FacHeader @setPodData="setPodData($event)" v-if="user_data.role_id == 2" />

        <div class="w-logo mb-3">
            <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="logo_file !== '' && logo_file !== null && user_data.role_id !== 4"/>
            <img src="../../assets/images/gamelab-logo.svg" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="user_data.role_id == 4"/>
        </div>

        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeInUp animate__delay-1s">Manage Alerts</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">
                    
                    <div class="row">
                        <div class="admin-general-section-buttons col-md-12">
                            <router-link :to="dashboard_link" class="admin-general-section-button">
                                Back To Dashboard
                            </router-link>
                        </div>
                        <div class="admin-general-section-buttons col-md-12">
                            <router-link :to="dashboard_link" class="alerts-breadcrumb">
                                Dashboard
                            </router-link>
                            <span class="alerts-breadcrumb">></span>
                            <span class="alerts-breadcrumb">Alerts</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 col-sm-12 alerts-section-heading first d-none">
                            <h5>Create A New Alert</h5>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="overflow-auto p-0 m-0">
                                <table id="alerts-table" role="table" aria-busy="false" aria-colcount="6" class="table b-table table-striped">
                                    <tbody role="rowgroup">
                                        <tr role="row" class="">
                                            <td aria-colindex="1" role="cell" class="alert-td-display">
                                                <div class="alert-preview" :class="[alert_form.type == 'success' ? 'success' : '', alert_form.type == 'failure' ? 'error' : '', alert_form.type == 'warning' ? 'warning' : '']">
                                                    <div class="alert-message">
                                                        <h5>
                                                            <input
                                                            type="text"
                                                            value=""
                                                            placeholder="Enter alert text here in this preview"
                                                            v-model="alert_form.text_head"
                                                            />
                                                        </h5>
                                                        <p>
                                                            <input
                                                            type="text"
                                                            value=""
                                                            placeholder="window so you know what fits!"
                                                            v-model="alert_form.text_sub"
                                                            />
                                                        </p>
                                                    </div>
                                                    <div class="icon-errorrr" v-if="alert_form.type == 'check'">
                                                        <i class="fas fa-circle fa-2xl icon h2 m-0"></i>
                                                    </div>
                                                    <div class="icon-success" v-if="alert_form.type == 'success'">
                                                        <i class="fa-solid fa-circle-check"></i>
                                                    </div>
                                                    <div class="icon-error" v-if="alert_form.type == 'failure'">
                                                        <i class="fa-solid fa-times-circle"></i>
                                                    </div>
                                                    <div class="icon-warning" v-if="alert_form.type == 'warning'">
                                                        <i class="fa-solid fa-exclamation-circle"></i>
                                                    </div>
                                                </div>
                                            </td>
                                            <td aria-colindex="2" role="cell" class="alert-td-datetime">
                                                <div class="alert-items">
                                                    <span class="alert-item-date">
                                                        <input
                                                            type="text"
                                                            value=""
                                                            placeholder="MM/DD/YYYY"
                                                            v-model="alert_form.active_start_date" 
                                                            v-mask="date_mask"
                                                        />
                                                    </span>
                                                    <span class="alert-item-time">
                                                        <input
                                                            type="text"
                                                            value=""
                                                            placeholder="00:00"
                                                            v-model="alert_form.active_start_time" 
                                                            v-mask="time_mask"
                                                        />
                                                    </span>
                                                    <span class="alert-item-ampm">
                                                        <select name="" id="" v-model="alert_form.active_start_ampm">
                                                            <option value="AM">AM</option>
                                                            <option value="PM">PM</option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td aria-colindex="3" role="cell" class="alert-td-datetime">
                                                <div class="alert-items">
                                                    <span class="alert-item-date">
                                                        <input
                                                            type="text"
                                                            value=""
                                                            placeholder="MM/DD/YYYY"
                                                            v-model="alert_form.active_end_date" 
                                                            v-mask="date_mask"
                                                        />
                                                    </span>
                                                    <span class="alert-item-time">
                                                        <input
                                                            type="text"
                                                            value=""
                                                            placeholder="00:00"
                                                            v-model="alert_form.active_end_time" 
                                                            v-mask="time_mask"
                                                        />
                                                    </span>
                                                    <span class="alert-item-ampm">
                                                        <select v-model="alert_form.active_end_ampm">
                                                            <option value="AM">AM</option>
                                                            <option value="PM">PM</option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td aria-colindex="4" role="cell" class="alert-td-type">
                                                <div class="alert-items">
                                                    <span class="alert-item-type-selection">
                                                        <select v-model="alert_form.type">
                                                            <option 
                                                            v-for="(selectOption, indexOpt) in alert_types" 
                                                            :key="indexOpt"
                                                            :value="selectOption.id"
                                                            >
                                                                {{ selectOption.value }}
                                                            </option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td aria-colindex="5" role="cell" class="alert-td-type" v-if="user_data.role_id == 4">
                                                <div class="alert-items">
                                                    <span class="alert-item-type-selection">
                                                        <select v-model="alert_form.scope">
                                                            <option 
                                                            v-for="(selectOption, indexOpt) in alert_scopes" 
                                                            :key="indexOpt"
                                                            :value="selectOption.id"
                                                            >
                                                                {{ selectOption.value }}
                                                            </option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td aria-colindex="5" role="cell" class="alert-td-type" v-if="user_data.role_id == 4">
                                                <div class="alert-items">
                                                    <span class="alert-item-type-selection">
                                                        <select v-model="alert_form.clients" @change="labsByClient">
                                                            <option 
                                                            v-for="(selectOption, indexOpt) in clients" 
                                                            :key="indexOpt"
                                                            :value="selectOption.id"
                                                            >
                                                                {{ selectOption.value }}
                                                            </option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td aria-colindex="4" role="cell" class="alert-td-location">
                                                <div class="alert-items alert-items-location">
                                                    <div class="alert-item-location-selection create-edit">
                                                        <select v-model="alert_form.labs">
                                                            <option 
                                                            v-for="(selectOption, indexOpt) in labs" 
                                                            :key="indexOpt"
                                                            :value="selectOption.id"
                                                            >
                                                                {{ selectOption.value }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </td>
                                            <td aria-colindex="5" role="cell" class="alert-td-enabled">
                                                <div class="alert-items alert-items-enabled">
                                                    <i class="fas fa-circle-check" @click="handleEnableFlag('1')" v-if="alert_form.enable == 0"></i>
                                                    <i class="fas fa-circle-check icon-checked" @click="handleEnableFlag('0')" v-if="alert_form.enable == 1"></i>
                                                </div>
                                            </td>
                                            <td aria-colindex="6" role="cell" class="alert-td-action">
                                                <div class="alert-action-button">
                                                    <button class="btn-sm" @click="handleAlertReq">
                                                        <template v-if="edit_id !== ''">Update</template>
                                                        <template v-if="edit_id == ''">Save</template>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-12 col-sm-12 alerts-section-heading d-none">
                            <h5>Edit Current Alerts</h5>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="overflow-auto p-0 m-0">
                                <b-table
                                id="alerts-table"
                                :striped="true"
                                :hover="false"
                                :dark="false"
                                :items="alerts"
                                :fields="(user_data.role_id == 4) ? fields_superadmin : fields"
                                show-empty
                                :emptyText="emptyText" 
                                >   
                                    <!-- start table header elements -->
                                    <template #head()="data">
                                        <div class="alert-header" v-if="data.label !== '' && data.label !== 'Enabled' && data.label !== 'Type' && data.label !== 'Scope'  && data.label !== 'Client'">
                                            <span>{{ data.label }}</span>
                                            <div class="alert-sort-buttons">
                                                <button class="btn-sm" :class="[(sort_by == data.column && sort_type == 'desc') ? 'active-sort' : '']" @click="handleSorting(data.column, 'desc')">
                                                    <i class="fas fa-caret-down icon m-0"></i>
                                                </button>
                                                <button class="btn-sm" :class="[(sort_by == data.column && sort_type == 'asc') ? 'active-sort' : '']" @click="handleSorting(data.column, 'asc')">
                                                    <i class="fas fa-caret-up icon m-0"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </template>
                                    <!-- end table header elements -->
                                    <template #cell(text_head)="data">
                                        <div class="alert-preview" :class="[data.item.type == 'success' ? 'success' : '', data.item.type == 'failure' ? 'error' : '', data.item.type == 'warning' ? 'warning' : '']">
                                            <div class="alert-message">
                                                <h5>{{ data.item.text_head }}</h5>
                                                <p>{{ data.item.text_sub }}</p>
                                            </div>
                                            <div class="icon-errorrr" v-if="data.item.type == 'check'">
                                                <i class="fas fa-circle fa-2xl icon h2 m-0"></i>
                                            </div>
                                            <div class="icon-success" v-if="data.item.type == 'success'">
                                                <i class="fa-solid fa-circle-check"></i>
                                            </div>
                                            <div class="icon-error" v-if="data.item.type == 'failure'">
                                                <i class="fa-solid fa-times-circle"></i>
                                            </div>
                                            <div class="icon-warning" v-if="data.item.type == 'warning'">
                                                <i class="fa-solid fa-exclamation-circle"></i>
                                            </div>
                                        </div>
                                    </template>
                                    <template #cell(active_start)="data">
                                        <div class="alert-items">
                                            <span class="alert-item-date">{{ data.item.alert_start.date == "" ? "MM/DD/YYYY" : data.item.alert_start.date }}</span>
                                            <span class="alert-item-time">{{ data.item.alert_start.time == "" ? "00:00" : data.item.alert_start.time }}</span>
                                            <span class="alert-item-ampm">{{ data.item.alert_start.ampm }}</span>
                                        </div>
                                    </template>
                                    <template #cell(active_untill)="data">
                                        <div class="alert-items">
                                            <span class="alert-item-date">{{ data.item.alert_end.date == "" ? "MM/DD/YYYY" : data.item.alert_end.date }}</span>
                                            <span class="alert-item-time">{{ data.item.alert_end.time == "" ? "00:00" : data.item.alert_end.time }}</span>
                                            <span class="alert-item-ampm">{{ data.item.alert_end.ampm }}</span>
                                        </div>
                                    </template>
                                    <template #cell(type)="data">
                                        <div class="alert-items">
                                            <span class="alert-item-type-selection">{{ data.item.type }}</span>
                                        </div>
                                    </template>
                                    <template #cell(scope)="data">
                                        <div class="alert-items">
                                            <span class="alert-item-type-selection">{{ data.item.scope }}</span>
                                        </div>
                                    </template>
                                    <template #cell(clients)="data">
                                        <div class="alert-items">
                                            <span class="alert-item-type-selection">
                                                <template v-if="data.item.linkedclient !== null && Object.prototype.hasOwnProperty.call(data.item.linkedclient, 'first_name')">
                                                    {{ data.item.linkedclient.first_name }}
                                                </template>
                                            </span>
                                        </div>
                                    </template>
                                    <template #cell(locations)="data">
                                        <div class="alert-items alert-items-location">
                                            <div class="alert-item-location-selection">
                                                <span v-if="data.item.scope == 'global'">
                                                    All Clients
                                                </span>
                                                <span v-else-if="(data.item.scope == 'client' || data.item.scope == 'lab') && data.item.linkedclient !== null && Object.prototype.hasOwnProperty.call(data.item.linkedclient, 'first_name')">
                                                    {{ data.item.linkedclient.first_name }}
                                                </span>
                                                <span v-else>&nbsp;</span>
                                                
                                                <span v-if="data.item.scope == 'global'">
                                                    All Labs
                                                </span>
                                                <span v-else-if="data.item.scope == 'client' && data.item.linkedclient !== null && Object.prototype.hasOwnProperty.call(data.item.linkedclient, 'first_name')">
                                                    All Labs
                                                </span>
                                                <span v-else-if="data.item.scope == 'lab' && data.item.linkedlab !== null && Object.prototype.hasOwnProperty.call(data.item.linkedlab, 'lab_name')">
                                                    {{ data.item.linkedlab.lab_name }}
                                                </span>
                                                <span v-else>&nbsp;</span>

                                            </div>
                                            <div class="alert-item-location-icon">
                                                <i class="fas fa-caret-down icon m-0"></i>
                                            </div>
                                        </div>
                                    </template>
                                    <template #cell(enable)="data">
                                        <div class="alert-items alert-items-enabled">
                                            <i class="fas fa-circle-check" v-if="data.item.enable == 0"></i>
                                            <i class="fas fa-circle-check icon-checked" v-if="data.item.enable == 1"></i>
                                        </div>
                                    </template>
                                    <template #cell(action)="data">
                                        <div class="alert-action-button" :class="data.item.enable">
                                            <button class="btn-sm" @click="handleEditClick(data.index, data.item)" v-if="edit_id !== data.item.id">Edit</button>
                                            <button class="btn-sm" @click="cancelEditClick()" v-if="edit_id == data.item.id">Cancel</button>
                                            <button class="btn-sm" @click="handleDeleteClick(data.item)" :disabled="is_delete">Delete</button>
                                        </div>
                                    </template>
                                    <template #empty="scope">
                                    <p class="text-center p-0 m-0">{{ scope.emptyText }}</p>
                                    </template>
                                </b-table>
                            </div>
                            <hr class="alerts-border">
                            <p class="alerts-pagination-status-text">{{ status_text }}</p>
                            <div class="alerts-pagination-container">
                                <div class="alerts-pagination">
                                    <button class="btn-prev" @click="hanldePagination('prev')" :class="[current_page == 1 ? 'alerts-page-disabled' : '']">Previous Pg</button>
                                    <button class="btn-prev" @click="hanldePagination('next')" :class="[current_page == last_page ? 'alerts-page-disabled' : '']">Next Pg</button>
                                </div>
                                <div class="alerts-back">
                                    <!-- <router-link :to="dashboard_link" class="back-button">
                                        Back To Dashboard
                                    </router-link> -->
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <AdminFooter type="inner" v-if="user_data.role_id == 3" />
            <SuperAdminFooter type="inner" v-if="user_data.role_id == 4" />
            <FacFooter type="inner" v-if="user_data.role_id == 2" />
        </div>
        <!-- end of footer section -->
    </div>
</template>

<script>
import AdminHeader from "@/views/admin/AdminHeader.vue";
import { adminGetAlertsList, adminGetClientsList, adminGetLabsList, adminSaveAlert, adminDeleteAlert } from "../../services/AdminService";
import AdminFooter from "@/views/admin/AdminFooter.vue";
import SuperAdminHeader from "@/views/superadmin/SuperAdminHeader.vue";
import SuperAdminFooter from "@/views/superadmin/SuperAdminFooter.vue";
import FacHeader from "@/views/facilitator/FacHeader.vue";
import FacFooter from "@/views/facilitator/FacFooter.vue";
import { global_config } from "@/config/config.js";
import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import { required, requiredIf } from 'vuelidate/lib/validators';
import moment from 'moment';
import moment_timezone from 'moment-timezone';

export default {
    name: "UsersList-view",
    components: { AdminHeader, AdminFooter, SuperAdminHeader, SuperAdminFooter, FacFooter, FacHeader },
    data() {
        return {
            api_state: false,
            sort_by: 'active_start',
            sort_type: 'desc',
            search_client_id: '',
            user_data: null,
            dashboard_link: '',
            status_text: '',
            per_page: 7,
            current_page: 1,
            total_rows: 0,
            last_page: 1,
            emptyText:"No alerts found at the moment!",
            emptyHtml:"No alerts found at the moment!",
            fields: [
                {'key' : 'text_head', 'label' : 'Alert Display Text', class:'alert-td-display'},
                {'key' : 'active_start', 'label' : 'Start Date/Time (CDT)', class:'alert-td-datetime'},
                {'key' : 'active_untill', 'label' : 'End Date/Time (CDT)', class:'alert-td-datetime'},
                {'key' : 'type', 'label' : 'Type', class:'alert-td-type'},
                {'key' : 'locations', 'label' : 'Alert Locations', class:'alert-td-location'},
                {'key' : 'enable', 'label' : 'Enabled', class:'alert-td-enabled'}, 
                {'key' : 'action', 'label' : '', class:'alert-td-action'}
            ],
            fields_superadmin: [
                {'key' : 'text_head', 'label' : 'Alert Display Text', class:'alert-td-display'},
                {'key' : 'active_start', 'label' : 'Start Date/Time (CDT)', class:'alert-td-datetime'},
                {'key' : 'active_untill', 'label' : 'End Date/Time (CDT)', class:'alert-td-datetime'},
                {'key' : 'type', 'label' : 'Type', class:'alert-td-type'},
                {'key' : 'scope', 'label' : 'Scope', class:'alert-td-scope'},
                {'key' : 'clients', 'label' : 'Client', class:'alert-td-scope'},
                {'key' : 'locations', 'label' : 'Alert Locations', class:'alert-td-location'},
                {'key' : 'enable', 'label' : 'Enabled', class:'alert-td-enabled'}, 
                {'key' : 'action', 'label' : '', class:'alert-td-action'}
            ],
            alerts: [],
            clients: [],
            labs:[],
            alert_scopes: global_config.ALERT_SCOPES,
            alert_types: global_config.ALERT_TYPES,
            alert_form: {
                text_head:'',
                text_sub:'',
                type:'check',
                active_start_date: '',
                active_start_time: '',
                active_start_ampm: 'AM',
                active_end_date: '',
                active_end_time: '',
                active_end_ampm: 'AM',
                scope:'global',
                clients: '',
                labs:'',
                enable:1,
            },
            date_mask: '##/##/####',
            time_mask: '##:##',
            edit_id:'',
            user_timezone: moment.tz.guess(),
            mtz: moment_timezone,
            is_delete: false,
        };
    },

    validations: {
        alert_form : {
            clients:{
                required: requiredIf(function (params) {
                    params;
                    if(this.user_data.role_id == 4 && this.alert_form.scope == 'client') {
                        return true;
                    }
                    return false;  
                })
            },
            labs:{
                required: requiredIf(function (params) {
                    params;
                    if(this.user_data.role_id == 4 && this.alert_form.scope == 'lab') {
                        return true;
                    }
                    return false;  
                })
            },
            text_head:{required},
            text_sub:{required:false},
            type:{required},
            active_start_date:{required:false},
            active_start_time:{required:false},
            active_start_ampm:{required:false},
            active_end_date:{required:false},
            active_end_time:{required:false},
            active_end_ampm:{required:false},
            scope:{
                required: requiredIf(function (params) {
                    params;
                    if(this.user_data.role_id == 4) {
                        return true;
                    }
                    return false;  
                })
            },
            enable: {required}
        }
    },
    computed: {
        logo_file() {
            return this.$store.getters.getThemeLogo().logo;
        }
    },

    methods: {
        labsList() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                client_id: (this.alert_form.clients !== '') ? this.alert_form.clients : user_data.client_id
            };
            adminGetLabsList(params).then(response => {
                this.labs = response.data.labs;
                // add empty option as placeholder
                this.labs.splice(0, 0, {'id' : '', 'value' : 'All Labs'});
            });
        },
        alertsList() {
            this.is_searching = true;
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                client_id: (this.search_client_id !== '') ? this.search_client_id : user_data.client_id,
                length: this.per_page,
                page: this.current_page,
                sort_by: this.sort_by,
                sort_type: this.sort_type,
                user_timezone: this.user_timezone
            };
            adminGetAlertsList(params).then(response => {
                this.is_searching = false;
                this.alerts = response.data.alerts;
                this.current_page = response.data.current_page;
                this.total_rows = response.data.total_rows;
                this.last_page = response.data.last_page;
                this.status_text = response.data.status_text;
                if(this.alerts.length > 0) {
                    this.alerts.map(function(lb){
                        return lb.is_editing=false;
                    });
                }
            });
        },
        makeToast(variant = "default", message, message1='') {
            this.$root.$emit("toast_message", {'type' : variant, 'message' : message, 'message1': message1});
        },
        setPodData(obj) {
            this[obj.key] = obj.value;
        },
        setUserData() {
            this.user_data = JSON.parse(localStorage.user_sess_data);
            if(!Object.prototype.hasOwnProperty.call(this.user_data, 'role_id')) {
                this.$router.push("/admin/login");
            }
            this.dashboard_link = this.user_data.role_id == 2 ? '/facilitator/dashboard' : (this.user_data.role_id == 3 ? '/admin/dashboard' : '/superadmin/dashboard');
            if(this.user_data.role_id == 4) {
                this.clientsList();
            }
        },
        handleSorting(column, type) {
            if(!(this.sort_by == column && this.sort_type == type)) {
                this.sort_by = column;
                this.sort_type = type;
                this.alertsList();
            }
        },
        clientsList() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token
            };
            adminGetClientsList(params).then(response => {
                this.clients = response.data.clients;
                // add empty option as placeholder
                this.clients.splice(0, 0, {'id' : '', 'value' : 'All clients'});
            });
        },
        handleAlertReq() {
            // fill in client id if user is not superadmin
            if(this.user_data.role_id !== 4) {
                this.alert_form.clients = this.user_data.client_id;
            }
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.makeToast("error", "Please fill all required fields");
            } 
            else {
                let user_data = JSON.parse(localStorage.user_sess_data);
                let data = this.alert_form;
                data.token = user_data.token;
                data.active_start = "";
                data.active_untill = "";
                if(data.active_start_date !== "" && data.active_start_time !== "" && data.active_end_date !== "" && data.active_end_time !== "") {
                    data.active_start = data.active_start_date + " " + data.active_start_time + " " + data.active_start_ampm;
                    data.active_untill = data.active_end_date + " " + data.active_end_time + " " + data.active_end_ampm;
                }
                data.edit_id = this.edit_id;
                data.user_timezone = this.user_timezone;
                data.enable = data.enable ? 1 : 0;
                adminSaveAlert(data).then(response => {
                    if(response.status){
                        this.makeToast("success", response.message);
                        this.$v.alert_form.$reset();
                        this.cancelEditClick();
                        this.alertsList();
                    } 
                    else {
                        this.makeToast("error", response.message);
                    }
                });
            }
        },
        handleEnableFlag(value) {
            this.alert_form.enable = value;
        },
        handleEditClick(index, item) {
            let will_edit = true;
            // check if facility
            if(this.user_data.role_id == 2 && item.user !== this.user_data.user_id) {
                will_edit = false;
                this.makeToast('error', "You can't edit this alert, contact:", "Admin or " + item.owner.email);
            }
            else if ((this.user_data.role_id == 3 || this.user_data.role_id == 4) && item.user !== this.user_data.user_id) {
                will_edit = true;
                this.makeToast('warning', "Editing this alert will overwrite it...", "Last edit by: " + item.owner.email);
            }
            if(will_edit) {
                this.alerts[index].is_editing = true;
                this.alert_form.text_head = item.text_head;
                this.alert_form.text_sub = item.text_sub;
                this.alert_form.active_start_date = item.alert_start.date;
                this.alert_form.active_start_time = item.alert_start.time;
                this.alert_form.active_start_ampm = item.alert_start.ampm;
                this.alert_form.active_end_date = item.alert_end.date;
                this.alert_form.active_end_time = item.alert_end.time;
                this.alert_form.active_end_ampm = item.alert_end.ampm;
                this.alert_form.type = item.type;
                this.alert_form.scope = item.scope;
                this.alert_form.clients = item.clients == null ? '' : item.clients;
                this.alert_form.labs = item.labs == null ? '' : item.labs;
                this.alert_form.enable = item.enable;
                this.edit_id = item.id;
                this.labsList();
            }
        },
        cancelEditClick() {
            this.edit_id = '';
            this.alert_form = {
                text_head:'',
                text_sub:'',
                type:'check',
                active_start_date: moment().format('MM/DD/YYYY'),
                active_start_time: moment().format('hh:mm'),
                active_start_ampm: moment().format('A'),
                active_end_date: '',
                active_end_time: '',
                active_end_ampm: 'AM',
                scope:'global',
                clients: '',
                labs:'',
                enable:1,
            };
        },
        hanldePagination(type) {
            if(type == 'prev' && this.current_page !== 1) {
                this.current_page -= 1;
                this.alertsList();
            }
            else if(type == 'next' && this.current_page !== this.last_page) {
                this.current_page += 1;
                this.alertsList();
            }
        },
        labsByClient() {
            if(this.alert_form.clients == "") {
                this.labs = [];
            }
            else {
                this.labsList();
            }
        },
        handleDeleteClick(item) {
            let will_edit = true;
            // check if facility
            if(this.user_data.role_id == 2 && item.user !== this.user_data.user_id) {
                will_edit = false;
                this.makeToast('error', "You can't delete this alert, contact:", "Admin or " + item.owner.email);
            }
            else if ((this.user_data.role_id == 3 || this.user_data.role_id == 4) && item.user !== this.user_data.user_id) {
                will_edit = true;
                this.makeToast('warning', "You are going to delete someone's alert...", "Last edit by: " + item.owner.email);
            }
            if(will_edit) {
                this.is_delete = true;
                let user_data = JSON.parse(localStorage.user_sess_data);
                let params = {
                    token: user_data.token,
                    id: item.id
                };
                adminDeleteAlert(params).then(response => {
                    this.is_delete = false;
                    if(response.status){
                        this.makeToast("success", response.message);
                        this.alertsList();
                    } 
                    else {
                        this.makeToast("error", response.message);
                    }
                });
            }
        },
    },

    mounted() {
        this.alertsList();
        this.labsList();
        // set start datetime on load
        this.alert_form.active_start_date = moment().format('MM/DD/YYYY');
        this.alert_form.active_start_time = moment().format('hh:mm');
        this.alert_form.active_start_ampm = moment().format('A');
    },
    created() {
        this.setUserData();
    }
};
</script>