<template>
    <div
    class="
        animate__animated animate__fadeIn animate__delay-3s
    "
    :class="[ type !== '' ? 'w-footer-block-inner' : 'w-footer-block' ]"
    >
        <div class="w-footer-logo">
            <object type="image/svg+xml" tabindex="-1" :data="require(`../../assets/images/gamelab.svg`)"></object>
        </div>
        <div class="w-chat-box" @click="$root.$emit('live-chat-widget-visibility', 'show-hide');">
            <!-- <span><i class="fa-brands fa-rocketchat"></i></span> -->
            <span tabindex="0"><img src="../../assets/images/chat-icon.png" alt="chat with us"></span>
        </div>
    </div>
</template>
<script>
export default {
    name: "FacFooter-view",
    data() {
        return {
        };
    },
    props: ['type'],
    mounted() {
    }
}
</script>