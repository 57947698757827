<template>
    <div class="main-contenttt">
        <h2 class="page-heading animate__animated animate__fadeIn animate__delay-1s saveseat-heading">Seat Saved!</h2>
        <h3 class="page-subheading mb-1 animate__animated animate__fadeIn animate__delay-1s saveseat-subheading">Return before timer expires<br>or you will be logged out.</h3>

        <!-- start timer section -->
        <div
            class="w-form-control timer animate__animated animate__fadeInUp animate__delay-2s timer_with_kb" 
        >
            <div class="timer-counter">{{ Math.ceil(seat_counter / 60000) }}</div>
            <div class="timer-unit">min</div>
        </div>
        <!-- end timer section -->

        <!-- start of login section -->
        <section class="login-page m-auto mt-4" style="width: 370px;">
            <form action="" class="" @submit.stop.prevent>
                <h3 class="page-subheading mb-1 animate__animated animate__fadeIn animate__delay-2s saveseat-username">{{username}}</h3>
                <div
                class="
                    w-form-control w-input-fields
                    animate__animated animate__fadeInUp animate__delay-2s
                "
                >
                <span><i class="fa-solid fa-lock"></i></span>
                <input
                    type="password"
                    name="password"
                    value=""
                    v-model="password"
                    placeholder="s3cretp@ssword" 
                    @keyup.enter="onPressEnter"
                />
                </div>
                <div class="main-action-box w-login-hide">
                    <div
                        class="
                        w-form-control
                        animate__animated animate__fadeInUp animate__delay-2s
                        "
                    >
                        <a
                        href="javascript:void(0)"
                        class="flex-fill" 
                        @click="handleUnlockRequest"
                        >Unlock</a
                        >
                    </div>
                </div>
                <div class="w-login-show" style="width: 370px !important; text-align: center; margin: auto;">
                    <div class="notification-block p-1"></div>
                </div>
            </form>

            <!-- start notification message section -->
            <div
            class="w-login-show animate__animated animate__fadeInUp"
            v-if="noti_class !== '' && noti_message !== ''"
            >
                <div class="notification-block" :class="noti_class">
                    <div class="notification-message">
                    <h5>{{ noti_message }}</h5>
                    <p v-if="noti_message1 !== ''">{{ noti_message1 }}</p>
                    </div>
                    <div class="icon-success" v-if="noti_class == 'success'">
                    <i class="fa-solid fa-circle-check"></i>
                    </div>
                    <div class="icon-error" v-if="noti_class == 'error'">
                    <i class="fa-solid fa-times-circle"></i>
                    </div>
                    <div class="icon-warning" v-if="noti_class == 'warning'">
                    <i class="fa-solid fa-exclamation-circle"></i>
                    </div>
                </div>
            </div>
        </section>
        <!-- end of login section -->
    </div>
</template>
  
<script>
import { userUnlock } from "../services/HomepageService";
import { global_config } from "@/config/config.js";
export default {
    name: "FacSaveSeatWidget-view",
    data() {
        return {
            seat_polling: null,
            username: "",
            password: "",
            user_data: null,
            messages: global_config.MESSAGES,
            seat_counter: 0,
            noti_class: '',
            noti_message: '',
            save_seat: false,
            save_seat_data: null,
        };
    },

    computed: {
    },

    methods: {
        setUserData() {
            this.user_data = JSON.parse(localStorage.user_sess_data);
            if(!Object.prototype.hasOwnProperty.call(this.user_data, 'role_id') || !Object.prototype.hasOwnProperty.call(this.user_data, 'email')) {
                this.$router.push("/");
            }
            this.username = this.user_data.email;
            // set item for tracking
            if(typeof localStorage.fac_save_seat == 'undefined') {
                localStorage.setItem('fac_save_seat', JSON.stringify({'active': 1, 'timer': process.env.VUE_APP_POLLING_SAVE_SEAT_ADMIN * 60 * 1000}));
            }
        },
        handleUnlockRequest() {
            if (this.password == "" || this.username == "")
                this.makeToast('generic-required-message', 'error');
            else {
                let pod_data = JSON.parse(localStorage.pod_sess_data);
                let session_id = pod_data.session_id_key;
                userUnlock(session_id, this.username, this.password).then((response) => {
                    if (response.status) {
                        clearInterval(this.seat_polling);
                        localStorage.removeItem('fac_save_seat');
                        this.$emit('initSaveSeat', false);
                    } else {
                        if(response.message == 'invalid') {
                            this.makeToast('saveseat-invalid-error-message', 'error');
                        }
                        else {
                            this.makeToast(response.message, 'error', response.message);
                        }
                    }
                });
            }
        },
        onPressEnter() {
            this.handleUnlockRequest();
        },
        pollSaveSeat() {
            clearInterval(this.seat_polling);
            this.seat_counter = process.env.VUE_APP_POLLING_SAVE_SEAT_ADMIN * 60 * 1000;
            // check if local storage save seat is not empty
            this.save_seat = false;
            this.save_seat_data = null;          
            if(typeof localStorage.fac_save_seat !== 'undefined') {
                this.save_seat = true;
                this.save_seat_data = JSON.parse(localStorage.getItem('fac_save_seat'));
                this.seat_counter = this.save_seat_data.timer;
            }

            this.seat_polling = setInterval(() => {
                localStorage.setItem('last_activity', new Date());
                this.seat_counter -= 15000;
                // update local storage save seat
                localStorage.setItem('fac_save_seat', JSON.stringify({'active': 1, 'timer': this.seat_counter}));

                if(this.seat_counter <= 0) {
                    clearInterval(this.seat_polling);
                    this.$emit('handleLogout', {reason: 'Save seat expired', source: 'web'});   
                }
            }, 15000);
        },
        makeToast(message_key = '', variant = "default", message="", message1="") {
            // search for key
            let smessage = this.$store.state.system_messages.list.find(o => o.msg_key === message_key);
            if(smessage !== null && typeof smessage !== 'undefined' && Object.prototype.hasOwnProperty.call(smessage, 'text_head')) {
                this.noti_class = smessage.type == 'failure' ? 'error' : smessage.type;
                this.noti_message = smessage.text_head;
                this.noti_message1 = smessage.text_sub;
            }
            else {
                this.noti_class = variant;
                this.noti_message = message;
                this.noti_message1 = message1;
            }
            // hide error div
            setTimeout(() => {
                this.noti_class = "";
                this.noti_message = "";
                this.noti_message1 = "";
            }, 5000);
        },
    },

    mounted() {
        this.pollSaveSeat();
    },
    beforeDestroy () {
        clearInterval(this.seat_polling);
    },
    created () {
        this.setUserData();
    }
};
</script>
<style scoped>
    .main-contenttt {
        width: 100%;
        min-height: 72vh;
    }
    div.timer {
        position: relative;
        margin: auto;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        /* overflow: hidden; */
        color: #d8d8d8;
        border-radius: 50%;
        font-weight: 700;
        font-family: 'Open Sans';
        animation: 60s 0.875s cubic-bezier(0.9, 0, 0.1);
    }

    div.timer_with_kb {
        width: 150px;
        height: 150px;
        font-size: 70px;
        border: 2px solid var(--client-primary);
        margin-top: 25px;
    }

    @keyframes timer_beat {
        40%,
        80% {
            transform: none; }
        50% {
            transform: scale(1.125);
        }
    }
    div.timer:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -100;
        width: 100%;
        height: 100%;
        animation: 60s 1s linear forwards timer_indicator;
    }

    @keyframes timer_indicator {
        100% {
            transform: translateY(100%);
        }
    }
    .saveseat-username {
        font-size: 20px;
        font-family: 'Open Sans';
        padding-bottom: 10px;
    }
    .saveseat-heading {
        font-size: 30px;
        font-family: 'Open Sans';
        font-weight: 600;
    }
    .saveseat-subheading {
        font-size: 16px;
        font-weight: normal;
        font-family: 'Open Sans';
    }
    .timer-unit {
        position: absolute;
        left: 130px;
        font-size: 14px;
        bottom: 0px;
        font-family: 'Open Sans';
    }
</style>