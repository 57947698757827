<template>
    <div
    class="
        animate__animated animate__fadeIn animate__delay-1s
    "
    :class="[ type !== '' ? 'w-footer-block-inner' : 'w-footer-block px-0 pb-1' ]"
    >
        <div class="w-footer-logo">
            <object type="image/svg+xml" tabindex="-1" :data="require(`../../assets/images/gamelab.svg`)"></object>
        </div>
        <div class="w-chat-box" :class="[ (typeof visib !== 'undefined' && visib !== '') ? visib : '' ]" id="w-chat-box" @click="$root.$emit('live-chat-widget-visibility', 'show-hide');">
            <!-- <span><i class="fa-brands fa-rocketchat"></i></span> -->
            <span tabindex="0"><img src="../../assets/images/chat-icon.png" alt="chat with us"></span>
        </div>
    </div>
</template>
<script>
export default {
    name: "PlayerFooter-view",
    data() {
        return {
        };
    },
    props: ['type', 'visib'],
    mounted() {
        this.$root.$on('show_hide_chat_icon', (message) => {
            if(message.type == 'show') {
                document.getElementById('w-chat-box').style.display = '';
            }
            else {
                document.getElementById('w-chat-box').style.display = 'none';
            }
        });
    }
}
</script>