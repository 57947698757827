export const getters = {
    getMessageDetail: state => msg_key => {
        let obj = state.list.find(o => o.msg_key === msg_key);
        if (obj !== null && typeof obj !== 'undefined' && Object.prototype.hasOwnProperty.call(obj, "msg_key")) {
            obj.is_exist = true;
            return obj;
        }
        else {
            return {is_exist: false};
        }
    },
};
