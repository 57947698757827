<template>
    <div class="main-layout-section admin-portal">
        <SuperAdminHeader />
        
        <div class="w-logo mb-3">
            <img src="../../assets/images/gamelab-logo.svg" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" />
        </div>

        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeInUp animate__delay-1s">Manage Your Games</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">

                    <div class="admin-general-section-buttons">
                        <router-link to="/superadmin/dashboard" class="admin-general-section-button">
                            Back To Dashboard
                        </router-link>
                        <router-link to="/superadmin/games/deploy" class="admin-general-section-button">
                            Deploy New Game
                        </router-link>
                    </div>

                    <div class="col-sm-12">
                        <div class="overflow-auto p-0 m-0">
                            <b-table
                            id="admin-general-table"
                            :striped="true"
                            :hover="false"
                            :dark="false"
                            :items="games"
                            :fields="fields"
                            show-empty
                            :emptyText="emptyText"
                            >
                                <template #cell(ImageURL)="data">
                                    <img :src="data.value" :alt="data.item.GameName" class="image">
                                </template>
                                <template #cell(action)="data">
                                    <div class="icon-action-buttons-container d-flex flex-row">
                                        <router-link :to="`/superadmin/games/edit/${data.item.GameId}`" :class="data.value" class="btn-sm icon-button-actions">
                                            <b-icon class="icon" icon="pencil-fill"></b-icon>
                                            Edit
                                        </router-link>
                                        <a 
                                        class="btn-sm icon-button-actions"
                                        @click="confirmGameDelete(data.item.GameId)" 
                                        tabindex="0"
                                        >
                                            <b-icon class="icon" :icon="data.value"></b-icon>
                                            Delete
                                        </a>
                                    </div>
                                </template>
                                <template #empty="scope">
                                    <p class="text-center p-0 m-0">{{ scope.emptyText }}</p>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <!-- start of delete confirm modal -->
        <b-modal id="delete-game-modal"  dialog-class="admin-portal-modal" header-class="admin-portal-bg px-3 py-2" hide-footer>
            <template #modal-title>
                Confirm
                <b-icon 
                class="icon" 
                icon="question-circle-fill"
                >
                </b-icon> 
            </template>
            <b-overlay :show="false" rounded="lg">
                <div class="d-flex">
                    <p>Are you sure you want to delete selected game ?</p>
                </div>
                <div class="d-flex text-center">
                    <button
                        type="button"
                        class="modal-button px-5 py-2"
                        @click="deleteGame"
                    >
                        Yes
                    </button>
                    <button
                        type="button"
                        class="modal-button px-5 py-2 mx-2"
                        @click="$bvModal.hide('delete-game-modal')"
                    >
                        No
                    </button>
                </div>
            </b-overlay>
        </b-modal>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <SuperAdminFooter type="inner" />
        </div>
        <!-- end of footer section -->
    </div>
</template>

<script>
import SuperAdminHeader from "@/views/superadmin/SuperAdminHeader.vue";
import SuperAdminFooter from "@/views/superadmin/SuperAdminFooter.vue";
import { adminGetGamesList, adminDeleteGame } from "../../services/AdminService";

export default {
  name: "SuperAdminGames-view",
  components: { SuperAdminHeader, SuperAdminFooter },
  data() {
    return {
      emptyText:"No games found at the moment!",
      fields: [
        {'key' : 'GameId', 'label' : 'ID'}, 
        {'key' : 'GameKey', 'label' : 'Key'},
        {'key' : 'ImageURL', 'label' : 'Image'},
        {'key' : 'GameName', 'label' : 'Name'}, 
        {'key' : 'GameGenre', 'label' : 'Genre'}, 
        {'key' : 'GamePlatform', 'label' : 'Platform'}, 
        {'key' : 'action', 'label' : 'Action'}
      ],
      games: [],
      api_state: false,
      delete_game_id: "",
    };
  },

  computed: {
  },

  methods: {
    gamesList() {
      let user_data = JSON.parse(localStorage.user_sess_data);
      let params = {
        token: user_data.token,
        client_id: 0
      };
      adminGetGamesList(params).then(response => {
        this.games = response.data.games;
        if(this.games.length > 0) {
          this.games.map(function(cl) {
            return cl.action='trash-fill';
          });
        }
      });
    },

    makeToast(variant = "default", message) {
        // this.$toast.open({
        // message: message,
        // type: variant,
        // position: 'top-right',
        // duration: 3000,
        // dismissible: true
        // });
        this.$root.$emit("toast_message", {'type' : variant, 'message' : message});
    },

    confirmGameDelete(game_id) {
      this.delete_game_id = game_id;
      this.$bvModal.show('delete-game-modal');
    },

    deleteGame() {
      let user_data = JSON.parse(localStorage.user_sess_data);
      let params = {
        token: user_data.token,
        game_id: this.delete_game_id
      };
      adminDeleteGame(params).then(response => {
        if(response.status) {
          this.$bvModal.hide('delete-game-modal');
          this.makeToast("success", response.message);
          this.gamesList();
        }
        else {
          this.makeToast("error", response.message);
        }
      });
    },

  },

  mounted() {
    this.gamesList();
  }

};
</script>