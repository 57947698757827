<template>
  <!-- new view -->
  <section class="blue register-page">  
    <AllTheme />
    <div class="w-logo">
        <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="logo_file !== '' && logo_file !== null"/>
        <img src="../../assets/images/header-auburn.svg" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="logo_file == '' || logo_file == null"/>
    </div>
    <div class="w-form-block h-60 mt-5">
        <!-- login form section -->
        <form action="" class="" @submit.stop.prevent v-if="remote_verified && !is_logged_in">
            <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s">
                <span><i class="fa-solid fa-user"></i></span>
                <input type="text" name="email" value="" v-model="username" placeholder="email@domain.com" autofocus/>
            </div>
            <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s">
                <span><i class="fa-solid fa-lock"></i></span>
                <input type="password" name="password" value="" v-model="password" placeholder="s3cretp@ssword"/>
            </div>
            <div class="main-action-box w-login-hide">
              <div class="w-form-control animate__animated animate__fadeInUp animate__delay-3s">
                <router-link :to="{name: 'Register', params: {qr_code:qr_code, uname:username, pass:password}}" class="flex-fill" style="width:50%;">Sign Up</router-link>
                <a href="javascript:void(0)" @click="handleLoginReq" style="width:50%;">Login</a>
              </div>
              <div
                    class="
                    w-form-control
                    animate__animated animate__fadeInUp animate__delay-1s
                    "
                    v-if="login_attempts > 0"
                >
                    <router-link :to="{name: 'Forgot Password', params: {qr_code:qr_code, uname:username}}" class="flex-fill">Reset My Password</router-link>
                </div>
            </div>
            <div class="w-login-show">
              <div class="notification-block">
              </div>
            </div>
        </form>

        <!-- error container -->
        <div class="w-login-show mb-5 notification-container w-320 mx-auto">
            <div class="animate__animated animate__fadeInUp w-320" v-if="noti_class !== '' && noti_message !== ''">
                <div class="notification-block" :class="noti_class">
                    <div class="notification-message w-270">
                        <h5>{{ noti_message }}</h5>
                        <p>{{ noti_message1 !== '' ? noti_message1 : '' }}</p>
                    </div>                
                    <div class="icon-success" v-if="noti_class == 'success'"><i class="fa-solid fa-circle-check"></i></div>
                    <div class="icon-error" v-if="noti_class == 'error'"><i class="fa-solid fa-times-circle"></i></div>
                    <div class="icon-warning" v-if="noti_class == 'warning'"><i class="fa-solid fa-exclamation-circle"></i></div>
                </div>
            </div>
        </div>
    </div>
    <PlayerFooter type="" class="animate__animated animate__fadeIn animate__delay-3s outer-footer" />
  </section>
</template>

<script>
import { validateRemoteQR, userMobileLogin } from "../../services/HomepageService";
import { global_config } from "@/config/config.js";
import AllTheme from "@/components/themes/AllTheme.vue";
import PlayerFooter from "@/views/player/PlayerFooter.vue";

export default {
  name: "mobilelogin-view",
  components: { AllTheme, PlayerFooter },

  data() {
    return {
        username: "",
        password: "",
        remote_requested: false,
        remote_verified: false,
        qr_code: this.$route.params.qr_code,
        theme_file: '',
        logo_file:'',
        theme_classes : global_config.THEME_CLASSES,
        messages: global_config.MESSAGES,
        noti_class: "",
        noti_message: "",
        noti_message1: "",
        is_logged_in: false,
        login_attempts: 0,
    };
  },

  computed: {
    remote_session_expired_error() {
        return this.$store.getters.getMessageDetail('remote-session-expired');
    },
  },

  methods: {
    handleLoginReq() {
      if (this.password == "" || this.username == "")
        this.makeToast('generic-required-message');
      else {
        let session_id = (JSON.parse(localStorage.qr_sess_data)).session_id_key;
        userMobileLogin(session_id, this.username, this.password).then(response => {
          if(response.status){
            this.is_logged_in = true;
            this.noti_class = this.messages.success_class;
            this.noti_message = "Success! You are logged in on "+response.data.nav_name+".";
            this.noti_message1 = "Please continue from the Gamepod now.";
            // this.noti_message = response.message;
          } else {
            if(response.message == 'invalid') {
                this.login_attempts += 1;
                this.makeToast('login-invalid-error-message');
            }
            else if(response.message == 'notpro') {
                this.makeToast('login-notpro-error-message');
            }
            else if(response.message == 'notauthorized') {
                this.makeToast('login-notauthorized-error-message');
            }
            else if(response.message == 'reserved') {
                this.makeToast('', 'warning', 'This Gamepod is currently reserved.', 'Please try another Gamepod or return at ' + response.data.reservation.stop_time);
            }
            else {
                this.login_attempts += 1;
                this.makeToast(response.message, this.messages.error_class, response.message);
            }
          }
        })
      }
    },

    validateQRRemoteId() {
      validateRemoteQR(this.qr_code).then(response => {
        this.remote_requested = true;
        if(!response.status) {
          this.remote_verified = false;
          this.noti_class = this.messages.error_class;
          this.noti_message = (this.remote_session_expired_error.is_exist) ? this.remote_session_expired_error.text_head : "This remote session has expired.";
          this.noti_message1 = (this.remote_session_expired_error.is_exist) ? this.remote_session_expired_error.text_sub : "Please scan the QR code again with your camera.";
        }
        else {
          this.remote_verified = true;
          if(response.message == 'remote_init') {
            this.makeToast('', this.messages.success_class, "Starting remote session with "+response.data.nav_name+".", "Please wait while the connection is established...");
          }
          else {
            this.makeToast(response.message, this.messages.success_class, response.message);
          }
          localStorage.setItem('qr_sess_data', JSON.stringify(response.data));
          this.$store.commit("setThemeLogo", response.data);
          // set theme file and logo file for pod
          this.logo_file = response.data.logo;
          this.theme_file = response.data.theme;
          this.getThemeFile();
        }
      })
    },

    makeToast(message_key = '', variant = "default", message, message1="") {
        // search for key
        let smessage = this.$store.state.system_messages.list.find(o => o.msg_key === message_key);
        if(smessage !== null && typeof smessage !== 'undefined' && Object.prototype.hasOwnProperty.call(smessage, 'text_head')) {
            this.noti_class = smessage.type == 'failure' ? 'error' : smessage.type;
            this.noti_message = smessage.text_head;
            this.noti_message1 = smessage.text_sub;
        }
        else {
            this.noti_class = variant;
            this.noti_message = message;
            this.noti_message1 = message1;
        }
        // hide error div
        setTimeout(() => {
            this.noti_class = "";
            this.noti_message = "";
            this.noti_message1 = "";
        }, 5000);
    },

    getThemeFile() {
      // remove existing classes
      for(let i = 0; i < this.theme_classes.length; i++) {
        document.body.classList.remove(this.theme_classes[i]);
      }
      if(this.theme_file !== "" && this.theme_file !== null) {
        let class_name = this.theme_file+'Theme';
        document.body.classList.add(class_name);
      }
    }
  },

  mounted() {
    this.noti_class = this.messages.warning_class;
    this.noti_message = this.messages.remote_verifying;
    this.validateQRRemoteId();
    // check if info recieved from register page
    let params = this.$route.params;
    let is_email_transfer = false;
    let is_pass_transfer = false;
    if(Object.prototype.hasOwnProperty.call(params, "username") && params.username !== '') {
        this.username = params.username;
        is_email_transfer = true;
    }
    if(Object.prototype.hasOwnProperty.call(params, "password") && params.password !== '') {
        this.password = params.password;
        is_pass_transfer = true;
    }
    is_email_transfer, is_pass_transfer;
  },
};
</script>