<template>
    <div class="main-layout-section admin-portal">
        <SuperAdminHeader />
        
        <div class="w-logo mb-3">
            <img src="../../assets/images/gamelab-logo.svg" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s"/>
        </div>

        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeInUp animate__delay-1s">Manage System Messages</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">
                    
                    <div class="row">
                        <div class="admin-general-section-buttons col-md-12">
                            <router-link to="/superadmin/dashboard" class="admin-general-section-button">
                                Back To Dashboard
                            </router-link>
                        </div>
                        <div class="admin-general-section-buttons col-md-12">
                            <router-link to="/superadmin/dashboard" class="alerts-breadcrumb">
                                Dashboard
                            </router-link>
                            <span class="alerts-breadcrumb">></span>
                            <span class="alerts-breadcrumb">System Messages</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-1 col-sm-12 alerts-section-heading first d-none">
                            <h5>Create A New Message</h5>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="overflow-auto p-0 m-0">
                                <table id="alerts-table" role="table" aria-busy="false" aria-colcount="6" class="table b-table table-striped">
                                    <tbody role="rowgroup">
                                        <tr role="row" class="">
                                            <td aria-colindex="1" role="cell" class="alert-td-display">
                                                <div class="alert-preview" :class="[alert_form.type == 'success' ? 'success' : '', alert_form.type == 'failure' ? 'error' : '', alert_form.type == 'warning' ? 'warning' : '']">
                                                    <div class="alert-message">
                                                        <h5>
                                                            <input
                                                            type="text"
                                                            value=""
                                                            placeholder="Enter message text here in this preview"
                                                            v-model="alert_form.text_head"
                                                            />
                                                        </h5>
                                                        <p>
                                                            <input
                                                            type="text"
                                                            value=""
                                                            placeholder="window so you know what fits!"
                                                            v-model="alert_form.text_sub"
                                                            />
                                                        </p>
                                                    </div>
                                                    <div class="icon-errorrr" v-if="alert_form.type == 'check'">
                                                        <i class="fas fa-circle fa-2xl icon h2 m-0"></i>
                                                    </div>
                                                    <div class="icon-success" v-if="alert_form.type == 'success'">
                                                        <i class="fa-solid fa-circle-check"></i>
                                                    </div>
                                                    <div class="icon-error" v-if="alert_form.type == 'failure'">
                                                        <i class="fa-solid fa-times-circle"></i>
                                                    </div>
                                                    <div class="icon-warning" v-if="alert_form.type == 'warning'">
                                                        <i class="fa-solid fa-exclamation-circle"></i>
                                                    </div>
                                                </div>
                                            </td>
                                            <td aria-colindex="4" role="cell" class="alert-td-type">
                                                <div class="alert-items">
                                                    <span class="alert-item-type-selection">
                                                        <select v-model="alert_form.type">
                                                            <option 
                                                            v-for="(selectOption, indexOpt) in alert_types" 
                                                            :key="indexOpt"
                                                            :value="selectOption.id"
                                                            >
                                                                {{ selectOption.value }}
                                                            </option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td aria-colindex="4" role="cell" class="alert-td-message-key">
                                                <div class="alert-items">
                                                    <div class="alert-message-key">
                                                        <input
                                                        type="text"
                                                        value=""
                                                        placeholder="Enter message key here"
                                                        v-model="alert_form.msg_key"
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                            <td aria-colindex="6" role="cell" class="alert-td-action">
                                                <div class="alert-action-button">
                                                    <button class="btn-sm" @click="handleAlertReq">
                                                        <template v-if="edit_id !== ''">Update</template>
                                                        <template v-if="edit_id == ''">Save</template>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-1 col-sm-12 alerts-section-heading d-none">
                            <h5>Edit Current Messages</h5>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="overflow-auto p-0 m-0">
                                <b-table
                                id="alerts-table"
                                :striped="true"
                                :hover="false"
                                :dark="false"
                                :items="alerts"
                                :fields="fields"
                                show-empty
                                :emptyText="emptyText" 
                                >   
                                    <!-- start table header elements -->
                                    <template #head()="data">
                                        <div class="alert-header" v-if="data.label !== '' && data.label !== 'Type'">
                                            <span>{{ data.label }}</span>
                                            <div class="alert-sort-buttons">
                                                <button class="btn-sm" :class="[(sort_by == data.column && sort_type == 'desc') ? 'active-sort' : '']" @click="handleSorting(data.column, 'desc')">
                                                    <i class="fas fa-caret-down icon m-0"></i>
                                                </button>
                                                <button class="btn-sm" :class="[(sort_by == data.column && sort_type == 'asc') ? 'active-sort' : '']" @click="handleSorting(data.column, 'asc')">
                                                    <i class="fas fa-caret-up icon m-0"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </template>
                                    <!-- end table header elements -->
                                    <template #cell(text_head)="data">
                                        <div class="alert-preview" :class="[data.item.type == 'success' ? 'success' : '', data.item.type == 'failure' ? 'error' : '', data.item.type == 'warning' ? 'warning' : '']">
                                            <div class="alert-message">
                                                <h5>{{ data.item.text_head }}</h5>
                                                <p>{{ data.item.text_sub }}</p>
                                            </div>
                                            <div class="icon-errorrr" v-if="data.item.type == 'check'">
                                                <i class="fas fa-circle fa-2xl icon h2 m-0"></i>
                                            </div>
                                            <div class="icon-success" v-if="data.item.type == 'success'">
                                                <i class="fa-solid fa-circle-check"></i>
                                            </div>
                                            <div class="icon-error" v-if="data.item.type == 'failure'">
                                                <i class="fa-solid fa-times-circle"></i>
                                            </div>
                                            <div class="icon-warning" v-if="data.item.type == 'warning'">
                                                <i class="fa-solid fa-exclamation-circle"></i>
                                            </div>
                                        </div>
                                    </template>
                                    <template #cell(type)="data">
                                        <div class="alert-items">
                                            <span class="alert-item-type-selection">{{ data.item.type }}</span>
                                        </div>
                                    </template>
                                    <template #cell(msg_key)="data">
                                        <div class="alert-items">
                                            <span class="alert-item-type-selection">{{ data.item.msg_key }}</span>
                                        </div>
                                    </template>
                                    <template #cell(action)="data">
                                        <div class="alert-action-button" :class="data.item.enable">
                                            <button class="btn-sm" @click="handleEditClick(data.index, data.item)" v-if="edit_id !== data.item.id">Edit</button>
                                            <button class="btn-sm" @click="cancelEditClick()" v-if="edit_id == data.item.id">Cancel</button>
                                        </div>
                                    </template>
                                    <template #empty="scope">
                                    <p class="text-center p-0 m-0">{{ scope.emptyText }}</p>
                                    </template>
                                </b-table>
                            </div>
                            <hr class="alerts-border">
                            <p class="alerts-pagination-status-text">{{ status_text }}</p>
                            <div class="alerts-pagination-container">
                                <div class="alerts-pagination">
                                    <button class="btn-prev" @click="hanldePagination('prev')" :class="[current_page == 1 ? 'alerts-page-disabled' : '']">Previous Pg</button>
                                    <button class="btn-prev" @click="hanldePagination('next')" :class="[current_page == last_page ? 'alerts-page-disabled' : '']">Next Pg</button>
                                </div>
                                <div class="alerts-back">
                                    <!-- <router-link to="/superadmin/dashboard" class="back-button">
                                        Back To Dashboard
                                    </router-link> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <SuperAdminFooter type="inner"/>
        </div>
        <!-- end of footer section -->
    </div>
</template>

<script>
import { adminGetMessagesList, adminSaveMessage } from "../../services/AdminService";
import SuperAdminHeader from "@/views/superadmin/SuperAdminHeader.vue";
import SuperAdminFooter from "@/views/superadmin/SuperAdminFooter.vue";
import { global_config } from "@/config/config.js";
import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import { required } from 'vuelidate/lib/validators';

export default {
    name: "SystemMessagesList-view",
    components: { SuperAdminHeader, SuperAdminFooter },
    data() {
        return {
            api_state: false,
            sort_by: 'text_head',
            sort_type: 'desc',
            status_text: '',
            per_page: 7,
            current_page: 1,
            total_rows: 0,
            last_page: 1,
            emptyText:"No messages found at the moment!",
            emptyHtml:"No messages found at the moment!",
            fields: [
                {'key' : 'text_head', 'label' : 'Message Display Text', class:'alert-td-display'},
                {'key' : 'type', 'label' : 'Type', class:'alert-td-type'},
                {'key' : 'msg_key', 'label' : 'Message Key', class:'alert-td-message-key'},
                {'key' : 'action', 'label' : '', class:'alert-td-action'}
            ],
            alerts: [],
            alert_types: global_config.ALERT_TYPES,
            alert_form: {
                text_head:'',
                text_sub:'',
                type:'check',
                msg_key:'',
            },
            edit_id:'',
        };
    },

    validations: {
        alert_form : {
            text_head:{required},
            text_sub:{required:false},
            type:{required},
            msg_key:{required}
        }
    },
    computed: {
    },

    methods: {
        alertsList() {
            this.is_searching = true;
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                length: this.per_page,
                page: this.current_page,
                sort_by: this.sort_by,
                sort_type: this.sort_type,
            };
            adminGetMessagesList(params).then(response => {
                this.is_searching = false;
                this.alerts = response.data.messages;
                this.current_page = response.data.current_page;
                this.total_rows = response.data.total_rows;
                this.last_page = response.data.last_page;
                this.status_text = response.data.status_text;
                if(this.alerts.length > 0) {
                    this.alerts.map(function(lb){
                        return lb.is_editing=false;
                    });
                }
            });
        },
        makeToast(variant = "default", message, message1='') {
            this.$root.$emit("toast_message", {'type' : variant, 'message' : message, 'message1': message1});
        },
        handleSorting(column, type) {
            if(!(this.sort_by == column && this.sort_type == type)) {
                this.sort_by = column;
                this.sort_type = type;
                this.alertsList();
            }
        },
        handleAlertReq() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.makeToast("error", "generic-required-message");
            } 
            else {
                let user_data = JSON.parse(localStorage.user_sess_data);
                let data = this.alert_form;
                data.token = user_data.token;
                data.edit_id = this.edit_id;
                adminSaveMessage(data).then(response => {
                    if(response.status){
                        this.makeToast("success", response.message);
                        this.$v.alert_form.$reset();
                        this.cancelEditClick();
                        this.alertsList();
                    } 
                    else {
                        this.makeToast("error", response.message);
                    }
                });
            }
        },
        handleEditClick(index, item) {
            this.alerts[index].is_editing = true;
            this.alert_form.text_head = item.text_head;
            this.alert_form.text_sub = item.text_sub;
            this.alert_form.type = item.type;
            this.alert_form.msg_key = item.msg_key;
            this.edit_id = item.id;
        },
        cancelEditClick() {
            this.edit_id = '';
            this.alert_form = {
                text_head:'',
                text_sub:'',
                type:'check',
                msg_key: '',
            };
        },
        hanldePagination(type) {
            if(type == 'prev' && this.current_page !== 1) {
                this.current_page -= 1;
                this.alertsList();
            }
            else if(type == 'next' && this.current_page !== this.last_page) {
                this.current_page += 1;
                this.alertsList();
            }
        },
    },
    mounted() {
        this.alertsList();
    },
};
</script>