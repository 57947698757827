<template>
  <div class="main-layout-section admin-portal secondary">
        <AdminHeader 
        @setPodData="setPodData($event)" />
        
        <div class="w-logo mb-3">
            <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeIn animate__fast" v-if="logo_file !== '' && logo_file !== null"/>
        </div>

        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeIn animate__fast">Edit Lab</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">
                    <div class="admin-general-section-buttons">
                        <router-link to="/admin/labs" class="admin-general-section-button">
                            Back To Labs Management
                        </router-link>
                    </div>
                </div>

                <div class="row m-0 p-0 col-md-12 col-sm-12 m-auto">
                    
                    <b-form inline @submit.stop.prevent class="m-0 p-0">
                        <!-- client and labe name field -->
                        
                        <div class="row mt-3">
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Select Client</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100"  :class="{ 'is_invalid': $v.form.client_id.$error }">
                                    <span><i class="fa-solid fa-user"></i></span>
                                    <b-form-select
                                        v-model="form.client_id"
                                        :options="clients"
                                        text-field="value"
                                        value-field="id"
                                        id="client_id"
                                        class=""
                                        :class="[ $v.form.client_id.$error ? 'is-invalid' : '', form.client_id == '' ? 'text-grey' : '' ]"
                                        placeholder="Select a client"
                                    ></b-form-select>
                                </div>
                            </div>
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Lab Name</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100"  :class="{ 'is_invalid': $v.form.lab_name.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-input
                                        v-model="form.lab_name"
                                        id="lab_name"
                                        class=""
                                        :class="{ 'is-invalid': $v.form.lab_name.$error }"
                                        placeholder="Lab name"
                                    ></b-form-input>
                                </div>
                            </div>
                            
                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Address 1</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100"  :class="{ 'is_invalid': $v.form.address1.$error }">
                                    <b-form-textarea
                                    v-model="form.address1"
                                    id="address1"
                                    class=""
                                    :class="{ 'is-invalid': $v.form.address1.$error }"
                                    placeholder="Address 1"
                                    no-resize
                                    ></b-form-textarea>
                                </div>
                            </div>

                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Address 2</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.address2.$error }">
                                    <b-form-textarea
                                        v-model="form.address2"
                                        id="address2"
                                        class=""
                                        :class="{ 'is-invalid': $v.form.address2.$error }"
                                        placeholder="Address 2"
                                        no-resize
                                    ></b-form-textarea>
                                </div>
                                
                            </div>
                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">City</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.city.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-input
                                        v-model="form.city"
                                        type="text"
                                        placeholder="Enter City"
                                        id="city"
                                        aria-describedby="city-help-block"
                                        class=""
                                        :class="{ 'is-invalid': $v.form.city.$error }"
                                    ></b-form-input>
                                </div>
                            </div>

                            <!-- phone field -->
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">State</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.state.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-input
                                        v-model="form.state"
                                        id="state"
                                        class="mb-2 mr-sm-2 mb-sm-0 user-name bg-lightBlack border-0 h-53"
                                        :class="{ 'is-invalid': $v.form.state.$error }"
                                        placeholder="Enter state"
                                    ></b-form-input>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Zip</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.zip.$error }">
                                    <span><i class="fa-solid fa-map-pin"></i></span>
                                    <b-form-input
                                        v-model="form.zip"
                                        id="zip" 
                                        class=""
                                        :class="{ 'is-invalid': $v.form.zip.$error }"
                                        placeholder="Enter zip"
                                    ></b-form-input>
                                </div>
                            </div>

                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Select Timezone</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" >
                                    <span><i class="fa-solid fa-user"></i></span>
                                    <b-form-select
                                        v-model="form.timezone"
                                        :options="timezones"
                                        text-field="value"
                                        value-field="id"
                                        id="timezone_id"
                                        class=""
                                        :class="[ $v.form.timezone.$error ? 'is-invalid' : '', form.timezone == '' ? 'text-grey' : '' ]"
                                        placeholder="Select a timezone"
                                    ></b-form-select>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-6  flex-column animate__animated animate__fadeIn animate__fast" :class="{ 'is_invalid': $v.form.is_active.$error }">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">&nbsp;</label>
                                <switches 
                                class="active-switch h-53" 
                                v-model="form.is_active"
                                theme="bootstrap" 
                                color="primary"
                                type-bold="true"
                                label="Active"
                                >
                                </switches>
                            </div>
                            <div class="d-flex mb-1 col-md-6  flex-column animate__animated animate__fadeIn animate__fast" :class="{ 'is_invalid': $v.form.is_paid.$error }">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">&nbsp;</label>
                                <switches 
                                class="active-switch h-53" 
                                v-model="form.is_paid"
                                theme="bootstrap" 
                                color="primary"
                                type-bold="true"
                                label="" 
                                text-enabled="Paid Mode" 
                                text-disabled="Free Mode" 
                                >
                                </switches>
                            </div>
                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-12 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Note</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.note.$error }">
                                    <b-form-textarea
                                        v-model="form.note"
                                        id="note"
                                        class=""
                                        :class="{ 'is-invalid': $v.form.note.$error }"
                                        placeholder="Note"
                                        no-resize
                                    ></b-form-textarea>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Reservation Email</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.reservation_email.$error }">
                                    <span><i class="fa-solid fa-envelope"></i></span>
                                    <b-form-input
                                        v-model="form.reservation_email"
                                        id="reservation_email" 
                                        class=""
                                        :class="{ 'is-invalid': $v.form.reservation_email.$error }"
                                        placeholder="Enter Reservation Email"
                                    ></b-form-input>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Contact Email</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.contact_email.$error }">
                                    <span><i class="fa-solid fa-envelope"></i></span>
                                    <b-form-input
                                        v-model="form.contact_email"
                                        id="contact_email" 
                                        class=""
                                        :class="{ 'is-invalid': $v.form.contact_email.$error }"
                                        placeholder="Enter zip"
                                    ></b-form-input>
                                </div>
                            </div>
                        </div>

                        <div
                        class="
                        w-form-control
                        animate__animated animate__fadeIn animate__fast m-0 p-0
                        "
                        >
                            <a
                            href="javascript:void(0)"
                            @click="handleLabReq"
                            class="flex-fill" 
                            :disabled="!is_valid_lab"
                            >Update</a
                            >
                        </div>

                    </b-form>

                </div>


                <!-- start of lab schedules section -->
                <div class="w-login-show notification-container w-alert-340 mx-auto mt-3" id="sch-notification-container">
                    <div class="notification-block animate__animated animate__fadeIn w-alert-340" :class="sch_type" v-if="sch_type !== '' && sch_message !== ''">
                        <div class="notification-message w-alert-290">
                            <h5>{{ sch_message }}</h5>
                            <p v-if="sch_message1 !== ''">{{ sch_message1 }}</p>
                        </div>
                        <div class="icon-success" v-if="sch_type == 'success'">
                            <i class="fa-solid fa-circle-check"></i>
                        </div>
                        <div class="icon-error" v-if="sch_type == 'error'">
                            <i class="fa-solid fa-times-circle"></i>
                        </div>
                        <div class="icon-warning" v-if="sch_type == 'warning'">
                            <i class="fa-solid fa-exclamation-circle"></i>
                        </div>
                    </div>
                </div>
                <h3 class="page-heading mb-3 animate__animated animate__fadeIn animate__fast mt-2 text-left">Lab Schedules</h3>
                <div class="row m-0 p-0 col-md-12 col-sm-12 m-auto">
                    
                    <b-form inline @submit.stop.prevent class="m-0 p-0">
                        <!-- client and labe name field -->
                        
                        <div class="row mt-3">
                            <!-- <div class="d-flex mb-1 col-md-5 flex-column" ref="mo_start_time">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Monday Start Time</label>
                                <b-form-timepicker :reset-button="true" :boundary="$refs.mo_start_time" v-model="schedule_form.mo_start" dropup class="overflow-visible timepicker-schedule w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100"></b-form-timepicker>
                            </div>
                            <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Monday End Time</label>
                                <b-form-timepicker :reset-button="true" :no-flip="true" v-model="schedule_form.mo_end" dropup class="overflow-visible timepicker-schedule w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.schedule_form.mo_end.$error }"></b-form-timepicker>
                            </div> -->

                            <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Monday Start Time</label>
                                <b-input-group class="timepicker-schedule animate__animated animate__fadeIn animate__fast">
                                    <b-input-group-append>
                                        <b-form-timepicker
                                        button-only 
                                        dropup 
                                        left 
                                        @context="moContextStart"
                                        ></b-form-timepicker>
                                    </b-input-group-append>
                                    <b-form-input
                                        v-model="schedule_form.mo_start"
                                        type="text"
                                        placeholder="HH:mm AM/PM" 
                                        class="w-form-control w-input-fields mb-0" 
                                        :class="{ 'is_invalid': $v.schedule_form.mo_start.$error }" 
                                        v-mask="'##:## AA'"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                            <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Monday End Time</label>
                                <b-input-group class="timepicker-schedule animate__animated animate__fadeIn animate__fast">
                                    <b-input-group-append>
                                        <b-form-timepicker
                                        button-only 
                                        dropup 
                                        left 
                                        @context="moContextEnd"
                                        ></b-form-timepicker>
                                    </b-input-group-append>
                                    <b-form-input
                                        v-model="schedule_form.mo_end"
                                        type="text"
                                        placeholder="HH:mm AM/PM" 
                                        class="w-form-control w-input-fields mb-0" 
                                        :class="{ 'is_invalid': $v.schedule_form.mo_end.$error }" 
                                        v-mask="'##:## AA'"
                                    ></b-form-input>
                                </b-input-group>
                            </div>

                            <div class="d-flex mb-1 col-md-2  flex-column animate__animated animate__fadeIn animate__fast" :class="{ 'is_invalid': $v.schedule_form.mo_is_closed.$error }">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Closed?</label>
                                <switches 
                                class="active-switch h-53" 
                                v-model="schedule_form.mo_is_closed"
                                theme="bootstrap" 
                                color="primary"
                                type-bold="true"
                                label="" 
                                >
                                </switches>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <!-- <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Tuesday Start Time</label>
                                <b-form-timepicker :reset-button="true" :no-flip="true" v-model="schedule_form.tu_start" dropup class="overflow-visible timepicker-schedule w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100"></b-form-timepicker>
                            </div>
                            <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Tuesday End Time</label>
                                <b-form-timepicker :reset-button="true" :no-flip="true" v-model="schedule_form.tu_end" dropup class="overflow-visible timepicker-schedule w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100"></b-form-timepicker>
                            </div> -->

                            <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Tueday Start Time</label>
                                <b-input-group class="timepicker-schedule animate__animated animate__fadeIn animate__fast">
                                    <b-input-group-append>
                                        <b-form-timepicker
                                        button-only 
                                        dropup 
                                        left 
                                        @context="tuContextStart"
                                        ></b-form-timepicker>
                                    </b-input-group-append>
                                    <b-form-input
                                        v-model="schedule_form.tu_start"
                                        type="text"
                                        placeholder="HH:mm AM/PM" 
                                        class="w-form-control w-input-fields mb-0" 
                                        :class="{ 'is_invalid': $v.schedule_form.tu_start.$error }" 
                                        v-mask="'##:## AA'"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                            <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Tuesday End Time</label>
                                <b-input-group class="timepicker-schedule animate__animated animate__fadeIn animate__fast">
                                    <b-input-group-append>
                                        <b-form-timepicker
                                        button-only 
                                        dropup 
                                        left 
                                        @context="tuContextEnd"
                                        ></b-form-timepicker>
                                    </b-input-group-append>
                                    <b-form-input
                                        v-model="schedule_form.tu_end"
                                        type="text"
                                        placeholder="HH:mm AM/PM" 
                                        class="w-form-control w-input-fields mb-0" 
                                        :class="{ 'is_invalid': $v.schedule_form.tu_end.$error }" 
                                        v-mask="'##:## AA'"
                                    ></b-form-input>
                                </b-input-group>
                            </div>

                            <div class="d-flex mb-1 col-md-2 flex-column animate__animated animate__fadeIn animate__fast" :class="{ 'is_invalid': $v.schedule_form.tu_is_closed.$error }">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast">Closed?</label>
                                <switches 
                                class="active-switch h-53" 
                                v-model="schedule_form.tu_is_closed"
                                theme="bootstrap" 
                                color="primary"
                                type-bold="true"
                                label="" 
                                >
                                </switches>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <!-- <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Wednesday Start Time</label>
                                <b-form-timepicker :reset-button="true" :no-flip="true" v-model="schedule_form.we_start" dropup class="overflow-visible timepicker-schedule w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100"></b-form-timepicker>
                            </div>
                            <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Wednesday End Time</label>
                                <b-form-timepicker :reset-button="true" :no-flip="true" v-model="schedule_form.we_end" dropup class="overflow-visible timepicker-schedule w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100"></b-form-timepicker>
                            </div> -->

                            <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Wednesday Start Time</label>
                                <b-input-group class="timepicker-schedule animate__animated animate__fadeIn animate__fast">
                                    <b-input-group-append>
                                        <b-form-timepicker
                                        button-only 
                                        dropup 
                                        left 
                                        @context="weContextStart"
                                        ></b-form-timepicker>
                                    </b-input-group-append>
                                    <b-form-input
                                        v-model="schedule_form.we_start"
                                        type="text"
                                        placeholder="HH:mm AM/PM" 
                                        class="w-form-control w-input-fields mb-0" 
                                        :class="{ 'is_invalid': $v.schedule_form.we_start.$error }" 
                                        v-mask="'##:## AA'"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                            <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Wednesday End Time</label>
                                <b-input-group class="timepicker-schedule animate__animated animate__fadeIn animate__fast">
                                    <b-input-group-append>
                                        <b-form-timepicker
                                        button-only 
                                        dropup 
                                        left 
                                        @context="weContextEnd"
                                        ></b-form-timepicker>
                                    </b-input-group-append>
                                    <b-form-input
                                        v-model="schedule_form.we_end"
                                        type="text"
                                        placeholder="HH:mm AM/PM" 
                                        class="w-form-control w-input-fields mb-0" 
                                        :class="{ 'is_invalid': $v.schedule_form.we_end.$error }" 
                                        v-mask="'##:## AA'"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                            <div class="d-flex mb-1 col-md-2 flex-column animate__animated animate__fadeIn animate__fast" :class="{ 'is_invalid': $v.schedule_form.we_is_closed.$error }">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast">Closed?</label>
                                <switches 
                                class="active-switch h-53" 
                                v-model="schedule_form.we_is_closed"
                                theme="bootstrap" 
                                color="primary"
                                type-bold="true"
                                label="" 
                                >
                                </switches>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <!-- <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Thursday Start Time</label>
                                <b-form-timepicker :reset-button="true" :no-flip="true" v-model="schedule_form.th_start" dropup class="overflow-visible timepicker-schedule w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100"></b-form-timepicker>
                            </div>
                            <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Thursday End Time</label>
                                <b-form-timepicker :reset-button="true" :no-flip="true" v-model="schedule_form.th_end" dropup class="overflow-visible timepicker-schedule w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100"></b-form-timepicker>
                            </div> -->

                            <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Thursday Start Time</label>
                                <b-input-group class="timepicker-schedule animate__animated animate__fadeIn animate__fast">
                                    <b-input-group-append>
                                        <b-form-timepicker
                                        button-only 
                                        dropup 
                                        left 
                                        @context="thContextStart"
                                        ></b-form-timepicker>
                                    </b-input-group-append>
                                    <b-form-input
                                        v-model="schedule_form.th_start"
                                        type="text"
                                        placeholder="HH:mm AM/PM" 
                                        class="w-form-control w-input-fields mb-0" 
                                        :class="{ 'is_invalid': $v.schedule_form.th_start.$error }" 
                                        v-mask="'##:## AA'"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                            <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Thursday End Time</label>
                                <b-input-group class="timepicker-schedule animate__animated animate__fadeIn animate__fast">
                                    <b-input-group-append>
                                        <b-form-timepicker
                                        button-only 
                                        dropup 
                                        left 
                                        @context="thContextEnd"
                                        ></b-form-timepicker>
                                    </b-input-group-append>
                                    <b-form-input
                                        v-model="schedule_form.th_end"
                                        type="text"
                                        placeholder="HH:mm AM/PM" 
                                        class="w-form-control w-input-fields mb-0" 
                                        :class="{ 'is_invalid': $v.schedule_form.th_end.$error }" 
                                        v-mask="'##:## AA'"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                            <div class="d-flex mb-1 col-md-2 flex-column animate__animated animate__fadeIn animate__fast" :class="{ 'is_invalid': $v.schedule_form.th_is_closed.$error }">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast">Closed?</label>
                                <switches 
                                class="active-switch h-53" 
                                v-model="schedule_form.th_is_closed"
                                theme="bootstrap" 
                                color="primary"
                                type-bold="true"
                                label="" 
                                >
                                </switches>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <!-- <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Friday Start Time</label>
                                <b-form-timepicker :reset-button="true" :no-flip="true" v-model="schedule_form.fr_start" dropup class="overflow-visible timepicker-schedule w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100"></b-form-timepicker>
                            </div>
                            <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Friday End Time</label>
                                <b-form-timepicker :reset-button="true" :no-flip="true" v-model="schedule_form.fr_end" dropup class="overflow-visible timepicker-schedule w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100"></b-form-timepicker>
                            </div> -->

                            <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Friday Start Time</label>
                                <b-input-group class="timepicker-schedule animate__animated animate__fadeIn animate__fast">
                                    <b-input-group-append>
                                        <b-form-timepicker
                                        button-only 
                                        dropup 
                                        left 
                                        @context="frContextStart"
                                        ></b-form-timepicker>
                                    </b-input-group-append>
                                    <b-form-input
                                        v-model="schedule_form.fr_start"
                                        type="text"
                                        placeholder="HH:mm AM/PM" 
                                        class="w-form-control w-input-fields mb-0" 
                                        :class="{ 'is_invalid': $v.schedule_form.fr_start.$error }" 
                                        v-mask="'##:## AA'"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                            <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Friday End Time</label>
                                <b-input-group class="timepicker-schedule animate__animated animate__fadeIn animate__fast">
                                    <b-input-group-append>
                                        <b-form-timepicker
                                        button-only 
                                        dropup 
                                        left 
                                        @context="frContextEnd"
                                        ></b-form-timepicker>
                                    </b-input-group-append>
                                    <b-form-input
                                        v-model="schedule_form.fr_end"
                                        type="text"
                                        placeholder="HH:mm AM/PM" 
                                        class="w-form-control w-input-fields mb-0" 
                                        :class="{ 'is_invalid': $v.schedule_form.fr_end.$error }" 
                                        v-mask="'##:## AA'"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                            <div class="d-flex mb-1 col-md-2 flex-column animate__animated animate__fadeIn animate__fast" :class="{ 'is_invalid': $v.schedule_form.fr_is_closed.$error }">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast">Closed?</label>
                                <switches 
                                class="active-switch h-53" 
                                v-model="schedule_form.fr_is_closed"
                                theme="bootstrap" 
                                color="primary"
                                type-bold="true"
                                label="" 
                                >
                                </switches>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <!-- <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Saturday Start Time</label>
                                <b-form-timepicker :reset-button="true" :no-flip="true" v-model="schedule_form.sa_start" dropup class="overflow-visible timepicker-schedule w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100"></b-form-timepicker>
                            </div>
                            <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Saturday End Time</label>
                                <b-form-timepicker :reset-button="true" :no-flip="true" v-model="schedule_form.sa_end" dropup class="overflow-visible timepicker-schedule w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100"></b-form-timepicker>
                            </div> -->

                            <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Saturday Start Time</label>
                                <b-input-group class="timepicker-schedule animate__animated animate__fadeIn animate__fast">
                                    <b-input-group-append>
                                        <b-form-timepicker
                                        button-only 
                                        dropup 
                                        left 
                                        @context="saContextStart"
                                        ></b-form-timepicker>
                                    </b-input-group-append>
                                    <b-form-input
                                        v-model="schedule_form.sa_start"
                                        type="text"
                                        placeholder="HH:mm AM/PM" 
                                        class="w-form-control w-input-fields mb-0" 
                                        :class="{ 'is_invalid': $v.schedule_form.sa_start.$error }" 
                                        v-mask="'##:## AA'"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                            <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Saturday End Time</label>
                                <b-input-group class="timepicker-schedule animate__animated animate__fadeIn animate__fast">
                                    <b-input-group-append>
                                        <b-form-timepicker
                                        button-only 
                                        dropup 
                                        left 
                                        @context="saContextEnd"
                                        ></b-form-timepicker>
                                    </b-input-group-append>
                                    <b-form-input
                                        v-model="schedule_form.sa_end"
                                        type="text"
                                        placeholder="HH:mm AM/PM" 
                                        class="w-form-control w-input-fields mb-0" 
                                        :class="{ 'is_invalid': $v.schedule_form.sa_end.$error }" 
                                        v-mask="'##:## AA'"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                            <div class="d-flex mb-1 col-md-2  flex-column animate__animated animate__fadeIn animate__fast" :class="{ 'is_invalid': $v.schedule_form.sa_is_closed.$error }">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast">Closed?</label>
                                <switches 
                                class="active-switch h-53" 
                                v-model="schedule_form.sa_is_closed"
                                theme="bootstrap" 
                                color="primary"
                                type-bold="true"
                                label="" 
                                >
                                </switches>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <!-- <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Sunday Start Time</label>
                                <b-form-timepicker :reset-button="true" :no-flip="true" v-model="schedule_form.su_start" dropup class="overflow-visible timepicker-schedule w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100"></b-form-timepicker>
                            </div>
                            <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Sunday End Time</label>
                                <b-form-timepicker :reset-button="true" :no-flip="true" v-model="schedule_form.su_end" dropup class="overflow-visible timepicker-schedule w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100"></b-form-timepicker>
                            </div> -->

                            <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Sunday Start Time</label>
                                <b-input-group class="timepicker-schedule animate__animated animate__fadeIn animate__fast">
                                    <b-input-group-append>
                                        <b-form-timepicker
                                        button-only 
                                        dropup 
                                        left 
                                        @context="suContextStart"
                                        ></b-form-timepicker>
                                    </b-input-group-append>
                                    <b-form-input
                                        v-model="schedule_form.su_start"
                                        type="text"
                                        placeholder="HH:mm AM/PM" 
                                        class="w-form-control w-input-fields mb-0" 
                                        :class="{ 'is_invalid': $v.schedule_form.su_start.$error }" 
                                        v-mask="'##:## AA'"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                            <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Sunday End Time</label>
                                <b-input-group class="timepicker-schedule animate__animated animate__fadeIn animate__fast">
                                    <b-input-group-append>
                                        <b-form-timepicker
                                        button-only 
                                        dropup 
                                        left 
                                        @context="suContextEnd"
                                        ></b-form-timepicker>
                                    </b-input-group-append>
                                    <b-form-input
                                        v-model="schedule_form.su_end"
                                        type="text"
                                        placeholder="HH:mm AM/PM" 
                                        class="w-form-control w-input-fields mb-0" 
                                        :class="{ 'is_invalid': $v.schedule_form.su_end.$error }" 
                                        v-mask="'##:## AA'"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                            <div class="d-flex mb-1 col-md-2 flex-column animate__animated animate__fadeIn animate__fast" :class="{ 'is_invalid': $v.schedule_form.su_is_closed.$error }">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast">Closed?</label>
                                <switches 
                                class="active-switch h-53" 
                                v-model="schedule_form.su_is_closed"
                                theme="bootstrap" 
                                color="primary"
                                type-bold="true"
                                label="" 
                                >
                                </switches>
                            </div>
                        </div>

                        <div
                        class="
                        w-form-control
                        animate__animated animate__fadeIn animate__fast m-0 p-0
                        "
                        >
                            <a
                            href="javascript:void(0)"
                            @click="handleLabScheduleReq"
                            class="flex-fill" 
                            :disabled="!is_valid_lab"
                            >Save</a
                            >
                        </div>

                    </b-form>

                </div>

                <!-- start of lab schedules override section -->
                <div class="w-login-show notification-container w-alert-340 mx-auto mt-3" id="sch-override-container">
                    <div class="notification-block animate__animated animate__fadeIn w-alert-340" :class="over_type" v-if="over_type !== '' && over_message !== ''">
                        <div class="notification-message w-alert-290">
                            <h5>{{ over_message }}</h5>
                            <p v-if="over_message1 !== ''">{{ over_message1 }}</p>
                        </div>
                        <div class="icon-success" v-if="over_type == 'success'">
                            <i class="fa-solid fa-circle-check"></i>
                        </div>
                        <div class="icon-error" v-if="over_type == 'error'">
                            <i class="fa-solid fa-times-circle"></i>
                        </div>
                        <div class="icon-warning" v-if="over_type == 'warning'">
                            <i class="fa-solid fa-exclamation-circle"></i>
                        </div>
                    </div>
                </div>
                <h3 class="page-heading mb-3 animate__animated animate__fadeIn animate__fast mt-2 text-left">Lab Schedule Overrides</h3>
                <div class="row m-0 p-0 col-md-12 col-sm-12 m-auto">
                    
                    <b-form inline @submit.stop.prevent class="m-0 p-0">
                        <!-- client and labe name field -->
                        
                        <div class="row mac-search mt-3">
                            <div class="d-flex mb-1 col-md-3 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Date</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100 overflow-visible" :class="{ 'is_invalid': $v.override_form.date.$error }">
                                    <span><i class="fa-solid fa-calendar"></i></span>
                                    <input type="text" name="date" value="" v-mask="'##/##/##?#?#'" v-model="override_form.date" class="form-control" :class="{ 'is-invalid': $v.override_form.date.$error }" placeholder="mm/dd/yyyy" />
                                    <!-- <b-form-datepicker 
                                    v-model="override_form.date" 
                                    id="override_sch_date"
                                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                                    :min="min_date" 
                                    :class="[ $v.override_form.date.$error ? 'is-invalid' : '']" 
                                    class="overflow-visible timepicker-schedule w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" 
                                    placeholder="No date selected"
                                    ></b-form-datepicker> -->
                                </div>
                            </div>
                            <!-- <div class="d-flex mb-1 col-md-3 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Start Time</label>
                                <b-form-timepicker :reset-button="true" :no-flip="true" v-model="override_form.time_start" dropup class="overflow-visible timepicker-schedule w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.override_form.time_start.$error }"></b-form-timepicker>
                            </div> -->
                            
                            <div class="d-flex mb-1 col-md-3 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Start Time</label>
                                <b-input-group class="timepicker-schedule animate__animated animate__fadeIn animate__fast">
                                    <b-input-group-append>
                                        <b-form-timepicker
                                        button-only 
                                        dropup 
                                        left 
                                        @context="overContextStart"
                                        ></b-form-timepicker>
                                    </b-input-group-append>
                                    <b-form-input
                                        v-model="override_form.time_start"
                                        type="text"
                                        placeholder="HH:mm AM/PM" 
                                        class="w-form-control w-input-fields mb-0" 
                                        :class="{ 'is_invalid': $v.override_form.time_start.$error }" 
                                        v-mask="'?##:## AA'"
                                    ></b-form-input>
                                </b-input-group>
                            </div>

                            <!-- <div class="d-flex mb-1 col-md-3 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">End Time</label>
                                <b-form-timepicker :reset-button="true" :no-flip="true" v-model="override_form.time_end" dropup class="overflow-visible timepicker-schedule w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.override_form.time_end.$error }"></b-form-timepicker>
                            </div> -->
                            <div class="d-flex mb-1 col-md-3 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">End Time</label>
                                <b-input-group class="timepicker-schedule animate__animated animate__fadeIn animate__fast">
                                    <b-input-group-append>
                                        <b-form-timepicker
                                        button-only 
                                        dropup 
                                        left 
                                        @context="overContextEnd"
                                        ></b-form-timepicker>
                                    </b-input-group-append>
                                    <b-form-input
                                        v-model="override_form.time_end"
                                        type="text"
                                        placeholder="HH:mm AM/PM" 
                                        class="w-form-control w-input-fields mb-0" 
                                        :class="{ 'is_invalid': $v.override_form.time_end.$error }" 
                                        v-mask="'#?#:## AA'"
                                    ></b-form-input>
                                </b-input-group>
                            </div>

                            <div class="d-flex mb-1 col-md-1 flex-column animate__animated animate__fadeIn animate__fast" :class="{ 'is_invalid': $v.override_form.is_paid.$error }">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Paid</label>
                                <switches 
                                class="active-switch h-53" 
                                v-model="override_form.is_paid"
                                theme="bootstrap" 
                                color="primary"
                                type-bold="true"
                                label="" 
                                >
                                </switches>
                            </div>
                            <div class="d-flex mb-1 col-md-1 flex-column animate__animated animate__fadeIn animate__fast" :class="{ 'is_invalid': $v.override_form.is_closed.$error }">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast">Closed?</label>
                                <switches 
                                class="active-switch h-53" 
                                v-model="override_form.is_closed"
                                theme="bootstrap" 
                                color="primary"
                                type-bold="true"
                                label="" 
                                >
                                </switches>
                            </div>
                            <div class="d-flex mb-1 col-md-1 flex-column">
                                <label for="" class="form-main-label w-100">&nbsp;</label>
                                <div
                                class="
                                w-form-control
                                animate__animated animate__fadeIn animate__fast m-0 p-0
                                "
                                >
                                    <a
                                    href="javascript:void(0)"
                                    @click="handleLabScheduleOverrideReq"
                                    class="flex-fill m-0" 
                                    :disabled="!is_valid_lab"
                                    >Save</a
                                    >
                                </div>
                            </div>
                        </div>

                    </b-form>

                </div>

                <!-- start of lab schedule override table -->
                <div class="col-sm-12 animate__animated animate__fadeIn animate__fast mt-2">
                    <div class="overflow-auto p-0 m-0">
                        <b-table
                        id="admin-general-table"
                        :striped="true"
                        :hover="false"
                        :dark="false"
                        :items="override_schedules"
                        :fields="fields"
                        show-empty
                        :emptyText="emptyText"
                        >
                            <template #cell(action)="data">
                                <div class="icon-action-buttons-container d-flex flex-row">
                                    <!-- delete button -->
                                    <button 
                                    class="btn-danger btn-sm" 
                                    :class="data.value"
                                    @click="confirmDelete(data.item.id)"
                                    >
                                    <b-icon class="icon" icon="trash2-fill"></b-icon>
                                    Delete
                                    </button>
                                </div>
                            </template>
                            <template #cell(is_paid)="data">
                                <i class="fas fa-circle-check fa-2xl icon h2" v-if="data.item.is_paid == 1"></i>
                                <i class="fas fa-times-circle fa-2xl icon h2" v-if="data.item.is_paid !== 1" style="color:#FF1744;"></i>
                            </template>
                            <template #empty="scope">
                            <p class="text-center p-0 m-0">{{ scope.emptyText }}</p>
                            </template>
                        </b-table>
                    </div>
                </div>
                <!-- end of lab schedule override table -->

                <!-- start of lab allowed ips section -->
                <div class="w-login-show notification-container w-alert-340 mx-auto mt-3" id="allowedips-container">
                    <div class="notification-block animate__animated animate__fadeIn w-alert-340" :class="allowedip_type" v-if="allowedip_type !== '' && allowedip_message !== ''">
                        <div class="notification-message w-alert-290">
                            <h5>{{ allowedip_message }}</h5>
                            <p v-if="allowedip_message1 !== ''">{{ allowedip_message1 }}</p>
                        </div>
                        <div class="icon-success" v-if="allowedip_type == 'success'">
                            <i class="fa-solid fa-circle-check"></i>
                        </div>
                        <div class="icon-error" v-if="allowedip_type == 'error'">
                            <i class="fa-solid fa-times-circle"></i>
                        </div>
                        <div class="icon-warning" v-if="allowedip_type == 'warning'">
                            <i class="fa-solid fa-exclamation-circle"></i>
                        </div>
                    </div>
                </div>
                <h3 class="page-heading mb-3 animate__animated animate__fadeIn animate__fast mt-2 text-left">Lab Allowed IPs</h3>
                <div class="row m-0 p-0 col-md-12 col-sm-12 m-auto">
                    
                    <b-form inline @submit.stop.prevent class="m-0 p-0">
                        <!-- client and labe name field -->
                        
                        <div class="row mac-search mt-3">
                            <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Choose Csv</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.allowedip_form.csv_file.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-file
                                        v-model="allowedip_form.csv_file"
                                        placeholder="Choose csv file"
                                        id="csv_file"
                                        accept="text/csv"
                                        aria-describedby="csv_file-help-block"
                                        class=""
                                        :class="[ $v.allowedip_form.csv_file.$error ? 'is-invalid' : '' ]"
                                    ></b-form-file>
                                </div>
                            </div>
                            <div class="d-flex mb-1 col-md-2 flex-column">
                                <label for="" class="form-main-label w-100">&nbsp;</label>
                                <div
                                class="
                                w-form-control
                                animate__animated animate__fadeIn animate__fast m-0 p-0
                                "
                                >
                                    <a
                                    href="javascript:void(0)"
                                    @click="handleLabAllowedIpsReq"
                                    class="flex-fill m-0" 
                                    :disabled="!is_valid_lab"
                                    >Save</a
                                    >
                                </div>
                            </div>
                            <div class="d-flex mb-1 col-md-2 flex-column">
                                <label for="" class="form-main-label w-100">&nbsp;</label>
                                <div
                                class="
                                w-form-control
                                animate__animated animate__fadeIn animate__fast m-0 p-0
                                "
                                >
                                    <a :href="sample_ips_template_url" target="_blank" role="button" type="button" class="flex-fill m-0">Download Sample</a>
                                </div>
                            </div>
                        </div>

                    </b-form>

                    <div class="row m-0 p-0 animate__animated animate__fadeIn animate__fast">
                        <div class="col-md-12 col-sm-12 p-0 m-0">
                            <ul v-if="allowedips_list.length > 0" class="admin-del-list">
                                <li v-for="alowedip in allowedips_list" :key="alowedip.id">
                                    <div class="domain-name-section">{{ alowedip.allowed_ip }}</div>
                                    <div class="domain-delete-section">
                                        <a 
                                        href="javascript:;"
                                        @click="confirmDeleteAllowedIp(alowedip.id)"
                                        ><i class="fas fa-times-circle"></i></a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

                <!-- start of homepage images section -->
                <div class="w-login-show notification-container w-alert-340 mx-auto mt-3" id="homeimage-container">
                    <div class="notification-block animate__animated animate__fadeIn w-alert-340" :class="homeimage_type" v-if="homeimage_type !== '' && homeimage_message !== ''">
                        <div class="notification-message w-alert-290">
                            <h5>{{ homeimage_message }}</h5>
                            <p v-if="homeimage_message1 !== ''">{{ homeimage_message1 }}</p>
                        </div>
                        <div class="icon-success" v-if="homeimage_type == 'success'">
                            <i class="fa-solid fa-circle-check"></i>
                        </div>
                        <div class="icon-error" v-if="homeimage_type == 'error'">
                            <i class="fa-solid fa-times-circle"></i>
                        </div>
                        <div class="icon-warning" v-if="homeimage_type == 'warning'">
                            <i class="fa-solid fa-exclamation-circle"></i>
                        </div>
                    </div>
                </div>
                <h3 class="page-heading mb-3 animate__animated animate__fadeIn animate__fast mt-2 text-left">Homepage Images</h3>
                <div class="row m-0 p-0 col-md-12 col-sm-12 m-auto">
                    
                    <b-form inline @submit.stop.prevent class="m-0 p-0">
                        <!-- client and labe name field -->
                        
                        <div class="row mac-search mt-3">
                            <div class="d-flex mb-1 col-md-5 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Homepage Image</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.homeimage_form.homeimage.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-file
                                        v-model="homeimage_form.homeimage"
                                        placeholder="Choose an Image"
                                        id="home_image"
                                        accept="image/*"
                                        aria-describedby="home_image-help-block"
                                        class=""
                                        :class="[ $v.homeimage_form.homeimage.$error ? 'is-invalid' : '' ]"
                                    ></b-form-file>
                                </div>
                            </div>
                            <div class="d-flex mb-1 col-md-2 flex-column">
                                <label for="" class="form-main-label w-100">&nbsp;</label>
                                <div
                                class="
                                w-form-control
                                animate__animated animate__fadeIn animate__fast m-0 p-0
                                "
                                >
                                    <a
                                    href="javascript:void(0)"
                                    @click="handleLabHomeImageReq('left')"
                                    class="flex-fill m-0" 
                                    :disabled="!is_valid_lab"
                                    >Upload Left Image</a
                                    >
                                </div>
                            </div>
                            <div class="d-flex mb-1 col-md-2 flex-column">
                                <label for="" class="form-main-label w-100">&nbsp;</label>
                                <div
                                class="
                                w-form-control
                                animate__animated animate__fadeIn animate__fast m-0 p-0
                                "
                                >
                                    <a
                                    href="javascript:void(0)"
                                    @click="handleLabHomeImageReq('right')"
                                    class="flex-fill m-0" 
                                    :disabled="!is_valid_lab"
                                    >Upload Right Image</a
                                    >
                                </div>
                            </div>
                            <div class="d-flex mb-1 col-md-2 flex-column">
                                <label for="" class="form-main-label w-100">&nbsp;</label>
                                <div
                                class="
                                w-form-control
                                animate__animated animate__fadeInUp animate__delay-3s m-0 p-0
                                "
                                >
                                    <a
                                    href="javascript:void(0)"
                                    @click="handleLabHomeImageReq('banner')"
                                    class="flex-fill m-0" 
                                    :disabled="!is_valid_lab"
                                    >Upload Banner Image</a
                                    >
                                </div>
                            </div>
                        </div>

                        <div class="d-flex mb-3 col-md-12 p-0 m-0 animate__animated animate__fadeIn animate__fast">
                            <p class="text-white m-0 p-0">Ideal resolution is no more than 720px wide and/or 800px tall.</p>
                        </div>
                    </b-form>

                    <div class="row m-0 p-0 animate__animated animate__fadeIn animate__fast">
                        <div class="col-md-12 col-sm-12 p-0 m-0">
                            <ul class="admin-del-list">
                                <li v-if="left_home_image !== '' && left_home_image !== null">
                                    <div class="domain-name-section">
                                        <img :src="left_home_image" alt="Left Home Image" style="width:150px;">
                                        <p class="p-0 m-0">Left Home Image</p>
                                    </div>
                                    <div class="domain-delete-section">
                                        <a 
                                        href="javascript:;"
                                        @click="confirmDeleteHomeImage('left')"
                                        ><i class="fas fa-times-circle"></i></a>
                                    </div>
                                </li>
                                <li v-if="right_home_image !== '' && right_home_image !== null">
                                    <div class="domain-name-section">
                                        <img :src="right_home_image" alt="Left Home Image" style="width:150px;">
                                        <p class="p-0 m-0">Right Home Image</p>
                                    </div>
                                    <div class="domain-delete-section">
                                        <a 
                                        href="javascript:;"
                                        @click="confirmDeleteHomeImage('right')"
                                        ><i class="fas fa-times-circle"></i></a>
                                    </div>
                                </li>
                                <li v-if="banner_home_image !== '' && banner_home_image !== null">
                                    <div class="domain-name-section">
                                        <img :src="banner_home_image" alt="Left Home Image" style="width:150px;">
                                        <p class="p-0 m-0">Banner Home Image</p>
                                    </div>
                                    <div class="domain-delete-section">
                                        <a 
                                        href="javascript:;"
                                        @click="confirmDeleteHomeImage('banner')"
                                        ><i class="fas fa-times-circle"></i></a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                <!-- end of home page images section -->

                <!-- start of lab rss ffed section -->
                <div class="w-login-show notification-container w-alert-340 mx-auto mt-3" id="rssfeed-container">
                    <div class="notification-block animate__animated animate__fadeIn w-alert-340" :class="rssfeed_type" v-if="rssfeed_type !== '' && rssfeed_message !== ''">
                        <div class="notification-message w-alert-290">
                            <h5>{{ rssfeed_message }}</h5>
                            <p v-if="rssfeed_message1 !== ''">{{ rssfeed_message1 }}</p>
                        </div>
                        <div class="icon-success" v-if="rssfeed_type == 'success'">
                            <i class="fa-solid fa-circle-check"></i>
                        </div>
                        <div class="icon-error" v-if="rssfeed_type == 'error'">
                            <i class="fa-solid fa-times-circle"></i>
                        </div>
                        <div class="icon-warning" v-if="rssfeed_type == 'warning'">
                            <i class="fa-solid fa-exclamation-circle"></i>
                        </div>
                    </div>
                </div>
                <h3 class="page-heading mb-3 animate__animated animate__fadeIn animate__fast mt-2 text-left">Lab Rss Feeds</h3>
                <div class="row m-0 p-0 col-md-12 col-sm-12 m-auto">
                    
                    <b-form inline @submit.stop.prevent class="m-0 p-0">
                        <!-- client and labe name field -->
                        
                        <div class="row mac-search mt-3">
                            <div class="d-flex mb-1 col-md-3 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Title</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.rssfeed_form.rss_title.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-input
                                        v-model="rssfeed_form.rss_title"
                                        id="rss-title"
                                        class="mb-2 mr-sm-2 mb-sm-0 user-name bg-lightBlack border-0 h-53"
                                        :class="{ 'is-invalid': $v.rssfeed_form.rss_title.$error }"
                                        placeholder="Enter title"
                                    ></b-form-input>
                                </div>
                            </div>
                            <div class="d-flex mb-1 col-md-3 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Url</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.rssfeed_form.rss_url.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-input
                                        v-model="rssfeed_form.rss_url"
                                        id="rss-url"
                                        class="mb-2 mr-sm-2 mb-sm-0 user-name bg-lightBlack border-0 h-53"
                                        :class="{ 'is-invalid': $v.rssfeed_form.rss_url.$error }"
                                        placeholder="Enter url"
                                    ></b-form-input>
                                </div>
                            </div>
                            <div class="d-flex mb-1 col-md-3 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Expire Time</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.rssfeed_form.rss_expire_time.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-input
                                        v-model="rssfeed_form.rss_expire_time"
                                        id="rss-expire-time"
                                        class="mb-2 mr-sm-2 mb-sm-0 user-name bg-lightBlack border-0 h-53"
                                        :class="{ 'is-invalid': $v.rssfeed_form.rss_expire_time.$error }"
                                        placeholder="Enter expire time in minutes"
                                    ></b-form-input>
                                </div>
                            </div>
                            <div class="d-flex mb-1 col-md-1 flex-column">
                                <label for="" class="form-main-label w-100">&nbsp;</label>
                                <div
                                class="
                                w-form-control
                                animate__animated animate__fadeIn animate__fast m-0 p-0
                                "
                                >
                                    <a
                                    href="javascript:void(0)"
                                    @click="handleLabRssFeedReq"
                                    class="flex-fill m-0" 
                                    :disabled="!is_valid_lab"
                                    >Save</a
                                    >
                                </div>
                            </div>
                        </div>

                    </b-form>

                </div>

                <!-- start of lab rss feed table -->
                <div class="col-sm-12 animate__animated animate__fadeIn animate__fast mt-2">
                    <div class="overflow-auto p-0 m-0">
                        <b-table
                        id="admin-general-table"
                        :striped="true"
                        :hover="false"
                        :dark="false"
                        :items="rss_feeds"
                        :fields="rssfeedFields"
                        show-empty
                        :emptyText="rssfeedEmptyText"
                        >
                            <template #cell(action)="data">
                                <div class="icon-action-buttons-container d-flex flex-row">
                                    <!-- delete button -->
                                    <button 
                                    class="btn-danger btn-sm" 
                                    :class="data.value"
                                    @click="confirmRssFeedDelete(data.item.id)"
                                    >
                                    <b-icon class="icon" icon="trash2-fill"></b-icon>
                                    Delete
                                    </button>
                                </div>
                            </template>
                            <template #empty="scope">
                                <p class="text-center p-0 m-0">{{ scope.emptyText }}</p>
                            </template>
                        </b-table>
                    </div>
                </div>
                <!-- end of lab rss feed table -->


                <!-- start of lab api keys section -->
                <div class="w-login-show notification-container w-alert-340 mx-auto mt-3" id="apikeys-container">
                    <div class="notification-block animate__animated animate__fadeInUp w-alert-340" :class="apikeys_type" v-if="apikeys_type !== '' && apikeys_message !== ''">
                        <div class="notification-message w-alert-290">
                            <h5>{{ apikeys_message }}</h5>
                            <p v-if="apikeys_message1 !== ''">{{ apikeys_message1 }}</p>
                        </div>
                        <div class="icon-success" v-if="apikeys_type == 'success'">
                            <i class="fa-solid fa-circle-check"></i>
                        </div>
                        <div class="icon-error" v-if="apikeys_type == 'error'">
                            <i class="fa-solid fa-times-circle"></i>
                        </div>
                        <div class="icon-warning" v-if="apikeys_type == 'warning'">
                            <i class="fa-solid fa-exclamation-circle"></i>
                        </div>
                    </div>
                </div>
                <h3 class="page-heading mb-3 animate__animated animate__fadeInUp animate__delay-1s mt-2 text-left">Lab API Keys</h3>
                <div class="row m-0 p-0 col-md-12 col-sm-12 m-auto">
                    
                    <b-form inline @submit.stop.prevent class="m-0 p-0">
                        <!-- client and labe name field -->
                        
                        <div class="row mac-search mt-3">
                            <div class="d-flex mb-1 col-md-3 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Key Name</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.apikeys_form.api_key_name.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-input
                                        v-model="apikeys_form.api_key_name"
                                        id="apikeys-name"
                                        class="mb-2 mr-sm-2 mb-sm-0 user-name bg-lightBlack border-0 h-53"
                                        :class="{ 'is-invalid': $v.apikeys_form.api_key_name.$error }"
                                        placeholder="Enter name"
                                    ></b-form-input>
                                </div>
                            </div>
                            
                            <div class="d-flex mb-1 col-md-1 flex-column">
                                <label for="" class="form-main-label w-100">&nbsp;</label>
                                <div
                                class="
                                w-form-control
                                animate__animated animate__fadeInUp animate__delay-3s m-0 p-0
                                "
                                >
                                    <a
                                    href="javascript:void(0)"
                                    @click="handleAPIKeysReq"
                                    class="flex-fill m-0" 
                                    :disabled="!is_valid_lab"
                                    >Create</a
                                    >
                                </div>
                            </div>
                        </div>

                    </b-form>

                </div>

                <!-- start of lab rss feed table -->
                <div class="col-sm-12 animate__animated animate__fadeInUp animate__delay-1s mt-2">
                    <div class="overflow-auto p-0 m-0">
                        <b-table
                        id="admin-general-table"
                        :striped="true"
                        :hover="false"
                        :dark="false"
                        :items="apikeys_data"
                        :fields="apikeysFields"
                        show-empty
                        :emptyText="apikeysEmptyText"
                        >
                            <template #cell(action)="data">
                                <div class="icon-action-buttons-container d-flex flex-row">
                                    <!-- delete button -->
                                    <button 
                                    class="btn-danger btn-sm" 
                                    :class="data.value"
                                    @click="confirmAPIKeysDelete(data.item.id)"
                                    >
                                    <b-icon class="icon" icon="trash2-fill"></b-icon>
                                    Delete
                                    </button>
                                </div>
                            </template>
                            <template #empty="scope">
                                <p class="text-center p-0 m-0">{{ scope.emptyText }}</p>
                            </template>
                        </b-table>
                    </div>
                </div>
                <!-- end of lab api keys table -->

            </div>

        </div>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <AdminFooter type="inner" />
        </div>
        <!-- end of footer section -->

        <!-- start of delete confirm modal -->
        <b-modal id="delete-override-modal" dialog-class="admin-portal-modal" header-class="admin-portal-bg secondary px-3 py-2" hide-footer>
            <template #modal-title>
                Confirm
                <b-icon 
                class="icon" 
                icon="question-circle-fill"
                >
                </b-icon> 
            </template>
            <b-overlay :show="false" rounded="lg">
                <div class="d-flex">
                <p>Are you sure you want to delete selected override schedule ?</p>
                </div>
                <div class="d-flex text-center">
                <button
                    type="button"
                    class="modal-button px-5 py-2"
                    @click="deleteOverrideSchedule"
                >
                    Yes
                </button>
                <button
                    type="button"
                    class="modal-button px-5 py-2 mx-2"
                    @click="$bvModal.hide('delete-override-modal')"
                >
                    No
                </button>
                </div>
            </b-overlay>
        </b-modal>

        <!-- start of delete allowed ip modal -->
        <b-modal id="delete-allowedip-modal" dialog-class="admin-portal-modal" header-class="admin-portal-bg secondary px-3 py-2" hide-footer>
            <template #modal-title>
                Confirm
                <b-icon 
                class="icon" 
                icon="question-circle-fill"
                >
                </b-icon> 
            </template>
            <b-overlay :show="false" rounded="lg">
                <div class="d-flex">
                <p>Are you sure you want to delete selected allowed IP ?</p>
                </div>
                <div class="d-flex text-center">
                <button
                    type="button"
                    class="modal-button px-5 py-2"
                    @click="deleteAllowedIp"
                >
                    Yes
                </button>
                <button
                    type="button"
                    class="modal-button px-5 py-2 mx-2"
                    @click="$bvModal.hide('delete-allowedip-modal')"
                >
                    No
                </button>
                </div>
            </b-overlay>
        </b-modal>

        <!-- start of delete homeimage modal -->
        <b-modal id="delete-homeimage-modal" dialog-class="admin-portal-modal" header-class="admin-portal-bg secondary px-3 py-2" hide-footer>
            <template #modal-title>
                Confirm
                <b-icon 
                class="icon" 
                icon="question-circle-fill"
                >
                </b-icon> 
            </template>
            <b-overlay :show="false" rounded="lg">
                <div class="d-flex">
                <p>Are you sure you want to delete selected home image ?</p>
                </div>
                <div class="d-flex text-center">
                <button
                    type="button"
                    class="modal-button px-5 py-2"
                    @click="deleteHomeImage"
                >
                    Yes
                </button>
                <button
                    type="button"
                    class="modal-button px-5 py-2 mx-2"
                    @click="$bvModal.hide('delete-homeimage-modal')"
                >
                    No
                </button>
                </div>
            </b-overlay>
        </b-modal>

        <!-- start of delete rss feed confirm modal -->
        <b-modal id="delete-rssfeed-modal" dialog-class="admin-portal-modal" header-class="admin-portal-bg secondary px-3 py-2" hide-footer>
            <template #modal-title>
                Confirm
                <b-icon 
                class="icon" 
                icon="question-circle-fill"
                >
                </b-icon> 
            </template>
            <b-overlay :show="false" rounded="lg">
                <div class="d-flex">
                <p>Are you sure you want to delete selected rss feed ?</p>
                </div>
                <div class="d-flex text-center">
                <button
                    type="button"
                    class="modal-button px-5 py-2"
                    @click="deleteRssFeed"
                >
                    Yes
                </button>
                <button
                    type="button"
                    class="modal-button px-5 py-2 mx-2"
                    @click="$bvModal.hide('delete-rssfeed-modal')"
                >
                    No
                </button>
                </div>
            </b-overlay>
        </b-modal>

        <!-- start of delete api keys confirm modal -->
        <b-modal id="delete-apikeys-modal" dialog-class="admin-portal-modal" header-class="admin-portal-bg secondary px-3 py-2" hide-footer>
            <template #modal-title>
                Confirm
                <b-icon 
                class="icon" 
                icon="question-circle-fill"
                >
                </b-icon> 
            </template>
            <b-overlay :show="false" rounded="lg">
                <div class="d-flex">
                <p>Are you sure you want to delete selected API Key ?</p>
                </div>
                <div class="d-flex text-center">
                <button
                    type="button"
                    class="modal-button px-5 py-2"
                    @click="deleteAPIKeys"
                >
                    Yes
                </button>
                <button
                    type="button"
                    class="modal-button px-5 py-2 mx-2"
                    @click="$bvModal.hide('delete-apikeys-modal')"
                >
                    No
                </button>
                </div>
            </b-overlay>
        </b-modal>

    </div>
</template>

<script>
import AdminHeader from "@/views/admin/AdminHeader.vue";
import { adminGetClientsList, adminSaveLab, adminGetLabDetails, adminSaveLabSchedule, adminSaveLabScheduleOverride, adminDeleteLabScheduleOverride, adminUploadLabAllowedIps, adminDeleteAllowedIp, adminUploadLabHomeImage, adminDeleteHomeImage, adminSaveLabRssFeed, adminDeleteLabRssFeed, adminSaveAPIKeys, adminDeleteAPIKeys } from "../../services/AdminService";
import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import { required, requiredIf, minLength } from 'vuelidate/lib/validators';
import Switches from 'vue-switches';
import AdminFooter from "@/views/admin/AdminFooter.vue";
import { global_config } from "@/config/config.js";

export default {
  name: "AdminEditLab-view",
  components: { AdminHeader, Switches, AdminFooter },
  data() {
    return {
        clients:[],
        form: {
            client_id:'',
            lab_name:'',
            address1:'',
            address2:'',
            city:'',
            state:'',
            zip:'',
            note:'',
            is_active: 0,
            timezone: '',
            is_paid: 0,
            contact_email: '',
            reservation_email: ''
        },
        edit_lab_id: this.$route.params.lab_id,
        is_valid_lab: false,
        schedule_form: {
            mo_start:'',
            mo_end:'',
            tu_start:'',
            tu_end:'',
            we_start:'',
            we_end:'',
            th_start:'',
            th_end:'',
            fr_start:'',
            fr_end:'',
            sa_start:'',
            sa_end:'',
            su_start:'',
            su_end:'',
            mo_is_closed: 0,
            tu_is_closed: 0,
            we_is_closed: 0,
            th_is_closed: 0,
            fr_is_closed: 0,
            sa_is_closed: 0,
            su_is_closed: 0
        },
        sch_type: '',
        sch_message: '',
        sch_message1: '',
        override_form: {
            date:null,
            time_start:'',
            time_end:'',
            is_paid:0,
            is_closed:0,
        },
        over_type: '',
        over_message: '',
        over_message1: '',
        emptyText:"No override schedule found at the moment!",
        emptyHtml:"No override schedule found at the moment!",
        fields: [
            {'key' : 'id', 'label' : 'ID'}, 
            {'key' : 'date', 'label' : 'Date'},
            {'key' : 'time_start_ampm', 'label' : 'Start Time'}, 
            {'key' : 'time_end_ampm', 'label' : 'End Time'}, 
            {'key' : 'is_paid', 'label' : 'Paid'},
            {'key' : 'action', 'label' : 'Action'}
        ],
        override_schedules: [],
        min_date: (new Date()),
        delete_override_id: '',
        timezones: global_config.TIMEZONES_LIST,
        allowedip_type: '',
        allowedip_message: '',
        allowedip_message1: '',
        allowedip_form: {
            csv_file:null
        },
        allowedips_list: [],
        delete_allowedip_id:'',
        sample_ips_template_url: window.location.origin + '/ips-template.csv',
        homeimage_type: '',
        homeimage_message: '',
        homeimage_message1: '',
        homeimage_form: {
            homeimage:null
        },
        left_home_image: '',
        right_home_image: '',
        delete_homeimage_type: '',
        rssfeed_type: '',
        rssfeed_message: '',
        rssfeed_message1: '',
        rssfeedEmptyText:"No rss feed found at the moment!",
        rssfeedEmptyHtml:"No rss feed found at the moment!",
        rssfeedFields: [
            {'key' : 'id', 'label' : 'ID'}, 
            {'key' : 'rss_title', 'label' : 'Title'},
            {'key' : 'rss_url', 'label' : 'Url'}, 
            {'key' : 'FormattedUpdatedAt', 'label' : 'Created At'},
            {'key' : 'rss_expire_time', 'label' : 'Expire Time'}, 
            {'key' : 'action', 'label' : 'Action'}
        ],
        rss_feeds: [],
        delete_rssfeed_id: '',
        rssfeed_form: {
            rss_title:'',
            rss_url:'',
            rss_expire_time:''
        },
        apikeys_type: '',
        apikeys_message: '',
        apikeys_message1: '',
        apikeysEmptyText:"No api keys found at the moment!",
        apikeysEmptyHtml:"No api keys found at the moment!",
        apikeysFields: [
            {'key' : 'api_key_name', 'label' : 'Name'},
            {'key' : 'api_key', 'label' : 'Key'}, 
            {'key' : 'action', 'label' : 'Action'}
        ],
        apikeys_data: [],
        delete_apikeys_id: '',
        apikeys_form: {
            api_key_name:''
        },
        banner_home_image: '',
    };
  },

  validations: {
    form : {
      client_id:{required},
      lab_name:{required},
      address1:{required},
      address2:{required},
      city:{required},
      state:{required},
      zip:{required},
      note:{required},
      is_active:{required},
      timezone: {required: false},
      is_paid:{required},
      contact_email: {required: false},
      reservation_email: {required:false}
    },
    schedule_form: {
        mo_start:{required:false},
        mo_end:{required: requiredIf('mo_start')},
        tu_start:{required: false},
        tu_end:{required: requiredIf('tu_start')},
        we_start:{required: false},
        we_end:{required: requiredIf('we_start')},
        th_start:{required: true},
        th_end:{required: requiredIf('th_start')},
        fr_start:{required: false},
        fr_end:{required: requiredIf('fr_start')},
        sa_start:{required: false},
        sa_end:{required: requiredIf('sa_start')},
        su_start:{required: false},
        su_end:{required: requiredIf('su_start')},
        mo_is_closed: {required: false},
        tu_is_closed: {required: false},
        we_is_closed: {required: false},
        th_is_closed: {required: false},
        fr_is_closed: {required: false},
        sa_is_closed: {required: false},
        su_is_closed: {required: false}
    },
    override_form: {
        date:{required, minLength: minLength(8)},
        time_start:{required:false},
        time_end:{required:false},
        is_paid:{required:false},
        is_closed:{required:false}
    },
    allowedip_form: {
        csv_file:{required}
    },
    homeimage_form: {
        homeimage:{required}
    },
    rssfeed_form: {
        rss_title:{required},
        rss_url:{required},
        rss_expire_time:{required}
    },
    apikeys_form: {
        api_key_name:{required}
    },
  },

  computed: {
    logo_file() {
        return this.$store.getters.getThemeLogo().logo;
    }
  },

  methods: {
    clientsList() {
      let user_data = JSON.parse(localStorage.user_sess_data);
      let params = {
        token: user_data.token
      };
      adminGetClientsList(params).then(response => {
        this.clients = response.data.clients;
        // add empty option as placeholder
        this.clients.splice(0, 0, {'id' : '', 'value' : 'Select a client'});
      });
    },

    handleLabReq() {
      this.$v.form.$touch();
      console.log(this.$v.form);
      if (this.$v.form.$invalid) {
        this.makeToast("generic-required-message", "error", "Please fill all required fields");
      } else {
        let user_data = JSON.parse(localStorage.user_sess_data);
        let data = this.form;
        data.token = user_data.token;
        data.lab_id = this.edit_lab_id;
        data.is_active = data.is_active ? 1 : 0;
        data.is_paid = data.is_paid ? 1 : 0;
        adminSaveLab(data).then(response => {
          if(response.status){
            this.makeToast(response.message, "success", response.message);
          } else {
            this.makeToast(response.message, "error", response.message);
          }
        }); 
      }
    },

    handleLabScheduleReq() {
        this.$v.schedule_form.$touch();
        console.log(this.$v.schedule_form);
        if (this.$v.schedule_form.$invalid) {
            this.makeToast("generic-required-message", "error", "Please fill all required fields", "sch");
        } 
        else {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let data = this.schedule_form;
            data.token = user_data.token;
            data.lab_id = this.edit_lab_id;
            adminSaveLabSchedule(data).then(response => {
                if(response.status){
                    this.makeToast(response.message, "success", response.message, "sch");
                } else {
                    this.makeToast(response.message, "error", response.message, "sch");
                }
            }); 
        }
    },

    handleLabScheduleOverrideReq() {
        this.$v.override_form.$touch();
        console.log(this.$v.override_form);
        if (this.$v.override_form.$invalid) {
            this.makeToast("generic-required-message", "error", "Please fill all required fields", "override");
        }
        else {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let data = this.override_form;
            data.token = user_data.token;
            data.lab_id = this.edit_lab_id;
            data.is_on = data.is_on ? 1 : 0;
            data.is_paid = data.is_paid ? 1 : 0;
            adminSaveLabScheduleOverride(data).then(response => {
                if(response.status){
                    this.makeToast(response.message, "success", response.message, "override");
                    //Iterate through each object field, key is name of the object field`
                    var self = this;
                    Object.keys(this.override_form).forEach(function(key) {
                        self.override_form[key] = '';
                    });
                    this.$v.override_form.$reset();
                    this.getLabDetails();
                } else {
                    this.makeToast(response.message, "error", response.message, "override");
                }
            }); 
        }
    },

    handleLabAllowedIpsReq() {
        this.$v.allowedip_form.$touch();
        console.log(this.$v.allowedip_form);
        if (this.$v.allowedip_form.$invalid) {
            this.makeToast("generic-required-message", "error", "Please fill all required fields", "allowedips");
        }
        else {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let data = this.allowedip_form;
            data.token = user_data.token;
            data.lab_id = this.edit_lab_id;
            adminUploadLabAllowedIps(data).then(response => {
                if(response.status){
                    this.makeToast(response.message, "success", response.message, "allowedips");
                    this.$v.allowedip_form.$reset();
                    this.allowedip_form.csv_file = null;
                    this.allowedips_list = response.data.allowedips;
                } else {
                    this.makeToast(response.message, "error", response.message, "allowedips");
                }
            }); 
        }
    },

    resetForm() {
        var self = this;
        //Iterate through each object field, key is name of the object field`
        Object.keys(this.form).forEach(function(key) {
            self.form[key] = '';
        });
    },

    makeToast(message_key, variant = "default", message, type="") {
        // search for key
        let smessage = this.$store.state.system_messages.list.find(o => o.msg_key === message_key);
        if(type == 'sch') {
            document.getElementById('sch-notification-container').scrollIntoView();
            if(smessage !== null && typeof smessage !== 'undefined' && Object.prototype.hasOwnProperty.call(smessage, 'text_head')) {
                this.sch_type = smessage.type == 'failure' ? 'error' : smessage.type;
                this.sch_message = smessage.text_head;
                this.sch_message1 = smessage.text_sub;
            }
            else {
                this.sch_type = variant;
                this.sch_message = message;
                this.sch_message1 = "";
            }
            setTimeout(() => {
                this.sch_type = "";
                this.sch_message = "";
                this.sch_message1 = "";
            }, 4000);
        }
        else if(type == 'override') {
            document.getElementById('sch-override-container').scrollIntoView();
            if(smessage !== null && typeof smessage !== 'undefined' && Object.prototype.hasOwnProperty.call(smessage, 'text_head')) {
                this.over_type = smessage.type == 'failure' ? 'error' : smessage.type;
                this.over_message = smessage.text_head;
                this.over_message1 = smessage.text_sub;
            }
            else {
                this.over_type = variant;
                this.over_message = message;
                this.over_message1 = "";
            }
            setTimeout(() => {
                this.over_type = "";
                this.over_message = "";
                this.over_message1 = "";
            }, 4000);
        }
        else if(type == 'allowedips') {
            document.getElementById('allowedips-container').scrollIntoView();
            if(smessage !== null && typeof smessage !== 'undefined' && Object.prototype.hasOwnProperty.call(smessage, 'text_head')) {
                this.allowedip_type = smessage.type == 'failure' ? 'error' : smessage.type;
                this.allowedip_message = smessage.text_head;
                this.allowedip_message1 = smessage.text_sub;
            }
            else {
                this.allowedip_type = variant;
                this.allowedip_message = message;
                this.allowedip_message1 = "";
            }
            setTimeout(() => {
                this.allowedip_type = "";
                this.allowedip_message = "";
                this.allowedip_message1 = "";
            }, 4000);
        }
        else if(type == 'homeimage') {
            document.getElementById('homeimage-container').scrollIntoView();
            if(smessage !== null && typeof smessage !== 'undefined' && Object.prototype.hasOwnProperty.call(smessage, 'text_head')) {
                this.homeimage_type = smessage.type == 'failure' ? 'error' : smessage.type;
                this.homeimage_message = smessage.text_head;
                this.homeimage_message1 = smessage.text_sub;
            }
            else {
                this.homeimage_type = variant;
                this.homeimage_message = message;
                this.homeimage_message1 = "";
            }
            setTimeout(() => {
                this.homeimage_type = "";
                this.homeimage_message = "";
                this.homeimage_message1 = "";
            }, 4000);
        }
        else if(type == 'rssfeed') {
            document.getElementById('rssfeed-container').scrollIntoView();
            if(smessage !== null && typeof smessage !== 'undefined' && Object.prototype.hasOwnProperty.call(smessage, 'text_head')) {
                this.rssfeed_type = smessage.type == 'failure' ? 'error' : smessage.type;
                this.rssfeed_message = smessage.text_head;
                this.rssfeed_message1 = smessage.text_sub;
            }
            else {
                this.rssfeed_type = variant;
                this.rssfeed_message = message;
                this.rssfeed_message1 = "";
            }
            setTimeout(() => {
                this.rssfeed_type = "";
                this.rssfeed_message = "";
                this.rssfeed_message1 = "";
            }, 4000);
        }
        else if(type == 'apikeys') {
            document.getElementById('apikeys-container').scrollIntoView();
            if(smessage !== null && typeof smessage !== 'undefined' && Object.prototype.hasOwnProperty.call(smessage, 'text_head')) {
                this.apikeys_type = smessage.type == 'failure' ? 'error' : smessage.type;
                this.apikeys_message = smessage.text_head;
                this.apikeys_message1 = smessage.text_sub;
            }
            else {
                this.apikeys_type = variant;
                this.apikeys_message = message;
                this.apikeys_message1 = "";
            }
            setTimeout(() => {
                this.apikeys_type = "";
                this.apikeys_message = "";
                this.apikeys_message1 = "";
            }, 4000);
        }
        else {
            this.$root.$emit("toast_message", {'type' : variant, 'message' : message});
        }
    },
    setPodData(obj) {
        this[obj.key] = obj.value;
    },
    getLabDetails() {
        let user_data = JSON.parse(localStorage.user_sess_data);
        let params = {
            token: user_data.token,
            lab_id: this.edit_lab_id
        };
        adminGetLabDetails(params).then(response => {
            if(response.status) {
                this.is_valid_lab = true;
                let lab_details = response.data.lab;
                let lab_schedule = response.data.schedule;
                this.override_schedules = response.data.overrides;
                this.allowedips_list = response.data.allowedips;
                this.left_home_image = lab_details.left_image;
                this.right_home_image = lab_details.right_image;
                this.banner_home_image = lab_details.banner_image;
                this.rss_feeds = response.data.rssfeeds;
                this.apikeys_data = response.data.apikeys;
                let self = this;
                Object.keys(lab_details).forEach(function(key,index) {
                    if(Object.prototype.hasOwnProperty.call(self.form, key) && lab_details[key] !== "" && lab_details[key] !== null) {
                        self.form[key] = lab_details[key];
                        index;
                    }
                });
                Object.keys(lab_schedule).forEach(function(key,index) {
                    if(Object.prototype.hasOwnProperty.call(self.schedule_form, key) && lab_schedule[key] !== "" && lab_schedule[key] !== null) {
                        if(Object.prototype.hasOwnProperty.call(lab_schedule, key+'_ampm')) {
                            self.schedule_form[key] = lab_schedule[key+'_ampm'];
                        }
                        else if(key.includes('_closed')) {
                            self.schedule_form[key] = lab_schedule[key];
                        }
                        index;
                    }
                });
            }
        });
    },

    confirmDelete(override_id) {
      this.delete_override_id = override_id;
      this.$bvModal.show('delete-override-modal');
    },

    deleteOverrideSchedule() {
        let user_data = JSON.parse(localStorage.user_sess_data);
        let params = {
            token: user_data.token,
            id: this.delete_override_id,
            lab_id: this.edit_lab_id
        };
        adminDeleteLabScheduleOverride(params).then(response => {
            if(response.status) {
                this.$bvModal.hide('delete-override-modal');
                this.makeToast(response.message, "success", response.message, 'override');
                this.override_schedules = response.data.overrides;
            }
            else {
                this.makeToast(response.message, "error", response.message, 'override');
            }
        });
    },

    confirmDeleteAllowedIp(allowedip_id) {
      this.delete_allowedip_id = allowedip_id;
      this.$bvModal.show('delete-allowedip-modal');
    },

    deleteAllowedIp() {
        let user_data = JSON.parse(localStorage.user_sess_data);
        let params = {
            token: user_data.token,
            id: this.delete_allowedip_id,
            lab_id: this.edit_lab_id
        };
        adminDeleteAllowedIp(params).then(response => {
            if(response.status) {
                this.$bvModal.hide('delete-allowedip-modal');
                this.makeToast(response.message, "success", response.message, 'allowedips');
                this.allowedips_list = response.data.allowedips;
            }
            else {
                this.makeToast(response.message, "error", response.message, 'allowedips');
            }
        });
    },

    handleLabHomeImageReq(type) {
        this.$v.homeimage_form.$touch();
        if (this.$v.homeimage_form.$invalid) {
            this.makeToast("generic-required-message", "error", "Please fill all required fields", "homeimage");
        }
        else {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let data = this.homeimage_form;
            data.token = user_data.token;
            data.lab_id = this.edit_lab_id;
            data.type = type;
            adminUploadLabHomeImage(data).then(response => {
                if(response.status){
                    this.makeToast(response.message, "success", response.message, "homeimage");
                    this.$v.homeimage_form.$reset();
                    this.homeimage_form.homeimage = null;
                    this.left_home_image = response.data.left_image;
                    this.right_home_image = response.data.right_image;
                    this.banner_home_image = response.data.banner_image;
                } else {
                    this.makeToast(response.message, "error", response.message, "homeimage");
                }
            }); 
        }
    },

    confirmDeleteHomeImage(type) {
      this.delete_homeimage_type = type;
      this.$bvModal.show('delete-homeimage-modal');
    },

    deleteHomeImage() {
        let user_data = JSON.parse(localStorage.user_sess_data);
        let params = {
            token: user_data.token,
            type: this.delete_homeimage_type,
            lab_id: this.edit_lab_id
        };
        adminDeleteHomeImage(params).then(response => {
            if(response.status) {
                this.$bvModal.hide('delete-homeimage-modal');
                this.makeToast(response.message, "success", response.message, 'homeimage');
                this.left_home_image = response.data.left_image;
                this.right_home_image = response.data.right_image;
                this.banner_home_image = response.data.banner_image;
            }
            else {
                this.makeToast(response.message, "error", response.message, 'homeimage');
            }
        });
    },

    handleLabRssFeedReq() {
        this.$v.rssfeed_form.$touch();
        if (this.$v.rssfeed_form.$invalid) {
            this.makeToast("generic-required-message", "error", "Please fill all required fields", "rssfeed");
        }
        else {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let data = this.rssfeed_form;
            data.token = user_data.token;
            data.lab_id = this.edit_lab_id;
            adminSaveLabRssFeed(data).then(response => {
                if(response.status){
                    this.makeToast(response.message, "success", response.message, "rssfeed");
                    //Iterate through each object field, key is name of the object field`
                    var self = this;
                    Object.keys(this.rssfeed_form).forEach(function(key) {
                        self.rssfeed_form[key] = '';
                    });
                    this.$v.rssfeed_form.$reset();
                    this.getLabDetails();
                } else {
                    this.makeToast(response.message, "error", response.message, "rssfeed");
                }
            }); 
        }
    },

    confirmRssFeedDelete(feed_id) {
      this.delete_rssfeed_id = feed_id;
      this.$bvModal.show('delete-rssfeed-modal');
    },

    deleteRssFeed() {
        let user_data = JSON.parse(localStorage.user_sess_data);
        let params = {
            token: user_data.token,
            id: this.delete_rssfeed_id,
            lab_id: this.edit_lab_id
        };
        adminDeleteLabRssFeed(params).then(response => {
            if(response.status) {
                this.$bvModal.hide('delete-rssfeed-modal');
                this.makeToast(response.message, "success", response.message, 'rssfeed');
                this.rss_feeds = response.data.rssfeeds;
            }
            else {
                this.makeToast(response.message, "error", response.message, 'rssfeed');
            }
        });
    },

    overContextStart(ctx) {
        if(ctx.minutes >= 0) {
            let ts = ctx.formatted.split(" ");
            if(ts.length > 1 && ts[0].includes(':')) {
                let ts1 = ts[0].split(":");
                let ts_final = (ts1[0] < 10 ? '0'+ts1[0] : ts1[0]) + ":" + ts1[1] + " " + ts[1];
                this.override_form.time_start = ts_final;
            }
        }
    },
    overContextEnd(ctx) {
        if(ctx.minutes >= 0) {
            let ts = ctx.formatted.split(" ");
            if(ts.length > 1 && ts[0].includes(':')) {
                let ts1 = ts[0].split(":");
                let ts_final = (ts1[0] < 10 ? '0'+ts1[0] : ts1[0]) + ":" + ts1[1] + " " + ts[1];
                this.override_form.time_end = ts_final;
            }
        }
    },
    moContextStart(ctx) {
        if(ctx.minutes >= 0) {
            let ts = ctx.formatted.split(" ");
            if(ts.length > 1 && ts[0].includes(':')) {
                let ts1 = ts[0].split(":");
                let ts_final = (ts1[0] < 10 ? '0'+ts1[0] : ts1[0]) + ":" + ts1[1] + " " + ts[1];
                this.schedule_form.mo_start = ts_final;
            }
        }
    },
    moContextEnd(ctx) {
        if(ctx.minutes >= 0) {
            let ts = ctx.formatted.split(" ");
            if(ts.length > 1 && ts[0].includes(':')) {
                let ts1 = ts[0].split(":");
                let ts_final = (ts1[0] < 10 ? '0'+ts1[0] : ts1[0]) + ":" + ts1[1] + " " + ts[1];
                this.schedule_form.mo_end = ts_final;
            }
        }
    },
    tuContextStart(ctx) {
        if(ctx.minutes >= 0) {
            let ts = ctx.formatted.split(" ");
            if(ts.length > 1 && ts[0].includes(':')) {
                let ts1 = ts[0].split(":");
                let ts_final = (ts1[0] < 10 ? '0'+ts1[0] : ts1[0]) + ":" + ts1[1] + " " + ts[1];
                this.schedule_form.tu_start = ts_final;
            }
        }
    },
    tuContextEnd(ctx) {
        if(ctx.minutes >= 0) {
            let ts = ctx.formatted.split(" ");
            if(ts.length > 1 && ts[0].includes(':')) {
                let ts1 = ts[0].split(":");
                let ts_final = (ts1[0] < 10 ? '0'+ts1[0] : ts1[0]) + ":" + ts1[1] + " " + ts[1];
                this.schedule_form.tu_end = ts_final;
            }
        }
    },
    weContextStart(ctx) {
        if(ctx.minutes >= 0) {
            let ts = ctx.formatted.split(" ");
            if(ts.length > 1 && ts[0].includes(':')) {
                let ts1 = ts[0].split(":");
                let ts_final = (ts1[0] < 10 ? '0'+ts1[0] : ts1[0]) + ":" + ts1[1] + " " + ts[1];
                this.schedule_form.we_start = ts_final;
            }
        }
    },
    weContextEnd(ctx) {
        if(ctx.minutes >= 0) {
            let ts = ctx.formatted.split(" ");
            if(ts.length > 1 && ts[0].includes(':')) {
                let ts1 = ts[0].split(":");
                let ts_final = (ts1[0] < 10 ? '0'+ts1[0] : ts1[0]) + ":" + ts1[1] + " " + ts[1];
                this.schedule_form.we_end = ts_final;
            }
        }
    },
    thContextStart(ctx) {
        if(ctx.minutes >= 0) {
            let ts = ctx.formatted.split(" ");
            if(ts.length > 1 && ts[0].includes(':')) {
                let ts1 = ts[0].split(":");
                let ts_final = (ts1[0] < 10 ? '0'+ts1[0] : ts1[0]) + ":" + ts1[1] + " " + ts[1];
                this.schedule_form.th_start = ts_final;
            }
        }
    },
    thContextEnd(ctx) {
        if(ctx.minutes >= 0) {
            let ts = ctx.formatted.split(" ");
            if(ts.length > 1 && ts[0].includes(':')) {
                let ts1 = ts[0].split(":");
                let ts_final = (ts1[0] < 10 ? '0'+ts1[0] : ts1[0]) + ":" + ts1[1] + " " + ts[1];
                this.schedule_form.th_end = ts_final;
            }
        }
    },
    frContextStart(ctx) {
        if(ctx.minutes >= 0) {
            let ts = ctx.formatted.split(" ");
            if(ts.length > 1 && ts[0].includes(':')) {
                let ts1 = ts[0].split(":");
                let ts_final = (ts1[0] < 10 ? '0'+ts1[0] : ts1[0]) + ":" + ts1[1] + " " + ts[1];
                this.schedule_form.fr_start = ts_final;
            }
        }
    },
    frContextEnd(ctx) {
        if(ctx.minutes >= 0) {
            let ts = ctx.formatted.split(" ");
            if(ts.length > 1 && ts[0].includes(':')) {
                let ts1 = ts[0].split(":");
                let ts_final = (ts1[0] < 10 ? '0'+ts1[0] : ts1[0]) + ":" + ts1[1] + " " + ts[1];
                this.schedule_form.fr_end = ts_final;
            }
        }
    },
    saContextStart(ctx) {
        if(ctx.minutes >= 0) {
            let ts = ctx.formatted.split(" ");
            if(ts.length > 1 && ts[0].includes(':')) {
                let ts1 = ts[0].split(":");
                let ts_final = (ts1[0] < 10 ? '0'+ts1[0] : ts1[0]) + ":" + ts1[1] + " " + ts[1];
                this.schedule_form.sa_start = ts_final;
            }
        }
    },
    saContextEnd(ctx) {
        if(ctx.minutes >= 0) {
            let ts = ctx.formatted.split(" ");
            if(ts.length > 1 && ts[0].includes(':')) {
                let ts1 = ts[0].split(":");
                let ts_final = (ts1[0] < 10 ? '0'+ts1[0] : ts1[0]) + ":" + ts1[1] + " " + ts[1];
                this.schedule_form.sa_end = ts_final;
            }
        }
    },
    suContextStart(ctx) {
        if(ctx.minutes >= 0) {
            let ts = ctx.formatted.split(" ");
            if(ts.length > 1 && ts[0].includes(':')) {
                let ts1 = ts[0].split(":");
                let ts_final = (ts1[0] < 10 ? '0'+ts1[0] : ts1[0]) + ":" + ts1[1] + " " + ts[1];
                this.schedule_form.su_start = ts_final;
            }
        }
    },
    suContextEnd(ctx) {
        if(ctx.minutes >= 0) {
            let ts = ctx.formatted.split(" ");
            if(ts.length > 1 && ts[0].includes(':')) {
                let ts1 = ts[0].split(":");
                let ts_final = (ts1[0] < 10 ? '0'+ts1[0] : ts1[0]) + ":" + ts1[1] + " " + ts[1];
                this.schedule_form.su_end = ts_final;
            }
        }
    },

    handleAPIKeysReq() {
        this.$v.apikeys_form.$touch();
        if (this.$v.apikeys_form.$invalid) {
            this.makeToast("generic-required-message", "error", "Please fill all required fields", "apikeys");
        }
        else {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let data = this.apikeys_form;
            data.token = user_data.token;
            data.lab_id = this.edit_lab_id;
            adminSaveAPIKeys(data).then(response => {
                if(response.status){
                    this.makeToast(response.message, "success", response.message, "apikeys");
                    //Iterate through each object field, key is name of the object field`
                    var self = this;
                    Object.keys(this.apikeys_form).forEach(function(key) {
                        self.rssfeed_form[key] = '';
                    });
                    this.$v.apikeys_form.$reset();
                    this.getLabDetails();
                } else {
                    this.makeToast(response.message, "error", response.message, "apikeys");
                }
            }); 
        }
    },
    confirmAPIKeysDelete(apikey_id) {
      this.delete_apikeys_id = apikey_id;
      this.$bvModal.show('delete-apikeys-modal');
    },

    deleteAPIKeys() {
        let user_data = JSON.parse(localStorage.user_sess_data);
        let params = {
            token: user_data.token,
            id: this.delete_apikeys_id,
            lab_id: this.edit_lab_id
        };
        adminDeleteAPIKeys(params).then(response => {
            if(response.status) {
                this.$bvModal.hide('delete-apikeys-modal');
                this.makeToast(response.message, "success", response.message, 'apikeys');
                this.apikeys_data = response.data.apikeys;
            }
            else {
                this.makeToast(response.message, "error", response.message, 'apikeys');
            }
        });
    },

  },

  mounted() {
    this.clientsList();
    this.getLabDetails();
  }

};
</script>
<style scoped>
.admin-portal ul.admin-del-list {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    flex-wrap: wrap;
    row-gap: 20px;
}
.admin-portal ul.admin-del-list li {
    border: 2px solid #7f7f7f;
    padding: 10px;
    border-radius: 5px;
}
</style>