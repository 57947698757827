<template>
    <div>
        <RssFeedWidget />
        <IdleWidget />
        <LogoutWarningWidget />
        <div class="main-header main-header-player" id="main-header-player">
        <AllTheme />
        <PlayerPollingAndSockets 
        @setPodData="setPodData($event)" 
        @setPodVolume="setPodVolume($event)" />
            <b-navbar toggleable="lg" type="dark" variant="info" class="mb-4 header-bg d-none" v-if="!pod_locked">
                <b-navbar-brand class="mx-2" v-if="logo_file == '' || logo_file == null">Game Lab</b-navbar-brand>
                <b-navbar-brand class="mx-2 nav_logo" v-if="logo_file !== '' && logo_file !== null"><img :src="logo_file" /></b-navbar-brand>
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav>
                <!-- Right aligned nav items -->
                <b-navbar-nav class="float-right w-100" align="right">
                    <b-navbar-brand class="main-header-link">
                    <b-icon 
                        class="icon h2" 
                        icon="volume-up-fill"
                        @click="volumeHandle"
                    >
                    </b-icon>
                    </b-navbar-brand>
                    
                    <b-navbar-brand @click="handleLogout('', 'web')" class="main-header-link">Log out</b-navbar-brand>
                </b-navbar-nav>

                </b-collapse>
            </b-navbar>

            <!-- start of volume modal -->
            <b-modal id="volume-modal" header-class="bg-labPrimary text-white px-3 py-2" class="d-none" hide-footer>
                <template #modal-title>
                Adjust Volume
                </template>
                <b-overlay :show="api_state" rounded="lg">
                <div class="d-block text-center">
                    
                </div>
                <b-input-group  v-if="false" class="d-flex text-center">
                    <b-input-group-prepend class="mute-icons">
                    <b-icon 
                        class="icon h4" 
                        icon="volume-mute-fill"
                        @click="volumeMuteUnmute('mute')"
                    >
                    </b-icon>
                    </b-input-group-prepend>
                    <b-form-input 
                    type="range" 
                    class="volume-input" 
                    min="0" 
                    max="100" 
                    step="1" 
                    v-model="pod_volume"
                    @change="volumeChange">
                    </b-form-input>
                    <b-input-group-append class="mute-icons">
                    <b-icon 
                        class="icon h4" 
                        icon="volume-up-fill"
                        @click="volumeMuteUnmute('unmute')"
                    >
                    </b-icon>
                    </b-input-group-append>
                </b-input-group>
                <div class="d-flex volume-value justify-content-center">
                    <button class="bg-labPrimary text-white text-center border-0">{{ pod_volume }}</button>
                </div>
                </b-overlay>
            </b-modal>

            <div class="notification-block-outer w-alert-340">
                <div class="notification-block animate__animated animate__fadeInUp w-alert-340" :class="toast_type" v-if="toast_type !== '' && toast_message !== ''">
                <div class="notification-message w-alert-290">
                    <h5>{{ toast_message }}</h5>
                    <p v-if="toast_message1 !== ''">{{ toast_message1 }}</p>
                </div>
                <div class="icon-success" v-if="toast_type == 'success'">
                    <i class="fa-solid fa-circle-check"></i>
                </div>
                <div class="icon-error" v-if="toast_type == 'error'">
                    <i class="fa-solid fa-times-circle"></i>
                </div>
                <div class="icon-warning" v-if="toast_type == 'warning'">
                    <i class="fa-solid fa-exclamation-circle"></i>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import PlayerPollingAndSockets from "@/views/player/PlayerPollingAndSockets.vue";
import store from "@/store";
import { userLogout } from "../../services/HomepageService";
import { setPodVolume } from "../../services/PlayerService";
import { postPodLogoutWeb, setFlaskPodVolume } from "../../services/FlaskService";
import { global_config } from "@/config/config.js";
import AllTheme from "@/components/themes/AllTheme.vue";
import IdleWidget from "@/components/IdleWidget.vue";
import LogoutWarningWidget from "@/components/LogoutWarningWidget.vue"; 
import RssFeedWidget from "@/components/RssFeedWidget.vue";

export default {
    name: "PlayerHeader",
    components: { PlayerPollingAndSockets, AllTheme, IdleWidget, LogoutWarningWidget, RssFeedWidget },
    data() {
      return {
        pod_id_key : "",
        pod_locked: false,
        pod_data: {},
        api_state: false,
        pod_volume: 0,
        xds_data: {
          "xd1" : "", 
          "xd2" : "", 
          "xb_disabled": true, 
          "ps_disabled": true, 
          "sw_disabled": true, 
          "pc_disabled": true, 
          "pod_disabled": true
        },
        theme_file: '',
        logo_file:'',
        theme_classes : global_config.THEME_CLASSES,
        toast_type: '',
        toast_message: '',
        toast_message1: '',
      };
    },
    methods: {
      handleLogout(reason = "", source = "polling", survey_needed=true) {
        let user_data = JSON.parse(localStorage.user_sess_data);
        let pod_data = JSON.parse(localStorage.pod_sess_data);
        let params = {
            session_id_key : pod_data.session_id_key,
            session_id : pod_data.session_id_key,
            token: user_data.token,
            reason_text: reason
        };
        userLogout(params).then(response => {
            if(response.status || 
                response.message == "Token is Expired" || 
                response.message == "Token is Invalid" || 
                response.message == "Session Id invalid!" || 
                response.message == "Session ID error--reloading app." ||
                response.message == 'Session ID expired! Please reload the app.' ||
                response.message == 'Session Id empty! Please reload the app.'
            ) {
                // init logout on pod as well
                if(source !== "polling") {
                    let user_data = JSON.parse(localStorage.user_sess_data);
                    let pod_data = JSON.parse(localStorage.pod_sess_data);
                    let params = {
                        session_id : pod_data.session_id_key,
                        user_id : user_data.user_id,
                        pod_id_key: this.pod_id_key,
                        logout_reason: source
                    };
                    postPodLogoutWeb(params).then(log_web => {
                        console.log("WEB LOGOUT API CALLED", log_web);
                    });
                }  
                // localStorage.removeItem('qr_sess_data');
                // localStorage.removeItem('user_sess_data');
                // localStorage.removeItem('pod_sess_data');
                // this.$root.$emit('live-chat-widget', 'logged-out');
                // this.makeToast("success", (reason !== '' ? reason : response.message));
                //   // remove existing classes
                //   for(let i = 0; i < this.theme_classes.length; i++) {
                //     document.body.classList.remove(this.theme_classes[i]);
                //   }
                this.$router.push({ name: "Player Logout", params: {'reason': (reason == '' ? 'you clicked the onscreen logout button' : reason), survey_needed: survey_needed}});
            } else {
              this.makeToast(response.message, "error", response.message);
            }
        });
      },

      volumeHandle() {
        this.$bvModal.show('volume-modal');
      },

      volumeMuteUnmute(type) {
        if(type == 'mute') {
          this.pod_volume = 0;
          this.volumeChange(this.pod_volume);
        }
        else if(type == 'unmute') {
          this.pod_volume = 100;
          this.volumeChange(this.pod_volume);
        }
      },

      volumeChange(volume, source="web") {
        console.log("GOT API REQUEST", volume);
        // clear interval first
        this.$children.forEach(function(item) {
            if(item.$options._componentTag == "PlayerPollingAndSockets") {
                clearInterval(item.volume_polling);
            }
        });
        this.api_state = true;
        let user_data = JSON.parse(localStorage.user_sess_data);
        let pod_data = JSON.parse(localStorage.pod_sess_data);
        let params = {
          session_id_key : pod_data.session_id_key,
          token: user_data.token,
          pod_id_key: this.pod_id_key,
          volume: volume,
        };
        let flag = true;
        if(source == "" && volume == this.pod_volume) {
          flag = false;
        }
        
        if(flag) {
          setPodVolume(params).then(response => {
            this.api_state = false;
            if(response.status) {
              this.pod_volume = volume;
              // init volume change on pod as well
              if(source !== "") {
                this.makeToast(response.message, "success", response.message);
                let params = {
                    volume : this.pod_volume
                };
                setFlaskPodVolume(params).then(vol_web => {
                  setTimeout(() => {
                    vol_web;
                    // emit event for child view
                    this.$emit('setPodData', {'key' : 'pod_volume', 'value' : this.pod_volume});
                    this.$children.forEach(function(item) {
                        if(item.$options._componentTag == "PlayerPollingAndSockets") {
                            item.pollFlaskVolume();
                        }
                    });
                  }, 3000);
                });
              }
              else {
                // emit event for child view
                this.$emit('setPodData', {'key' : 'pod_volume', 'value' : this.pod_volume});
                this.$children.forEach(function(item) {
                    if(item.$options._componentTag == "PlayerPollingAndSockets") {
                        item.pollFlaskVolume();
                    }
                });
              }
            }
            else {
                this.makeToast(response.message, "error", response.message);
                this.$children.forEach(function(item) {
                    if(item.$options._componentTag == "PlayerPollingAndSockets") {
                        item.pollFlaskVolume();
                    }
                });
            }
          })
        }
        else {
            this.api_state = false;
            this.$children.forEach(function(item) {
                if(item.$options._componentTag == "PlayerPollingAndSockets") {
                    item.pollFlaskVolume();
                }
            });
        }
      },

      makeToast(message_key = '', variant = "default", message, message1="") {
        // search for key
        let smessage = this.$store.state.system_messages.list.find(o => o.msg_key === message_key);
        if(smessage !== null && typeof smessage !== 'undefined' && Object.prototype.hasOwnProperty.call(smessage, 'text_head')) {
            this.toast_type = smessage.type == 'failure' ? 'error' : smessage.type;
            this.toast_message = smessage.text_head;
            this.toast_message1 = smessage.text_sub;
        }
        else {
            this.toast_type = variant;
            this.toast_message = message;
            this.toast_message1 = message1;
        }
        // hide error div
        setTimeout(() => {
            this.toast_type = "";
            this.toast_message = "";
            this.toast_message1 = "";
        }, 5000);
      },

      setPodData(obj) {
        this[obj.key] = obj.value;
        this.$emit('setPodData', obj);
        if(obj.key == 'theme_file') {
          if(obj.value !== '' && obj.value !== null) {
            this.theme_file = obj.value;
          }
          this.getThemeFile();
        }
        else if(obj.key == 'logo_file' && obj.value !== '' && obj.value !== null) {
          this.logo_file = obj.value;
        }
      },

      setPodVolume(val) {
        this.pod_volume = val;
        this.$emit('setPodData', {'key' : 'pod_volume', 'value' : this.pod_volume});
      },
      playGame(val, search, platform, install=null, guests="") {
        this.$children.forEach(function(item) {
          if(item.$options._componentTag == "PlayerPollingAndSockets") {
            item.playGame(val, search, platform, install, guests);
          }
        });
      },
      getThemeFile() {
        // remove existing classes
        for(let i = 0; i < this.theme_classes.length; i++) {
          document.body.classList.remove(this.theme_classes[i]);
        }
        if(this.theme_file !== "" && this.theme_file !== null) {
          let class_name = this.theme_file+'Theme';
          document.body.classList.add(class_name);
        }
      }
    },
    computed: {
      getMessage() {
        return store.getters.getMessage;
      },
    },
    mounted() {
        this.$root.$on('toast_message', (message) => {
            this.makeToast(message.message, message.type, message.message);
        });
    },
};
</script>

<style scoped>
.main-header .main-header-link {
  cursor: pointer;
}
/* .header-bg {
  background-color: #dd550c !important;
} */
.main-header .nav_logo img {
  min-width: 100px;
  max-width: 150px;
  max-height: 57px;
}
#volume-modal .mute-icons {
  width: 5%;
  margin: 0px;
  display: flex;
  align-items: center;
}
#volume-modal .mute-icons .icon {
  margin: 0px;
  background: #e24e00;
  border-radius: 100%;
  padding: 2px;
  color: #fff;
  cursor: pointer;
}
#volume-modal .volume-input  {
  margin: 0px 10px;
  padding: 0px;
}
#volume-modal .volume-input:focus,
#volume-modal .volume-input:visited  {
  box-shadow: unset;
}
#volume-modal .volume-value button  {
  border-radius: 100% !important;
  width: 35px;
  height: 35px;
}
.main-header .notification-block-outer {
    width: 100%;
    position: absolute;
    left: 0;
}
.main-header .notification-block-outer .notification-block {
    position: relative;
    width: max-content;
    margin: auto;
    z-index: 999999;
}
.main-header .notification-block-outer .notification-block.success {
    background: #00a152;
}
.main-header .notification-block-outer .notification-block.error {
    background: #ff1744b3;
}
.main-header .notification-block-outer .notification-block.warning {
    background: #ffea005e;
}
</style>
<style>
#volume-modal button.close {
  background: transparent;
  border: unset;
  font-size: 24px;
  color: #fff;
  padding: 5px;
  margin: 0px;
  line-height: 1.1em;
}
</style>