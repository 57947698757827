<template>
    <div class="main-keyboard" v-if="input_element !== null && not_kb && !pod_idle">
        <div class="overlay"></div>
        <div class="main-kb-container animate__animated animate__fadeInUp animate__fast" id="main-kb-container">
            <label>{{ placeholder }}</label>
            <textarea class="w-form-control w-input-fields" @input="onInputChange" :value="typed_value" placeholder="begin typing..." tabindex="-1" @keydown.enter="onPressEnter($event)" ref="keyboardinputbox"></textarea>
            <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="typed_value" :theme="theme" tabindex="-1" />
        </div>
    </div>
</template>
  
<script>
import store from "@/store";
import SimpleKeyboard from "@/components/SimpleKeyboard";
import { checkFlaskPodKB } from "../services/FlaskService";

export default {
    name: "MainKeyboard",
    components: {
        SimpleKeyboard
    },
    props:['main_element'],
    data() {
        return {
            main_elem: this.main_element,
            theme: "hg-theme-default myTheme1",
            typed_value: "",
            placeholder: "",
            not_kb: true
        }
    },
    computed: {
        input_element() {
            return store.getters.getFocusedState();
        },
        form_element() {
            return store.getters.getFocusedFormState();
        },
        pod_idle() {
            return this.$store.getters.getIdleState();
        },
    },
    watch: {
        input_element: {
            handler: function(value, ovalue) {
                value;
                if(ovalue == null) {
                    this.typed_value = "";
                    console.log(this.form_element.target);
                    if(this.form_element.target !== null && this.form_element.target !== "") {
                        if(this.input_element == 'code') {
                            if(this.form_element.form !== '' && this.form_element.form !== null) {
                                this.typed_value = this.main_elem[this.form_element.form][this.input_element];
                            }
                            else {
                                this.typed_value = this.main_elem[this.input_element];
                            }
                        }
                        else {
                            this.typed_value = this.form_element.target.target.value;
                        }
                    }
                }
            }
        }
    },
    methods: {
        onChange(input) {
            this.typed_value = input;
        },
        onKeyPress(button) {
            if(button === "{enter}" || button === "{done}") {
                if(this.form_element.form !== '' && this.form_element.form !== null) {
                    this.main_elem[this.form_element.form][this.input_element] = this.typed_value;
                    this.setCodeInputValues(this.typed_value);
                }
                else {
                    this.main_elem[this.input_element] = this.typed_value;
                    this.setCodeInputValues(this.typed_value);
                }
                setTimeout(() => {
                    // this.closeKeyboard();
                    this.moveControlNext();
                }, 500);
            }
            else if(button == "{tab}" || button === "{next}") {
                this.moveControlNext();
            }
        },
        onInputChange(input) {
            this.typed_value = input.target.value;
        },
        closeKeyboard() {
            this.$store.commit("setFocusedElement", null);
            this.$store.commit("setFocusedElementForm", {'form': null, 'target': null});
        },
        setKBFlag() {
            checkFlaskPodKB().then(fpi => {
                if (Object.prototype.hasOwnProperty.call(fpi, "success") && fpi.success) {
                    this.not_kb = false;
                    this.closeKeyboard();
                }
                else {
                    this.not_kb = true;
                    if (Object.prototype.hasOwnProperty.call(this.$refs, "keyboardinputbox") && typeof this.$refs.keyboardinputbox !== 'undefined') {
                        this.$refs.keyboardinputbox.focus();
                    }
                }
            });
        },
        setCodeInputValues(pastevalue) {
            if(this.input_element == 'code') {
                pastevalue = pastevalue.split("");
                let elems = document.getElementsByClassName('react-code-input')[0].getElementsByTagName('input');
                setTimeout(() => {
                    for (let index = 0; index < elems.length; index++) {
                        elems[index].focus();
                        if(index < pastevalue.length) {
                            elems[index].value = pastevalue[index];
                        }
                        else {
                            elems[index].value = '';
                        }
                    }
                    this.main_elem.$refs.inputboxes.triggerChange(pastevalue);
                }, 200);
            }
        },
        onPressEnter(event) {
            event.preventDefault();
            if(this.form_element.form !== '' && this.form_element.form !== null) {
                this.main_elem[this.form_element.form][this.input_element] = this.typed_value;
                this.setCodeInputValues(this.typed_value);
            }
            else {
                this.main_elem[this.input_element] = this.typed_value;
                this.setCodeInputValues(this.typed_value);
            }
            setTimeout(() => {
                this.moveControlNext();
            }, 500);
        },
        moveControlNext() {
            let inputs = document.getElementsByTagName('input');
            let index = [...inputs].indexOf(this.form_element.target.target);
            if (index < inputs.length - 1) {
                this.closeKeyboard();
                setTimeout(() => { 
                    inputs[index + 1].focus();
                }, 10);
            }
            else {
                this.closeKeyboard();
            }
        }
    },
    mounted() {
        this.setKBFlag();
        this.placeholder = "Enter " + this.input_element;
        this.typed_value = '';
        let _self = this;
        document.body.addEventListener('keydown', function(e){
            if(e.key == 'Escape' || e.key == 'escape' || e.keyCode == 27) {
                _self.closeKeyboard();
            }
        }, true);
    },
    updated() {
        if(this.input_element !== null) {
            this.placeholder = "Enter " + this.input_element.split('_').join(' ');
        }
        this.setKBFlag();
    }
};
</script>
<style scoped>
.main-keyboard {
    width: 100%;
    height: 100vh;
    z-index: 100000000;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    background: transparent;
    top: 0;
    left: 0;
}
.main-keyboard .overlay {
    position: absolute;
    background: black;
    width: 100%;
    left: 0;
    height: 100%;
    opacity: 0.8;
    filter: opacity(1);
}
.main-keyboard .simple-keyboard {
    position: inherit;
    width:100%;
}
.main-keyboard .main-kb-container {
    width: 100%;
    z-index: 10;
    margin-top: auto;
}
.main-keyboard .w-form-control {
    background: #d8d8d8;
    height: 100px;
    resize: none;
    padding: 10px 10px;
    width: 90%;
    margin: auto;
    margin-bottom: 15px;
    outline: unset !important;
}
.main-keyboard .main-kb-container label {
    width: 90%;
    text-align: left;
    padding: 10px;
    font-size: 15px;
    font-weight: 600;
    background: #d8d8d8;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-bottom: -8px;
    border-top: 1px solid rgb(118,118,118);
    border-left: 1px solid rgb(118,118,118);
    border-right: 1px solid rgb(118,118,118);
}
</style>
<style>
/*
Theme: myTheme1
*/
.main-keyboard .main-kb-container .simple-keyboard.myTheme1 {
    background-color: #3c3c3c;
    border-radius: 0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.main-keyboard .main-kb-container .simple-keyboard.myTheme1 .hg-button {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0);
    color: white;
}
.main-keyboard .main-kb-container .simple-keyboard.myTheme1 .hg-button:active {
    background: #1c4995;
    color: white;
}
#root .main-keyboard .main-kb-container .simple-keyboard.myTheme1 + .main-keyboard .main-kb-container .simple-keyboard-preview {
    background: #1c4995;
}
.main-keyboard .main-kb-container .simple-keyboard.myTheme1 .hg-button.custom-kb-btn {
    flex-grow: 0.073;
}
</style>