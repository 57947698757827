<template>
    <section class="w-spalsh-screen" v-if="pod_idle" style="z-index: 1;">  
        <!-- <div class="w-logo">
            <object type="image/svg+xml" class="animate__animated animate__fadeInUp animate__delay-1s" tabindex="-1" style="width:250px;" :data="require(`../assets/images/gamelab.svg`)"></object>
        </div>
        <div class="lds-dual-ring mt-5 px-2 animate__animated animate__fadeInUp animate__delay-1s"></div> -->
        <div class="lds-dual-ring px-2"></div>
    </section>

</template>

<script>
import store from "@/store";
export default {
    name: "IdleWiget-view",
    data() {
        return {
        };
    },

    computed: {
        pod_idle() {
            return store.getters.getIdleState();
        }
    },

    methods: {
    },
};
</script>
<style scoped>
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>