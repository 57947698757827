<template>
    <div class="main-layout-section">
        <div class="main-content">
            <div class="overlay"></div>
            <div class="col-md-8" style="width: 365px;max-width: 365px;min-width: 365px;">
                <h2 class="page-heading animate__animated animate__fadeIn conc-heading">Login here instead...?</h2>
                <h3 class="page-subheading mb-1 animate__animated animate__fadeIn conc-subheading" v-html="r_info"></h3>

                <!-- start timer section -->
                <div class="dashboard-buttons-container mt-5" style="width: 100%;">
                    <div class="dashboard-buttons animate__animated animate__fadeInUp">
                        <button class="dashboard-button" @click="cancelLogin()">
                            Nevermind
                        </button>
                        <button class="dashboard-button" @click="expireSessions()">
                            Login Here
                        </button>
                    </div>
                </div>
                <!-- end timer section -->
            </div>
        </div>
    </div>

</template>

<script>
import { userExpireSessions } from "../services/HomepageService";
export default {
    name: "ConcurrentLoginWidget-view",
    data() {
        return {
            r_info: this.info_message,
            r_params: this.params,
            r_response: this.response 
        };
    },
    props: ['info_message', 'params', 'response'],
    methods: {
        expireSessions() {
            userExpireSessions(this.r_params).then((resp) => {
                if (resp.status) {
                    this.$emit('redirectPlayerToDashboard', this.r_response);
                } else {
                    this.$emit('makeToast', 'login-unable-unable-login');
                }
            });
        },
        cancelLogin() {
            window.location.reload();
        }
    },
};
</script>
<style scoped>
.main-content {
    width: 100%;
    height: 100vh;
    z-index: 1;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    backdrop-filter: blur(30px);
    background: transparent;
}
.conc-heading {
    font-size: 30px;
    font-family: 'Open Sans';
    font-weight: 600;
}
.conc-subheading {
    font-size: 16px;
    font-weight: normal;
    font-family: 'Open Sans';
}
.main-content .overlay {
    position: absolute;
    background: black;
    width: 100%;
    left: 0;
    height: 100%;
    opacity: 0.65;
}
</style>