<template>
    <div class="main-layout-section admin-portal">
        <SuperAdminHeader />
        
        <div class="w-logo mb-3">
            <img src="../../assets/images/gamelab-logo.svg" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" />
        </div>

        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeInUp animate__delay-1s">Deploy New Client</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">

                    <div class="admin-general-section-buttons">
                        <router-link to="/superadmin/clients" class="admin-general-section-button">
                            Back To Clients Management
                        </router-link>
                    </div>

                    <div class="row m-0 p-0 col-md-12 col-sm-12 m-auto">
                        
                        <b-form inline @submit.stop.prevent class="p-0 m-0">
                            <!-- client and labe name field -->
                            <div class="row">
                                <div class="d-flex mb-1 col-md-12 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Name</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.first_name.$error }">
                                        <span><i class="fa-solid fa-user"></i></span>
                                        <b-form-input
                                            v-model="form.first_name"
                                            type="text"
                                            placeholder="Enter name"
                                            id="first_name"
                                            aria-describedby="first_name-help-block"
                                            class=""
                                            :class="{ 'is-invalid': $v.form.first_name.$error }"
                                        ></b-form-input>
                                    </div>
                                </div>

                                <!-- <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Last Name</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.last_name.$error }">
                                        <span><i class="fa-solid fa-user"></i></span>
                                        <b-form-input
                                            v-model="form.last_name"
                                            id="last_name"
                                            class="mb-2 mr-sm-2 mb-sm-0 user-name bg-lightBlack border-0 h-53"
                                            :class="{ 'is-invalid': $v.form.last_name.$error }"
                                            placeholder="Enter last name"
                                        ></b-form-input>
                                    </div>
                                </div> -->
                            </div>

                            <div class="row">
                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Email Address</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.email.$error }">
                                        <span><i class="fa-solid fa-envelope"></i></span>
                                        <b-form-input
                                            v-model="form.email"
                                            type="text"
                                            placeholder="Enter email"
                                            id="email"
                                            aria-describedby="email-help-block"
                                            class=""
                                            :class="{ 'is-invalid': $v.form.email.$error }"
                                        ></b-form-input>
                                    </div>
                                </div>

                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Phone#</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.phone.$error }">
                                        <span><i class="fa-solid fa-phone"></i></span>
                                        <b-form-input
                                            v-model="form.phone"
                                            id="phone"
                                            class=""
                                            :class="{ 'is-invalid': $v.form.phone.$error }"
                                            placeholder="Enter phone#"
                                        ></b-form-input>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">DOB</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100 overflow-visible" :class="{ 'is_invalid': $v.form.date_of_birth.$error }">
                                        <span><i class="fa-solid fa-calendar"></i></span>
                                        <b-form-datepicker
                                            v-model="form.date_of_birth"
                                            id="dob" 
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            class=""
                                            :class="{ 'is-invalid': $v.form.date_of_birth.$error }"
                                            placeholder="Enter date of birth"
                                        ></b-form-datepicker>
                                    </div>
                                </div>

                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Phone# 2</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.phone2.$error }">
                                        <span><i class="fa-solid fa-phone"></i></span>
                                        <b-form-input
                                            v-model="form.phone2"
                                            id="phone2"
                                            class=""
                                            :class="{ 'is-invalid': $v.form.phone2.$error }"
                                            placeholder="Enter phone# 2"
                                        ></b-form-input>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Logo File</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.logo_file.$error }">
                                        <span><i class="fa-solid fa-link"></i></span>
                                        <b-form-input
                                            v-model="form.logo_file"
                                            id="logo_file" 
                                            class=""
                                            :class="{ 'is-invalid': $v.form.logo_file.$error }"
                                            placeholder="Enter logo file url"
                                        ></b-form-input>
                                    </div>
                                </div>

                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Theme Pallet</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.theme_rgb_pallet_id.$error }">
                                        <span><i class="fa-solid fa-file"></i></span>
                                        <b-form-input
                                            v-model="form.theme_rgb_pallet_id"
                                            id="theme_rgb_pallet_id"
                                            class=""
                                            :class="{ 'is-invalid': $v.form.theme_rgb_pallet_id.$error }"
                                            placeholder="Enter rgb pallet id"
                                        ></b-form-input>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Address 1</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.address1.$error }">
                                        <b-form-textarea
                                            v-model="form.address1"
                                            id="address1"
                                            class=""
                                            :class="{ 'is-invalid': $v.form.address1.$error }"
                                            placeholder="Enter address 1"
                                            no-resize
                                        ></b-form-textarea>
                                    </div>
                                </div>

                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Address 2</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.address2.$error }">
                                        <b-form-textarea
                                            v-model="form.address2"
                                            id="address2"
                                            class=""
                                            :class="{ 'is-invalid': $v.form.address2.$error }"
                                            placeholder="Enter address 2"
                                            no-resize
                                        ></b-form-textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">City</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.city.$error }">
                                        <span><i class="fa-solid fa-file"></i></span>
                                        <b-form-input
                                            v-model="form.city"
                                            type="text"
                                            placeholder="Enter City"
                                            id="city"
                                            aria-describedby="city-help-block"
                                            class=""
                                            :class="{ 'is-invalid': $v.form.city.$error }"
                                        ></b-form-input>
                                    </div>
                                </div>

                                <!-- phone field -->
                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">State</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.state.$error }">
                                        <span><i class="fa-solid fa-file"></i></span>
                                        <b-form-input
                                            v-model="form.state"
                                            id="state"
                                            class=""
                                            :class="{ 'is-invalid': $v.form.state.$error }"
                                            placeholder="Enter state"
                                        ></b-form-input>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="d-flex mb-1 col-md-6 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Zip</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.zip.$error }">
                                        <span><i class="fa-solid fa-map-pin"></i></span>
                                        <b-form-input
                                            v-model="form.zip"
                                            id="zip" 
                                            class=""
                                            :class="{ 'is-invalid': $v.form.zip.$error }"
                                            placeholder="Enter zip"
                                        ></b-form-input>
                                    </div>
                                </div>

                                <div class="d-flex mb-1 col-md-6 flex-column animate__animated animate__fadeInUp animate__delay-2s" :class="{ 'is_invalid': $v.form.is_active.$error }">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">&nbsp;</label>
                                    <switches 
                                    class="active-switch h-53" 
                                    v-model="form.is_active"
                                    theme="bootstrap" 
                                    color="primary"
                                    type-bold="true"
                                    label="Active"
                                    >
                                    </switches>
                                </div>
                            </div>

                            <div class="row">
                                <div class="d-flex mb-1 col-md-12 flex-column">
                                    <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Note</label>
                                    <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.note.$error }">
                                        <b-form-textarea
                                            v-model="form.note"
                                            id="note"
                                            class=""
                                            :class="{ 'is-invalid': $v.form.note.$error }"
                                            placeholder="Note"
                                            no-resize
                                        ></b-form-textarea>
                                    </div>
                                </div>
                            </div>

                            <div
                            class="
                            w-form-control
                            animate__animated animate__fadeInUp animate__delay-3s m-0 p-0
                            "
                            >
                                <a
                                href="javascript:void(0)"
                                @click="handleClientReq"
                                class="flex-fill"
                                >Deploy</a
                                >
                            </div>

                        </b-form>

                    </div>
                </div>

            </div>

        </div>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <SuperAdminFooter type="inner" />
        </div>
        <!-- end of footer section -->
    </div>
</template>

<script>
import SuperAdminHeader from "@/views/superadmin/SuperAdminHeader.vue";
import SuperAdminFooter from "@/views/superadmin/SuperAdminFooter.vue";
import { adminSaveClient } from "../../services/AdminService";
import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import { required } from 'vuelidate/lib/validators';
import Switches from 'vue-switches';

export default {
  name: "AdminLabs-view",
  components: { SuperAdminHeader, Switches, SuperAdminFooter },
  data() {
    return {
      form: {
        first_name : '',
        last_name : '',
        email : '',
        phone : '',
        date_of_birth : '',
        phone2 : '',
        address1 : '',
        address2 : '',
        city : '',
        state : '',
        zip : '',
        note : '',
        is_active : 0,
        logo_file : '',
        theme_rgb_pallet_id : '',
      },
    };
  },

  validations: {
    form : {
      first_name : {required},
      last_name : {required:false},
      email : {required},
      phone : {required},
      date_of_birth : {required},
      phone2 : {required},
      address1 : {required},
      address2 : {required:false},
      city : {required},
      state : {required},
      zip : {required},
      note : {required:false},
      is_active : 0,
      logo_file : {required:false},
      theme_rgb_pallet_id : {required:false},
    }
  },

  computed: {
  },

  methods: {

    handleClientReq() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.makeToast("error", "generic-required-message");
      } else {
        let user_data = JSON.parse(localStorage.user_sess_data);
        let data = this.form;
        data.token = user_data.token;
        // change values to 0 1
        data.is_active = data.is_active ? 1 : 0;
        adminSaveClient(data).then(response => {
          if(response.status){
            this.makeToast("success", response.message);
            this.resetForm();
            this.$v.$reset();
          } else {
            this.makeToast("error", response.message);
          }
        }); 
      }
    },

    resetForm() {
      var self = this;
      //Iterate through each object field, key is name of the object field`
      Object.keys(this.form).forEach(function(key) {
        self.form[key] = '';
      });
    },

    makeToast(variant = "default", message) {
        // this.$toast.open({
        // message: message,
        // type: variant,
        // position: 'top-right',
        // duration: 3000,
        // dismissible: true
        // });
        this.$root.$emit("toast_message", {'type' : variant, 'message' : message});
    }
  },

  mounted() {
  }

};
</script>