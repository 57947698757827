<template>
    <div class="main-layout-section admin-portal">
        <SuperAdminHeader />
          
        <div class="w-logo mb-3">
            <img src="../../assets/images/gamelab-logo.svg" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" />
        </div>
  
        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeIn animate__fast">Universal Pod IDs</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">
                    <div class="admin-general-section-buttons">
                        <router-link to="/superadmin/dashboard" class="admin-general-section-button">
                            Back To Dashboard
                        </router-link>
                        <a
                        href="javascript:void(0)"
                        @click="handlePodIdReq"
                        class="admin-general-section-button" 
                        :disabled="!is_processing"
                        >Generate Pod ID</a
                        >
                    </div>
                </div>

               <div class="col-sm-12 animate__animated animate__fadeInUp animate__delay-1s mt-2">
                    <div class="overflow-auto p-0 m-0">
                        <b-table
                        id="admin-general-table"
                        :striped="true"
                        :hover="false"
                        :dark="false"
                        :items="ids_data"
                        :fields="idsFields"
                        show-empty
                        :emptyText="idsEmptyText"
                        >

                            <template #cell(is_active)="data">
                                <i class="fas fa-circle-check fa-2xl icon h2" v-if="data.item.is_active == 1"></i>
                                <i class="fas fa-times-circle fa-2xl icon h2" v-if="data.item.is_active == 0" style="color:#FF1744;"></i>
                            </template>
                            <template #cell(action)="data">
                                <div class="icon-action-buttons-container d-flex flex-row">
                                    <!-- delete button -->
                                    <button 
                                    class="btn-danger btn-sm" 
                                    :class="data.value"
                                    @click="confirmDelete(data.item.id)"
                                    :disabled="data.item.is_active == 0"
                                    >
                                    <b-icon class="icon" icon="trash2-fill"></b-icon>
                                    {{ data.item.is_active == 1 ? 'Invalidate' : 'Invalidated' }}
                                    </button>
                                </div>
                            </template>
                            <template #empty="scope">
                                <p class="text-center p-0 m-0">{{ scope.emptyText }}</p>
                            </template>
                        </b-table>
                    </div>
                    <b-pagination
                    v-model="current_page"
                    :total-rows="total_rows"
                    :per-page="per_page" 
                    limit="3"
                    aria-controls="admin-general-table" 
                    align="right"
                    ></b-pagination>
                    <p class="admin-general-table-status-text">{{ status_text }}</p>
                </div>

            </div>

        </div>
    
        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <SuperAdminFooter type="inner" />
        </div>
        <!-- end of footer section -->

        <!-- start of delete api keys confirm modal -->
        <b-modal id="delete-modal" dialog-class="admin-portal-modal" header-class="admin-portal-bg secondary px-3 py-2" hide-footer>
            <template #modal-title>
                Confirm
                <b-icon 
                class="icon" 
                icon="question-circle-fill"
                >
                </b-icon> 
            </template>
            <b-overlay :show="false" rounded="lg">
                <div class="d-flex">
                <p>Are you sure you want to invalidate selected pod id ?</p>
                </div>
                <div class="d-flex text-center">
                <button
                    type="button"
                    class="modal-button px-5 py-2"
                    @click="deletePodId"
                >
                    Yes
                </button>
                <button
                    type="button"
                    class="modal-button px-5 py-2 mx-2"
                    @click="$bvModal.hide('delete-modal')"
                >
                    No
                </button>
                </div>
            </b-overlay>
        </b-modal>
  
    </div>
</template>
  
<script>
import SuperAdminHeader from "@/views/superadmin/SuperAdminHeader.vue";
import SuperAdminFooter from "@/views/superadmin/SuperAdminFooter.vue";
import { adminGetPodIdsList, adminSaveUniversalPodId, adminDeleteUniversalPodId } from "../../services/AdminService";
  
export default {
    name: "SuperAdminUniversalPodIds-view",
    components: { SuperAdminHeader, SuperAdminFooter },
    data() {
        return {
            idsEmptyText:"No pod ids found at the moment!",
            idsEmptyHtml:"No pod ids found at the moment!",
            idsFields: [
                {'key' : 'id', 'label' : '#'},
                {'key' : 'pod_id', 'label' : 'Pod ID'},
                {'key' : 'is_active', 'label' : 'Status'}, 
                {'key' : 'action', 'label' : 'Action'}
            ],
            ids_data: [],
            delete_id: '',
            status_text: '',
            per_page: 10,
            current_page: 1,
            total_rows: 0,
            is_processing: false,
        };
    },
  
    methods: {
  
        idsList() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                length: this.per_page,
                page: this.current_page,
            };
            this.is_processing = true;
            adminGetPodIdsList(params).then(response => {
                this.is_processing = false;
                this.ids_data = response.data.pod_ids;
                this.current_page = response.data.current_page;
                this.total_rows = response.data.total_rows;
                this.status_text = response.data.status_text;
                if(this.ids_data.length > 0) {
                    this.ids_data.map(function(lb){
                        return lb.action='icon-button-actions';
                    });
                }
            });
        },

        handlePodIdReq() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let data = {};
            data.token = user_data.token;
            data.length = this.per_page;
            data.page = this.current_page;
            this.is_processing = true;
            adminSaveUniversalPodId(data).then(response => {
                this.is_processing = false;
                if(response.status){
                    this.makeToast("success", response.message);
                    this.idsList();
                } else {
                    this.makeToast("error", response.message);
                }
            });
        },

        confirmDelete(id) {
            this.delete_id = id;
            this.$bvModal.show('delete-modal');
        },

        deletePodId() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                id: this.delete_id
            };
            this.is_processing = true;
            adminDeleteUniversalPodId(params).then(response => {
                this.is_processing = false;
                if(response.status) {
                    this.$bvModal.hide('delete-modal');
                    this.makeToast("success", response.message, 'apikeys');
                    this.idsList();
                }
                else {
                    this.makeToast("error", response.message, 'apikeys');
                }
            });
        },
    
        makeToast(variant = "default", message) {
            this.$root.$emit("toast_message", {'type' : variant, 'message' : message});
        },
    },
  
    mounted() {
      this.idsList();
    }
  
  };
  </script>
  <style scoped>
  .admin-portal ul.admin-del-list {
      display: flex;
      flex-direction: row;
      column-gap: 20px;
      flex-wrap: wrap;
      row-gap: 20px;
  }
  .admin-portal ul.admin-del-list li {
      border: 2px solid #7f7f7f;
      padding: 10px;
      border-radius: 5px;
  }
  </style>