<template>
  <div id="app" class="bg-labPrimary">
    <AppTransitionWidget/>
    <ButtonsWidget/>
    <router-view />
    <LiveChatWidgetCustom/>
  </div>
</template>

<script>
 /* eslint-disable */
import LiveChatWidgetCustom from "@/components/LiveChatWidgetCustom.vue";
import { getPodId, setFlaskLoginPod, setFlaskRebootPod, setFlaskLED1, setFlaskLED2, setFlaskPodUser } from "./services/FlaskService";
import { userAdminLogin } from "./services/HomepageService";
import ButtonsWidget from "@/components/ButtonsWidget.vue";
import AppTransitionWidget from "@/components/AppTransitionWidget.vue";

export default {
    components: { LiveChatWidgetCustom, ButtonsWidget , AppTransitionWidget },
    beforeCreate() {
        // init app transition updater
        this.$store.commit("setAppTransitionUpater", true);
        
        this.$store.commit("getMessagesJson");
        // capture click events
        document.body.addEventListener('click', function(){
            localStorage.setItem('last_activity', new Date());
        }, true);
        document.body.addEventListener('keydown', function(){
            localStorage.setItem('last_activity', new Date());
        }, true);
        document.body.addEventListener('mousedown', function(){
            localStorage.setItem('last_activity', new Date());
        }, true);
        document.body.addEventListener('mousemove', function(){
            localStorage.setItem('last_activity', new Date());
        }, true);
        // check if page refreshed and user logged in
        if(typeof localStorage.user_sess_data !== 'undefined') {
            let user_data = JSON.parse(localStorage.user_sess_data);
            if (user_data.role_id && (user_data.role_id == 1 || user_data.role_id == 2)) {
                this.$store.commit("fetchPlayerFacThemeLogo");
                
                // init game images preloading
                this.$store.commit("setGamesImagesList", true);
            }
            else if (user_data.role_id && user_data.role_id == 3) {
                this.$store.commit("fetchAdminThemeLogo");
            }
        }
        // init activity updater
        this.$store.commit("setSessionActivityUpater", true);

        // init reservation if any
        if(typeof localStorage.reservation_alert !== 'undefined') {
            let reservation_alert = JSON.parse(localStorage.reservation_alert);
            this.$store.commit("setReservationAlertData", reservation_alert);
        }

        // init pod screen buttons updater
        this.$store.commit("setPodScreenButtonsUpater", true);

        // windows titles updater
        this.$store.commit("setWindowsTitlesUpater", true);
    },
    mounted() {
        // init websocket for rss feed alert
        // check if channel already subscribed
        if(!Object.prototype.hasOwnProperty.call(window.Echo.connector.channels, "rss-feed-command")) {
            // listen for pod schedule event
            window.Echo.channel('rss-feed-command').listen('RssFeedCommandEvent', (e) => {

                
                getPodId().then((pod) => {
                    if (
                        Object.prototype.hasOwnProperty.call(pod, "success") &&
                        pod.success &&
                        Object.prototype.hasOwnProperty.call(pod, "data") &&
                        Object.prototype.hasOwnProperty.call(pod.data, "pod_id") &&
                        pod.data.pod_id.length > 0
                    ) {
                        if(
                        e.message.data.pod_ids.indexOf(pod.data.pod_id) !== -1 && 
                        e.message.server_info == process.env.VUE_APP_WS_ENV
                        ) {
                            // set data for alert
                            this.$store.commit("setFeedAlertData", e.message.data.alert_data);
                        }
                    }
                });
            });
        }

        // init websocket for admin pod websockets
        // check if channel already subscribed
        if(!Object.prototype.hasOwnProperty.call(window.Echo.connector.channels, "admin-pod-command")) {
            // listen for pod schedule event
            window.Echo.channel('admin-pod-command').listen('AdminPodCommandEvent', (e) => {

                
                getPodId().then((pod) => {
                    if (
                        Object.prototype.hasOwnProperty.call(pod, "success") &&
                        pod.success &&
                        Object.prototype.hasOwnProperty.call(pod, "data") &&
                        Object.prototype.hasOwnProperty.call(pod.data, "pod_id") &&
                        pod.data.pod_id.length > 0
                    ) {
                        if(
                        e.message.data.pod_ids.indexOf(pod.data.pod_id) !== -1 && 
                        e.message.server_info == process.env.VUE_APP_WS_ENV
                        ) {
                            let command_type = e.message.data.command_type;
                            if(typeof localStorage.pod_sess_data !== 'undefined') {
                                if(typeof localStorage.user_sess_data !== 'undefined') {}
                                else {
                                    let pod_data = JSON.parse(localStorage.pod_sess_data);
                                    let session_id = pod_data.session_id_key;

                                    if(command_type == 'admin_play_game' || command_type == 'save_seat') {
                                        // call login api to get login details
                                        userAdminLogin(session_id, e.message.data.user_id).then((response) => {
                                            if (response.status) {
                                                if(response.data.role_id == 3) {
                                                    // get alerts and remove from response
                                                    let is_alerts = response.data.is_alerts;
                                                    let alerts = response.data.alerts;
                                                    delete response.data.is_alerts;
                                                    delete response.data.alerts;
                                                    let is_orientation = response.data.is_orientation;
                                                    delete response.data.is_orientation;
                                                    let is_orientation_paid = response.data.is_orientation_paid;
                                                    delete response.data.is_orientation_paid;
                                                    localStorage.setItem('user_sess_data', JSON.stringify(response.data));
                                                    // call login pod flask api
                                                    let params1 = {
                                                        session_id: pod_data.session_id_key,
                                                        pod_id_key: pod_data.pod_id_key,
                                                        user_id: response.data.user_id,
                                                        user_name: response.data.user_name +";" + response.data.role_name
                                                    };
                                                    setFlaskLoginPod(params1).then((fst) => {
                                                        fst;
                                                        let params_pod_user = {
                                                            user_name: response.data.user_name
                                                        };
                                                        setFlaskPodUser(params_pod_user).then((sfp) => {
                                                            sfp;
                                                        });
                                                    });  
                                        
                                                    if(command_type == 'admin_play_game') {
                                                        e.message.data['command_pod'] = pod_data.pod_id_key;
                                                        this.$router.push({ name: "Admin Pod Games", params: {'admin_websocket': e.message.data, 'xd' : e.message.data.game_platform}});
                                                    }
                                                    else if(command_type == 'save_seat') {
                                                        this.$router.push({ name: "Admin Dashboard", params: {'admin_websocket': e.message.data}});
                                                    }
                                                }
                                            }
                                        });
                                    }
                                    else if(command_type == 'reboot') {
                                        setFlaskRebootPod().then(reb => {
                                            reb;
                                        });
                                    }
                                    else if(command_type == 'led1_lock' || command_type == 'led2_lock') {
                                        if(command_type == 'led1_lock') {
                                            let params1 = {
                                                led1_lock: e.message.data.command_value
                                            };
                                            setFlaskLED1(params1, 'lock').then(lc => {
                                                lc;
                                            });
                                        }
                                        else if(command_type == 'led2_lock') {
                                            let params1 = {
                                                led2_lock: e.message.data.command_value
                                            };
                                            setFlaskLED2(params1, 'lock').then(lc => {
                                                lc;
                                            });
                                        }
                                    }
                                    else if(command_type == 'led1' || command_type == 'led2') {
                                        if(command_type == 'led1') {
                                            let params = {
                                                led1_default: e.message.data.command_value
                                            };
                                            setFlaskLED1(params, 'default').then(lc => {
                                                console.log("LED1 CHANGE FLASK API CALLED : ", lc);
                                            });
                                        }
                                        else if(command_type == 'led2') {
                                            let params = {
                                                led2_default: e.message.data.command_value
                                            };
                                            setFlaskLED2(params, 'default').then(lc => {
                                                console.log("LED2 CHANGE FLASK API CALLED : ", lc);
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    }
                });
            });
        }

        // init websocket for reservation pod websockets
        // check if channel already subscribed
        if(!Object.prototype.hasOwnProperty.call(window.Echo.connector.channels, "reservation-command")) {
            // listen for pod schedule event
            window.Echo.channel('reservation-command').listen('ReservationCommandEvent', (e) => {

                
                getPodId().then((pod) => {
                    if (
                        Object.prototype.hasOwnProperty.call(pod, "success") &&
                        pod.success &&
                        Object.prototype.hasOwnProperty.call(pod, "data") &&
                        Object.prototype.hasOwnProperty.call(pod.data, "pod_id") &&
                        pod.data.pod_id.length > 0
                    ) {
                        if(
                        e.message.data.pod_ids.indexOf(pod.data.pod_id) !== -1 && 
                        e.message.server_info == process.env.VUE_APP_WS_ENV
                        ) {
                            // set data for alert
                            this.$store.commit("setReservationAlertData", e.message.data.reservation_data);
                        }
                    }
                });
            });
        }

        // init websocket for host pod websockets
        // check if channel already subscribed
        if(!Object.prototype.hasOwnProperty.call(window.Echo.connector.channels, "fac-app-command")) {
            // listen for pod schedule event
            window.Echo.channel('fac-app-command').listen('FacAppCommandEvent', (e) => {

                getPodId().then((pod) => {
                    if (
                        Object.prototype.hasOwnProperty.call(pod, "success") &&
                        pod.success &&
                        Object.prototype.hasOwnProperty.call(pod, "data") &&
                        Object.prototype.hasOwnProperty.call(pod.data, "pod_id") &&
                        pod.data.pod_id.length > 0
                    ) {
                        if(
                        e.message.data.pod_ids.indexOf(pod.data.pod_id) !== -1 && 
                        e.message.server_info == process.env.VUE_APP_WS_ENV
                        ) {
                            let command_type = e.message.data.command_type;
                            if(typeof localStorage.pod_sess_data !== 'undefined') {
                                if(typeof localStorage.user_sess_data !== 'undefined') {
                                    if (
                                        command_type == 'login_fac' || 
                                        command_type == 'save_seat_fac' || 
                                        command_type == 'fac_play_game'
                                    ) {
                                        localStorage.setItem('host_websocket', JSON.stringify(e.message.data));
                                        if(e.message.data.command_type == 'login_fac') {
                                            localStorage.removeItem('host_websocket_command');
                                        }
                                        else {
                                            localStorage.setItem('host_websocket_command', JSON.stringify(e.message.data));
                                        }
                                        this.$root.$emit("host_websocket_event_loggedin", {'host_websocket': e.message.data });
                                    }
                                }
                                else {
                                    if(command_type == 'login_fac') {
                                        localStorage.setItem('host_websocket', JSON.stringify(e.message.data));
                                        localStorage.removeItem('host_websocket_command');
                                        if(this.$route.name !== 'Login') {
                                            this.$router.push({ name: "Login", params: {'host_websocket': e.message.data }});
                                        }
                                        else {
                                            this.$root.$emit("host_websocket_event", {'host_websocket': e.message.data });
                                        }
                                    }
                                    else if(command_type == 'save_seat_fac') {
                                        localStorage.setItem('host_websocket', JSON.stringify(e.message.data));
                                        localStorage.setItem('host_websocket_command', JSON.stringify(e.message.data));
                                        if(this.$route.name !== 'Login') {
                                            this.$router.push({ name: "Login", params: {'host_websocket': e.message.data }});
                                        }
                                        else {
                                            this.$root.$emit("host_websocket_event", {'host_websocket': e.message.data });
                                        }
                                    }
                                    else if(command_type == 'fac_play_game') {
                                        localStorage.setItem('host_websocket', JSON.stringify(e.message.data));
                                        localStorage.setItem('host_websocket_command', JSON.stringify(e.message.data));
                                        if(this.$route.name !== 'Login') {
                                            this.$router.push({ name: "Login", params: {'host_websocket': e.message.data }});
                                        }
                                        else {
                                            this.$root.$emit("host_websocket_event", {'host_websocket': e.message.data });
                                        }
                                    }
                                }
                            }
                        }
                    }
                });
            });
        }
    }
}
</script>

<style>
body:not(:has(div.super-admin-portal)) {
  user-select: none;
  -webkit-user-drag: none;
}
img, a {
  user-select: none;
  -webkit-user-drag: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.bg-labPrimary {
  background-color: #dd550c;
}
</style>
