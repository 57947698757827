<template>
  <div class="main-layout-section admin-portal secondary">
        <AdminHeader 
        @setPodData="setPodData($event)" />
        
        <div class="w-logo mb-3">
            <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeIn animate__fast" v-if="logo_file !== '' && logo_file !== null"/>
        </div>

        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeIn animate__fast">Edit Pod</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">
                    <div class="admin-general-section-buttons">
                        <router-link :to="`/admin/labs/${lab_id}/pods`" class="admin-general-section-button">
                            Back To Pods Management
                        </router-link>
                    </div>
                </div>

                <div class="row m-0 p-0 col-md-12 col-sm-12 m-auto">
                    <!-- start of pod form -->
                    <b-form inline @submit.stop.prevent class="m-0 p-0">
                        <!-- client and labe name field -->
                        <div class="row">
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">IP Address</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.ip_address.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-input
                                        v-model="form.ip_address"
                                        type="text"
                                        placeholder="Enter IP Address"
                                        id="ip_address"
                                        aria-describedby="ip_address-help-block"
                                        class=""
                                        :class="{ 'is-invalid': $v.form.ip_address.$error }"
                                    ></b-form-input>
                                </div>
                            </div>

                            <div class="d-flex mb-1 col-md-3 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Mac Address</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.mac_address.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-input
                                        v-model="form.mac_address"
                                        id="mac_address"
                                        class="mb-2 mr-sm-2 mb-sm-0 user-name bg-lightBlack border-0 h-53"
                                        :class="{ 'is-invalid': $v.form.mac_address.$error }"
                                        placeholder="Enter mac address"
                                    ></b-form-input>
                                </div>
                            </div>
                            <div class="d-flex mb-1 col-md-3 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Pod ID</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.pod_id_key.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-input
                                        v-model="form.pod_id_key"
                                        id="pod_id_key"
                                        class="mb-2 mr-sm-2 mb-sm-0 user-name bg-lightBlack border-0 h-53"
                                        :class="{ 'is-invalid': $v.form.pod_id_key.$error }"
                                        placeholder="Enter pod id"
                                    ></b-form-input>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Select Client</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.client_id.$error }">
                                    <span><i class="fa-solid fa-person"></i></span>
                                    <b-form-select
                                        v-model="form.client_id"
                                        :options="clients"
                                        text-field="value"
                                        value-field="id"
                                        id="client_id"
                                        class=""
                                        :class="[ $v.form.client_id.$error ? 'is-invalid' : '', form.client_id == '' ? 'text-grey' : '' ]"
                                        placeholder="Select a client"
                                        :disabled="client_disabled"
                                    ></b-form-select>
                                </div>
                            </div>

                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Select Lab</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.lab_id.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-select
                                        v-model="form.lab_id"
                                        :options="labs"
                                        text-field="value"
                                        value-field="id"
                                        id="lab_id"
                                        class=""
                                        :class="[ $v.form.lab_id.$error ? 'is-invalid' : '', form.lab_id == '' ? 'text-grey' : '' ]"
                                        placeholder="Select a lab"
                                    ></b-form-select>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Select Pod Type</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.pod_type_id.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-select
                                        v-model="form.pod_type_id"
                                        v-on:change="setWithKeyboard"
                                        :options="pod_types"
                                        text-field="value"
                                        value-field="id"
                                        id="pod_type_id"
                                        class=""
                                        :class="[ $v.form.pod_type_id.$error ? 'is-invalid' : '', form.pod_type_id == '' ? 'text-grey' : '' ]"
                                        placeholder="Select a pod type"
                                    ></b-form-select>
                                </div>
                            </div>

                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Enter Nav</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.nav_id.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-input
                                        v-model="form.nav_id"
                                        id="nav_id"
                                        class=""
                                        :class="[ $v.form.nav_id.$error ? 'is-invalid' : '', form.nav_id == '' ? 'text-grey' : '' ]"
                                        placeholder="Enter nav"
                                    ></b-form-input>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Select Left Nav</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100 overflow-visible" :class="{ 'is_invalid': $v.form.nav_id_left.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <!-- <b-form-select
                                        v-model="form.nav_id_left"
                                        :options="left_navs"
                                        text-field="value"
                                        value-field="id"
                                        id="nav_id_left"
                                        class=""
                                        :class="[ $v.form.nav_id_left.$error ? 'is-invalid' : '', form.nav_id_left == '' ? 'text-grey' : '' ]"
                                        placeholder="Select a left nav"
                                    ></b-form-select> -->
                                    <Select2 class="gl_select2" v-model="form.nav_id_left" ref="select_nav_id_left" :options="left_navs" :settings="{tags:true, placeholder: 'Select a left nav', allowClear: true}" @update:modelValue="changeEvent($event, 'nav_id_left')" />
                                </div>
                            </div>

                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Select Right Nav</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.nav_id_right.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <!-- <b-form-select
                                        v-model="form.nav_id_right"
                                        :options="right_navs"
                                        text-field="value"
                                        value-field="id"
                                        id="nav_id_right"
                                        class="mb-2 mr-sm-2 mb-sm-0 w-100 user-name bg-lightBlack border-0 h-53"
                                        :class="[ $v.form.nav_id_right.$error ? 'is-invalid' : '', form.nav_id_right == '' ? 'text-grey' : '' ]"
                                        placeholder="Select a right nav"
                                    ></b-form-select> -->
                                    <Select2 class="gl_select2" v-model="form.nav_id_right" ref="select_nav_id_right" :options="right_navs" :settings="{tags:true, placeholder: 'Select a right nav', allowClear: true}" @update:modelValue="changeEvent($event, 'nav_id_right')" />
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Select Rear Nav</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.nav_id_rear.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <!-- <b-form-select
                                        v-model="form.nav_id_rear"
                                        :options="rear_navs"
                                        text-field="value"
                                        value-field="id"
                                        id="nav_id_rear"
                                        class=""
                                        :class="[ $v.form.nav_id_rear.$error ? 'is-invalid' : '', form.nav_id_rear == '' ? 'text-grey' : '' ]"
                                        placeholder="Select a rear nav"
                                    ></b-form-select> -->
                                    <Select2 class="gl_select2" v-model="form.nav_id_rear" ref="select_nav_id_rear" :options="rear_navs" :settings="{tags:true, placeholder: 'Select a rear nav', allowClear: true}" @update:modelValue="changeEvent($event, 'nav_id_rear')" />
                                </div>
                            </div>

                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Lan IP Address</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.lan_ip.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-input
                                        v-model="form.lan_ip"
                                        type="text"
                                        placeholder="Enter lan IP address"
                                        id="lan_ip"
                                        aria-describedby="lan_ip-help-block"
                                        class=""
                                        :class="{ 'is-invalid': $v.form.lan_ip.$error }"
                                    ></b-form-input>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Wan IP Address</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.wan_ip.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-input
                                        v-model="form.wan_ip"
                                        type="text"
                                        placeholder="Enter wan IP address"
                                        id="wan_ip"
                                        aria-describedby="wan_ip-help-block"
                                        class=""
                                        :class="{ 'is-invalid': $v.form.wan_ip.$error }"
                                    ></b-form-input>
                                </div>
                            </div>

                            <!-- phone field -->
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">XD1</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.xd1.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-input
                                        v-model="form.xd1"
                                        id="xd1"
                                        class=""
                                        :class="{ 'is-invalid': $v.form.xd1.$error }"
                                        placeholder="Enter device #1 installed"
                                    ></b-form-input>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">XD2</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.xd2.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-input
                                        v-model="form.xd2"
                                        id="xd2" 
                                        class=""
                                        :class="{ 'is-invalid': $v.form.xd2.$error }"
                                        placeholder="Enter device #2 installed"
                                    ></b-form-input>
                                </div>
                            </div>

                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Select Deployment Status</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.seat.$error }">
                                    <span><i class="fa-solid fa-person"></i></span>
                                    <b-form-select
                                        v-model="form.seat"
                                        :options="seats"
                                        text-field="value"
                                        value-field="id"
                                        id="seat"
                                        class="mb-2 mr-sm-2 mb-sm-0 w-100 user-name bg-lightBlack border-0 h-53"
                                        :class="[ $v.form.seat.$error ? 'is-invalid' : '', form.seat == '' ? 'text-grey' : '' ]"
                                        placeholder="Select a deployment status"
                                    ></b-form-select>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-12 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Note</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.note.$error }">
                                    <b-form-textarea
                                        v-model="form.note"
                                        id="note"
                                        class="mb-2 mr-sm-2 mb-sm-0 user-name bg-lightBlack border-0 w-textarea"
                                        :class="{ 'is-invalid': $v.form.note.$error }"
                                        placeholder="Note"
                                        no-resize
                                    ></b-form-textarea>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-4" :class="{ 'is_invalid': $v.form.is_active.$error }">
                                <switches 
                                class="active-switch h-53" 
                                v-model="form.is_active"
                                theme="bootstrap" 
                                color="primary"
                                type-bold="true"
                                label="Active"
                                >
                                </switches>
                            </div>
                            <div class="d-flex mb-1 col-md-4" :class="{ 'is_invalid': $v.form.with_keyboard.$error }">
                                <switches 
                                class="active-switch h-53" 
                                v-model="form.with_keyboard"
                                theme="bootstrap" 
                                color="primary"
                                type-bold="true"
                                label="With Keyboard"
                                >
                                </switches>
                            </div>
                            <div class="d-flex mb-1 col-md-4" :class="{ 'is_invalid': $v.form.pro.$error }">
                                <switches 
                                class="active-switch h-53" 
                                v-model="form.pro"
                                theme="bootstrap" 
                                color="primary"
                                type-bold="true"
                                label="Pro"
                                >
                                </switches>
                            </div>
                        </div>

                        <div
                        class="
                        w-form-control
                        animate__animated animate__fadeIn animate__fast m-0 p-0
                        "
                        >
                            <a
                            href="javascript:void(0)"
                            @click="handlePodReq"
                            :disabled="!is_valid_pod"
                            class="flex-fill"
                            >Update</a
                            >
                        </div>

                    </b-form>
                    <!-- end of pod form -->
                </div>

            </div>

        </div>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <AdminFooter type="inner" />
        </div>
        <!-- end of footer section -->
        
    </div>
</template>

<script>
import AdminHeader from "@/views/admin/AdminHeader.vue";
import { adminGetClientsList, adminUpdatePod, adminGetLabsList, adminGetPodTypesList, adminGetNavsList, adminEditPod } from "../../services/AdminService";
import { setPodType, writeFlaskPodId } from "../../services/FlaskService";
import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import { required } from 'vuelidate/lib/validators';
import Switches from 'vue-switches';
import AdminFooter from "@/views/admin/AdminFooter.vue";
import Select2 from 'vue3-select2-component';

export default {
  name: "AdminEditPod-view",
  components: { AdminHeader, Switches, AdminFooter, Select2 },
  data() {
    return {
      lab_id: this.$route.params.lab_id,
      edit_pod_id: this.$route.params.pod_id,
      client_disabled: false,
      is_valid_pod: false,
      navs: [],
      left_navs:[],
      right_navs:[],
      rear_navs:[],
      labs: [],
      clients:[],
      pod_types:[],
      selected_pod_type: "",
      seats:[{'id': '', 'value' : 'Select a deployment status'}, {'id': 'ACTIVE', 'value' : 'ACTIVE'}, {'id': 'INACTIVE', 'value' : 'INACTIVE'}, {'id': 'DE-REGISTERED', 'value' : 'DE-REGISTERED'}],
      form: {
        ip_address : '',
        mac_address : '',
        client_id : '',
        lab_id : '',
        pod_type_id : '',
        nav_id : '',
        nav_id_left : '',
        nav_id_right : '',
        nav_id_rear : '',
        lan_ip : '',
        wan_ip : '',
        xd1 : '',
        xd2 : '',
        seat : '',
        note : '',
        is_active : 0,
        with_keyboard : 0,
        pro: 0,
        pod_id_key:'',
      },
      edit_pod_key: '',
    };
  },

  validations: {
    form : {
      ip_address : {required},
      mac_address : {required},
      client_id : {required},
      lab_id : {required},
      pod_type_id : {required},
      nav_id : {required},
      nav_id_left : {required:false},
      nav_id_right : {required:false},
      nav_id_rear : {required:false},
      lan_ip : {required:false},
      wan_ip : {required:false},
      xd1 : {required:false},
      xd2 : {required:false},
      note : {required:false},
      seat : {required},
      is_active : {required},
      with_keyboard : {required},
      pro : {required},
      pod_id_key : {required},
    }
  },

  computed: {
    logo_file() {
        return this.$store.getters.getThemeLogo().logo;
    }
  },

  methods: {
    clientsList() {
      let user_data = JSON.parse(localStorage.user_sess_data);
      let params = {
        token: user_data.token
      };
      adminGetClientsList(params).then(response => {
        this.clients = response.data.clients;
        // add empty option as placeholder
        this.clients.splice(0, 0, {'id' : '', 'value' : 'Select a client'});
        if(this.client_disabled) {
          this.clients = this.clients.filter(item => {
            return item.id == user_data.client_id;
          });
          this.form.client_id = user_data.client_id;
        }
      });
    },

    labsList() {
      let user_data = JSON.parse(localStorage.user_sess_data);
      let params = {
        token: user_data.token,
        client_id: user_data.client_id
      };
      adminGetLabsList(params).then(response => {
        this.labs = response.data.labs;
        // add empty option as placeholder
        this.labs.splice(0, 0, {'id' : '', 'value' : 'Select a lab'});
      });
    },

    podTypeList() {
      let user_data = JSON.parse(localStorage.user_sess_data);
      let params = {
        token: user_data.token
      };
      adminGetPodTypesList(params).then(response => {
        this.pod_types = response.data.pod_types;
        if(this.pod_types.length > 0) {
          this.pod_types.map(function(pt){
            return pt.kb = (pt.code == 'gp_arc' || pt.code == 'gp_duo') ? 0 : 1;
          });
        }
        // add empty option as placeholder
        this.pod_types.splice(0, 0, {'id' : '', 'value' : 'Select a pod type'});
      });
    },

    navsList() {
      let user_data = JSON.parse(localStorage.user_sess_data);
      let params = {
        token: user_data.token,
        client_id: user_data.client_id
      };
      adminGetNavsList(params).then(response => {
        this.navs = response.data.navs;
        this.left_navs = JSON.parse(JSON.stringify(this.navs));
        this.right_navs = JSON.parse(JSON.stringify(this.navs));
        this.rear_navs = JSON.parse(JSON.stringify(this.navs));
        // add empty option as placeholder
        this.navs.splice(0, 0, {'id' : '', 'value' : 'Select a nav'});
        this.left_navs.splice(0, 0, {'id' : '', 'value' : 'Select a left nav'});
        this.right_navs.splice(0, 0, {'id' : '', 'value' : 'Select a right nav'});
        this.rear_navs.splice(0, 0, {'id' : '', 'value' : 'Select a rear nav'});
      });
    },

    getPodDetails() {
      let user_data = JSON.parse(localStorage.user_sess_data);
      let params = {
        token: user_data.token,
        pod_id: this.edit_pod_id
      };
      adminEditPod(params).then(response => {
        if(response.status) {
          this.is_valid_pod = true;
          this.edit_pod_key = response.data.pod.pod_id_key;
          let pod_details = response.data.pod;
          let self = this;
            Object.keys(pod_details).forEach(function(key,index) {
                if(Object.prototype.hasOwnProperty.call(self.form, key) && pod_details[key] !== "" && pod_details[key] !== null) {
                    self.form[key] = pod_details[key];
                    index;
                    if(key == 'nav_id') {
                        for (let i = 0; i < self.navs.length; i++) {
                            if(pod_details[key] == self.navs[i].id) {
                                self.form[key] = self.navs[i].value;
                            }
                        }
                    }
                    else if(key == 'nav_id_left' || key == 'nav_id_right' || key == 'nav_id_rear') {
                        self.$refs['select_'+key].setValue(pod_details[key]);
                    }
                }
            });
            //   this.setWithKeyboard();
        }
      });
    },

    handlePodReq() {
      if(this.is_valid_pod) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.makeToast("error", "generic-required-message");
        } else {
          let user_data = JSON.parse(localStorage.user_sess_data);
          let data = this.form;
          data.token = user_data.token;
          // change values to 0 1
          data.is_active = data.is_active ? 1 : 0;
          data.with_keyboard = data.with_keyboard ? 1 : 0;
          data.pro = data.pro ? 1 : 0;
          data.pod_id = this.edit_pod_id;
          adminUpdatePod(data).then(response => {
            if(response.status){
                this.makeToast("success", response.message);
                let params = {
                    pod_type: this.selected_pod_type
                };
                setPodType(params).then(write_pod => {
                    console.log("SET POD ID FLASK API CALLED : ", write_pod);
                });
                // call flask api to write pod id
                let params1 = {
                    pod_id: response.data.pod_id_key
                };
                writeFlaskPodId(params1).then(write_pod => {
                    console.log("WRITE POD ID FLASK API CALLED : ", write_pod);
                });
            } else {
              this.makeToast("error", response.message);
            }
          });
        }
      }
      else {
        this.makeToast("error", "admin-pod-invalid-error");
      }
    },

    resetForm() {
      var self = this;
      //Iterate through each object field, key is name of the object field`
      Object.keys(this.form).forEach(function(key) {
        self.form[key] = '';
      });
    },

    setWithKeyboard() {
      let self = this;
      let selected = this.form.pod_type_id;
      this.pod_types.map(function(item) {
        if(item.id == selected) {
          self.selected_pod_type = item.code;
          if(item.kb == 1) {
            self.form.with_keyboard = 1;
          }
          else {
            self.form.with_keyboard = 0;
          }
        }
      });
    },

    setClientDisabled() {
      let user_data = JSON.parse(localStorage.user_sess_data);
      if(user_data.role_id == 3) {
        this.client_disabled = true;
      }
    },

    makeToast(variant = "default", message) {
        // this.$toast.open({
        //     message: message,
        //     type: variant,
        //     position: 'top-right',
        //     duration: 3000,
        //     dismissible: true
        // });
        this.$root.$emit("toast_message", {'type' : variant, 'message' : message});
    },
    setPodData(obj) {
        this[obj.key] = obj.value;
    },
    changeEvent(value, type){
        if(value.length <= 0) {
            this.form[type] = '';
        }
        else {
            this.form[type] = value;
        }
    }
  },

  mounted() {
    this.setClientDisabled();
    this.clientsList();
    this.labsList();
    this.podTypeList();
    this.navsList();
    this.getPodDetails();
  }

};
</script>
<style>
    .gl_select2 {
        width: 100%;
        display: flex;
    }
    .gl_select2 > select {
        width: 100% !important;
        background: transparent;
        flex-grow: 1;
    }
    .gl_select2 .select2-container {
        width: 100% !important;
        border-radius: unset;
        background: transparent !important;
        text-align: left;
        display: flex !important;
        flex-direction: row;
        margin: 0;
        padding: 0 !important;
    }
    .gl_select2 .selection {
        width: 90%;
        display: flex !important;
        flex-direction: row;
        background: transparent !important;
        padding: 0 !important;
        margin: 0;
    }
    .gl_select2 .dropdown-wrapper {
        width: 10%;
        border: unset;
        border-radius: unset;
        background: transparent !important;
    }
    .gl_select2 .select2-selection {
        width: 100%;
        border: unset;
        height: 56px;
        padding: 0 !important;
        margin: 0px;
        background: transparent !important;
        border-radius: unset;
    }
    .gl_select2 .select2-selection:focus,
    .gl_select2 .select2-selection:focus-visible {
        outline: 0 !important;
    }
    .gl_select2 .select2-selection__rendered {
        padding: 0 !important;
        margin: 0;
        height: 56px;
        line-height: 56px !important;
        border: unset;
        border-radius: unset;
        background: transparent !important;
    }
    .gl_select2 .select2-selection__placeholder {
        background: transparent !important;
        padding: 0 !important;
    }
    .gl_select2 .select2-selection__arrow {
        height: 53px !important;
        margin: 0px;
        padding: 0px !important;
        background: transparent !important;
    }
    .gl_select2 .select2-selection__clear {
        padding: 2px 5px !important;
        height: 56px;
        border: unset;
        border-radius: unset;
        background: transparent !important;
        line-height: 50px;
    }
</style>