<template>
  <div class="main-layout-section">
    
    <PlayerHeader @setPodData="setPodData($event)" />

    <div class="smaller-screen-header">
        <div class="smaller-screen-header-logo">
            <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s"
                v-if="logo_file !== '' && logo_file !== null"
            />
        </div>
        <div v-if="nav_name !== ''" class="w-form-control pod-info-nav animate__animated animate__fadeInUp animate__delay-1s smaller-screen-header-nav">
            <span class="pod-info-nav-name">
                {{ nav_name }}
            </span>
        </div>
    </div>

    <div class="w-logo mb-3 larger-screen-header">
        <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="logo_file !== '' && logo_file !== null"/>
    </div>

    <template v-if="offer_step && !card_step && !confirm_step">
        <div class="main-content offers" v-if="!pod_locked">
            <h2 class="page-heading animate__animated animate__fadeInUp animate__delay-2s">Add Time</h2>
            <h3 class="page-subheading mb-2 animate__animated animate__fadeInUp animate__delay-2s">Note: Any unused time saves to your<br>account untill you are ready to use it.</h3>

            <!-- start of offers container section -->
            <div class="col-md-11 mx-auto">
                <div class="offers-section-container">

                    <div class="offers-section-list" v-if="offer_step && !card_step && !confirm_step">
                        <b-overlay :show="api_state" rounded="sm" variant="light" opacity="1" class="overlay-bg">
                            <div class="offers-section-list-data mt-1">

                                <div class="offers-section-list-data-list" id="offers-list" :class="[api_state ? 'zero-opacity' : '']">
                                    <div class="offers-section-list-data-list-inner" id="offers-listtt">
                                        <div class="offers-section-list-data-list-item animate__animated animate__fadeIn animate__fast" tabindex="0" v-for="offer in offers" :key="offer.id" @click="selectOffer(offer)" v-show="loaded_offers.indexOf(offer.ImageURL) !== -1">
                                            <img 
                                            :src="`${offer.ImageURL}`" 
                                            alt="" 
                                            :class="[ offer_selected !== null && offer_selected.id == offer.id ? 'selected-offer' : '' ]" @load="loadOfferImage(offer.ImageURL)">
                                        </div>
                                        <p class="text-left no-results" v-if="offers.length <= 0" :key="offers.length">No offers found at the moment!</p>
                                    </div>
                                </div>
                                <div class="offers-section-list-data-play">
                                    <div class="offers-section-list-data-play-buttons">
                                        <a href="javascript:;" class="btn" @click="handleBack">
                                            Back
                                        </a>
                                        <button 
                                        class="btn"
                                        v-if="offer_selected !== null"
                                        @click="selectCard"
                                        >
                                        Continue to Purchase
                                        </button>
                                        <button 
                                        class="btn"
                                        disabled="disabled" 
                                        v-else
                                        >
                                        Continue to Purchase
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </b-overlay>
                    </div>
                </div>

            </div>
            <!-- end of games container section -->
        </div>
    </template>
    
    <template v-if="offer_step && card_step && !confirm_step">
        <div class="main-content offers" v-if="!pod_locked">
            <h2 class="page-heading animate__animated animate__fadeInUp animate__delay-2s">Add Time</h2>
            <h3 class="page-subheading mb-2 animate__animated animate__fadeInUp animate__delay-2s">Enter your payment card details</h3>

            <!-- start of offers container section -->
            <div class="col-md-11 mx-auto">
                <div class="offers-section-container">
                    <!-- stripe form area -->
                    <StripePaymentWidget :offer="offer_selected" :payment_intent="payment_intent" @paymentComplete="paymentComplete($event)" @setPodData="setPodData($event)" @handleBack="handleBack" v-if="offer_step && card_step && offer_selected !== null" />
                </div>

            </div>
            <!-- end of games container section -->
        </div>
    </template>

    <template v-if="offer_step && card_step && confirm_step">
        <div class="main-content offers">
            <h2 class="page-heading animate__animated animate__fadeIn animate__delay-1s">Purchase confirmed!</h2>
            <!-- start of buttons section -->
            <div class="dashboard-buttons-container col-md-3">
                <div class="logout-reason mt-70 animate__animated animate__fadeIn animate__delay-1s">
                    <p class="text-center" style="font-weight: 600;">Returning you to the dashboard.</p>
                </div>
                <div class="logout-loading-image mx-auto animate__animated animate__fadeIn animate__delay-1s">
                    <object type="image/svg+xml" :data="require(`../../assets/images/bits.svg`)" style="width:120px;"></object>
                </div>
            </div>
            <!-- end of buttons section -->
        </div>
    </template>

    <div class="main-content" v-if="pod_locked">
      <div class="d-flex justify-content-center">
        <h2 class="text-white mt-25">You have been locked out by Admin. Please wait...</h2>
      </div>
    </div>

    <!-- start of footer section -->
    <div class="col-sm-12 mx-auto player-offers-footer">
        <PlayerFooter type="inner" class="animate__animated animate__fadeIn animate__delay-3s" />
    </div>
    <!-- end of footer section -->

  </div>
</template>

<script>
import PlayerHeader from "@/views/player/PlayerHeader.vue";
import { getOffersList } from "../../services/PlayerService";
import { global_config } from "@/config/config.js";
import PlayerFooter from "@/views/player/PlayerFooter.vue";
import { sessionActivitySave } from "../../services/HomepageService";
import { handleDocumentHeight } from "../../services/Guard";
import StripePaymentWidget from "@/components/StripePaymentWidget.vue";

export default {
    name: "PlayerOffers-view",
    components: { PlayerHeader, PlayerFooter, StripePaymentWidget },
    data() {
        return {
            offers: [],
            original_offers: [],
            pod_id_key : "",
            pod_locked: false,
            pod_data: {},
            api_state: false,
            page: 1,
            last_page: 1,
            offer_selected: null,
            window_width: window.innerWidth,
            lazy_load_props: {
                blank: true,
                blankColor: '#d3d3d3'
            },
            loaded_offers: [],
            offer_step: true,
            card_step: false,
            confirm_step: false,
            payment_intent: '',
        };
    },

    computed: {
        logo_file() {
            return this.$store.getters.getThemeLogo().logo;
        },
        nav_name() {
            return this.$store.getters.getNavName();
        }
    },

    methods: {
        offersList() {
        let user_data = JSON.parse(localStorage.user_sess_data);
        let pod_data = JSON.parse(localStorage.pod_sess_data);
        let params = {
            session_id_key : pod_data.session_id_key,
            token: user_data.token,
            user_id: user_data.user_id,
            page: this.page
        };
        this.api_state = true;
        getOffersList(params).then(response => {
            this.api_state = false;
            this.offers = response.data.offers;
            this.page = response.data.current_page + 1;
            this.last_page = response.data.last_page;
        })
        },
        setPodData(obj) {
            this[obj.key] = obj.value;
        },
        selectOffer(offer) {
            if(this.offer_selected == null) {
                this.offer_selected = offer;
            }
            else {
                if(this.offer_selected.id == offer.id) {
                    this.offer_selected = null;
                }
                else {
                    this.offer_selected = offer;
                }
            }
        },
        handleBack() {
            if(this.offer_step && !this.card_step && !this.confirm_step) {
                document.getElementsByClassName('main-content')[0].classList.add('animate__animated');
                document.getElementsByClassName('main-content')[0].classList.add('animate__fadeOut');
                setTimeout(() => {
                    this.$router.push({ path: '/player/dashboard' });
                }, 500);
            }
            else if(this.offer_step && this.card_step && !this.confirm_step) {
                this.card_step = false;
                this.confirm_step = false;
            }
        },
        loadOfferImage(offer) {
            this.loaded_offers.push(offer);
        },
        selectCard() {
            this.card_step = true;
        },
        paymentComplete() {
            this.confirm_step = true;
            setTimeout(() => {
                this.$router.push({ path: '/player/dashboard' });
            }, 5000);
        }
    },

    mounted() {
        handleDocumentHeight();
        // call activity record api
        sessionActivitySave({'activity': global_config.ACTIVITIES.UI_BUY}).then((response) => {
            console.log("ACTIVITY API CALLED : ", response);
        });
        document.getElementById('offers-list').onscroll = () => {
            let bottomOfWindow = document.getElementById('offers-list').scrollTop + document.getElementById('offers-list').offsetHeight === document.getElementById('offers-list').scrollHeight;
            if (bottomOfWindow && !this.api_state && this.page <= this.last_page) {
                let user_data = JSON.parse(localStorage.user_sess_data);
                let pod_data = JSON.parse(localStorage.pod_sess_data);
                let params = {
                    session_id_key : pod_data.session_id_key,
                    token: user_data.token,
                    user_id: user_data.user_id,
                    page: this.page
                };
                this.api_state = true;
                getOffersList(params).then(response => {
                    this.api_state = false;
                    this.page = response.data.current_page + 1;
                    this.last_page = response.data.last_page;
                    this.offers = this.offers.concat(response.data.offers);
                })
            }
        };
    },
    created() {
        this.offersList();
    }
};
</script>
<style scoped>
.main-layout-section .main-content .offers-section-container {
    min-height: 87vh;
}
.w-footer-block-inner {
    padding-top: 0px;
}
body::-webkit-scrollbar {
  display: none;
}
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>