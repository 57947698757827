const axios = require('axios');
import { cloud_api_routes } from '../routes/cloud_api_routes.js';
import { checkIfNotTokenIssue } from "../services/Guard";
let cloudAPI = process.env.VUE_APP_CLOUD_API;

export async function facRunCommand(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.FAC_RUN_COMMAND}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function getFacPodsList(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.FAC_PODS_LIST}`, requestOptions)
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}

export async function facGetPalletsList(params) {
    return await axios.get(`${cloudAPI}${cloud_api_routes.FAC_RGB_PALLETS}`, { params })
    .then(response => checkIfNotTokenIssue(response.data))
    .catch(error => {
        return error.response;
    });
}