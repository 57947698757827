<template>
  <div class="main-layout-section admin-portal" :class="[user_data.role_id == 4 ? '' : 'secondary']">
        <AdminHeader @setPodData="setPodData($event)" v-if="user_data.role_id == 3" />
        <SuperAdminHeader v-if="user_data.role_id == 4" />
        
        <div class="w-logo mb-3">
            <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="logo_file !== '' && logo_file !== null && user_data.role_id !== 4"/>
            <img src="../../assets/images/gamelab-logo.svg" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="user_data.role_id == 4"/>
        </div>

        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeInUp animate__delay-1s">Create User</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">
                    <div class="admin-general-section-buttons">
                        <router-link to="/common/users" class="admin-general-section-button">
                            Back To Users Management
                        </router-link>
                    </div>
                </div>

                <div class="row m-0 p-0 col-md-12 col-sm-12 m-auto">
                    
                    <b-form inline @submit.stop.prevent class="m-0 p-0">
                        <!-- client and labe name field -->
                        
                        <div class="row mt-3">
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Name</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100"  :class="{ 'is_invalid': $v.form.name.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-input
                                        v-model="form.name"
                                        id="name"
                                        class=""
                                        :class="{ 'is-invalid': $v.form.name.$error }"
                                        placeholder="name"
                                    ></b-form-input>
                                </div>
                            </div>
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Email</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100"  :class="{ 'is_invalid': $v.form.email.$error }">
                                    <span><i class="fa-solid fa-user"></i></span>
                                    <b-form-input
                                        v-model="form.email"
                                        id="email"
                                        class=""
                                        :class="{ 'is-invalid': $v.form.email.$error }"
                                        placeholder="email@domain.com"
                                    ></b-form-input>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Mobile Number</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.phone.$error }">
                                    <span><i class="fa-solid fa-phone"></i></span>
                                    <b-form-input
                                        v-model="form.phone"
                                        id="dob"
                                        v-mask="phone_mask" 
                                        class="mb-2 mr-sm-2 mb-sm-0 user-name bg-lightBlack border-0 h-53"
                                        :class="{ 'is-invalid': $v.form.phone.$error }"
                                        placeholder="555-555-5555"
                                    ></b-form-input>
                                </div>
                            </div>

                            <!-- phone field -->
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Date of Birth</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.dob.$error }">
                                    <span><i class="fa-solid fa-calendar"></i></span>
                                    <b-form-input
                                        v-model="form.dob"
                                        id="dob"
                                        v-mask="date_mask" 
                                        class="mb-2 mr-sm-2 mb-sm-0 user-name bg-lightBlack border-0 h-53"
                                        :class="{ 'is-invalid': $v.form.dob.$error }"
                                        placeholder="mm/dd/yyyy"
                                    ></b-form-input>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Password</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.password.$error }">
                                    <span><i class="fa-solid fa-lock"></i></span>
                                    <b-form-input
                                        type="password" 
                                        v-model="form.password"
                                        id="password" 
                                        class=""
                                        :class="{ 'is-invalid': $v.form.password.$error }"
                                        placeholder="s3cretp@ssword"
                                    ></b-form-input>
                                </div>
                            </div>

                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Password Again</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.password_again.$error }">
                                    <span><i class="fa-solid fa-lock"></i></span>
                                    <b-form-input
                                        type="password" 
                                        v-model="form.password_again"
                                        id="password_again" 
                                        class=""
                                        :class="{ 'is-invalid': $v.form.password_again.$error }"
                                        placeholder="s3cretp@ssword"
                                    ></b-form-input>
                                </div>
                            </div>

                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Select Role</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100"  :class="{ 'is_invalid': $v.form.user_role.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-select
                                        v-model="form.user_role"
                                        :options="roles"
                                        text-field="value"
                                        value-field="id"
                                        id="user_role"
                                        class=""
                                        :class="[ $v.form.user_role.$error ? 'is-invalid' : '', form.user_role == '' ? 'text-grey' : '' ]"
                                        placeholder="Select a role"
                                    ></b-form-select>
                                </div>
                            </div>
                            <div class="d-flex mb-1 col-md-6 flex-column" v-if="user_data.role_id == 4">
                                <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Select Client</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100"  :class="{ 'is_invalid': $v.form.client_id.$error }">
                                    <span><i class="fa-solid fa-user"></i></span>
                                    <b-form-select
                                        v-model="form.client_id"
                                        :options="clients"
                                        text-field="value"
                                        value-field="id"
                                        id="client_id"
                                        class=""
                                        :class="[ $v.form.client_id.$error ? 'is-invalid' : '', form.client_id == '' ? 'text-grey' : '' ]"
                                        placeholder="Select a client"
                                    ></b-form-select>
                                </div>
                            </div>
                        </div>

                        <div
                        class="
                        w-form-control
                        animate__animated animate__fadeInUp animate__delay-3s m-0 p-0
                        "
                        >
                            <a
                            href="javascript:void(0)"
                            @click="handleUserReq"
                            class="flex-fill"
                            >Create</a
                            >
                        </div>

                    </b-form>

                </div>

            </div>

        </div>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <AdminFooter type="inner" v-if="user_data.role_id == 3" />
            <SuperAdminFooter type="inner" v-if="user_data.role_id == 4" />
        </div>
        <!-- end of footer section -->

    </div>
</template>

<script>
import AdminHeader from "@/views/admin/AdminHeader.vue";
import { adminGetClientsList, adminSaveUSer, adminGetRolesList } from "../../services/AdminService";
import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import { required, minLength, sameAs, requiredIf } from 'vuelidate/lib/validators';
import AdminFooter from "@/views/admin/AdminFooter.vue";
import SuperAdminHeader from "@/views/superadmin/SuperAdminHeader.vue";
import SuperAdminFooter from "@/views/superadmin/SuperAdminFooter.vue";

export default {
    name: "NewUser-view",
    components: { AdminHeader, AdminFooter, SuperAdminHeader, SuperAdminFooter },
    data() {
        return {
            phone_mask: '###-###-####',
            date_mask: '##/##/####',
            user_data: null,
            clients:[],
            roles: [],
            form: {
                client_id:'',
                user_role:'',
                name:'',
                email:'',
                password: '',
                password_again: '',
                dob:'',
                phone: ''
            }
        };
    },

    validations: {
        form : {
            client_id:{
                required: requiredIf(function (params) {
                    params;
                    if(this.form.user_role == 3 && this.user_data.role_id == 4) {
                        return false;
                    }
                    else if(this.form.user_role == 4 && this.user_data.role_id == 4) {
                        return false;
                    }
                    return true;  
                })
            },
            user_role:{required},
            name:{
                required: requiredIf(function (params) {
                    params;
                    if((this.form.user_role == 4 && this.user_data.role_id == 4) 
                    || (this.form.user_role == 2 || this.form.user_role == 5)) {
                        return false;
                    }
                    return true;  
                })
            },
            email: {required},
            dob: {
                required: requiredIf(function (params) {
                    params;
                    if((this.form.user_role == 4 && this.user_data.role_id == 4) 
                    || (this.form.user_role == 2 || this.form.user_role == 5)) {
                        return false;
                    }
                    return true;  
                }), 
                minLength: minLength(10)
            },
            phone: {
                required: requiredIf(function (params) {
                    params;
                    if((this.form.user_role == 4 && this.user_data.role_id == 4) 
                    || (this.form.user_role == 2 || this.form.user_role == 5)) {
                        return false;
                    }
                    return true;  
                }), 
                minLength: minLength(12)
            },
            password: {required, minLength: minLength(8)},
            password_again: {sameAsPassword: sameAs('password')},
        }
    },

    computed: {
        logo_file() {
            return this.$store.getters.getThemeLogo().logo;
        }
    },

    methods: {
        clientsList() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token
            };
            adminGetClientsList(params).then(response => {
                this.clients = response.data.clients;
                // add empty option as placeholder
                this.clients.splice(0, 0, {'id' : '', 'value' : 'Select a client'});
            });
        },

        rolesList() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token
            };
            adminGetRolesList(params).then(response => {
                this.roles = response.data.roles;
                // add empty option as placeholder
                this.roles.splice(0, 0, {'id' : '', 'value' : 'Select a role'});
            });
        },

        handleUserReq() {
            // fill in client id if user is not superadmin
            if(this.user_data.role_id !== 4) {
                this.form.client_id = this.user_data.client_id;
            }
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.makeToast("error", "Please fill all required fields");
            } 
            else {
                let user_data = JSON.parse(localStorage.user_sess_data);
                let data = this.form;
                data.token = user_data.token;
                adminSaveUSer(data).then(response => {
                    if(response.status){
                        this.makeToast("success", response.message);
                        this.resetForm();
                        this.$v.$reset();
                    } 
                    else {
                        this.makeToast("error", response.message);
                    }
                }); 
            }
        },

        resetForm() {
            var self = this;
            //Iterate through each object field, key is name of the object field`
            Object.keys(this.form).forEach(function(key) {
                self.form[key] = '';
            });
        },

        makeToast(variant = "default", message) {
            this.$root.$emit("toast_message", {'type' : variant, 'message' : message});
        },
        setPodData(obj) {
            this[obj.key] = obj.value;
        },
        setUserData() {
            this.user_data = JSON.parse(localStorage.user_sess_data);
            if(!Object.prototype.hasOwnProperty.call(this.user_data, 'role_id')) {
                this.$router.push("/admin/login");
            }
        }
    },

    mounted() {
        this.clientsList();
        this.rolesList();
    },
    created() {
        this.setUserData();
    }

};
</script>