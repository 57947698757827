<template>
    <div class="main-layout-section admin-portal">
        <SuperAdminHeader />
          
        <div class="w-logo mb-3">
            <img src="../../assets/images/gamelab-logo.svg" alt="logo" class="w-input-fields animate__animated animate__fadeIn animate__fast" />
        </div>
        
        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeIn animate__fast">Deploy New Lab</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">
                    <div class="admin-general-section-buttons">
                        <router-link to="/superadmin/dashboard" class="admin-general-section-button">
                            Back To Dashboard
                        </router-link>
                    </div>
                </div>

                <div class="row m-0 p-0 col-md-12 col-sm-12 m-auto">
                    
                    <b-form inline @submit.stop.prevent class="m-0 p-0">
                        <!-- client and labe name field -->
                        
                        <div class="row mt-3">
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Select Client</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100"  :class="{ 'is_invalid': $v.form.client_id.$error }">
                                    <span><i class="fa-solid fa-user"></i></span>
                                    <b-form-select
                                        v-model="form.client_id"
                                        :options="clients"
                                        text-field="value"
                                        value-field="id"
                                        id="client_id"
                                        class=""
                                        :class="[ $v.form.client_id.$error ? 'is-invalid' : '', form.client_id == '' ? 'text-grey' : '' ]"
                                        placeholder="Select a client"
                                    ></b-form-select>
                                </div>
                            </div>
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Lab Name</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100"  :class="{ 'is_invalid': $v.form.lab_name.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-input
                                        v-model="form.lab_name"
                                        id="lab_name"
                                        class=""
                                        :class="{ 'is-invalid': $v.form.lab_name.$error }"
                                        placeholder="Lab name"
                                    ></b-form-input>
                                </div>
                            </div>
                            
                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Address 1</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100"  :class="{ 'is_invalid': $v.form.address1.$error }">
                                    <b-form-textarea
                                    v-model="form.address1"
                                    id="address1"
                                    class=""
                                    :class="{ 'is-invalid': $v.form.address1.$error }"
                                    placeholder="Address 1"
                                    no-resize
                                    ></b-form-textarea>
                                </div>
                            </div>

                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Address 2</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.address2.$error }">
                                    <b-form-textarea
                                        v-model="form.address2"
                                        id="address2"
                                        class=""
                                        :class="{ 'is-invalid': $v.form.address2.$error }"
                                        placeholder="Address 2"
                                        no-resize
                                    ></b-form-textarea>
                                </div>
                                
                            </div>
                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">City</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.city.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-input
                                        v-model="form.city"
                                        type="text"
                                        placeholder="Enter City"
                                        id="city"
                                        aria-describedby="city-help-block"
                                        class=""
                                        :class="{ 'is-invalid': $v.form.city.$error }"
                                    ></b-form-input>
                                </div>
                            </div>

                            <!-- phone field -->
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">State</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.state.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-input
                                        v-model="form.state"
                                        id="state"
                                        class="mb-2 mr-sm-2 mb-sm-0 user-name bg-lightBlack border-0 h-53"
                                        :class="{ 'is-invalid': $v.form.state.$error }"
                                        placeholder="Enter state"
                                    ></b-form-input>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-6 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Zip</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.zip.$error }">
                                    <span><i class="fa-solid fa-map-pin"></i></span>
                                    <b-form-input
                                        v-model="form.zip"
                                        id="zip" 
                                        class=""
                                        :class="{ 'is-invalid': $v.form.zip.$error }"
                                        placeholder="Enter zip"
                                    ></b-form-input>
                                </div>
                            </div>

                            <div class="d-flex mb-1 col-md-2  flex-column animate__animated animate__fadeIn animate__fast" :class="{ 'is_invalid': $v.form.is_active.$error }">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">&nbsp;</label>
                                <switches 
                                class="active-switch h-53" 
                                v-model="form.is_active"
                                theme="bootstrap" 
                                color="primary"
                                type-bold="true"
                                label="Active"
                                >
                                </switches>
                            </div>
                            <div class="d-flex mb-1 col-md-4 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Select Timezone</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" >
                                    <span><i class="fa-solid fa-user"></i></span>
                                    <b-form-select
                                        v-model="form.timezone"
                                        :options="timezones"
                                        text-field="value"
                                        value-field="id"
                                        id="timezone_id"
                                        class=""
                                        :class="[ $v.form.timezone.$error ? 'is-invalid' : '', form.timezone == '' ? 'text-grey' : '' ]"
                                        placeholder="Select a timezone"
                                    ></b-form-select>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-12 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Note</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.note.$error }">
                                    <b-form-textarea
                                        v-model="form.note"
                                        id="note"
                                        class=""
                                        :class="{ 'is-invalid': $v.form.note.$error }"
                                        placeholder="Note"
                                        no-resize
                                    ></b-form-textarea>
                                </div>
                            </div>
                        </div>

                        <div
                        class="
                        w-form-control
                        animate__animated animate__fadeIn animate__fast m-0 p-0
                        "
                        >
                            <a
                            href="javascript:void(0)"
                            @click="handleLabReq"
                            class="flex-fill"
                            >Deploy</a
                            >
                        </div>

                    </b-form>

                </div>

            </div>

        </div>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <SuperAdminFooter type="inner" />
        </div>
        <!-- end of footer section -->

    </div>
</template>

<script>
import SuperAdminHeader from "@/views/superadmin/SuperAdminHeader.vue";
import SuperAdminFooter from "@/views/superadmin/SuperAdminFooter.vue";
import { adminGetClientsList, adminSaveLab } from "../../services/AdminService";
import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import { required } from 'vuelidate/lib/validators';
import Switches from 'vue-switches';
import { global_config } from "@/config/config.js";

export default {
    name: "SuperAdminNewLab-view",
    components: { SuperAdminHeader, Switches, SuperAdminFooter },
    data() {
        return {
            emptyText:"No labs found at the moment!",
            emptyHtml:"No labs found at the moment!",
            fields: [{'key' : 'id', 'label' : 'ID'}, {'key' : 'value', 'label' : 'Name'}, {'key' : 'action', 'label' : 'Action'}],
            labs: [],
            clients:[{'id' : '', 'value' : 'Select a client'}, {'id' : 1, 'value' : 'Test Client'}],
            form: {
                client_id:'',
                lab_name:'',
                address1:'',
                address2:'',
                city:'',
                state:'',
                zip:'',
                note:'',
                is_active: 0,
                timezone: '',
            },
            timezones: global_config.TIMEZONES_LIST
        };
    },

    validations: {
        form : {
            client_id:{required},
            lab_name:{required},
            address1:{required},
            address2:{required},
            city:{required},
            state:{required},
            zip:{required},
            note:{required},
            is_active:{required},
            timezone:{required:false}
        }
    },

    methods: {
        clientsList() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token
            };
            adminGetClientsList(params).then(response => {
                this.clients = response.data.clients;
                // add empty option as placeholder
                this.clients.splice(0, 0, {'id' : '', 'value' : 'Select a client'});
            });
        },

        handleLabReq() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.makeToast("error", "generic-required-message");
            } else {
                let user_data = JSON.parse(localStorage.user_sess_data);
                let data = this.form;
                data.token = user_data.token;
                adminSaveLab(data).then(response => {
                    if(response.status){
                        this.makeToast("success", response.message);
                        this.resetForm();
                        this.$v.$reset();
                    } else {
                        this.makeToast("error", response.message);
                    }
                }); 
            }
        },

        resetForm() {
            var self = this;
            //Iterate through each object field, key is name of the object field`
            Object.keys(this.form).forEach(function(key) {
                self.form[key] = '';
            });
        },

        makeToast(variant = "default", message) {
            this.$root.$emit("toast_message", {'type' : variant, 'message' : message});
        },
        setPodData(obj) {
            this[obj.key] = obj.value;
        },
    },

    mounted() {
        this.clientsList();
    }
};
</script>