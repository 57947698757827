<template>
    <div>
        <MainKeyboard :main_element="current_component" />
        <!-- start of inactive section -->
        <div class="inactive-contenttt" v-if="remaining_time <= 5 && remaining_time >= 0">
            <div class="overlay"></div>
            <h2 class="page-heading animate__animated animate__fadeIn">Still there...?</h2>
            <h3 class="page-subheading mb-1 animate__animated animate__fadeIn">You will be logged out due to inactivity in...</h3>

            <!-- start timer section -->
            <div
                class="w-form-control timer animate__animated animate__fadeInUp timer_with_kb" 
            >
                <div class="timer-counter">{{ remaining_time }}</div>
            </div>
            <!-- end timer section -->
        </div>
        <!-- end of inactive section -->
        <div class="main-contenttt player-survey-page" :class="[(step1 && step2 && !step3) ? 'games-step col-md-11' : '']">
            <!-- start of rating form -->
            <template v-if="step1 && !step2 && !step3 && !step_inbetween">
                <h3 class="page-subheading mb-1 animate__animated animate__fadeIn animate__delay-1s">Before we log you out, please take<br>a few seconds to help us improve.</h3>

                <div class="mx-auto">
                    <div class="dashboard-buttons-container animate__animated animate__fadeIn animate__delay-2s w-100">
                        <div class="rating-form-item">
                            <div class="rating-form-item-inner">
                                <h3 class="rating-form-heading">Cleanliness</h3>
                                <stars-rating :settings="settings" v-model="form1.mark_cleanliness" :dynamic_id="'rating_clean'"></stars-rating>
                            </div>
                        </div>
                        <div class="rating-form-item">
                            <div class="rating-form-item-inner">
                                <h3 class="rating-form-heading">Game Selection</h3>
                                <stars-rating :settings="settings" v-model="form1.mark_game_selection" :dynamic_id="'rating_game'"></stars-rating>
                            </div>
                        </div>
                        <div class="rating-form-item">
                            <div class="rating-form-item-inner">
                                <h3 class="rating-form-heading">Network Speed</h3>
                                <stars-rating :settings="settings" v-model="form1.mark_network_speed" :dynamic_id="'rating_network'"></stars-rating>
                            </div>
                        </div>
                        <div class="rating-form-item">
                            <div class="rating-form-item-inner">
                                <h3 class="rating-form-heading">Overall Experience</h3>
                                <stars-rating :settings="settings" v-model="form1.mark_overall_experience" :dynamic_id="'rating_overall'"></stars-rating>
                            </div>
                        </div>

                        <div class="dashboard-buttons animate__animated animate__fadeInUp mt-4">
                            <button class="dashboard-button flex-fill" @click="handleFirstStep">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>

            </template>
            <!-- end of rating form -->

            <!-- start of survey group and count section -->
            <template  v-if="step1 && step_inbetween && !step2 && !step3">
                <template>
                    <h3 class="step3-selection page-subheading mt-4 mb-3 animate__animated animate__fadeIn">Did you play with anyone in person today?</h3>
                    <div class="dashboard-buttons-container w-100">
                        <div class="dashboard-buttons animate__animated animate__fadeIn mt-2 survey-count-buttons">
                            <button class="dashboard-button flex-fill" :class="{'survey-count-button-filled':show_inbetween_step}" @click="showInbetweenStep('yes')">
                                Yes
                            </button>
                            <button class="dashboard-button flex-fill" @click="showInbetweenStep('now')">
                                No
                            </button>
                        </div>
                    </div>
                </template>
                <template v-if="show_inbetween_step">
                    <h3 class="step3-selection page-subheading mt-3 mb-3 animate__animated animate__fadeIn">How many other people?</h3>
                    <div class="mx-auto">
                        <div class="row mt-2 mb-3">
                            <div class="d-flex mb-1 flex-column">
                                <b-input-group class="d-flex text-center align-items-center survey-count-section">
                                    <b-input-group-prepend class="survey-count-icons" :class="{ 'is_disabled': form4.survey_count <= 1 }" @click="plusMinusSurveyCount('minus')">
                                        <span class="fa fa-minus"></span>
                                    </b-input-group-prepend>
                                    <b-form-input 
                                    type="text" 
                                    class="count-input" 
                                    readOnly="readOnly" 
                                    tabindex="-1" 
                                    v-model="form4.survey_count"
                                    >
                                    </b-form-input>
                                    <b-input-group-append class="survey-count-icons" @click="plusMinusSurveyCount('plus')" :class="{ 'is_disabled': form4.survey_count >= 9 }">
                                        <span class="fa fa-plus"></span>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                        </div>

                        <div class="dashboard-buttons-container w-100">
                            <div class="dashboard-buttons animate__animated animate__fadeIn mt-2">
                                <button class="dashboard-button flex-fill" @click="handleStepBetween">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </template>

            </template>
            <!-- end of survey group and count section -->

            <!-- start of games section -->
            <template v-if="step1 && step_inbetween && step2 && !step3">
                <h3 class="page-subheading mb-1 animate__animated animate__fadeIn animate__delay-1s">Which upcoming games are you<br>most interested in playing?</h3>
                <div class="games-section-container">
                    <div class="games-section-list extras"></div>
                    <div class="games-section-list">
                        <div class="games-section-list-data mt-0">

                            <div class="games-section-list-data-title m-0">
                                <h3 class="m-0 mb-2">{{ latest_selected_game !== null ? latest_selected_game.GameName : '' }}</h3>
                            </div>

                            <div class="games-section-list-data-list" id="games-list">
                                <div class="games-section-list-data-list-inner" id="games-listtt">
                                    <div class="games-section-list-data-list-item animate__animated animate__fadeIn animate__fast" tabindex="0" v-for="game in games" :key="game.GameId">
                                        <img 
                                        :src="`${game.ImageURL}`" 
                                        alt="" @click="selectGame(game)" 
                                        :class="[form2.upcoming_games.indexOf(game.GameId) !== -1 ? 'selected-game' : '']">
                                        <i 
                                        class="fas fa-circle-check fa-2xl icon" 
                                        v-if="form2.upcoming_games.indexOf(game.GameId) !== -1" 
                                        >
                                        </i>
                                    </div>
                                </div>
                            </div>

                            <div class="games-section-list-data-play">
                                <div class="games-section-list-data-play-buttons">
                                    <button 
                                    class="btn" 
                                    @click="handleSecondStep"
                                    >
                                    Submit
                                    </button>    
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="games-section-list extras"></div>
                </div>
            </template>
            <!-- end of games section -->

            <!-- start of comments section -->
            <template  v-if="step1 && step_inbetween && step2 && step3 && with_keyboard == 1">
                <template v-if="!step3_needed">
                    <h3 class="step3-selection page-subheading mb-5 animate__animated animate__fadeIn">Anything our team should know about?<br>(e.g. issues, ideas, game requests)</h3>
                    <div class="dashboard-buttons-container w-100">
                        <div class="dashboard-buttons animate__animated animate__fadeIn mt-2">
                            <button class="dashboard-button flex-fill" @click="showThirdStep">
                                Yes
                            </button>
                            <button class="dashboard-button flex-fill" @click="handleThirdStep">
                                No
                            </button>
                        </div>
                    </div>
                </template>
                <template v-if="step3_needed">
                    <h3 class="page-subheading mb-1 animate__animated animate__fadeIn">Provide us some details!<br>We read every survey.</h3>
                    <!-- <p class="comments-subheader animate__animated animate__fadeIn">Leave Blank or Type a Comment</p> -->

                    <div class="mx-auto">
                        <div class="row mt-4">
                            <div class="d-flex mb-1 flex-column">
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn w-100">
                                    <b-form-textarea
                                    v-model="form3.comment"
                                    id="address1" 
                                    ref="step3commentfield"
                                    class=""
                                    placeholder=""
                                    no-resize 
                                    @focus="setFocusedElement($event, 'comment', 'form3')"
                                    ></b-form-textarea>
                                </div>
                            </div>
                        </div>

                        <div class="dashboard-buttons-container w-100">
                            <div class="dashboard-buttons animate__animated animate__fadeIn mt-2">
                                <button class="dashboard-button flex-fill" @click="handleThirdStep">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </template>

            </template>
            <!-- end of comments section -->

            <!-- start of notification block -->
            <template>
                <div class="mx-auto animate__animated animate__fadeIn mt-3 move-to-header with-top" v-if="login_class !== '' && login_message !== ''">
                    <div class="notification-block" :class="login_class">
                        <div class="notification-message">
                        <h5>{{ login_message }}</h5>
                        <p v-if="login_message1 !== ''">{{ login_message1 }}</p>
                        </div>
                        <div class="icon-success" v-if="login_class == 'success'">
                        <i class="fa-solid fa-circle-check"></i>
                        </div>
                        <div class="icon-error" v-if="login_class == 'error'">
                        <i class="fa-solid fa-times-circle"></i>
                        </div>
                        <div class="icon-warning" v-if="login_class == 'warning'">
                        <i class="fa-solid fa-exclamation-circle"></i>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
  
<script>
import { handleDocumentHeight } from "../services/Guard";
import StarsRating from "stars-rating-component-vue";
import { getSurveyGamesList, saveSurvey } from "../services/PlayerService";
import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import { required, requiredIf } from 'vuelidate/lib/validators';

export default {
    name: "ExitSurveyWidget-view",
    components: { StarsRating },
    data() {
        return {
            settings: {
                starsQuantity: 5,
                stroke: 'var(--client-primary)',
                strokeWidth: 5,
                fill: 'transparent',
                highlighted: 'var(--client-primary)',
                hover: 'var(--client-primary)',
                size: 45,
                readOnly: false,
                rating: 0,
                marginRight: 0,
                d: "M50 0 l-15 35 -35 5 25 24 -6 35 31 -18 31 18 -6 -35 25 -24 -35 -5 -15 -35 z",
                viewBox: "0 0 100 100"
            },
            games: [],
            step1: true,
            step2: false,
            step3: false,
            with_keyboard: this.session_data.with_keyboard,
            form1: {
                mark_cleanliness: '',
                mark_network_speed: '',
                mark_game_selection: '',
                mark_overall_experience: ''
            },
            form2: {
                upcoming_games: []
            },
            form3: {
                comment: ''
            },
            sess_data: this.session_data,
            survey_polling: null,
            login_class: '',
            login_message: '',
            login_message1: '',
            last_survey: -1,
            survey_id: '',
            remaining_time: -1,
            latest_selected_game_id: '',
            step3_needed: false,
            step_inbetween: false,
            show_inbetween_step: false,
            form4: {
                survey_group: 0,
                survey_count: 1
            },
            current_component: this,
        };
    },
    validations: {
        form1 : {
            mark_cleanliness: {required},
            mark_network_speed: {required},
            mark_game_selection: {required},
            mark_overall_experience: {required}
        },
        form2 : {
            upcoming_games: {required:false}
        },
        form3 : {
            comment: {required:false}
        },
        form4: {
            survey_group: {required: false},
            survey_count: {required: requiredIf('survey_group')}
        }
    },
    props: ['session_data'],
    computed: {
        inactive_counter() {
            return this.$store.getters.getInactiveCounter();
        },
        latest_selected_game() {
            let game = null;
            if(this.latest_selected_game_id != '') {
                this.games.forEach((item) => {
                    if(item.GameId == this.latest_selected_game_id) {
                        game = item;
                    }
                });
            }
            return game;
        }
    },
    watch: {
        inactive_counter(new_val) {
            let difference = process.env.VUE_APP_EXIT_SURVEY_INACTIVITY_DURATION - new_val;
            if(new_val != -1 && difference <= 5000) {
                this.setInactiveCounter(Math.ceil(difference/1000));
            }
            else {
                this.setInactiveCounter(60);
            }
        }
    },
    methods: {
        gamesList() {
            let params = {
                session_id_key : this.sess_data.session_id_key,
            };
            getSurveyGamesList(params).then(response => {
                this.games = response.data.games;
                this.last_survey = response.data.last_survey;
            });
        },
        selectGame(game) {
            if(this.form2.upcoming_games.indexOf(game.GameId) !== -1) {
                this.form2.upcoming_games.splice(this.form2.upcoming_games.indexOf(game.GameId), 1);
            }
            else {
                this.form2.upcoming_games.push(game.GameId);
            }

            // check for latest item in selected games
            if(this.form2.upcoming_games.length > 0) {
                this.latest_selected_game_id = this.form2.upcoming_games[this.form2.upcoming_games.length - 1];
            }
            else {
                this.latest_selected_game_id = '';
            }
        },
        handleFirstStep() {
            this.$v.form1.$touch();

            if (this.$v.form1.$invalid) {
                this.makeToast('generic-required-message', 'error');
            }
            else {
                this.processSurveySave(this.form1, 1, false);
            }
        },
        handleStepBetween() {
            this.$v.form4.$touch();

            if (this.$v.form4.$invalid) {
                this.makeToast('generic-required-message', 'error');
            }
            else {
                if(this.last_survey != -1 && this.last_survey <= process.env.VUE_APP_SURVEY_DAYS) {
                    if(this.with_keyboard == 1) {
                        this.processSurveySave(this.form4, 'between', false);
                        this.step2 = true;
                        this.step3 = true;
                    }
                    else {
                        this.processSurveySave(this.form4, 'between', true);
                    }
                }
                else {
                    if(this.games.length <= 0) {
                        this.processSurveySave(this.form4, 'between', this.with_keyboard == 1 ? false : true);
                        this.step2 = true;
                        this.step3 = true;
                    }
                    else {
                        this.step2 = true;
                        this.processSurveySave(this.form4, 'between', false);
                    }
                }
            }
        },
        handleSecondStep() {
            if(this.with_keyboard == 1) {
                this.processSurveySave(this.form2, 2, false);
            }
            else {
                this.processSurveySave(this.form2, 2, true);
            }
        },
        handleThirdStep() {
            this.processSurveySave(this.form3, 3, true);
        },
        processSurveySave(form, step, is_last_page=false) {
            let session_id = this.session_data.session_id_key;
            let data = form;
            data.session_id_key = session_id;
            data.id = this.survey_id;
            data.step = step;
            saveSurvey(data).then(response => {
                if(response.status) {
                    if(is_last_page) {
                        this.$emit('surveyComplete', true);
                    }
                    else {
                        if(step == 'between') {
                            console.log('');
                        }
                        else if(step == 1) {
                            this.step_inbetween = true;
                        }
                        else if(step < 3) {
                            this['step'+(step+1)] = true;
                        }
                        this.survey_id = response.data.id;
                    }
                }
                else {
                    this.makeToast(response.message, 'error', response.message);
                }
            }); 
        },
        surveryPolling() {
            // let duration = process.env.VUE_APP_SURVEY_DURATION;
            // clearInterval(this.survey_polling);
            // this.survey_polling = setInterval(() => {
            //     duration -= process.env.VUE_APP_POLLING_SURVEY;
            //     if(duration <= 0) {
            //         this.$emit('surveyComplete', true);
            //     }
            // }, process.env.VUE_APP_POLLING_SURVEY);

            clearInterval(this.survey_polling);
            this.survey_polling = setInterval(() => {
                let last_activity = Math.floor((new Date()).getTime() - (new Date(localStorage.getItem('last_activity'))).getTime());
                this.$store.commit("setInactiveCounter", last_activity);
                if(last_activity >= process.env.VUE_APP_EXIT_SURVEY_INACTIVITY_DURATION) {
                    this.$emit('surveyComplete', true);
                    return;
                }
            }, process.env.VUE_APP_POLLING_INACTIVITY);
        },
        makeToast(message_key = '', variant = "default", message="", message1="") {
            // search for key
            let smessage = this.$store.state.system_messages.list.find(o => o.msg_key === message_key);
            if(smessage !== null && typeof smessage !== 'undefined' && Object.prototype.hasOwnProperty.call(smessage, 'text_head')) {
                this.login_class = smessage.type == 'failure' ? 'error' : smessage.type;
                this.login_message = smessage.text_head;
                this.login_message1 = smessage.text_sub;
            }
            else {
                this.login_class = variant;
                this.login_message = message;
                this.login_message1 = message1;
            }
            // hide error div
            setTimeout(() => {
                this.login_class = "";
                this.login_message = "";
                this.login_message1 = "";
            }, 5000);
        },
        setInactiveCounter(val) {
            this.remaining_time = val;
        },
        showThirdStep() {
            this.step3_needed = true;
            setTimeout(() => {
                this.$refs.step3commentfield.focus();
            }, 500);
        },
        showInbetweenStep(type='yes') {
            if(type == 'yes') {
                this.show_inbetween_step = true;
                this.form4.survey_group = 1;
            }
            else {
                this.show_inbetween_step = false;
                this.form4.survey_group = 0;
                this.handleStepBetween();
            }
        },
        plusMinusSurveyCount(type='plus') {
            if(type == 'plus' && this.form4.survey_count < 9) {
                this.form4.survey_count = this.form4.survey_count + 1;
            }
            else if(type == 'minus' && this.form4.survey_count > 1) {
                this.form4.survey_count = this.form4.survey_count - 1;
            }
        },
        setFocusedElement(event, ref, form) {
            this.$store.commit("setFocusedElement", ref);
            this.$store.commit("setFocusedElementForm", {'form': form, 'target' : event});
        }
    },

    mounted() {
        handleDocumentHeight();
        this.surveryPolling();
        this.gamesList();
        // listen for websocket event
        if(
            Object.prototype.hasOwnProperty.call(this.$root, "_events") && 
            Object.prototype.hasOwnProperty.call(this.$root._events, "nav_logout")
        ) {
            this.$root.$off("nav_logout");
        }
        this.$root.$on('nav_logout', (message) => {
            message;
            this.$emit('surveyComplete', true);
        });
    },
    beforeDestroy () {
        clearInterval(this.survey_polling);
    },
    created () {
    }
};
</script>
<style scoped>
.games-section-container .games-section-list .games-section-list-data .games-section-list-data-list {
    height: 80vh;
}
.main-layout-section .main-content .games-section-container {
    /* max-width: 425px; */
    margin: auto;
    min-height: unset;
}
.main-layout-section .main-content .games-section-container .games-section-list {
    flex: calc(100%/3 - 20px);
    padding: 0px 10px;
}
.main-layout-section .main-content .games-section-container .games-section-list .games-section-list-data .games-section-list-data-list {
    height: 42vh;
}
.main-layout-section .main-content .games-section-container .games-section-list .games-section-list-data .games-section-list-data-list .games-section-list-data-list-item {
    position: relative;
}
.main-layout-section .main-content .games-section-container .games-section-list .games-section-list-data .games-section-list-data-list .games-section-list-data-list-item img.selected-game {
    border: 4px solid var(--client-primary);
}
.main-layout-section .main-content .games-section-container .games-section-list .games-section-list-data .games-section-list-data-list .games-section-list-data-list-item .icon {
    position: absolute;
    right: 6px;
    bottom: 21px;
    color: var(--client-primary);
}
textarea.form-control {
    height: 250px;
    background: #d8d8d8;
}
.main-contenttt:not(.games-step) {
    max-width: 320px;
}
.main-contenttt {
    margin: auto;
}
.inactive-contenttt {
    width: 100%;
    height: 100vh;
    z-index: 1;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background: transparent;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
div.timer {
    position: relative;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    /* overflow: hidden; */
    color: #d8d8d8;
    border-radius: 50%;
    font-weight: 700;
    font-family: 'Open Sans';
    animation: 60s 0.875s cubic-bezier(0.9, 0, 0.1);
}

div.timer_with_kb {
    width: 150px;
    height: 150px;
    font-size: 70px;
    border: 2px solid var(--client-primary);
    margin-top: 25px;
}

@keyframes timer_beat {
    40%,
    80% {
        transform: none; }
    50% {
        transform: scale(1.125);
    }
}
div.timer:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -100;
    width: 100%;
    height: 100%;
    animation: 60s 1s linear forwards timer_indicator;
}

@keyframes timer_indicator {
    100% {
        transform: translateY(100%);
    }
}
.inac-contenttt .overlay {
    position: absolute;
    background: black;
    width: 100%;
    left: 0;
    height: 100%;
    opacity: 0.65;
}
.comments-subheader {
    color: #d8d8d8;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: -15px;
}
.main-layout-section .main-content .games-section-container .games-section-list .games-section-list-data .games-section-list-data-play {
    position: relative;
    z-index: 10;
    margin-top: 10px;
}
@media screen and (max-width: 768px) {
    .main-layout-section .games-section-container {
        padding-top: 10px;
    }
    .main-layout-section .games-section-container .games-section-list:is(.extras) {
      display: none;
    }
}
.main-contenttt.player-survey-page:has(.step3-selection) {
    max-width: max-content;
}
.survey-count-section .survey-count-icons {
    padding: 10px 15px;
    background: #d8d8d8;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: inherit;
    font-weight: 700;
    cursor: pointer;
}
.survey-count-section .survey-count-icons.is_disabled {
    opacity: 0.8;
}
.survey-count-icons:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.survey-count-icons:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.survey-count-section .count-input {
    height: 50px;
    background: #d8d8d8;
    text-align: center;
}
.survey-count-button-filled {
    background: var(--client-primary) !important;
    color: var(--client-hover) !important;
}
</style>