<template>
    <div class="main-content">
        <div class="w-logo mb-5 pt-5">
            <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeIn animate__delay-1s" v-if="logo_file !== '' && logo_file !== null"/>
        </div>
        <!-- start of bttons section -->
        <template>
            <h2 class="page-heading mb-1 animate__animated animate__fadeIn animate__delay-1s">How Many Players?</h2>
            <h3 class="page-subheading mb-1 animate__animated animate__fadeIn animate__delay-1s">This Gamepod is setup for group play.</h3>

            <!-- start of buttons section -->
            <div class="col-lg-6 col-md-8 mx-auto">
                <div class="dashboard-buttons-container mt-4 col-md-6">
                    <div class="dashboard-buttons animate__animated animate__fadeIn animate__delay-1s">
                        <button class="dashboard-button pdash-flex" v-for="(pb, index) in player_icons" :key="index" @click="handlePlayerSelection(pb)" :class="[Object.prototype.hasOwnProperty.call(selected_players, 'label') && pb.label == selected_players.label ? 'selected-player' : '']">
                            <object type="image/svg+xml" class="icon-button" :data="pb.icon" tabindex="-1"></object>
                            {{ pb.label }}
                        </button>
                    </div>

                    <div class="dashboard-buttons animate__animated animate__fadeInUp animate__delay-1s mt-3">
                        <button class="dashboard-button" v-if="Object.prototype.hasOwnProperty.call(selected_players, 'label')" @click="confirmGuestPlay">
                            Continue with {{ selected_players.label }}
                        </button>
                    </div>

                    <div class="mx-auto animate__animated animate__fadeIn mt-3" v-if="login_class !== '' && login_message !== ''">
                        <div class="notification-block" :class="login_class">
                            <div class="notification-message w-90">
                            <h5 class="ellipsis">{{ login_message }}</h5>
                            <p v-if="login_message1 !== ''" class="ellipsis">{{ login_message1 }}</p>
                            </div>
                            <div class="icon-success" v-if="login_class == 'success'">
                            <i class="fa-solid fa-circle-check"></i>
                            </div>
                            <div class="icon-error" v-if="login_class == 'error'">
                            <i class="fa-solid fa-times-circle"></i>
                            </div>
                            <div class="icon-warning" v-if="login_class == 'warning'">
                            <i class="fa-solid fa-exclamation-circle"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end of buttons section -->

        </template>
        <!-- end of comments section -->
    </div>
</template>
  
<script>
import p1_icon from "../assets/images/players-1.svg";
import p2_icon from "../assets/images/players-2.svg";
import p3_icon from "../assets/images/players-3.svg";
import p4_icon from "../assets/images/players-4.svg";
import { handleDocumentHeight } from "../services/Guard";
export default {
    name: "ExitSurveyWidget-view",
    components: { },
    data() {
        return {
            login_class: '',
            login_message: '',
            login_message1: '',
            player_icons: {
                "p1" : {label: '1 Player', count: 1, icon: p1_icon, message: 'guests_message'},
                "p2" : {label: '2 Players', count: 2, icon: p2_icon, message: ''},
                "p3" : {label: '3 Players', count: 3, icon: p3_icon, message: 'controllers_message'},
                "p4" : {label: '4 Players', count: 4, icon: p4_icon, message: 'controllers_message'},
            },
            selected_players: {},
            guest_game_data: this.game_data, 
        };
    },
    props: ['game_data'],
    computed: {
        logo_file() {
            return this.$store.getters.getThemeLogo().logo;
        },
        guests_message() {
            return this.$store.getters.getMessageDetail('playgame-guests-group-message');
        },
        controllers_message() {
            return this.$store.getters.getMessageDetail('playgame-guests-controllers-message');
        }
    },
    methods: {
        makeToast(message_key = '', variant = "default", message="", message1="") {
            // search for key
            let smessage = this.$store.state.system_messages.list.find(o => o.msg_key === message_key);
            if(smessage !== null && typeof smessage !== 'undefined' && Object.prototype.hasOwnProperty.call(smessage, 'text_head')) {
                this.login_class = smessage.type == 'failure' ? 'error' : smessage.type;
                this.login_message = smessage.text_head;
                this.login_message1 = smessage.text_sub;
            }
            else {
                this.login_class = variant;
                this.login_message = message;
                this.login_message1 = message1;
            }
            // hide error div
            setTimeout(() => {
                this.login_class = "";
                this.login_message = "";
                this.login_message1 = "";
            }, 5000);
        },
        handlePlayerSelection(selection) {
            this.selected_players = selection;
            this.guest_game_data.guests = selection.count;
            if(selection.message !== '') {
                this.login_class = this[selection.message].type;
                this.login_message = this[selection.message].text_head;
                this.login_message1 = this[selection.message].text_sub;
            }
            else {
                this.login_class = "";
                this.login_message = "";
                this.login_message1 = "";
            }
        },
        confirmGuestPlay() {
            this.$emit('handleGuestGamePlay', this.guest_game_data);
        }
    },

    mounted() {
        handleDocumentHeight();
    },
    beforeDestroy () {
    },
    created () {
    }
};
</script>
<style scoped>
.main-layout-section .main-content {
    min-height: 86.5vh !important;
}
.main-layout-section .main-content .dashboard-buttons .dashboard-button.selected-player {
    border: 2px solid var(--client-primary);
    background: var(--client-primary);
}
@media screen and (max-width: 1024px) and (max-height: 600px) {
    .main-layout-section .main-content {
        min-height: 82.5vh !important;
    }
}
</style>