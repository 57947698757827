<template>
    <div>
  <div class="artboard" @keydown="handleKeydown" tabindex="0">

    <div class="keyboard">
      <div class="row">
        <div class="keys">
          <div class="kescape"><a>esc</a></div>
          <div class="kf1"><a>f1</a></div>
          <div class="kf2"><a>f2</a></div>
          <div class="kf3"><a>f3</a></div>
          <div class="kf4"><a>f4</a></div>
          <div class="kf5"><a>f5</a></div>
          <div class="kf6"><a>f6</a></div>
          <div class="kf7"><a>f7</a></div>
          <div class="kf8"><a>f8</a></div>
          <div class="kf9"><a>f9</a></div>
          <div class="kf10"><a>f10</a></div>
          <div class="kf11"><a>f11</a></div>
          <div class="kf12"><a>f12</a></div>
          <div class="kbacktick"><a>`</a></div>
          <div class="k1"><a>1</a></div>
          <div class="k2"><a>2</a></div>
          <div class="k3"><a>3</a></div>
          <div class="k4"><a>4</a></div>
          <div class="k5"><a>5</a></div>
          <div class="k6"><a>6</a></div>
          <div class="k7"><a>7</a></div>
          <div class="k8"><a>8</a></div>
          <div class="k9"><a>9</a></div>
          <div class="k0"><a>0</a></div>
          <div class="kminus"><a>-</a></div>
          <div class="kequals"><a>=</a></div>
          <div class="kbackspace"><a>del</a></div>
          <div class="ktab"><a>tab</a></div>
          <div class="kq"><a>q</a></div>
          <div class="kw"><a>w</a></div>
          <div class="ke"><a>e</a></div>
          <div class="kr"><a>r</a></div>
          <div class="kt"><a>t</a></div>
          <div class="ky"><a>y</a></div>
          <div class="ku"><a>u</a></div>
          <div class="ki"><a>i</a></div>
          <div class="ko"><a>o</a></div>
          <div class="kp"><a>p</a></div>
          <div class="kbracketleft"><a>[</a></div>
          <div class="kbracketright"><a>]</a></div>
          <div class="kbackslash"><a>\</a></div>
          <div class="kcapslock"><a>caps</a></div>
          <div class="ka"><a>a</a></div>
          <div class="ks"><a>s</a></div>
          <div class="kd"><a>d</a></div>
          <div class="kf"><a>f</a></div>
          <div class="kg"><a>g</a></div>
          <div class="kh"><a>h</a></div>
          <div class="kj"><a>j</a></div>
          <div class="kk"><a>k</a></div>
          <div class="kl"><a>l</a></div>
          <div class="ksemicolon"><a>;</a></div>
          <div class="kquote"><a>'</a></div>
          <div class="kenter"><a>return</a></div>
          <div class="kshiftleft"><a>shift</a></div>
          <div class="kz"><a>z</a></div>
          <div class="kx"><a>x</a></div>
          <div class="kc"><a>c</a></div>
          <div class="kv"><a>v</a></div>
          <div class="kb"><a>b</a></div>
          <div class="kn"><a>n</a></div>
          <div class="km"><a>m</a></div>
          <div class="kcomma"><a>,</a></div>
          <div class="kperiod"><a>.</a></div>
          <div class="kslash"><a>/</a></div>
          <div class="kshiftright"><a>shift</a></div>
          <div class="kcontrolleft"><a>ctrl</a></div>
          <div class="kcontrolright"><a>ctrl</a></div>
          <div class="kaltleft"><a>option</a></div>
          <div class="kmetaleft"><a>cmd</a></div>
          <div class="kspace"><a>space</a></div>
          <div class="kmetaright"><a>cmd</a></div>
          <div class="kaltright"><a>option</a></div>
          <div class="karrowup"><a>up</a></div>
          <div class="karrowdown"><a>down</a></div>
          <div class="karrowleft"><a>left</a></div>
          <div class="karrowright"><a>right</a></div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "KeyboardTest",
mounted() {
    console.clear();
    const keys = this.$el.querySelectorAll(".keys");
    window.addEventListener("keydown", (e) => {
      e.preventDefault();
      let j = e.code.toLowerCase();
      let code = keys[0].getElementsByClassName("k" + j)[0];
      let key = keys[0].getElementsByClassName("k" + e.key.toLowerCase())[0];

            // Handle the '=' key explicitly
      if (e.code === "Equal" || e.key === "=") {
        code = keys[0].getElementsByClassName("kequals")[0];
      }

          // Handle the backtick key explicitly
      if (e.code === "Backquote" || e.key === "`") {
        code = keys[0].getElementsByClassName("kbacktick")[0];
      }


      if (code) {
        code.classList.add("pressed");
      } else if (key) {
        key.classList.add("pressed");
      }
    });
  },
};
</script>

<style>
:root {
  --base: #f4f9f9;
  --primary: #f4f9f9;
  --accent: #aaaaaa;
  --secondary: #a4ebf3;
}

* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6, a, p, span {
  /* margin: 10px 10px 0 0; */
  /* padding-bottom: 0.714em !important; */
  /* padding-top: 0.714em !important; */
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: bold;
  color: var(--accent);
  text-align: center;
}

h4 {
  color: var(--secondary);
  text-transform: capitalize;
}

body {
  background: var(--base);
  font-family: 'Raleway', sans-serif;
  text-align: center;
}

.noselect {
  user-select: none;
}

@keyframes beat {
  to {
    transform: scale(1.4);
  }
}

/* .artboard {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
} */

.keyboard .row {
  display: block;
  padding: 10px;
  min-width: 1150px;
  max-width: 1200px;
}

.keyboard .keys > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  display: inline-flex;
  width: auto;
  height: auto;
  max-height: 65px;
  min-width: 65px;
  margin: 5px 5px;
  border: var(--primary) solid 5px;
  text-align: center;
}

.keyboard .keys > div.kspace {
  min-width: 300px;
}

.keyboard .keys > div.kshiftleft,
.keyboard .keys > div.kshiftright {
  min-width: 150px;
}

.keyboard .keys > div.kbackspace,
.keyboard .keys > div.ktab {
  min-width: 100px;
}

.keyboard .keys > div a {
  margin: 12px -4px;
  padding: 4px;
}

.keyboard .keys > div:nth-child(1),
.keyboard .keys > div:nth-child(2),
.keyboard .keys > div:nth-child(3),
.keyboard .keys > div:nth-child(4),
.keyboard .keys > div:nth-child(5),
.keyboard .keys > div:nth-child(6),
.keyboard .keys > div:nth-child(7),
.keyboard .keys > div:nth-child(8),
.keyboard .keys > div:nth-child(9),
.keyboard .keys > div:nth-child(10),
.keyboard .keys > div:nth-child(11),
.keyboard .keys > div:nth-child(12),
.keyboard .keys > div:nth-child(13),
.keyboard .keys > div:nth-child(14) {
  min-width: 69px;
  margin: 4px 5px;
}

.keyboard .pressed {
  background: var(--secondary);
  transition: 350ms ease-in-out;
}

.keyboard .pressed a {
  color: var(--base);
}
</style>
