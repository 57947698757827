<template>
    <div class="main-layout-section">
        <!-- load bootstrap min file for switch styles -->
        <link href="https://unpkg.com/bootstrap@4.5.2/dist/css/bootstrap.min.css" rel="stylesheet" />
        <FacHeader @setPodData="setPodData($event)" />

        <div class="w-logo mb-3">
            <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="logo_file !== '' && logo_file !== null"/>
        </div>
        
        <div class="main-content">
            <h2 class="page-heading mb-3">Manage Event</h2>

            <div class="col-md-11 mx-auto">

                <div class="facility-pods-section">
                
                    <div class="facility-pods-section-buttons">
                        <button
                        type="button"
                        class="facility-pods-section-button"
                        :disabled="!pod_selected"
                        @click="openButtonModals('audio-modal')"
                        >
                        Audio
                        </button>
                        <button
                        type="button"
                        class="facility-pods-section-button"
                        :disabled="!pod_selected"
                        @click="openButtonModals('theme-modal')"
                        >
                        Led Color
                        </button>
                        <button
                        type="button"
                        class="facility-pods-section-button"
                        :disabled="!pod_selected"
                        @click="openButtonModals('games-modal')"
                        >
                        Games
                        </button>
                        <button
                        type="button"
                        class="facility-pods-section-button"
                        :disabled="!pod_selected"
                        @click="openButtonModals('reset-modal')"
                        >
                        Reset
                        </button>

                        <router-link to="/facilitator/dashboard" class="facility-pods-section-button">
                            BACK TO DASHBOARD
                        </router-link>
                    </div>

                    <div class="facility-pods-section-pods" v-if="pods.length > 0">
                        <div class="facility-pods-section-pod" v-for="pod in pods" :key="pod.pod_id_key">
                            <div class="facility-pods-section-pod-container">
                                <div class="facility-pods-section-pod-data">
                                    <p><span>Nav ID :</span> {{ pod.nav_id }}</p>
                                </div>
                                <div class="facility-pods-section-pod-data">
                                    <p><span>POD ID :</span> {{ pod.pod_id_key }}</p>
                                </div>
                                <div class="facility-pods-section-pod-select-section d-flex">
                                    <p>Select</p>
                                    <div v-if="pod.lock_out == 0">
                                        <i 
                                        class="fas fa-circle-check fa-2xl icon h2 icon-checked" 
                                        @click="selectPod(pod)"
                                        v-if="pod_selected_id.indexOf(pod.pod_id_key) !== -1">
                                        </i>
                                        <i 
                                        class="fas fa-circle-check fa-2xl icon h2" 
                                        @click="selectPod(pod)"
                                        v-if="pod_selected_id.indexOf(pod.pod_id_key) == -1">
                                        </i>
                                    </div>
                                    <div v-if="pod.lock_out == 1">
                                        <b-icon 
                                            class="icon h2" 
                                            icon="lock-fill"
                                            >
                                        </b-icon>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="facility-pods-section-pods row m-0 p-0 col-md-8 col-sm-12 m-auto" v-if="pods.length <= 0">
                        <p class="text-left text-white h4 no-results">No pods found at the moment!</p>
                    </div>

                    <!-- start of audio modal -->
                    <b-modal id="audio-modal" header-class="bg-labPrimary text-white px-3 py-2" hide-footer>
                        <template #modal-title>
                        Update Audio Settings
                        </template>
                        <b-overlay :show="api_state" rounded="lg">
                        <div class="d-block text-center">
                            <b-form-checkbox size="lg" switch class="mute-switch" v-model="mute_state" @change="audioHandle"> {{ mute_state ? 'Unmute' : 'Mute' }}</b-form-checkbox>
                        </div>
                        </b-overlay>
                    </b-modal>

                    <!-- start of themse modal -->
                    <b-modal id="theme-modal" header-class="bg-labPrimary text-white px-3 py-2" hide-footer>
                        <template #modal-title>
                        Update LED Settings
                        </template>
                        <b-overlay :show="api_state" rounded="lg">
                        <div class="d-block text-center theme-color-buttons">
                            <button
                            v-for="clr in rgb_pallets"
                            :key="clr.id"
                            type="button"
                            class="btn bg mb-3"
                            @click="selectUnselectLED(clr.id)"
                            :style="{ background: clr.value, color: '#fff' }"
                            :class="[ theme_state !== clr.id ? 'theme-opacity' : '' ]"
                            >
                            {{ clr.value }}
                            </button>
                        </div>
                        <div class="d-flex theme-led-button" v-if="theme_state !== ''">
                            <button 
                            class=""
                            @click="themeHandle(theme_state, 'led1')"
                            >Set Led1</button>
                        </div>
                        <div class="d-flex mt-2 theme-led-button" v-if="theme_state !== ''">
                            <button 
                            class=""
                            @click="themeHandle(theme_state, 'led2')"
                            >Set Led2</button>
                        </div>
                        </b-overlay>
                    </b-modal>

                    <!-- start of games modal -->
                    <b-modal id="games-modal" header-class="bg-labPrimary text-white px-3 py-2" size="xl" hide-footer>
                        <template #modal-title>
                        Play a Game
                        </template>
                        <b-overlay :show="api_state" rounded="lg">
                        <div class="modal-games-search col-md-12 ml-auto">
                            <b-form-input
                            type="text"
                            placeholder="Type the name of the game"
                            class="search-box h-53"
                            autocomplete="off"
                            v-model="gameSearchQuery"
                            ></b-form-input>
                        </div>

                        <div class="modal-games-list row m-0 p-0 col-md-12 m-auto" v-if="filteredGames.length > 0">
                            <div class="modal-game-list-item col-lg-4 col-md-4 col-sm-12 mb-4" v-for="game in filteredGames" :key="game.GameId">
                                <div class="modal-game-list-item-container">
                                    <div class="modal-game-list-item-title d-flex">
                                        {{ game.GameName }}
                                        <div class="modal-game-list-item-title-check">
                                            <i 
                                            class="fas fa-circle-check fa-2xl icon h2 icon-checked" 
                                            @click="selectGame(game)"
                                            v-if="selected_game == game.GameId">
                                            </i>
                                            <i 
                                            class="fas fa-circle-check fa-2xl icon h2" 
                                            @click="selectGame(game)"
                                            v-if="selected_game !== game.GameId">
                                            </i>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="games-list row m-0 p-0 px-1 col-sm-12 m-auto" v-if="filteredGames.length <= 0">
                            <p class="text-left h5 no-results">No games found at the moment!</p>
                        </div>
                        
                        <div class="modal-games-buttons col-md-12 ml-auto mt-3" v-if="filteredGames.length > 0 && selected_game !== ''">
                            <button
                            type="button"
                            class="mr-3"
                            @click="gameHandle('xd1')"
                            >
                            Play XD1 on Selected Gamepods?
                            </button>
                            <button
                            type="button"
                            class="mr-3"
                            @click="gameHandle('xd2')"
                            >
                            Play XD2 on Selected Gamepods?
                            </button>
                            <button
                            type="button"
                            class=""
                            @click="selected_game = ''"
                            >
                            Cancel
                            </button>
                        </div>

                        </b-overlay>
                    </b-modal>

                    <!-- start of reset modal -->
                    <b-modal id="reset-modal" header-class="bg-labPrimary text-white px-3 py-2" hide-footer>
                        <template #modal-title>
                        Reset Selected Pods
                        </template>
                        <b-overlay :show="api_state" rounded="lg">
                        <div class="d-block text-center theme-color-buttons">
                            <button
                            type="button"
                            class="btn bg mb-3 w-100"
                            @click="resetHandle(1)"
                            >
                            Yes
                            </button>
                            <button
                            type="button"
                            class="btn w-100"
                            @click="resetHandle(0)"
                            >
                            No
                            </button>
                        </div>
                        </b-overlay>
                    </b-modal>

                </div>

            </div>

        </div>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <FacFooter type="inner" />
        </div>
        <!-- end of footer section -->
    
    </div>
</template>

<script>
import FacHeader from "@/views/facilitator/FacHeader.vue";
import { getFacPodsList, facRunCommand, facGetPalletsList } from "../../services/FacService";
import { getGamesList } from "../../services/PlayerService";
import FacFooter from "@/views/facilitator/FacFooter.vue";

export default {
  name: "FacPods-view",
  components: { FacHeader, FacFooter },
  data() {
    return {
      pod_selected: false,
      pod_selected_id: [],
      mute_state: '',
      api_state: false,
      theme_state: '',
      games: [],
      gameSearchQuery:'',
      selected_game:'',
      command_types: {
        'mute' : 'mute', 
        'play_game' : 'play_game', 
        'led1' : 'led1', 
        'led2' : 'led2',
        'reset' : 'reset'
      },
      rgb_pallets: [],
      pods: [],
    };
  },

  computed: {
    filteredGames () {
      if(this.gameSearchQuery) {
        return this.games.filter(item => {
          return item.GameName.toLowerCase().includes(this.gameSearchQuery.toLowerCase())
        });
      }
      else {
        return this.games;
      }
    },
    logo_file() {
        return this.$store.getters.getThemeLogo().logo;
    }
  },

  methods: {
    podsList() {
      let user_data = JSON.parse(localStorage.user_sess_data);
      let pod_data = JSON.parse(localStorage.pod_sess_data);
      let params = {
        session_id_key : pod_data.session_id_key,
        token: user_data.token,
        user_id: user_data.user_id
      };
      getFacPodsList(params).then(response => {
        this.pods = response.data.pods;
      })
    },

    palletsList() {
      let user_data = JSON.parse(localStorage.user_sess_data);
      let pod_data = JSON.parse(localStorage.pod_sess_data);
      let params = {
        session_id_key : pod_data.session_id_key,
        token: user_data.token,
        user_id: user_data.user_id
      };
      facGetPalletsList(params).then(response => {
        this.rgb_pallets = response.data.pallets;
      });
    },

    selectPod(pod) {
      if(this.pod_selected_id.indexOf(pod.pod_id_key) !== -1) {
        this.pod_selected_id.splice(this.pod_selected_id.indexOf(pod.pod_id_key), 1);
      }
      else {
        this.pod_selected_id.push(pod.pod_id_key);
      }
      // set pod selection state
      if(this.pod_selected_id.length > 0) {
        this.pod_selected = true;
      }
      else {
        this.pod_selected = false;
      }
    },

    openButtonModals(modal_id) {
      this.$bvModal.show(modal_id);
    },

    audioHandle() {
      this.commandExecute(this.command_types.mute, this.mute_state);
      this.$bvModal.hide('audio-modal');
    },

    selectUnselectLED(value) {
      if(this.theme_state == value) {
        this.theme_state = '';
      }
      else {
        this.theme_state = value;
      }
    },

    themeHandle(color, type) {
      this.commandExecute(this.command_types[type], this.theme_state);
      this.$bvModal.hide('theme-modal');
    },

    commandExecute(command_type, command_value, platform="") {
      this.api_state = true;
      let user_data = JSON.parse(localStorage.user_sess_data);
      let pod_data = JSON.parse(localStorage.pod_sess_data);
      let params = {
        session_id_key : pod_data.session_id_key,
        token: user_data.token,
        command_type: command_type,
        command_value: command_value,
        command_platform: platform,
        pod_ids: this.pod_selected_id
      };
      facRunCommand(params).then(response => {
        this.api_state = false;
        if(response.status) {
          this.theme_state = '';
          this.makeToast("success", response.message);
        }
        else {
          this.mute_state = !this.mute_state;
          this.makeToast("error", response.message);
        }
      })
    },

    gamesList() {
      let user_data = JSON.parse(localStorage.user_sess_data);
      let pod_data = JSON.parse(localStorage.pod_sess_data);
      let params = {
        session_id_key : pod_data.session_id_key,
        token: user_data.token,
        user_id: user_data.user_id
      };
      getGamesList(params).then(response => {
        this.games = response.data.games;
      })
    },

    selectGame(game) {
      if(this.selected_game == game.GameId) {
        this.selected_game = '';
      }
      else {
        this.selected_game = game.GameId;
      }
    },

    gameHandle(platform) {
      this.commandExecute(this.command_types.play_game, this.selected_game, platform);
      this.$bvModal.hide('games-modal');
    },

    resetHandle(value) {
      if(value == 1) {
        this.commandExecute(this.command_types.reset, value);
      }
      this.$bvModal.hide('reset-modal');
    },
    setPodData(obj) {
        this[obj.key] = obj.value;
    },
    makeToast(variant = "default", message) {
        //   this.$toast.open({
        //     message: message,
        //     type: variant,
        //     position: 'top-right',
        //     duration: 3000,
        //     dismissible: true
        //   });
        this.$root.$emit("toast_message", {'type' : variant, 'message' : message});
    }
  },

  mounted() {
    this.podsList();
    this.gamesList();
    this.palletsList();
  }

};
</script>