<template>
  <div class="main-layout-section">
    
    <ReservationWidget v-if="is_reserved" @reservationAcknowledged="reservationAcknowledged($event)" :reservation="reservation" />

    <FacHeader @setPodData="setPodData($event)" />
    <FacPollingAndSockets @setPodData="setPodData($event)" />

    <OrientationWidget @orientationComplete="orientationComplete($event)" :otype="orientation_type" v-if="is_orientation" />
    <!-- notifications section -->
    <AlertsWidget @alertsComplete="alertsComplete($event)" :alerts="alerts" v-if="is_alerts && !is_orientation" />

    <div class="w-logo mb-1" v-if="is_save_seat">
        <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="logo_file !== '' && logo_file !== null"/>
    </div>

    <div class="admin-pod-dashboard" v-if="!is_alerts && !is_orientation">

        <div class="apd-sections" :class="[is_save_seat ? 'd-none' : '']">

            <!-- pods list section -->
            <div class="apd-sections-item full animate__animated animate__fadeIn animate__delay-3s">

                <div class="apd-logs apd-pods-list">

                    <div class="apd-pod-buttons" style="padding-right:0px;padding-left:7px;margin-top:0px;">
                        <div class="apd-pod-buttons-header">
                            Select Target Action | <span> Affects All Selected Gamepods Above</span>
                        </div>
                        <div class="apd-pod-buttons-body">
                            <button @click="commandHandle(1, 'login')" class="small-button">Login</button>
                            <button @click="commandHandle(1, 'seat')" class="small-button">Save Seat</button>
                            <button @click="commandHandle(1, 'logout')" class="small-button">Logout</button>
                            <button @click="ledModal1" class="half-button">Change Front LEDs</button>
                            <button @click="ledModal2" class="half-button">Change Side LEDs</button>
                            <button @click="loadGames('xb')" class="half-button">
                                <object type="image/svg+xml" class="" :data="xb_icon" tabindex="-1"></object>
                                Play Xbox
                            </button>
                            <button @click="loadGames('pc')" class="half-button">
                                <object type="image/svg+xml" class="" :data="pc_icon" tabindex="-1"></object>
                                Play PC
                            </button>
                            <button @click="loadGames('sw')" class="half-button">
                                <object type="image/svg+xml" class="" :data="sw_icon" tabindex="-1"></object>
                                Play Switch
                            </button>
                            <button @click="loadGames('ps')" class="half-button">
                                <object type="image/svg+xml" class="" :data="ps_icon" tabindex="-1"></object>
                                Play PS5
                            </button>
                        </div>

                    </div>

                </div>

                </div> 
                <!-- end pods list section -->

            <!-- pod info section -->
            <div class="apd-sections-item half">

                <div class="w-logo mb-1">
                    <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="logo_file !== '' && logo_file !== null"/>
                </div>
                <h3 class="page-heading animate__animated animate__fadeInUp animate__delay-1s mb-4">Host Dashboard</h3>

                <div v-if="nav_name !== ''" class="w-form-control apd-pod-info-nav animate__animated animate__fadeInUp animate__delay-2s mt-3">
                    <span class="apd-pod-info-nav-name">
                        {{ nav_name }}
                    </span>
                </div>

                <div class="w-form-control apd-pod-info animate__animated animate__fadeInUp animate__delay-2s mt-3" v-if="(xds_data.xd1 !== '' && xds_data.xd1 !== 'na') || (xds_data.xd2 !== '' && xds_data.xd2 !== 'na')">
                    <div class="apd-pod-info-left">
                        <i class="fa-solid fa-wave-square"></i>
                    </div>
                    <div class="apd-pod-info-right">
                        <template v-if="xds_data.xd1 !== '' && xds_data.xd1 !== 'na' && xds_data.xd1 !== 'start'">
                            <div class="apd-pod-info-right-button">
                                <div class="apd-pod-info-right-button-left">
                                    <object type="image/svg+xml" class="" :data="getXdButtonIcons(xds_data.xd1)" tabindex="-1"></object>
                                    {{ xds_icons[xds_data.xd1].xd }}
                                </div>
                                <div class="apd-pod-info-right-button-right">
                                    <label class="gen-switch lockunlock">
                                        <input type="checkbox" 
                                        :ref="pod_id+'_xd_'+xds_data.xd1" 
                                        :checked="!xds_data[xds_data.xd1+'_disabled']" 
                                        :disabled="true"
                                        >
                                        <span class="gen-switch-slider gen-switch-round lockunlock"></span>
                                    </label>
                                </div>
                            </div>
                        </template>
                        <template v-if="xds_data.xd2 !== '' && xds_data.xd2 !== 'na' && xds_data.xd2 !== 'start'">
                            <div class="apd-pod-info-right-button">
                                <div class="apd-pod-info-right-button-left">
                                    <object type="image/svg+xml" class="" :data="getXdButtonIcons(xds_data.xd2)" tabindex="-1"></object>
                                    {{ xds_icons[xds_data.xd2].xd }}
                                </div>
                                <div class="apd-pod-info-right-button-right">
                                    <label class="gen-switch lockunlock">
                                        <input type="checkbox" 
                                        :ref="pod_id+'_xd_'+xds_data.xd2"
                                        :checked="!xds_data[xds_data.xd2+'_disabled']"
                                        :disabled="true"
                                        >
                                        <span class="gen-switch-slider gen-switch-round lockunlock"></span>
                                    </label>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>

                <!-- start of volume section -->
                <div class="apd-volume-container col-md-12 mt-4 animate__animated animate__fadeInUp animate__delay-3s">
                    <div class="apd-volume mb-3">
                        <b-input-group class="d-flex text-center align-items-center">
                            <b-input-group-prepend class="mute-icons">
                                <b-icon 
                                class="icon h4" 
                                icon="volume-down-fill" 
                                @click="volumeMuteUnmute('mute')"
                                >
                                </b-icon>
                            </b-input-group-prepend>
                            <b-form-input 
                            type="range" 
                            class="volume-input" 
                            min="0" 
                            max="50" 
                            step="1" 
                            tabindex="-1" 
                            v-model="pod_volume"
                            @change="volumeChangePodDashboard"
                            v-b-tooltip="{ title: (pod_volume * 2 + '%'), placement: 'top', trigger: 'hover focus', customClass: 'volume-tooltip' }"
                            >
                            </b-form-input>
                            <b-input-group-append class="mute-icons">
                                <b-icon 
                                class="icon h4" 
                                icon="volume-up-fill" 
                                @click="volumeMuteUnmute('unmute')"
                                >
                                </b-icon>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </div>
                <!-- end of voulme section -->

            </div>
            <!-- end of pod info section -->

            <!-- pods list section -->
            <div class="apd-sections-item full animate__animated animate__fadeIn animate__delay-3s">

                <div class="apd-logs apd-pods-list">

                    <!-- start of pods list panel -->
                    <div class="apd-logs-item">
                        <div class="apd-logs-panel">
                            <div class="apd-logs-panel-header">
                                <span>Select Target Gamepods</span>
                                <span>
                                    Select All
                                    <label class="gen-switch tickcross">
                                        <input type="checkbox" 
                                        ref="select_all_pods" 
                                        @change="handleSelectPods('all')"
                                        >
                                        <span class="gen-switch-slider gen-switch-round tickcross"></span>
                                    </label>
                                </span>
                            </div>
                            <div class="apd-logs-panel-body">
                                <ul>
                                    <li class="apd-logs-panel-body-header">
                                        <div>
                                            <span>Nav ID</span>
                                            <span>Selected</span>
                                        </div>
                                        <div>
                                            <span>Nav ID</span>
                                            <span>Selected</span>
                                        </div>
                                        <div>
                                            <span>Nav ID</span>
                                            <span>Selected</span>
                                        </div>
                                    </li>
                                    <div class="apd-logs-panel-body-scroll">
                                        <li class="apd-logs-panel-body-data">
                                            <div v-for="pod in pods" :key="pod.id">
                                                <span>{{pod.license}}</span>
                                                <label class="gen-switch tickcross">
                                                    <input type="checkbox" 
                                                    :ref="pod.pod_id_key+'_select_pod'" 
                                                    :class="pod.id+'-pod-id'"
                                                    :checked="selected_pod_ids.indexOf(pod.pod_id_key) !== -1" 
                                                    @change="handleSelectPods('', pod.pod_id_key, pod.pod_id_key+'_select_pod')"
                                                    >
                                                    <span class="gen-switch-slider gen-switch-round tickcross"></span>
                                                </label>
                                            </div>
                                        </li>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- end of pods list panel -->

                </div>
                
            </div> 
            <!-- end pods list section -->

        </div>

        <!-- start of save seat section -->
        <template v-if="is_save_seat">
            <FacSaveSeatWidget @handleLogout="initSaveSeatLogout($event)" @initSaveSeat="initSaveSeat($event)" />
        </template>
        <!-- end of save seat section -->

        <!-- start of pallets modal -->
        <b-modal id="pallets-modal-front" dialog-class="admin-portal-modal" header-class="admin-portal-bg secondary px-3 py-2" hide-footer>
            <template #modal-title>
                Update LED Settings
            </template>
            <div class="d-block text-center theme-color-buttons">
                <button
                    v-for="clr in rgb_pallets"
                    :key="clr.id"
                    type="button"
                    class="btn bg mb-3"
                    :style="{ background: clr.value, color: '#fff' }"
                    :class="[ led_state !== clr.id ? 'theme-opacity' : '' ]"
                    @click="selectUnselectLED1(clr.id)"
                >
                    {{ clr.value.toUpperCase() }}
                </button>
            </div>
            <div class="d-flex" v-if="led_state !== ''">
                <button 
                class="modal-button w-100"
                @click="commandHandle(led_state, 'led1')"
                >Change Front LEDs</button>
            </div>
        </b-modal>

        <b-modal id="pallets-modal-side" dialog-class="admin-portal-modal" header-class="admin-portal-bg secondary px-3 py-2" hide-footer>
            <template #modal-title>
                Update LED Settings
            </template>
            <div class="d-block text-center theme-color-buttons">
                <button
                    v-for="clr in rgb_pallets"
                    :key="clr.id"
                    type="button"
                    class="btn bg mb-3"
                    :style="{ background: clr.value, color: '#fff' }"
                    :class="[ led_state1 !== clr.id ? 'theme-opacity' : '' ]"
                    @click="selectUnselectLED2(clr.id)"
                >
                    {{ clr.value.toUpperCase() }}
                </button>
            </div>
            <div class="d-flex mt-2" v-if="led_state1 !== ''">
                <button 
                class="modal-button w-100"
                @click="commandHandle(led_state1, 'led2')"
                >Change Side LEDs</button>
            </div>
        </b-modal>

    </div>

    <!-- start of footer section -->
    <div class="col-md-12 mx-auto">
        <FacFooter type="inner" />
    </div>
    <!-- end of footer section -->

  </div>
</template>

<script>
import FacHeader from "@/views/facilitator/FacHeader.vue";
import FacFooter from "@/views/facilitator/FacFooter.vue";
import AlertsWidget from "@/components/AlertsWidget.vue";
import OrientationWidget from "@/components/OrientationWidget.vue";
import ReservationWidget from "@/components/ReservationWidget.vue";
import FacPollingAndSockets from "@/views/facilitator/FacPollingAndSockets.vue";
import { getPodDetails, setPodVolume } from "../../services/PlayerService";
import xb_icon from "../../assets/images/icon_xbox.svg";
import sw_icon from "../../assets/images/icon_switch.svg";
import pc_icon from "../../assets/images/icon_pc.svg";
import ps_icon from "../../assets/images/icon_ps5.svg";
import { global_config } from "@/config/config.js";
import { getXdButtonIcons } from "../../services/Guard";
import { getXdTypes, getPodVolume, setFlaskPodVolume, setFlaskLED1, setFlaskLED2 } from "../../services/FlaskService";
import { getFacPodsList, facGetPalletsList, facRunCommand } from "../../services/FacService";
import FacSaveSeatWidget from "@/components/FacSaveSeatWidget.vue";

export default {
    name: "FacDashboard-view",
    components: { FacHeader, FacFooter, AlertsWidget, OrientationWidget, ReservationWidget, FacPollingAndSockets, FacSaveSeatWidget },
    data() {
        return {
            user_data: null,
            is_alerts: false,
            alerts: [],
            is_orientation: false,
            orientation_type: '',
            is_reserved: false,
            reservation: {},
            pod_data: null,
            pod_volume: 0,
            nav_name: '',
            xds_data: {
                "xd1" : "start", 
                "xd2" : "start", 
                "xb_disabled": false, 
                "ps_disabled": false, 
                "sw_disabled": false, 
                "pc_disabled": false, 
                "pod_disabled": false
            },
            xds_icons: global_config.XD_ICONS,
            xb_icon: xb_icon,
            sw_icon: sw_icon,
            pc_icon: pc_icon,
            ps_icon: ps_icon,
            pods: [],
            rgb_pallets: [],
            led_state: "",
            led_state1: "",
            pod_id: "",
            selected_pod_ids: [],
            command_types: {
                'logout' : 'logout_fac', 
                'led1' : 'led1', 
                'led2' : 'led2',
                'seat' : 'save_seat_fac',
                'login': 'login_fac'
            },
            is_save_seat: false,
        };
    },
    computed: {
        logo_file() {
            return this.$store.getters.getThemeLogo().logo;
        }
    },
    methods: {
        setPodData(obj) {
            this[obj.key] = obj.value;
        },
        setUserData() {
            if(typeof localStorage.user_sess_data !== 'undefined') {
                let user_data = JSON.parse(localStorage.user_sess_data);
                this.user_data = user_data
            }
        },
        handleLogout() {
            this.$children.forEach(function(item) {
                if(item.$options._componentTag == "FacHeader") {
                    item.handleLogout();
                }
            });
        },
        alertsComplete(obj) {
            obj;
            setTimeout(() => {
                this.is_alerts = false;
                this.alerts = [];
            }, 2000);
        },
        orientationComplete(obj) {
            obj;
            this.is_orientation = false;
        },
        // check is_paid status of the pod in the lab. 
        checkIsPaidLab(){
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            if(pod_data.is_paid != undefined && pod_data.is_paid == 1){
                this.is_paid_lab = pod_data.is_paid;
            }
        },
        reservationAcknowledged(obj) {
            obj;
            this.is_reserved = false;
            this.reservation = {};
        },
        podDetails() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            this.nav_name = pod_data.nav_name;
            this.pod_id = pod_data.pod_id_key;
            let params = {
                session_id_key : pod_data.session_id_key,
                session_id : pod_data.session_id_key,
                token: user_data.token
            };
            getPodDetails(this.pod_id, params).then(response => {
                if(response.status) {
                    this.pod_data = response.data.pod;
                    this.pod_volume = response.data.pod.volume;
                }
            });
        },
        getXdButtonIcons(xd) {
            return getXdButtonIcons(xd);
        },
        getPodXdTypes() {
            // get xd types for buttons
            getXdTypes().then(xd => {
                if(xd.data !== undefined) {
                    this.xds_data = xd.data;
                }
            });
        },
        volumeMuteUnmute(type) {
            if(type == 'mute') {
                this.pod_volume = 0;
                this.volumeChange(this.pod_volume);
            }
            else if(type == 'unmute') {
                this.pod_volume = 100;
                this.volumeChange(this.pod_volume);
            }
        },
        volumeChangePodDashboard() {
            this.volumeChange(this.pod_volume);
        },
        volumeChange(volume, source="web") {
            // clear interval first
            clearInterval(this.volume_polling);
            this.api_state_general = true;
            let user_data = JSON.parse(localStorage.user_sess_data);
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                session_id_key : pod_data.session_id_key,
                token: user_data.token,
                pod_id_key: this.pod_id,
                volume: volume,
            };
            let flag = true;
            if(source == "" && volume == this.pod_volume) {
                flag = false;
            }
            
            if(flag) {
                setPodVolume(params).then(response => {
                    this.api_state_general = false;
                    if(response.status) {
                        this.pod_volume = volume;
                        // init volume change on pod as well
                        if(source !== "") {
                            this.$root.$emit("toast_message", {'type' : 'success', 'message' : response.message});
                            let params = {
                                volume : this.pod_volume
                            };
                            setFlaskPodVolume(params).then(vol_web => {
                                setTimeout(() => {
                                    vol_web;
                                    this.pollPodVolume();  
                                }, 3000);
                            });
                        }
                        else {
                            this.pollPodVolume();
                        }
                    }
                    else {
                        this.$root.$emit("toast_message", {'type' : 'error', 'message' : response.message});
                        this.pollPodVolume();
                    }
                })
            }
            else {
                this.api_state_general = false;
                this.pollPodVolume();
            }
        },
        pollPodVolume () {
            this.volume_polling = setInterval(() => {
                getPodVolume().then(vol => {
                    if(vol.data.vol_now !== undefined && vol.data.vol_now !== "") {
                        this.volumeChange(vol.data.vol_now, "");
                    }
                });
            }, process.env.VUE_APP_POLLING_TIME_POD_VOLUME_CHECK);
        },
        podsList() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                session_id_key : pod_data.session_id_key,
                token: user_data.token,
                user_id: user_data.user_id
            };
            getFacPodsList(params).then(response => {
                this.pods = response.data.pods;
            })
        },
        palletsList() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                session_id_key : pod_data.session_id_key,
                token: user_data.token,
                user_id: user_data.user_id
            };
            facGetPalletsList(params).then(response => {
                this.rgb_pallets = response.data.pallets;
            });
        },
        ledModal1() {
            this.$bvModal.show('pallets-modal-front');
        },
        selectUnselectLED1(value) {
            if(this.led_state == value) {
                this.led_state = '';
            }
            else {
                this.led_state = value;
            }
        },
        ledModal2() {
            this.$bvModal.show('pallets-modal-side');
        },
        selectUnselectLED2(value) {
            if(this.led_state1 == value) {
                this.led_state1 = '';
            }
            else {
                this.led_state1 = value;
            }
        },
        commandHandle(value, name) {
            this.commandExecute(this.command_types[name], value);
        },

        commandExecute(command_type, command_value) {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                session_id_key : pod_data.session_id_key,
                token: user_data.token,
                command_type: command_type,
                command_value: command_value,
                pod_ids: this.selected_pod_ids
            };
            let _self = this;
            if(this.selected_pod_ids.length > 0) {
                facRunCommand(params).then(response => {
                    if(response.status) {
                        this.$bvModal.hide('pallets-modal-front');
                        this.$bvModal.hide('pallets-modal-side');
                        this.$root.$emit("toast_message", {'type' : 'success', 'message' : response.message});
                        
                        if(_self.selected_pod_ids.indexOf(_self.pod_id) !== -1) { 
                            if(command_type == 'led1') {
                                this.led_state = "";
                                let params = {
                                    led1_lock: response.data.command_value
                                };
                                setFlaskLED1(params, 'default').then(lc => {
                                    console.log("LED1 CHANGE FLASK API CALLED : ", lc);
                                });
                            }
                            else if(command_type == 'led2') {
                                this.led_state = "";
                                let params = {
                                    led2_lock: response.data.command_value
                                };
                                setFlaskLED2(params, 'default').then(lc => {
                                    console.log("LED2 CHANGE FLASK API CALLED : ", lc);
                                });
                            }
                            else if(command_type == 'logout_fac') {
                                this.handleLogout();
                            }
                            else if(command_type == 'save_seat_fac') {
                                this.initSaveSeat(true);
                            }
                        }
                    }
                    else {
                        this.$root.$emit("toast_message", {'type' : 'error', 'message' : response.message});
                    }
                });
            }
            else {
                this.$root.$emit("toast_message", {'type' : 'warning', 'message' : 'no-pod-selected'});
            }
        },
        handleSelectPods(type="", pod_id, ref_id) {
            let _self = this;
            if(type == "all") {
                this.selected_pod_ids = [];
                if(this.$refs.select_all_pods.checked) {
                    this.pods.forEach((item) => {
                        _self.selected_pod_ids.push(item.pod_id_key);
                    });
                }
            }
            else {
                if(this.$refs[ref_id][0].checked) {
                    this.selected_pod_ids.push(pod_id);
                }
                else {
                    this.selected_pod_ids.splice(this.selected_pod_ids.indexOf(pod_id), 1);
                }
                if(this.pods.length == this.selected_pod_ids.length) {
                    this.$refs.select_all_pods.checked = true;
                }
                else {
                    this.$refs.select_all_pods.checked = false;
                }
            }
            localStorage.setItem('host_selected_pods', JSON.stringify({'items' : this.selected_pod_ids}));
        },
        initSaveSeat(value) {
            if(this.selected_pod_ids.length > 0) {
                this.is_save_seat = value;
            }
            else {
                this.$root.$emit("toast_message", {'type' : 'warning', 'message' : 'no-pod-selected'});
            }
        },
        initSaveSeatLogout(value) {
            value;
            this.handleLogout();
        },
        loadGames(xd) {
            if(this.selected_pod_ids.length > 0) {
                localStorage.setItem('fac_selected_pods', JSON.stringify({'items' : this.selected_pod_ids}));
                this.$router.push('/facilitator/games/' + xd);
            }
            else {
                this.$root.$emit("toast_message", {'type' : 'warning', 'message' : 'no-pod-selected'});
            }
        },
    },
    mounted() {
        this.getPodXdTypes();
        document.body.classList.remove('overflow-hidden');
        this.setUserData();
        this.podDetails();
        this.podsList();
        this.palletsList();

        // listen for save seat event
        this.$root.$on('fac_save_seat', (message) => {
            this.initSaveSeat(message);
        });
    },
    created () {
        this.checkIsPaidLab();
        let params = this.$route.params;
        if(Object.prototype.hasOwnProperty.call(params, "is_orientation") && params.is_orientation && !this.is_paid_lab) {
            this.orientation_type = 'free';
            this.is_orientation = params.is_orientation;
        }
        if(Object.prototype.hasOwnProperty.call(params, "is_orientation_paid") && params.is_orientation_paid && this.is_paid_lab) {
            this.orientation_type = 'paid';
            this.is_orientation = params.is_orientation_paid;
        }
        if(Object.prototype.hasOwnProperty.call(params, "is_alerts") && params.is_alerts) {
            this.is_alerts = params.is_alerts;
            this.alerts = params.alerts;
        }
        if(Object.prototype.hasOwnProperty.call(params, "reservation") && params.reservation.is_started && !params.reservation.is_user_allowed) {
            this.is_reserved = true;
            this.reservation = params.reservation;
        }
        if(Object.prototype.hasOwnProperty.call(params, "fac_save_seat") && params.fac_save_seat) {
            this.initSaveSeat(params.fac_save_seat);
        }
        // check if save seat in progress
        if(typeof localStorage.fac_save_seat !== 'undefined') {
            let save_seat_data = JSON.parse(localStorage.getItem('fac_save_seat'));
            if(save_seat_data.active == 1) {
                this.selected_pod_ids = [this.pod_id];
                localStorage.setItem('fac_selected_pods', JSON.stringify({'items' : [this.pod_id]}));
                this.initSaveSeat(true);
            }
        }
        // check if pods already selected
        if(typeof localStorage.fac_selected_pods !== 'undefined') {
            this.selected_pod_ids = JSON.parse(localStorage.getItem('fac_selected_pods')).items;
        }
    }
};
</script>
<style scoped>
.admin-pod-dashboard {
    min-height: 84vh;
}
.admin-pod-dashboard .page-heading {
    font-size: 26px;
}
.admin-pod-dashboard .apd-sections {
    min-height: 67vh;
    display: flex;
    flex-direction: row;
    column-gap: 60px;
    max-height: 85vh;
}
.admin-pod-dashboard .apd-sections .apd-sections-item {
    height: auto;
}
.admin-pod-dashboard .apd-sections .apd-sections-item.full {
    width: calc(75% / 2);
}
.admin-pod-dashboard .apd-sections .apd-sections-item.half {
    width: 25%;
}
.admin-pod-dashboard .apd-pod-info {
    width: 100%;
    text-align: center;
    margin: auto;
    border: 2px solid var(--client-primary);
    border-radius: 8px;
    justify-content: flex-start !important;
    column-gap: 0px !important;
}
.admin-pod-dashboard .apd-pod-info .apd-pod-info-left {
    width: 10%;
    background: var(--client-primary);
    display: flex;
    padding: 15px 10px;
    justify-content: center;
    align-items: center;
    color: #d8d8d8;
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: 700;
}
.admin-pod-dashboard .apd-pod-info .apd-pod-info-right {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 15px 30px;
    row-gap: 15px;
    min-height: 105.66px;
    justify-content: center;
}
.admin-pod-dashboard .apd-pod-info .apd-pod-info-right .apd-pod-info-right-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.admin-pod-dashboard .apd-pod-info .apd-pod-info-right .apd-pod-info-right-button .apd-pod-info-right-button-left {
    color: #d8d8d8;
    align-items: center;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Open Sans';
    column-gap: 10px;
}
.admin-pod-dashboard .apd-pod-info .apd-pod-info-right .apd-pod-info-right-button .apd-pod-info-right-button-left object {
    width: 40px;
}
.admin-pod-dashboard .apd-pod-info .apd-pod-info-right .apd-pod-info-right-button .apd-pod-info-right-button-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #d8d8d8;
    font-weight: 700;
    column-gap: 10px;
}
.admin-pod-dashboard .apd-pod-info .apd-pod-info-disabled {
    opacity: 0.65;
}
.admin-pod-dashboard .apd-pod-info-nav .apd-pod-info-nav-name {
    background: var(--client-primary);
    padding: 7px;
    color: #d8d8d8;
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: 700;
    border-radius: 8px;
}


.admin-pod-dashboard .apd-volume-container {
    margin: auto;
    width: 100%;
}
.admin-pod-dashboard .apd-volume-container .apd-volume {
    display: flex;
    align-items: center;   
}
.admin-pod-dashboard .apd-volume-container .apd-volume .mute-icons .icon{
    margin: 0px;
    font-size: 26px;
    color: #fff;
    margin-top: 3px;
}
.admin-pod-dashboard .apd-audio {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    align-items: center;
    justify-content: center;
}

.admin-pod-dashboard .apd-audio label.apt-audio-title {
    font-size: 16px;
    color: #d8d8d8;
    font-weight: 500;
}
/* start of general switches */
.gen-switch {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 34px;
}
.gen-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.gen-switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #7f7f7f;
    -webkit-transition: .4s;
    transition: .4s;
}  
.gen-switch-slider:before {
    position: absolute;
    content: "";
    height: 40px;
    width: 40px;
    left: 0px;
    bottom: -2px;
    -webkit-transition: .4s;
    transition: .4s;
    background-color: var(--client-hover);
    font-family: "FontAwesome";
    display: flex;
    align-items: center;
    justify-content: center;;
    color: #d8d8d8;
}
.gen-switch input:checked + .gen-switch-slider:before {
    -webkit-transform: translateX(31px);
    -ms-transform: translateX(31px);
    transform: translateX(31px);
    background-color: var(--client-primary);
}
.gen-switch-slider.gen-switch-round {
    border-radius: 34px;
}
.gen-switch-slider.gen-switch-round:before {
    border-radius: 50%;
}
.gen-switch-slider.lockunlock:before {
    content: "\f023";
}
.gen-switch.lockunlock input:checked + .gen-switch-slider.lockunlock:before {
    content: "\f13e";
}
.gen-switch-slider.tickcross:before {
    content: "\f00d";
}
.gen-switch.tickcross input:checked + .gen-switch-slider.tickcross:before {
    content: "\f00c";
}
.gen-switch-slider.playpause:before {
    content: "\f04c";
}
.gen-switch.playpause input:checked + .gen-switch-slider.playpause:before {
    content: "\f04b";
}
/* end of general switches */
.admin-pod-dashboard .w-form-control.w-input-fields textarea {
    height: 170px;
    max-height: unset;
}
.admin-pod-dashboard .apd-logs {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    column-gap: 40px;
}
.admin-pod-dashboard .apd-logs .apd-logs-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
}
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: start;
    row-gap: 10px;
}
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-header {
    width: 100%;
    padding: 10px;
    font-size: 15px;
    font-weight: 700;
    color: #d8d8d8;
    font-family: 'Open Sans';
}
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-body {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-body .apd-logs-panel-body-scroll {
    max-height: 29.5vh;
    min-height: 29.5vh;
    overflow-x: hidden;
    overflow-y: scroll;
}

.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-body .apd-logs-panel-body-scroll::-webkit-scrollbar {
    width: 15px;
}
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-body .apd-logs-panel-body-scroll::-webkit-scrollbar-track {
    background: #d3d3d3;
    border-radius: 10px;
}
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-body .apd-logs-panel-body-scroll::-webkit-scrollbar-thumb {
    background: rgb(244 130 38);
    border: 4px solid #d3d3d3;
    border-radius: 10px;
}
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-body .apd-logs-panel-body-scroll::-webkit-scrollbar-thumb:hover {
    background: rgb(244 130 38);
}
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-body > ul {
    list-style: none;
    padding: 0px 0px 0px 7px;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-body > ul li {
    background: transparent;
    font-size: 14px;
    font-family: 'Open Sans';
    font-weight: 500;
    color: #d8d8d8;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 20px;
}
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-body > ul li.apd-logs-panel-body-header {
    border-bottom: 2px solid var(--client-primary);
    margin: 0px !important;
    padding: 0px 0px 7px 0px;
    align-items: self-end;
    font-size: 14px;
    font-weight: 600;
}
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-body > ul li > div:nth-child(1),
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-body > ul li > div:nth-child(2) {
    width: 25%;
}
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-body > ul li > div:nth-child(3) {
    width: 50%;
}

/* start of pods list section */
.admin-pod-dashboard .apd-pods-list .apd-logs-item .apd-logs-panel .apd-logs-panel-body > ul {
    padding: 0px 7px 0px 0px;
}
.admin-pod-dashboard .apd-pods-list .apd-logs-item .apd-logs-panel .apd-logs-panel-body > ul li {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 15px;
    font-weight: 700;
}
.admin-pod-dashboard .apd-pods-list .apd-logs-item .apd-logs-panel .apd-logs-panel-body > ul li.apd-logs-panel-body-header {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    padding-right: 15px;
}
.admin-pod-dashboard .apd-pods-list .apd-logs-item .apd-logs-panel .apd-logs-panel-body > ul li > div {
    display: flex;
    flex-direction: row;
    width: 100% !important;
    column-gap: 20px;
}
.admin-pod-dashboard .apd-pods-list .apd-logs-item .apd-logs-panel .apd-logs-panel-body > ul li > div > span:first-child {
    width: 30%;
}
.admin-pod-dashboard .apd-pods-list .apd-logs-item .apd-logs-panel .apd-logs-panel-body > ul li:not(.apd-logs-panel-body-header) > div > span:first-child {
    text-align: center;
}
.admin-pod-dashboard .apd-pods-list .apd-logs-item .apd-logs-panel .apd-logs-panel-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0px;
}
.admin-pod-dashboard .apd-pods-list .apd-logs-item .apd-logs-panel .apd-logs-panel-header > span:last-child {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 15px;
}
.admin-pod-dashboard .apd-pods-list .apd-logs-item .apd-logs-panel .apd-logs-panel-body .apd-logs-panel-body-scroll {
    min-height: 42vh;
    max-height: 42vh;
    overflow-y: scroll;
}
/* end of pods list section */

/* start of pod buttons section */
.admin-pod-dashboard .apd-pod-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 25px;
    padding-right: 7px;
}
.admin-pod-dashboard .apd-pod-buttons .apd-pod-buttons-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: 700;
    color: #d8d8d8;
    align-items: center;
    padding: 10px 0px;
    border-bottom: 2px solid var(--client-primary);
    margin-bottom: 15px;
}
.admin-pod-dashboard .apd-pod-buttons .apd-pod-buttons-header > span {
    font-size: 12px;
    margin-left: 5px;
    margin-top: 3px;
}
.admin-pod-dashboard .apd-pod-buttons .apd-pod-buttons-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}
.admin-pod-dashboard .apd-pod-buttons .apd-pod-buttons-body > button {
    width: calc(100%/4 - 9px);
    padding: 10px 8px;
    background: transparent;
    border: 2px solid var(--client-primary);
    border-radius: 7px;
    color: #d8d8d8;
    font-size: 14px;
    font-family: 'Open Sans';
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.admin-pod-dashboard .apd-pod-buttons .apd-pod-buttons-body > button.small-button {
    width: calc(100%/3 - 9px);
}
.admin-pod-dashboard .apd-pod-buttons .apd-pod-buttons-body > button.half-button {
    width: calc(100%/2 - 9px);
}
.admin-pod-dashboard .apd-pod-buttons .apd-pod-buttons-body > button object {
    width: 45px;
    pointer-events: none;
}
.admin-pod-dashboard .apd-pod-buttons .apd-pod-buttons-body > button:hover {
    background: var(--client-primary);
}
/* end of pod buttons section */
</style>