<template>
    <div class="main-layout-section admin-portal secondary">
          <AdminHeader 
          @setPodData="setPodData($event)" 
          @setHeaderData="setHeaderData($event)" />
          
          <div class="w-logo mb-3">
              <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeIn animate__fast" v-if="logo_file !== '' && logo_file !== null"/>
          </div>
  
          <div class="main-content">
              <h3 class="page-subheading mb-3 animate__animated animate__fadeIn animate__fast">Account Settings</h3>
  
              <div class="col-md-11 mx-auto">
  
                  <div class="admin-general-section">
                      <div class="admin-general-section-buttons">
                          <router-link to="/admin/dashboard" class="admin-general-section-button">
                              Back To Dashboard
                          </router-link>
                      </div>
                  </div>
  
                  <div class="client-form row m-0 p-0 col-md-12 col-sm-12 m-auto">
                      
                      <div class="col-md-6 col-sm-12">
                          <b-form inline @submit.stop.prevent class="p-0 m-0">
                              <div class="row col-md-8 p-0 m-0">
                                  
                                  <div class="d-flex mb-3 col-md-12 justify-content-center">
                                      <img :src="logo_file" alt="" class="rounded animate__animated animate__fadeIn animate__fast" style="max-width: 200px;">  
                                  </div>
  
                                  <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Upload Logo</label>
                                  <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast p-0 m-0"  :class="{ 'is_invalid': $v.form.image.$error }">
                                      <span><i class="fa-solid fa-file"></i></span>
                                      <b-form-file
                                      v-model="form.image"
                                      placeholder="Choose a file"
                                      id="image"
                                      accept="image/*"
                                      ref="imageinput"
                                      aria-describedby="image-help-block"
                                      :class="[ $v.form.image.$error ? 'is-invalid' : '', form.image == null ? 'text-grey' : '' ]"
                                      ></b-form-file>
                                  </div>
                                  <div class="d-flex mb-3 col-md-12 p-0 m-0 my-3 animate__animated animate__fadeIn animate__fast">
                                      <p class="text-white m-0 p-0">Recommended size for image is 150 x 60</p>
                                  </div>
  
                                  <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-1s w-100">Contact Email</label>
                                  <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-1s p-0 mb-3">
                                      <span><i class="fa-solid fa-eye-dropper"></i></span>
                                      <b-form-input
                                          v-model="form.contact_email"
                                          id="contact_email"
                                          class=""
                                          :class="{ 'is-invalid': $v.form.contact_email.$error }"
                                          placeholder="Enter contact email"
                                      ></b-form-input>
                                  </div>
                                  <!-- <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Select Theme</label>
                                  <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast p-0 m-0 mb-3" :class="{ 'is_invalid': $v.form.theme.$error }">
                                      <span><i class="fa-solid fa-palette"></i></span>
                                      <b-form-select
                                      v-model="form.theme"
                                      :options="rgb_pallets"
                                      text-field="value"
                                      value-field="id"
                                      id="theme"
                                      class=""
                                      :class="[ $v.form.theme.$error ? 'is-invalid' : '' ]"
                                      placeholder="Select a Theme"
                                      ></b-form-select>
                                  </div> -->
  
                                  <div
                                  class="
                                  w-form-control
                                  animate__animated animate__fadeIn animate__fast m-0 p-0 mb-5
                                  "
                                  >
                                      <a
                                      href="javascript:void(0)"
                                      @click="handleSettingsReq"
                                      class="flex-fill"
                                      >Save Settings</a
                                      >
                                  </div>
                              </div>
                          </b-form>
                      </div>
  
                      <div class="col-md-6 col-sm-12">
                          <h3 class="page-subheading mb-3 mt-2 animate__animated animate__fadeIn animate__fast text-left p-0">Allowed Domains</h3>
  
                          <b-form inline @submit.stop.prevent class="p-0 m-0 col-md-12">
                              <div class="row mac-search m-0 p-0">
                                  <div class="d-flex col-md-12 p-0">
                                      <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100">
                                          <span><i class="fa-solid fa-globe"></i></span>
                                          <b-form-input
                                              v-model="form1.domain"
                                              id="domain"
                                              class=""
                                              :class="{ 'is-invalid': $v.form1.domain.$error }"
                                              placeholder="Enter domain name"
                                          ></b-form-input>
                                      </div>
                                      <a
                                      href="javascript:void(0)"
                                      class="flex-fill mac-button animate__animated animate__fadeIn animate__fast" 
                                      style="min-width:65px;"
                                      @click="handleDomainReq"
                                      >Add</a>
                                  </div>
                              </div>
                          </b-form>
  
                          <div class="row m-0 p-0 animate__animated animate__fadeIn animate__fast">
                              <div class="col-md-12 col-sm-12 p-0 m-0">
                                  <ul v-if="domains.length > 0" class="admin-del-list">
                                      <li v-for="domain in domains" :key="domain.id">
                                          <div class="domain-name-section">{{ domain.domain }}</div>
                                          <div class="domain-delete-section">
                                              <a 
                                              href="javascript:;"
                                              @click="confirmDelete(domain.id)"
                                              ><i class="fas fa-times-circle"></i></a>
                                          </div>
                                      </li>
                                  </ul>
                              </div>
                          </div>
  
                          <!-- start of theme section -->
                          <h3 class="page-subheading mb-3 mt-5 animate__animated animate__fadeIn animate__fast text-left p-0">Theme Settings</h3>
  
                          <b-form inline @submit.stop.prevent class="p-0 m-0">
                              <div class="col-md-8 p-0 m-0">
                                  <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Primary Color</label>
                                  <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100">
                                      <span><i class="fa-solid fa-eye-dropper"></i></span>
                                      <b-form-input
                                          v-model="form2.primary_color"
                                          id="primary_color"
                                          class=""
                                          :class="{ 'is-invalid': $v.form2.primary_color.$error }"
                                          :placeholder="`Enter primary color e.g. ${ default_theme.primary.placeholder }`"
                                      ></b-form-input>
                                      <!-- <label class="color-preview" v-if="isValidHexColor(form2.primary_color)" :style="{background: form2.primary_color}"></label>
                                      <label class="color-preview" v-if="!isValidHexColor(form2.primary_color)" :style="{background: default_theme.primary.color}"></label> -->
                                      <label class="color-preview" v-if="isValidHexColor(current_primary_color)" :style="{background: current_primary_color}"></label>
                                  </div>
  
                                  <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Accent Color</label>
                                  <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100">
                                      <span><i class="fa-solid fa-eye-dropper"></i></span>
                                      <b-form-input
                                          v-model="form2.accent_color"
                                          id="accent_color"
                                          class=""
                                          :class="{ 'is-invalid': $v.form2.accent_color.$error }"
                                          :placeholder="`Enter accent color e.g. ${ default_theme.accent.placeholder }`"
                                      ></b-form-input>
                                      <!-- <label class="color-preview" v-if="isValidHexColor(form2.accent_color)" :style="{background: form2.accent_color}"></label>
                                      <label class="color-preview" v-if="!isValidHexColor(form2.accent_color)" :style="{background: default_theme.accent.color}"></label> -->
                                      <label class="color-preview" v-if="isValidHexColor(current_accent_color)" :style="{background: current_accent_color}"></label>
                                  </div>
  
                                  <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Gradient Color</label>
                                  <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100">
                                      <span><i class="fa-solid fa-eye-dropper"></i></span>
                                      <b-form-input
                                          v-model="form2.gradiant_color"
                                          id="gradiant_color"
                                          class=""
                                          :class="{ 'is-invalid': $v.form2.gradiant_color.$error }"
                                          :placeholder="`Enter gradient color e.g. ${ default_theme.gradiant.placeholder }`"
                                      ></b-form-input>
                                      <!-- <label class="color-preview" v-if="isValidHexColor(form2.gradiant_color)" :style="{background: form2.gradiant_color}"></label>
                                      <label class="color-preview" v-if="!isValidHexColor(form2.gradiant_color)" :style="{background: default_theme.gradiant.color}"></label> -->
                                      <label class="color-preview" v-if="isValidHexColor(current_gradiant_color)" :style="{background: current_gradiant_color}"></label>
                                  </div>
  
                                  <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Hover Color</label>
                                  <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100">
                                      <span><i class="fa-solid fa-eye-dropper"></i></span>
                                      <b-form-input
                                          v-model="form2.hover_color"
                                          id="hover_color"
                                          class=""
                                          :class="{ 'is-invalid': $v.form2.hover_color.$error }"
                                          :placeholder="`Enter hover color e.g. ${ default_theme.hover.placeholder }`"
                                      ></b-form-input>
                                      <!-- <label class="color-preview" v-if="isValidHexColor(form2.hover_color)" :style="{background: form2.hover_color}"></label>
                                      <label class="color-preview" v-if="!isValidHexColor(form2.hover_color)" :style="{background: default_theme.hover.color}"></label> -->
                                      <label class="color-preview" v-if="isValidHexColor(current_hover_color)" :style="{background: current_hover_color}"></label>
                                  </div>
  
                                  <div
                                  class="
                                  w-form-control
                                  animate__animated animate__fadeIn animate__fast m-0 p-0
                                  "
                                  >
                                      <a
                                      href="javascript:void(0)"
                                      @click="handleThemeColorsReq"
                                      class="flex-fill"
                                      >Save Theme Settings</a
                                      >
                                  </div>
                              </div>
                          </b-form>
                          <!-- end of theme section -->
  
                      </div>
  
                      <div class="col-md-6 col-sm-12">
                          <b-form inline @submit.stop.prevent class="p-0 m-0">
                              <div class="row col-md-8 p-0 m-0">
                                  
                                  <div class="d-flex mb-3 col-md-12 m-0 p-0">
                                      <label alt="" class="rounded animate__animated animate__fadeIn animate__fast" v-if="typeof client.audio_file != 'undefined' && client.audio_file != null && client.audio_file != ''" style="width: 100%;background: #7f7f7f;padding: 15px;color: #191919;display: flex;align-items: center;">
                                          {{ client.audio_file }}
                                          <span class="fa fa-times" style="margin-left: auto;cursor: pointer;" @click="removeAudio"></span>
                                      </label>  
                                  </div>
  
                                  <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Upload Audio</label>
                                  <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast p-0 m-0"  :class="{ 'is_invalid': $v.form3.audio.$error }">
                                      <span><i class="fa-solid fa-file"></i></span>
                                      <b-form-file
                                      v-model="form3.audio"
                                      placeholder="Choose a file"
                                      id="audio"
                                      accept="audio/*"
                                      ref="audioinput"
                                      aria-describedby="image-help-block"
                                      :class="[ $v.form3.audio.$error ? 'is-invalid' : '', form3.audio == null ? 'text-grey' : '' ]"
                                      ></b-form-file>
                                  </div>
                                  <div class="d-flex mb-3 col-md-12 p-0 m-0 my-3 animate__animated animate__fadeIn animate__fast">
                                      <p class="text-white m-0 p-0">Only mp3, wav and aac format allowed</p>
                                  </div>
  
                                  <div
                                  class="
                                  w-form-control
                                  animate__animated animate__fadeIn animate__fast m-0 p-0
                                  "
                                  >
                                      <a
                                      href="javascript:void(0)"
                                      @click="handleAudioReq"
                                      class="flex-fill"
                                      >Save Audio</a
                                      >
                                  </div>
                              </div>
                          </b-form>
                      </div>
  
                  </div>
  
              </div>
  
          </div>
  
          <!-- start of footer section -->
          <div class="col-md-12 mx-auto">
              <AdminFooter type="inner" />
          </div>
          <!-- end of footer section -->
          
          <!-- start of delete confirm modal -->
          <b-modal id="delete-modal" dialog-class="admin-portal-modal" header-class="admin-portal-bg secondary px-3 py-2" hide-footer>
              <template #modal-title>
                  Confirm
                  <b-icon 
                  class="icon" 
                  icon="question-circle-fill"
                  >
                  </b-icon> 
              </template>
              <b-overlay :show="false" rounded="lg">
                  <div class="d-flex">
                  <p>Are you sure you want to delete selected domain ?</p>
                  </div>
                  <div class="d-flex text-center">
                  <button
                      type="button"
                      class="modal-button px-5 py-2"
                      @click="deleteDomain"
                  >
                      Yes
                  </button>
                  <button
                      type="button"
                      class="modal-button px-5 py-2 mx-2"
                      @click="$bvModal.hide('delete-modal')"
                  >
                      No
                  </button>
                  </div>
              </b-overlay>
          </b-modal>
  
      </div>
  </template>
  
  <script>
  import AdminHeader from "@/views/admin/AdminHeader.vue";
  import AdminFooter from "@/views/admin/AdminFooter.vue";
  import { adminGetClientDetails, adminUpdateThemeAndLogo, adminGetPalletsList, adminGetDomainsList, adminSaveDomain, adminDeleteDomain } from "../../services/AdminService";
  import { global_config } from "@/config/config.js";
  import Vue from 'vue'
  import Vuelidate from 'vuelidate'
  Vue.use(Vuelidate);
  import { required } from 'vuelidate/lib/validators';
  
  export default {
      name: "AdminSettings-view",
      components: { AdminHeader, AdminFooter },
      data() {
          return {
              form: {
                  theme : '',
                  image : null,
                  contact_email: ''
              },
              rgb_pallets:[],
              client:null,
              allowed_extensions: global_config.LOGO_EXTENSIONS,
              emptyText:"No domains added yet!",
              emptyHtml:"No domains added yet!",
              fields: [{'key' : 'domain', 'label' : 'Domain'}, {'key' : 'action', 'label' : 'Action'}],
              domains: [],
              delete_domain_id: null,
              form1: {
                  domain : '',
              },
              form2: {
                  primary_color : '',
                  accent_color : '',
                  gradiant_color : '',
                  hover_color : '',
              },
              form3: {
                  audio: null
              },
              default_theme: {
                  'primary' : {'color' : '#f48226', 'placeholder': '#f48226'},
                  'accent' : {'color' : '#496e9c', 'placeholder': '#496e9c'},
                  'gradiant' : {'color' : '#03244d', 'placeholder': '#03244d'},
                  'hover' : {'color' : '#191919', 'placeholder': '#191919'},
              },
              current_primary_color: '',
              current_accent_color: '',
              current_hover_color: '',
              current_gradiant_color: ''
          };
      },
  
      validations: {
          form : {
              theme : {required:false},
              image : {required:false},
              contact_email : {required:false}
          },
          form1 : {
              domain : {required}
          },
          form2 : {
              primary_color : {required:false},
              accent_color : {required:false},
              gradiant_color : {required:false},
              hover_color : {required:false},
          },
          form3 : {
              audio : {required}
          },
      },
  
      computed: {
          logo_file() {
              return this.$store.getters.getThemeLogo().logo;
          }
      },
  
      methods: {
          palletsList() {
              let user_data = JSON.parse(localStorage.user_sess_data);
              let params = {
                  token: user_data.token
              };
              adminGetPalletsList(params).then(response => {
                  this.rgb_pallets = response.data.pallets;
                  this.rgb_pallets.splice(0, 0, {'id' : '', 'value' : 'Select a theme'});
              });
          },
  
          clientDetails() {
              let user_data = JSON.parse(localStorage.user_sess_data);
              let params = {
                  token: user_data.token,
                  client_id: user_data.client_id
              };
              adminGetClientDetails(params).then(response => {
                  this.client = response.data.clients[0];
                  // this.logo_file = this.client.logo_url;
                  this.form.theme = this.client.theme_rgb_pallet_id;
                  this.form.contact_email = this.client.contact_email;
                  this.form2.primary_color = this.client.primary_color;
                  this.form2.accent_color = this.client.accent_color;
                  this.form2.gradiant_color = this.client.gradiant_color;
                  this.form2.hover_color = this.client.hover_color;
                  // set swatch colors
                  this.current_primary_color = (this.client.primary_color !== '' && this.client.primary_color !== null) ? this.client.primary_color : this.default_theme.primary.color;
                  this.current_accent_color = (this.client.accent_color !== '' && this.client.accent_color !== null) ? this.client.accent_color : this.default_theme.accent.color;
                  this.current_gradiant_color = (this.client.gradiant_color !== '' && this.client.gradiant_color !== null) ? this.client.gradiant_color : this.default_theme.gradiant.color;
                  this.current_hover_color = (this.client.hover_color !== '' && this.client.hover_color !== null) ? this.client.hover_color : this.default_theme.hover.color;
              });
          },
  
          handleSettingsReq() {
              this.$v.form.$touch();
              if (this.$v.form.$invalid) {
                  this.makeToast("error", "generic-required-message");
              } else {
              if(this.$refs.imageinput.files.length > 0) {
                  const file = this.$refs.imageinput.files[0];
                  if(this.allowed_extensions.indexOf(file.type) <= -1) {
                      this.makeToast("error", "admin-setting-logo-format-error");
                      return;
                  }
                  else if (file.size > 3000000) {
                      this.makeToast("error", "admin-setting-logo-size-error");
                      return;
                  }
              }
              let user_data = JSON.parse(localStorage.user_sess_data);
              let data = this.form;
              data.client_id = user_data.client_id;
              data.token = user_data.token;
              adminUpdateThemeAndLogo(data).then(response => {
                  if(response.status){
                      this.makeToast("success", response.message);
                      this.$v.form.$reset();
                      this.form.image = null;
                      this.clientDetails();
                      // this.$children[0].themeLogoDetails();
                      this.$store.commit("setThemeLogo", response.data);
                  } else {
                      this.makeToast("error", response.message);
                  }
              }); 
              }
          },
  
          makeToast(variant = "default", message) {
              // this.$toast.open({
              //     message: message,
              //     type: variant,
              //     position: 'top-right',
              //     duration: 3000,
              //     dismissible: true
              // });
              this.$root.$emit("toast_message", {'type' : variant, 'message' : message});
          },
          setPodData(obj) {
              this[obj.key] = obj.value;
          },
  
          domainsList() {
              let user_data = JSON.parse(localStorage.user_sess_data);
              let params = {
                  token: user_data.token,
                  client_id: user_data.client_id
              };
              adminGetDomainsList(params).then(response => {
                  this.domains = response.data.domains;
                  if(this.domains.length > 0) {
                      this.domains.map(function(lb){
                          return lb.action='eye-fill';
                      });
                  }
              });
          },
          handleDomainReq() {
              this.$v.form1.$touch();
              if (this.$v.form1.$invalid) {
                  this.makeToast("error", "admin-setting-domain-error");
              } else {
                  let user_data = JSON.parse(localStorage.user_sess_data);
                  let data = this.form1;
                  data.client_id = user_data.client_id;
                  data.token = user_data.token;
                  adminSaveDomain(data).then(response => {
                      if(response.status){
                          this.makeToast("success", response.message);
                          this.$v.form1.$reset();
                          this.form1.domain = "";
                          this.domainsList();
                      } else {
                          this.makeToast("error", response.message);
                      }
                  }); 
              }
          },
          confirmDelete(domain_id) {
              this.delete_domain_id = domain_id;
              this.$bvModal.show('delete-modal');
          },
          deleteDomain() {
              let user_data = JSON.parse(localStorage.user_sess_data);
              let params = {
                  token: user_data.token,
                  domain_id: this.delete_domain_id
              };
              adminDeleteDomain(params).then(response => {
                  if(response.status) {
                      this.$bvModal.hide('delete-modal');
                      this.makeToast("success", response.message);
                      this.domainsList();
                  }
                  else {
                      this.makeToast("error", response.message);
                  }
              });
          },
          handleThemeColorsReq() {
              this.$v.form2.$touch();
              if (this.$v.form2.$invalid) {
                  this.makeToast("error", "generic-required-message");
              } else {
                  if((this.form2.primary_color != null && this.form2.primary_color != "" && !this.isValidHexColor(this.form2.primary_color)) 
                  || (this.form2.accent_color != null && this.form2.accent_color != "" && !this.isValidHexColor(this.form2.accent_color)) 
                  || (this.form2.gradiant_color != null && this.form2.gradiant_color != "" && !this.isValidHexColor(this.form2.gradiant_color)) 
                  || (this.form2.hover_color != null && this.form2.hover_color != "" && !this.isValidHexColor(this.form2.hover_color))) {
                      this.makeToast("error", "admin-setting-color-format-error");
                      return;
                  }
              let user_data = JSON.parse(localStorage.user_sess_data);
              let data = this.form2;
              data.client_id = user_data.client_id;
              data.token = user_data.token;
              adminUpdateThemeAndLogo(data).then(response => {
                  if(response.status){
                      this.makeToast("success", response.message);
                      this.clientDetails();
                      this.$store.commit("setThemeLogo", response.data);
                  } else {
                      this.makeToast("error", response.message);
                  }
              }); 
              }
          },
          isValidHexColor(color) {
              if(color == null || color == '') return false;
              let reg = /^#([0-9a-f]{3}){1,2}$/i;
              return reg.test(color);
          },
          handleAudioReq() {
              this.$v.form3.$touch();
              if (this.$v.form3.$invalid) {
                  this.makeToast("error", "generic-required-message");
              } else {
                  let user_data = JSON.parse(localStorage.user_sess_data);
                  let data = this.form3;
                  data.client_id = user_data.client_id;
                  data.token = user_data.token;
                  adminUpdateThemeAndLogo(data).then(response => {
                      if(response.status){
                          this.makeToast("success", response.message);
                          this.$v.form3.$reset();
                          this.form3.audio = null;
                          this.clientDetails();
                      } else {
                          this.makeToast("error", response.message);
                      }
                  }); 
              }
          },
          removeAudio() {
              let user_data = JSON.parse(localStorage.user_sess_data);
              let params = {
                  token: user_data.token,
                  client_id: user_data.client_id,
                  remove_audio: 1
              };
              adminUpdateThemeAndLogo(params).then(response => {
                  if(response.status){
                      this.makeToast("success", response.message);
                      this.clientDetails();
                  } else {
                      this.makeToast("error", response.message);
                  }
              }); 
          },
      },
  
      mounted() {
          this.palletsList();
          this.clientDetails();
          this.domainsList();
          this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
              if(modalId == "delete-modal") {
                  this.delete_domain_id = "";
              }
          });
      }
  };
  </script>
  <style scoped>
  .color-preview {
      width: 76px;
  }
  </style>