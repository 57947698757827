<template>
    <div class="main-layout-section admin-portal" :class="[user_data.role_id == 4 ? '' : 'secondary']">
        <AdminHeader @setPodData="setPodData($event)" v-if="user_data.role_id == 3" />
        <SuperAdminHeader v-if="user_data.role_id == 4" />

        <div class="w-logo mb-3">
            <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="logo_file !== '' && logo_file !== null && user_data.role_id !== 4"/>
            <img src="../../assets/images/gamelab-logo.svg" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="user_data.role_id == 4"/>
        </div>

        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeInUp animate__delay-1s">Manage Users</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">

                    <div class="row">

                        <div class="admin-general-section-buttons col-md-12">
                            <router-link :to="dashboard_link" class="admin-general-section-button">
                                Back To Dashboard
                            </router-link>
                            <router-link to="/common/users/create" class="admin-general-section-button">
                                Create New User
                            </router-link>

                            <b-overlay :show="is_searching" rounded="sm" variant="dark" opacity="0.80" class="table-search-input-overlay">
                                <div class="w-form-control w-input-fields table-search-input" v-if="user_data.role_id == 4">
                                    <span><i class="fa-solid fa-user"></i></span>
                                    <b-form-select
                                        v-model="search_client_id"
                                        :options="clients"
                                        text-field="value"
                                        value-field="id"
                                        id="client_id"
                                        class=""
                                        placeholder="Select a client" 
                                        @change="searchByClient"
                                    ></b-form-select>
                                </div>
                                <div class="w-form-control w-input-fields" :class="[user_data.role_id !== 4 ? 'table-search-input' : '']">
                                    <span><i class="fa-solid fa-search"></i></span>
                                    <input
                                        type="text"
                                        v-model="search"
                                        placeholder="press enter to search..." 
                                        @keyup.enter="searchUsersList"
                                    />
                                </div>
                            </b-overlay>
                        </div>
                    </div>

                    <div class="col-sm-12">
                        <div class="overflow-auto p-0 m-0">
                            <b-table
                            id="admin-general-table"
                            :striped="true"
                            :hover="false"
                            :dark="false"
                            :items="users"
                            :fields="(user_data.role_id == 4) ? fields_superadmin : fields"
                            show-empty
                            :emptyText="emptyText" 
                            >

                                <!-- start table header elements -->
                                <template #head()="data">
                                    <div class="alert-header" v-if="data.label !== '' && (data.label == 'Play Time' || data.label == 'Paid Time' || data.label == 'Signed-Up')">
                                        <span>{{ data.label }}</span>
                                        <div class="alert-sort-buttons">
                                            <button class="btn-sm" :class="[(sort_by == data.column && sort_type == 'desc') ? 'active-sort' : '']" @click="handleSorting(data.column, 'desc')">
                                                <i class="fas fa-caret-down icon m-0"></i>
                                            </button>
                                            <button class="btn-sm" :class="[(sort_by == data.column && sort_type == 'asc') ? 'active-sort' : '']" @click="handleSorting(data.column, 'asc')">
                                                <i class="fas fa-caret-up icon m-0"></i>
                                            </button>
                                        </div>
                                    </div>
                                </template>

                                <template #cell(action)="data">
                                    <div class="icon-action-buttons-container d-flex flex-row">
                                        <!-- edit button -->
                                        <router-link :to="`/common/users/view/${data.item.id}`" :class="data.value" class="btn-sm">
                                            <b-icon class="icon" icon="eye-fill"></b-icon>
                                            View
                                        </router-link>
                                        <!-- edit button -->
                                        <!-- edit button -->
                                        <!-- <router-link :to="`/common/users/edit/${data.item.id}`" :class="data.value" class="btn-sm">
                                            <b-icon class="icon" icon="pencil-fill"></b-icon>
                                            Edit
                                        </router-link> -->
                                        <!-- edit button -->
                                    </div>
                                </template>
                                <template #cell(user_role_id)="data">
                                    <template v-if="data.item.player !== null && Object.prototype.hasOwnProperty.call(data.item.player, 'pro')">
                                        <template v-if="data.item.player.pro == 1">
                                            Pro
                                        </template>
                                        <template v-else>
                                            {{ data.item.role.role_name }}
                                        </template>
                                    </template>
                                    <template v-else>
                                        {{ data.item.role.role_name }}
                                    </template>
                                </template>
                                <template #cell(client_email)="data">
                                    <template v-if="data.item.client !== null && Object.prototype.hasOwnProperty.call(data.item.client, 'email')">
                                        {{ data.item.client.email }}
                                    </template>
                                    <template v-else-if="data.item.clientuser !== null && Object.prototype.hasOwnProperty.call(data.item.clientuser, 'client') && data.item.clientuser.client !== null && Object.prototype.hasOwnProperty.call(data.item.clientuser.client, 'email')">
                                        {{ data.item.clientuser.client.email }}
                                    </template>
                                </template>
                                <template #cell(email_verified_at)="data">
                                    <i class="fas fa-circle-check fa-2xl icon h2" v-if="data.item.email_verified_at !== null"></i>
                                    <i class="fas fa-times-circle fa-2xl icon h2" v-if="data.item.email_verified_at == null" style="color:#FF1744;"></i>
                                </template>
                                <template #cell(time_paid)="data">
                                    <template v-if="data.item.player !== null && Object.prototype.hasOwnProperty.call(data.item.player, 'time_paid')">
                                        {{ formatTimeString(data.item.player.time_paid) }}
                                    </template>
                                    <template v-else>
                                        N/A
                                    </template>
                                </template>
                                <template #cell(time_play)="data">
                                    <template v-if="data.item.player !== null && Object.prototype.hasOwnProperty.call(data.item.player, 'time_play')">
                                        {{ formatTimeString(data.item.player.time_play) }}
                                    </template>
                                    <template v-else>
                                        N/A
                                    </template>
                                </template>
                                <template #cell(created_at)="data">
                                    {{ data.item.formatted_created_at_tz }}
                                </template>
                                <template #empty="scope">
                                <p class="text-center p-0 m-0">{{ scope.emptyText }}</p>
                                </template>
                            </b-table>
                        </div>
                        <b-pagination
                        v-model="current_page"
                        :total-rows="total_rows"
                        :per-page="per_page" 
                        limit="3"
                        aria-controls="admin-general-table" 
                        align="right"
                        ></b-pagination>
                        <p class="admin-general-table-status-text">{{ status_text }}</p>
                    </div>
                </div>

            </div>

        </div>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <AdminFooter type="inner" v-if="user_data.role_id == 3" />
            <SuperAdminFooter type="inner" v-if="user_data.role_id == 4" />
        </div>
        <!-- end of footer section -->
    </div>
</template>

<script>
import AdminHeader from "@/views/admin/AdminHeader.vue";
import { adminGetUsersList, adminGetClientsList } from "../../services/AdminService";
import AdminFooter from "@/views/admin/AdminFooter.vue";
import SuperAdminHeader from "@/views/superadmin/SuperAdminHeader.vue";
import SuperAdminFooter from "@/views/superadmin/SuperAdminFooter.vue";

export default {
    name: "UsersList-view",
    components: { AdminHeader, AdminFooter, SuperAdminHeader, SuperAdminFooter },
    data() {
        return {
            is_searching: false,
            search: '',
            search_client_id: '',
            user_data: null,
            dashboard_link: '',
            status_text: '',
            per_page: 10,
            current_page: 1,
            total_rows: 0,
            emptyText:"No users found at the moment!",
            emptyHtml:"No users found at the moment!",
            fields: [
                {'key' : 'id', 'label' : 'ID'}, 
                {'key' : 'user_name', 'label' : 'Username'},
                {'key' : 'time_play', 'label' : 'Play Time'},
                {'key' : 'time_paid', 'label' : 'Paid Time'},
                {'key' : 'user_role_id', 'label' : 'Role'}, 
                {'key' : 'email_verified_at', 'label' : 'Active'},
                {'key' : 'created_at', 'label' : 'Signed-Up'},
                {'key' : 'action', 'label' : 'Action'}
            ],
            fields_superadmin: [
                {'key' : 'id', 'label' : 'ID'}, 
                {'key' : 'client_email', 'label' : 'Client Email'},
                {'key' : 'user_name', 'label' : 'Username'},
                {'key' : 'time_play', 'label' : 'Play Time'},
                {'key' : 'time_paid', 'label' : 'Paid Time'},
                {'key' : 'user_role_id', 'label' : 'Role'}, 
                {'key' : 'email_verified_at', 'label' : 'Active'},
                {'key' : 'created_at', 'label' : 'Signed-Up'},
                {'key' : 'action', 'label' : 'Action'}
            ],
            users: [],
            clients: [],
            sort_by: 'created_at',
            sort_type: 'desc',
        };
    },

    computed: {
        logo_file() {
            return this.$store.getters.getThemeLogo().logo;
        }
    },

    watch: {
        current_page: {
            handler: function(value) {
                this.current_page = value;
                this.usersList();
            }
        }
    },

    methods: {
        usersList() {
            this.is_searching = true;
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                client_id: (this.search_client_id !== '') ? this.search_client_id : user_data.client_id,
                length: this.per_page,
                page: this.current_page,
                search: this.search,
                sort_by: this.sort_by,
                sort_type: this.sort_type,
            };
            adminGetUsersList(params).then(response => {
                this.is_searching = false;
                this.users = response.data.users;
                this.current_page = response.data.current_page;
                this.total_rows = response.data.total_rows;
                this.status_text = response.data.status_text;
                if(this.users.length > 0) {
                    this.users.map(function(lb){
                        return lb.action='icon-button-actions';
                    });
                }
            });
        },
        makeToast(variant = "default", message) {
            this.$root.$emit("toast_message", {'type' : variant, 'message' : message});
        },
        setPodData(obj) {
            this[obj.key] = obj.value;
        },
        setUserData() {
            this.user_data = JSON.parse(localStorage.user_sess_data);
            if(!Object.prototype.hasOwnProperty.call(this.user_data, 'role_id')) {
                this.$router.push("/admin/login");
            }
            this.dashboard_link = this.user_data.role_id == 3 ? '/admin/dashboard' : '/superadmin/dashboard';
            if(this.user_data.role_id == 4) {
                this.clientsList();
            }
        },
        searchUsersList() {
            if(this.current_page > 1) {
                this.current_page = 1;
            }
            else {
                this.usersList();
            }
        },
        clientsList() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token
            };
            adminGetClientsList(params).then(response => {
                this.clients = response.data.clients;
                // add empty option as placeholder
                this.clients.splice(0, 0, {'id' : '', 'value' : 'All clients'});
            });
        },  
        searchByClient() {
            this.usersList();
        },
        formatTimeString(time) {
            if(typeof time == 'undefined' || time == 'null' || time == '') {
                return '0m';
            }
            else {
                // convert into hours and minutes
                let hours = Math.floor(time / 60);
                let minutes = Math.ceil(time % 60); 
                if(hours > 0) {
                    return hours + "h " + minutes + "m";
                }
                else {
                    return minutes + "m";
                }
                
            }
        },
        handleSorting(column, type) {
            if(!(this.sort_by == column && this.sort_type == type)) {
                this.sort_by = column;
                this.sort_type = type;
                this.usersList();
            }
        }
    },

    mounted() {
        this.usersList();
    },
    created() {
        this.setUserData();
    }
};
</script>
<style scoped>
.alert-header {
    display: flex;
    column-gap: 15px;
    align-items: center;
}
.alert-header .alert-sort-buttons {
    display: flex;
    column-gap: 5px;
}
.alert-header .alert-sort-buttons button {
    background: transparent;
    color: #d8d8d8;
    border: 1px solid #d8d8d8;
    border-radius: 50%;
    width: 25px;
    height: 25px;
}
.alert-header .alert-sort-buttons button.active-sort {
    background-color: var(--client-primary);
    border-color: var(--client-primary);
    color: #191919;
}
</style>