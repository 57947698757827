<template>
  <div>
    <!-- <v-idle
    @idle="onidle"
    @remind="onremind"
    :loop="true"
    :reminders="[10, 20]"
    :wait="5"
    :duration="inactivity_duration" /> -->

    <div class="game-startup-timer d-none" v-if="game_init">
      <span>00:{{ game_startup_duration < 10 ? "0"+game_startup_duration : game_startup_duration }}</span>
    </div>
    <!-- start of game startup modal -->
    <b-modal id="game-startup-modal" header-class="bg-labPrimary text-white px-3 py-2" hide-footer :hide-header-close="hide_header_close" :no-close-on-backdrop="no_close_bd" :no-close-on-esc="no_close_esc">
      <template #modal-title>
        Alert
        <b-icon 
          class="icon" 
          icon="question-circle-fill"
          >
        </b-icon> 
      </template>
      <b-overlay :show="false" rounded="lg">
        <div class="d-flex">
          <p>{{ game_startup_message }}</p>
        </div>
        <div class="d-flex text-center">
          <button
            type="button"
            class="btn btn-info text-white px-5"
            @click="$bvModal.hide('game-startup-modal')"
            v-if="game_startup_button !== ''"
          >
            {{ game_startup_button }}
          </button>
        </div>
      </b-overlay>
    </b-modal>

  </div>
</template>
<script>
import { getPodLogoutReason, getPodLogoutAcknowledge, getPodId, getPodVolume, playFlaskGame, getXdTypes, getFlaskGameStarted, setFlaskLED1, setFlaskLED2, setFlaskResetPod, setFlaskPodDisabled, getFlaskGamePlaying, setFlaskPodSchedule, setFlaskPodWebTime, setFlaskRebootPod } from "../../services/FlaskService";
import { getPodDetails, setPodLockout, getPlayerRemainingTime } from "../../services/PlayerService";
import { global_config } from "@/config/config.js";
import store from "@/store";
import { sessionActivitySave } from "../../services/HomepageService";
import { handlePushMenuPolling } from "../../services/Guard";

export default {
    name: "PlayerPollingAndSockets-view",
    data() {
        return {
            logout_polling : null,
            lockout_polling : null,
            volume_polling : null,
            pod_id_key : "",
            pod_locked: false,
            pod_data: {},
            inactivity_duration: global_config.INACTIVITY_DURATION,
            lockout_duration : global_config.LOCKOUT_DURATION,
            xds_data: {
              "xd1" : "", 
              "xd2" : "", 
              "xb_disabled": true, 
              "ps_disabled": true, 
              "sw_disabled": true, 
              "pc_disabled": true, 
              "pod_disabled": true
            },
            game_init: false,
            game_startup_duration: global_config.GAME_STARTUP_DURATION,
            game_polling: null,
            game_startup_message: "",
            game_startup_button: "",
            hide_header_close: false,
            no_close_bd: false,
            no_close_esc: false,
            game_playing_polling: null,
            xds_polling: null,
            menu_polling: null,
            remaining_time_polling: null,
            // pod_idle_polling: null,
            // pod_idle_polling: null,
            pod_inactive_polling: null,
        };
    },
    methods: {
        pollFlaskLogout () {
            this.logout_polling = setInterval(() => {
                // get logout status
                getPodLogoutReason().then(logout_reason => {
                    if(logout_reason.data.logout !== undefined && logout_reason.data.logout == 1 
                    && logout_reason.data.logout_reason !== undefined && logout_reason.data.logout_reason.length > 0) {
                        getPodLogoutAcknowledge().then(logout_acknowledge => {
                            if(logout_acknowledge.success !== undefined && logout_acknowledge.success) {
                                let reason = "";
                                if(logout_reason.data.logout_reason.toLowerCase() == 'button') {
                                    reason = "You pushed the physical logout button";
                                    this.$parent.handleLogout(reason);
                                }
                                else if(logout_reason.data.logout_reason.toLowerCase() == 'seat') {
                                    reason = "You left your seat for more than 30 seconds";
                                    this.inactivityLogout(reason, 'seat');
                                }
                            }
                        });
                    }
                });
            }, process.env.VUE_APP_POLLING_TIME_POD_LOGOUT_REASON_CHECK);
        },
        pollFlaskVolume () {
            this.volume_polling = setInterval(() => {
                // get logout status
                getPodVolume().then(vol => {
                    if(vol.data.vol_now !== undefined && vol.data.vol_now !== "") {
                        this.$parent.volumeChange(vol.data.vol_now, "");
                    }
                });
            }, process.env.VUE_APP_POLLING_TIME_POD_VOLUME_CHECK);
        },
        onidle() {
            // this.$parent.handleLogout("You have been logged out due to inactivity", "lock");
            // this.inactivityLogout("You have been logged out due to inactivity", "lock");
        },
        onremind(time) {
            console.log("Remaining Time : ", time);
        },
        getPodIdFromFlask() {
            getPodId().then(pod => {
                if(pod.data.pod_id !== undefined && pod.data.pod_id.length > 0) {
                    this.pod_id_key = pod.data.pod_id;
                    this.$emit('setPodData', {'key' : 'pod_id_key', 'value' : this.pod_id_key});
                    this.podDetails();
                }
            });
        },
        podDetails(type="") {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                session_id_key : pod_data.session_id_key,
                session_id : pod_data.session_id_key,
                token: user_data.token
            };
            getPodDetails(this.pod_id_key, params).then(response => {
                if(response.status) {
                    this.pod_data = response.data.pod;
                    this.pod_locked = response.data.pod.lock_out == 1 ? true : false;
                    this.$emit('setPodData', {'key' : 'pod_locked', 'value' : this.pod_locked});
                    this.$emit('setPodData', {'key' : 'pod_data', 'value' : this.pod_data});
                    this.$emit('setPodVolume', response.data.pod.volume);
                    this.isLockoutNeeded();
                }
                if(type !== '') {
                    this.pollXdTypes();
                }
            });
        },

        isLockoutNeeded() {
            if(this.pod_locked) {
                this.lockout_duration = global_config.LOCKOUT_DURATION;
                this.lockout_polling = setInterval(() => {
                    this.lockout_duration--;
                    if(this.lockout_duration <= 0) {
                        // logout user on lockout timeout
                        let reason = "You have been logged out due to lockout by Admin";
                        // this.$parent.handleLogout(reason, "lock");
                        this.inactivityLogout(reason, "lock");
                    }
                }, 1000);
            }
            else {
                clearInterval(this.lockout_polling);
            }
        },

        gameStartInitiated(params) {
            // // show popup for init game
            // this.game_startup_message = "The selected game will be played soon!";
            // this.game_startup_button = "Ok";
            // this.$bvModal.show('game-startup-modal');
            this.game_init = true;
            // this.game_startup_duration = global_config.GAME_STARTUP_DURATION;
            this.game_startup_duration = 0;
            clearTimeout(this.game_polling);
            clearInterval(this.remaining_time_polling);
            this.remaining_time_polling = null;
            this.game_polling = setInterval(() => {
                // get xd types for buttons
                // let self = this;
                getFlaskGameStarted().then(gs => {
                    if(gs.success && gs.data !== undefined && typeof gs.data.game_started !== undefined) {
                        if(!gs.data.game_started) {
                            //   self.game_startup_message = "Still Here? Something must have gone wrong--please start a chat with us!";
                            //   self.game_startup_button = "Yes";
                            //   self.$bvModal.show('game-startup-modal');
                            if(this.game_startup_duration == global_config.GAME_STARTUP_DURATION) {
                                this.$root.$emit("game_message", {'type' : 'fail'});
                            }
                        }
                        else {
                            clearInterval(this.game_polling);
                            this.game_init = false;
                            this.$root.$emit("game_message", {'type' : 'success'});
                            localStorage.removeItem('game_playing');
                            localStorage.setItem('game_playing_file', true);
                            this.recordGamePlayActivity(params, 'first');
                        }
                    }
                    else {
                        if(this.game_startup_duration == global_config.GAME_STARTUP_DURATION) {
                            this.$root.$emit("game_message", {'type' : 'fail'});
                        }
                    }
                });
                this.game_startup_duration += 3;
            }, process.env.VUE_APP_POLLING_TIME_POD_GAME_STARTED_CHECK);
        },

        playGame(gid, search, platform, install=null, guests="") {
            if(typeof this.pod_data.id !== undefined) {
                this
                //   let user_data = JSON.parse(localStorage.user_sess_data);
                //   let pod_data = JSON.parse(localStorage.pod_sess_data);
                let params = {
                    // pod_id : this.pod_id_key,
                    // nav_id: this.pod_data.nav_id,
                    // session_id : pod_data.session_id_key,
                    // user_id: user_data.user_id,
                    // game_id: gid,
                    // xb1: this.xds_data[this.xds_data.xd1+"_disabled"],
                    // xb2: this.xds_data[this.xds_data.xd2+"_disabled"],
                    platform: platform,
                    select_type: install,
                    search_title: search
                };
                playFlaskGame(params).then(response => {
                    response;
                    // this.makeToast("success", "Play game initiated on your Kiosk machine!");
                    // this.$root.$emit("toast_message", {'type' : 'success', 'message' : 'Play game initiated on your Kiosk machine!'});
                    this.$root.$emit("game_message", {'type' : 'init'});
                    params['game_id'] = gid;
                    params['guests'] = guests;
                    this.gameStartInitiated(params);
                })
            }
            else {
                //   this.makeToast("success", "Unable to initiate game play on your Kiosk machine!, please try again after refreshing page");
                this.$root.$emit("toast_message", {'type' : 'warning', 'message' : 'player-games-play-error'});
            }
        },

        getXdsData() {
            // get xd types for buttons
            getXdTypes().then(xd => {
                if(xd.data !== undefined) {
                    this.xds_data = xd.data;
                    this.$emit('setPodData', {'key' : 'xds_data', 'value' : xd.data});
                }
            });
        },

        inactivityLogout(reason, type="", survey_needed=true) {
            let self = this;
            getFlaskGameStarted().then(gs => {
                var game_started = false;
                if(gs.success && gs.data !== undefined && typeof gs.data.game_started !== undefined && typeof gs.data.game_started !== 'undefined') {
                    if(!gs.data.game_started) {
                        game_started = true;
                    }
                }

                if(!game_started){
                    //   self.hide_header_close = true;
                        //   self.no_close_bd = true;
                        //   self.no_close_esc = true;
                        //   self.game_startup_message = "Logging out user due to Timeout!";
                        //   self.game_startup_button = "";
                        //   self.$bvModal.show('game-startup-modal');
                        //   setTimeout(() => {
                        //     self.$parent.handleLogout(reason, type);
                        //   }, 5000);
                        self.$parent.handleLogout(reason, type, survey_needed);
                }else{
                    // action when game started. 
                    // no logout in that case.
                    // ...
                }
            });
        },

        makeToast(variant = "default", message) {
            this.$toast.open({
                message: message,
                type: variant,
                position: 'top-right',
                duration: 3000,
                dismissible: true
            });
        },

        recordGamePlayActivity(params, type) {
            let activity = "";
            if(params.platform == 'xb') {
                activity = global_config.ACTIVITIES.PLAY_XBOX;
            }
            else if(params.platform == 'pc') {
                activity = global_config.ACTIVITIES.PLAY_PC;
            }
            else if(params.platform == 'sw') {
                activity = global_config.ACTIVITIES.PLAY_SWITCH;
            }
            else if(params.platform == 'ps') {
                activity = global_config.ACTIVITIES.PLAY_PS5;
            }

            // check if host is logged in
            if(typeof localStorage.user_sess_data !== 'undefined') {
                let user_data = JSON.parse(localStorage.user_sess_data);
                if(
                    Object.prototype.hasOwnProperty.call(user_data, 'is_host') && 
                    user_data.is_host == 'Yes'
                ) {
                    activity = global_config.ACTIVITIES.UI_EVENTPLAY;
                }
            }

            // alert(JSON.stringify(params));
            // alert(type);
            // call game play activity record api
            if(activity !== "") {
                if(type == 'first') {
                    localStorage.setItem('game_playing', JSON.stringify({'gid': params.game_id, 'gname': params.search_title, guests: params.guests}));
                }
                sessionActivitySave({'activity': activity, 'game_id' : params.game_id, 'guests': params.guests}).then((response) => {
                    console.log("ACTIVITY API CALLED : ", response);
                });
            }
        },

        gamePlayingPolling() {
            clearTimeout(this.game_playing_polling);
            this.game_playing_polling = setInterval(() => {
                let is_playing = false;
                getFlaskGameStarted().then(gs => {
                    if(gs.success && gs.data !== undefined && typeof gs.data.game_started !== undefined) {
                        if(gs.data.game_started) {
                            getFlaskGamePlaying().then(gp => {
                                if (
                                    Object.prototype.hasOwnProperty.call(gp, "success") && gp.success &&
                                    Object.prototype.hasOwnProperty.call(gp, "data") &&
                                    Object.prototype.hasOwnProperty.call(gp.data, "platform") &&
                                    gp.data.platform.length > 0
                                ) {
                                    // set game playing file exists
                                    localStorage.setItem('game_playing_file', 'true');
                                    is_playing = true;
                                    if(typeof localStorage.game_playing !== 'undefined') {
                                        let game_data = JSON.parse(localStorage.game_playing);
                                        if (typeof game_data.gid !== 'undefined') {
                                            this.recordGamePlayActivity({platform: gp.data.platform, game_id: game_data.gid, guests: game_data.guests}, 'loop');
                                        }
                                    }
                                }
                                else {
                                    this.updateActivity(is_playing);
                                }
                            });
                        }
                        else {
                            this.updateActivity(is_playing);    
                        }
                    }
                    else {
                        this.updateActivity(is_playing);
                    }
                });
            }, process.env.VUE_APP_POLLING_TIME_GAME_PLAYING_CHECK);
        },

        pollXdTypes () {
            this.xds_polling = setInterval(() => {
                // get xd types for buttons
                getXdTypes().then(xd => {
                    if(xd.data !== undefined) {
                        if(xd.data.pod_disabled !== this.pod_locked) {
                            this.lockoutChange(xd.data.pod_disabled);
                        }
                    }
                });
            }, process.env.VUE_APP_POLLING_TIME_XD_TYPE_CHECK);
        },

        lockoutChange(lockout) {
            clearInterval(this.xds_polling);
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                session_id_key : pod_data.session_id_key,
                session_id: pod_data.session_id_key,
                pod_id_key: this.pod_id_key,
                lockout: lockout ? 'yes' : 'no',
            };

            setPodLockout(params).then(response => {
                if(response.status) {
                    this.podDetails("poll");
                }
                else {
                    this.podDetails("poll");
                }
            })
        },
        remainingTimePolling() {
            clearInterval(this.remaining_time_polling);
            this.remaining_time_polling = null;
            this.remaining_time_polling = setInterval(() => {
                // call payment save function here
                let user_data = JSON.parse(localStorage.user_sess_data);
                let pod_data = JSON.parse(localStorage.pod_sess_data);
                let params = {
                    session_id_key : pod_data.session_id_key,
                    token: user_data.token,
                    user_id: user_data.user_id
                };

                // check if game pod is paid mode. 
                let is_paid_lab = (pod_data.is_paid != undefined) ? pod_data.is_paid : 1;
                if(!is_paid_lab){
                    // when free mode, no need to check remaining time.
                    clearInterval(this.remaining_time_polling);
                    this.remaining_time_polling = null;
                }else{
                    getPlayerRemainingTime(params).then(rmt => {
                        // set local storage record
                        pod_data.is_paid = rmt.data.mode == 'paid' ? 1 : 0;
                        localStorage.setItem('pod_sess_data', JSON.stringify(pod_data));

                        // send stream default call
                        setFlaskPodWebTime({web_time: rmt.data.time}).then(fst => {
                            console.log("WEB TIME API CALLED : ", fst);
                        });
                        // if time remaining is less than 0
                        if(rmt.data.time <= 0 && (this.$route.path == '/player/games/xd1' || this.$route.path == '/player/games/xd2')) {
                            clearInterval(this.remaining_time_polling);
                            this.remaining_time_polling = null;
                            this.$router.push('/player/dashboard');
                        }
                        else {
                            // convert into hours and minutes
                            let hours = Math.floor(rmt.data.time / 60);
                            let minutes = Math.ceil(rmt.data.time % 60);
                            this.$root.$emit("remaining_time", { 'time' : rmt.data.time, 'ftime' : hours + "h " + minutes + "m"});
                        }
                    });
                }
            }, process.env.VUE_APP_POLLING_TIME_PLAYER_REMAIN_TIME_CHECK);
        },
        updateActivity(is_playing) {
            // check if game playing is stopped
            let is_stopped_playing = localStorage.getItem('game_playing_file');
            if(typeof is_stopped_playing !== 'undefined' && is_stopped_playing == 'true' && is_playing == false) {
                localStorage.removeItem('game_playing_file');
                localStorage.removeItem('game_playing');
                sessionActivitySave({'activity': global_config.ACTIVITIES.UI_DASHBOARD}).then((response) => {
                    console.log("ACTIVITY API CALLED : ", response);
                });
            }
        },
        inactivePolling() {
            clearInterval(this.pod_inactive_polling);
            this.pod_inactive_polling = setInterval(() => {
                // check if host logged in
                if(typeof localStorage.user_sess_data !== 'undefined') {
                    let user_data = JSON.parse(localStorage.user_sess_data);
                    if(Object.prototype.hasOwnProperty.call(user_data, 'is_host') && user_data.is_host == 'Yes') {
                        localStorage.setItem('last_activity', new Date());
                    }
                }

                let last_activity = Math.floor((new Date()).getTime() - (new Date(localStorage.getItem('last_activity'))).getTime());
                this.$store.commit("setInactiveCounter", last_activity);
                if(last_activity >= process.env.VUE_APP_LOGOUT_INACTIVITY_DURATION) {
                    this.inactivityLogout("You have been logged out due to inactivity", "lock", false);
                    return;
                }
            }, process.env.VUE_APP_POLLING_INACTIVITY);
        }
    },
    computed: {
      getMessage() {
        return store.getters.getMessage;
      },
    },
    mounted() {
        // check if channel already subscribed
        if(!Object.prototype.hasOwnProperty.call(window.Echo.connector.channels, "pod-command")) {
            // listen for websocket messages
            window.Echo.channel('pod-command').listen('PodCommandEvent', (e) => {
                if(
                e.message.data.pod_ids.indexOf(this.pod_id_key) !== -1 && 
                e.message.server_info == process.env.VUE_APP_WS_ENV
                ) {
                    if(e.message.data.command_type == 'reset') {
                        // call pod reset api from flask before logging out
                        setFlaskResetPod().then(reset => {
                        console.log("RESET POD API IS CALLED : ", reset);
                        });
                        this.$parent.handleLogout("We're closed right now! Come back and see us tomorrow.", "closed");
                    }
                    else if(e.message.data.command_type == 'logout') {
                        this.$parent.handleLogout("Admin forced the logout from kioske", "admin", false);
                    }
                    else if(e.message.data.command_type == 'reboot') {
                        setFlaskRebootPod().then(reb => {
                            console.log("REBOOT POD API IS CALLED : ", reb);
                        });
                        this.$parent.handleLogout("Admin forced the kioske reboot", "push", false);
                    }
                    else if(e.message.data.command_type == 'lockout' && typeof localStorage.user_sess_data !== 'undefined') {
                        clearInterval(this.xds_polling);
                        // call flask disabled api
                        let params1 = {
                            pod_disabled: 'yes'
                        };
                        setFlaskPodDisabled(params1).then(ds => {
                            console.log("FLASK DISABLED API CALLED : ", ds);
                            this.podDetails('poll');
                        });
                    }
                    else if(e.message.data.command_type == 'unlockout' && typeof localStorage.user_sess_data !== 'undefined') {
                            clearInterval(this.xds_polling);
                        // call flask disabled api
                        let params1 = {
                            pod_disabled: 'no'
                        };
                        setFlaskPodDisabled(params1).then(ds => {
                            console.log("FLASK DISABLED API CALLED : ", ds);
                            this.podDetails('poll');
                            clearInterval(this.lockout_polling);
                        });
                    }
                    else if(e.message.data.command_type == 'play_game') {
                        if(typeof this.xds_data[e.message.data.game_platform] !== undefined && this.xds_data[this.xds_data[e.message.data.game_platform]+"_disabled"] || 1==1) {
                            this.game_startup_message = "Game play initiated but platform " + e.message.data.game_platform.toUpperCase() + " not supported by pod";
                            this.game_startup_button = "Ok";
                            this.$bvModal.show('game-startup-modal');
                        }
                        else {
                            this.playGame(e.message.data.command_value, e.message.data.game_name, this.xds_data[e.message.data.game_platform]);
                        }
                    }
                    else if(e.message.data.command_type == 'admin_play_game') {
                        if((this.xds_data['xd1'] == e.message.data.game_platform || this.xds_data['xd2'] == e.message.data.game_platform) && typeof this.xds_data[e.message.data.game_platform+"_disabled"] != 'undefined' && !this.xds_data[e.message.data.game_platform+"_disabled"]) {
                            this.playGame(e.message.data.command_value, e.message.data.game_name, e.message.data.game_platform);
                        }
                    }
                    else if(e.message.data.command_type == 'led1' || e.message.data.command_type == 'led2') {
                        if(e.message.data.command_type == 'led1') {
                            let params = {
                                led1_default: e.message.data.command_value
                            };
                            setFlaskLED1(params, 'default').then(lc => {
                                console.log("LED1 CHANGE FLASK API CALLED : ", lc);
                            });
                        }
                        else if(e.message.data.command_type == 'led2') {
                            let params = {
                                led2_default: e.message.data.command_value
                            };
                            setFlaskLED2(params, 'default').then(lc => {
                                console.log("LED2 CHANGE FLASK API CALLED : ", lc);
                            });
                        }
                        
                    }
                    else if(e.message.data.command_type == 'led1_lock' || e.message.data.command_type == 'led2_lock') {
                        if(e.message.data.command_type == 'led1_lock') {
                            let params = {
                                led1_lock: e.message.data.command_value
                            };
                            setFlaskLED1(params, 'lock').then(lc => {
                                console.log("LED1 CHANGE FLASK API CALLED : ", lc);
                            });
                        }
                        else if(e.message.data.command_type == 'led2_lock') {
                            let params = {
                                led2_lock: e.message.data.command_value
                            };
                            setFlaskLED2(params, 'lock').then(lc => {
                                console.log("LED2 CHANGE FLASK API CALLED : ", lc);
                            });
                        }
                        
                    }
                    else if(e.message.data.command_type == 'led_default') {
                        if(e.message.data.led_type == 'led1') {
                            let params = {
                                led1_default: e.message.data.command_value
                            };
                            setFlaskLED1(params, 'default').then(lc => {
                                console.log("LED1 DEFAULT FLASK API CALLED : ", lc);
                            });
                        }
                        else if(e.message.data.led_type == 'led2') {
                            let params = {
                                led2_default: e.message.data.command_value
                            };
                            setFlaskLED2(params, 'default').then(lc => {
                                console.log("LED2 DEFAULT FLASK API CALLED : ", lc);
                            });
                        }
                    }
                    else if(e.message.data.command_type == 'schedule') {
                        // set pod schedule
                        if(e.message.data.start !== '' && e.message.data.end !== '' ) {
                            setFlaskPodSchedule({'scheduled_start': (e.message.data.is_closed ? 'closed' : e.message.data.start), 'scheduled_sleep': (e.message.data.is_closed ? 'closed' : e.message.data.end)}).then(sch => {
                                console.log("FLASK POD SCHEDULE CALLED : ", sch);
                            });
                            // check if out of schedule
                            if(e.message.data.out_of_schedule) {
                                // trigger pod logout as mode changed
                                this.$parent.handleLogout("We're closed right now! Come back and see us tomorrow.", 'web');        
                            }
                        }
                    }
                    else if(e.message.data.command_type == 'modechange') {
                        // trigger pod logout as mode changed
                        this.$parent.handleLogout(e.message.message, 'web');
                    }
                    else if(e.message.data.command_type == 'navlogout') {
                        // trigger pod logout as user logged in on another location
                        if(this.$route.path !== '/player/logout') {
                            this.$parent.handleLogout(e.message.message, 'web', false);
                        }
                        else if(this.$route.path == '/player/logout') {
                            this.$root.$emit("nav_logout", true);
                        }
                    }
                    else if(e.message.data.command_type == 'save_seat') {
                        // trigger pod logout as user logged in on another location
                        if(this.$route.path !== '/player/dashboard') {
                            this.$router.push({name: "Dashboard", params: {save_seat: true}});
                        }
                        else {
                            this.$root.$emit("save_seat", true);
                        }
                    }
                    else if(e.message.data.command_type == 'sso_logout') {
                        this.$parent.handleLogout(e.message.message, 'web');
                    }
                    else if(e.message.data.command_type == 'logout_fac') {
                        this.$parent.handleLogout("Host forced the logout from kioske", "host", false);
                    }
                }
            });
        }

        // listen for modal close
        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            if(modalId == "game-startup-modal") {
            this.game_startup_message = "";
            this.game_startup_button = "";
            this.hide_header_close = false;
            this.no_close_bd = false;
            this.no_close_esc = false;
            }
        });

        // listen for lab mode change event
        this.$root.$on('lab_mode_message', (message) => {
            // set local storage record
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            pod_data.is_paid = message == 'paid' ? 1 : 0;
            localStorage.setItem('pod_sess_data', JSON.stringify(pod_data));
            // trigger remaining time polling
            
            if(message == 'paid') {
                if(this.remaining_time_polling == null) {
                    this.remainingTimePolling();
                }
            }
            else {
                clearInterval(this.remaining_time_polling);
                this.remaining_time_polling = null;
            }
        });

        // check for host sepecific commands
        if(
            Object.prototype.hasOwnProperty.call(this.$root, "_events") && 
            Object.prototype.hasOwnProperty.call(this.$root._events, "host_websocket_event_loggedin")
        ) {
            this.$root.$off("host_websocket_event_loggedin");
        }
        this.$root.$on('host_websocket_event_loggedin', (message) => {
            if(Object.prototype.hasOwnProperty.call(message, "host_websocket") && message.host_websocket) {
                if(
                    message.host_websocket.command_type == 'login_fac' || 
                    message.host_websocket.command_type == 'save_seat_fac' || 
                    message.host_websocket.command_type == 'fac_play_game'
                ) {
                    let host_command = message.host_websocket;
                    // check if host is logged in
                    if(typeof localStorage.user_sess_data !== 'undefined') {
                        let user_data = JSON.parse(localStorage.user_sess_data);
                        if(
                            Object.prototype.hasOwnProperty.call(user_data, 'is_host') && 
                            user_data.is_host == 'Yes'
                        ) {
                            localStorage.removeItem('host_websocket');
                            if(host_command.command_type == 'save_seat_fac') {
                                if(this.$route.path !== '/player/dashboard') {
                                    this.$router.push({name: "Dashboard", params: {save_seat: true}});
                                }
                                else {
                                    this.$root.$emit("save_seat", true);
                                }
                            }
                            else if(host_command.command_type == 'fac_play_game') {
                                if(this.$route.path !== '/player/dashboard') {
                                    this.$router.push({name: "Dashboard"});
                                }
                                else {
                                    if((this.xds_data['xd1'] == host_command.game_platform || this.xds_data['xd2'] == host_command.game_platform) && typeof this.xds_data[host_command.game_platform+"_disabled"] != 'undefined' && !this.xds_data[host_command.game_platform+"_disabled"]) {
                                        if(this.xds_data['xd1'] == host_command.game_platform) {
                                            this.$router.push({ name: "Games", params: {'host_websocket': host_command, 'xd' : 'xd1'}});
                                        }
                                        else if(this.xds_data['xd2'] == host_command.game_platform) {
                                            this.$router.push({ name: "Games", params: {'host_websocket': host_command, 'xd' : 'xd2'}});
                                        }
                                    }
                                }
                            }
                        }
                        else {
                            this.$parent.handleLogout("Host forced the logout from kioske", "web", false);
                        }
                    }
                }
            }
        });
        // end checking host specific commands
    },
    beforeDestroy () {
      clearInterval(this.logout_polling);
      clearTimeout(this.lockout_polling);
      clearInterval(this.volume_polling);
      clearInterval(this.game_polling);
      clearInterval(this.game_playing_polling);
      clearInterval(this.xds_polling);
      clearInterval(this.menu_polling);
      clearInterval(this.remaining_time_polling);
      // clearInterval(this.pod_idle_polling);
      // clearInterval(this.pod_idle_polling);
      clearInterval(this.pod_inactive_polling);
    },
    created () {
      this.getPodIdFromFlask();
      this.getXdsData();
      this.pollFlaskLogout();
      this.pollFlaskVolume();
      clearInterval(this.game_polling);
      clearInterval(this.game_playing_polling);
      this.gamePlayingPolling();
      this.pollXdTypes();
      clearInterval(this.remaining_time_polling);
      this.remaining_time_polling = null;
      this.remainingTimePolling();
      clearInterval(this.menu_polling);
      this.menu_polling = handlePushMenuPolling(this, 'afterlogin');
      // this.pod_idle_polling = handleIdlePolling();
      this.$store.commit("setIdleFlagUpater", true);
      this.inactivePolling();
    }
};
</script>
<style scoped>
  .v-idle {
    display: none !important;
  }
  .game-startup-timer {
    /* position: absolute; */
    color: #fff;
    top: 1.5%;
    z-index: 1000;
    left: auto;
    font-size: 25px;
    font-weight: bold;
    margin: auto;
    /* padding-left: 45%; */
    margin: 0;
    padding: 0;
  }
</style>