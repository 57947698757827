<template>
  <div class="d-flex flex-column align-items-center">
    <div ref="clickBox" :style="{ background: clickBackground, width: '150px', height: '150px', borderRadius: '50%' }" class="mb-3"></div>

    <div class="mb-3">
      <label for="countLeft">Left Clicks:</label>
      <input type="text" id="countLeft" :value="countLeft" readonly>
    </div>
    <div class="mb-3">
      <label for="countRight">Right Clicks:</label>
      <input type="text" id="countRight" :value="countRight" readonly>
    </div>
    <div class="mb-3">
      <label for="countWheel">Wheel Clicks:</label>
      <input type="text" id="countWheel" :value="countWheel" readonly>
    </div>
    
    <p id="scrollDirection" class="mb-3">Scroll Direction: {{ scrollDirection }}</p>
    <p id="instructions" class="mb-3">{{ instructions }}</p>
    <button @click="reset" class="mb-3">Reset</button>
    <button @click="mouseTest" class="mb-3">Start Mouse Test</button>
    <p>Click the Fail button if the mouse clicks are not registering properly.</p>
    <button @click="failTest" class="mb-3">Fail</button>
  </div>
</template>


<script>
export default {
    name: 'MouseTest',
  data() {
    return {
      countLeft: 0,
      countRight: 0,
      countWheel: 0,
      dcCount: 0,
      scrollDirection: '',
      instructions: 'Instructions: ',
      clickBackground: 'orange',
      prevClickMicrotime: this.microtime(true),
      testStage: 0,
      leftClickCount: 0,
      rightClickCount: 0,
      wheelClickCount: 0,
      testStarted: false,
      mouseTestPassed: false
    };
  },
  methods: {
    microtime(get_as_float) {
      const now = new Date().getTime() / 1000;
      const s = parseInt(now, 10);
      return (get_as_float) ? now : (Math.round((now - s) * 1000) / 1000) + ' ' + s;
    },
    clickEvent(buttonType) {
      const clickTime = this.microtime(true);
      const diff = clickTime - this.prevClickMicrotime;

      if (diff <= 0.08) {
        this.clickBackground = 'red';
        this.dcCount++;
      }

      this.prevClickMicrotime = clickTime;

      if (buttonType === 0) {
        this.countLeft++;
      } else if (buttonType === 1) {
        this.countWheel++;
      } else if (buttonType === 2) {
        this.countRight++;
      }
    },
    mouseClick(event) {
      this.clickEvent(event.button);
      this.trackMouseTest(event);
      return false;
    },
    wheelScroll(event) {
      if (event.deltaY > 0) {
        this.scrollDirection = 'Down';
      } else {
        this.scrollDirection = 'Up';
      }
      this.trackMouseTest(event);
    },
    preventDefaultContextMenu(event) {
      event.preventDefault();
    },
    reset() {
      this.countLeft = 0;
      this.countRight = 0;
      this.countWheel = 0;
      this.dcCount = 0;
      this.clickBackground = 'orange';
      this.scrollDirection = 'Scroll Direction: ';
      this.instructions = 'Instructions: ';
      this.mouseTestPassed = false;
    },
    mouseTest() {
      this.testStage = 1;
      this.leftClickCount = 0;
      this.rightClickCount = 0;
      this.wheelClickCount = 0;
      this.testStarted = true;
      this.instructions = 'Instructions: Hover over the square and press the left mouse button 10 times.';
    },
    failTest() {
      this.mouseTestPassed = false;
      this.instructions = 'Mouse Test Failed';
    },
    trackMouseTest(event) {
      if (!this.testStarted) return;

      if (this.testStage === 1 && event.button === 0) {
        this.leftClickCount++;
        if (this.leftClickCount === 10) {
          this.testStage = 2;
          this.instructions = 'Instructions: Hover over the square and press the right mouse button 10 times.';
        }
      } else if (this.testStage === 2 && event.button === 2) {
        this.rightClickCount++;
        if (this.rightClickCount === 10) {
          this.testStage = 3;
          this.instructions = 'Instructions: Hover over the square and roll the mouse wheel up.';
        }
      } else if (this.testStage === 3 && event.deltaY < 0) {
        this.testStage = 4;
        this.instructions = 'Instructions: Hover over the square and roll the mouse wheel down.';
      } else if (this.testStage === 4 && event.deltaY > 0) {
        this.testStage = 5;
        this.instructions = 'Instructions: Hover over the square and click the mouse wheel 10 times.';
      } else if (this.testStage === 5 && event.button === 1) {
        this.wheelClickCount++;
        if (this.wheelClickCount === 10) {
          this.testStage = 0;
          this.testStarted = false;
          this.mouseTestPassed = true;
          this.instructions = 'Mouse Test Finished';
        }
      }
    }
  },
  mounted() {
    this.$refs.clickBox.onmousedown = this.mouseClick;
    this.$refs.clickBox.onwheel = this.wheelScroll;
    this.$refs.clickBox.oncontextmenu = this.preventDefaultContextMenu;
  }
};
</script>

<style scoped>
#click {
  cursor: pointer;
}
button {
  margin-top: 10px;
}
</style>