<template>
  <section class="black admin-portal">
    <div class="w-logo">
      <img
        src="../../assets/images/gamelab-logo.svg"
        alt="logo"
        class="
          w-input-fields
          animate__animated animate__fadeInUp animate__delay-1s
        "
      />
    </div>
    <div class="w-form-block">
      <!-- login form section -->
      <form action="" class="" @submit.stop.prevent v-if="with_keyboard">
        <div
          class="
            w-form-control w-input-fields
            animate__animated animate__fadeInUp animate__delay-2s
          "
        >
          <span><i class="fa-solid fa-user"></i></span>
          <input
            type="text"
            name="email"
            value=""
            v-model="username"
            placeholder="Please Enter your Email"
            autofocus
          />
        </div>
        <div
          class="
            w-form-control w-input-fields
            animate__animated animate__fadeInUp animate__delay-2s
          "
        >
          <span><i class="fa-solid fa-lock"></i></span>
          <input
            type="password"
            name="password"
            value=""
            v-model="password"
            placeholder="Password"
          />
        </div>
        <div class="main-action-box w-login-hide">
          <div
            class="
              w-form-control
              animate__animated animate__fadeInUp animate__delay-3s 
              login-buttons
            "
          >
            <a
              href="javascript:void(0)"
              @click="handleLoginReq"
              class="flex-fill"
              >Login</a
            >
          </div>
        </div>
        <div class="w-login-show">
          <div class="notification-block"></div>
        </div>
      </form>

      <div
        class="main-action-box w-login-hide"
        v-if="!with_keyboard && pod_validated"
      >
        <div
          class="
            w-form-control
            animate__animated animate__fadeInUp animate__delay-3s
          "
        >
          <p>Scan the QR code with your Phone to get started</p>
        </div>
        <div
          class="
            w-form-control
            animate__animated animate__fadeInUp animate__delay-3s
          "
        >
          <div class="qr-code">
            <!-- <img src="assets/img/qr.png"/> -->
            <qr-code :text="remote_url" class="qr-code-container" error-level="L"></qr-code>
          </div>
        </div>
      </div>

      <!-- error container -->
      <div
        class="w-login-show animate__animated animate__fadeInUp"
        v-if="login_class !== '' && login_message !== ''"
      >
        <div class="notification-block" :class="login_class">
          <div class="notification-message">
            <h5>{{ login_message }}</h5>
            <p>{{ login_message }}</p>
          </div>
          <div class="icon-success" v-if="login_class == 'success'">
            <i class="fa-solid fa-circle-check"></i>
          </div>
          <div class="icon-error" v-if="login_class == 'error'">
            <i class="fa-solid fa-times-circle"></i>
          </div>
          <div class="icon-warning" v-if="login_class == 'warning'">
            <i class="fa-solid fa-exclamation-circle"></i>
          </div>
        </div>
      </div>
    </div>
    <AdminFooter type="" />
  </section>
</template>

<script>
import store from "@/store";
import { adminLogin, adminValidatePodId } from "../../services/AdminService";
import { getPodId } from "../../services/FlaskService";
import AdminFooter from "@/views/admin/AdminFooter.vue";

export default {
  name: "adminlogin-view",
  components: { AdminFooter },

  data() {
    return {
      username: "",
      password: "",
      pod_validated: false,
      pod_id_key: "",
      with_keyboard: true,
      remote_url: "",
      login_message: "",
      login_class: "",
    };
  },

  computed: {
    getMessage() {
      return store.getters.getMessage;
    },
  },

  methods: {
    handleLoginReq() {
      if (this.password == "" || this.username == "")
        this.makeToast("error", "Please fill all required fields");
      else {
        adminLogin(this.username, this.password).then(response => {
          if(response.status) {
            if(response.data.role_id == 3) {
              this.makeToast("success", response.message);
              localStorage.setItem('user_sess_data', JSON.stringify(response.data));
              this.$router.push("/admin/dashboard");
            }
            else if(response.data.role_id == 4) {
              this.makeToast("success", response.message);
              localStorage.setItem('user_sess_data', JSON.stringify(response.data));
              this.$router.push("/superadmin/dashboard");
            }
            else {
              this.makeToast("error", "You don't have permissions to access this area");
            }
          }
          else {
            this.makeToast("error", response.message);
          }
        })
      }
    },

    adminGetPodIdFromFlask() {
      getPodId().then(pod => {
        
        if(Object.prototype.hasOwnProperty.call(pod, 'success') && pod.success 
        && Object.prototype.hasOwnProperty.call(pod, 'data') 
        && Object.prototype.hasOwnProperty.call(pod.data, 'pod_id') 
        && pod.data.pod_id.length > 0) {
          this.pod_id_key = pod.data.pod_id;
          this.adminValidateKioskPodId();
        }
      });
    },

    adminValidateKioskPodId() {
      adminValidatePodId(this.pod_id_key).then(response => {
        if(response.status) {
          this.pod_validated = response.status;
          this.with_keyboard = response.data.with_keyboard;
          if(!response.data.with_keyboard) {
            this.remote_url = window.location.origin + "/admin/mobile-login";
          }
        }
      })
    },

    makeToast(variant = "default", message) {
        //   this.$toast.open({
        //     message: message,
        //     type: variant,
        //     position: 'top-right',
        //     duration: 3000,
        //     dismissible: true
        //   });
        this.login_class = variant;
        this.login_message = message;
        setTimeout(() => {
        this.login_class = "";
        this.login_message = "";
        }, 5000);
    },
  },

  mounted() {
    this.adminGetPodIdFromFlask();
    // listen for websocket messages
    window.Echo.channel('admin-mobile-login').listen('AdminMobileLogin', (e) => {
        
        if (e.message.server_info == process.env.VUE_APP_WS_ENV) {
            let user_data = localStorage.user_sess_data;
            // check if user not logged in already
            if(typeof user_data === 'undefined') {
                if(e.message.status) {
                this.makeToast("success", e.message.message);
                if(e.message.data.user.role_id == 3) {
                    localStorage.setItem('user_sess_data', JSON.stringify(e.message.data.user));
                    this.$router.push("/admin/dashboard");
                }
                else if(e.message.data.user.role_id == 4) {
                    localStorage.setItem('user_sess_data', JSON.stringify(e.message.data.user));
                    this.$router.push("/superadmin/dashboard");
                }
                else {
                    this.makeToast("error", "You don't have permissions to access this area");
                }
                } else {
                this.makeToast("error", e.message.message);
                }
            }
        }
    });
  },
  created() {
    this.$router.push("/");
  }
};
</script>