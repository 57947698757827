export const getters = {
    getThemeLogo: state => key_name => {
        console.log("HERE IS STATE : ", state);
        console.log("HERE IS KEY NAME : ", key_name);
        if (Object.prototype.hasOwnProperty.call(state, "logo")) {
            return state;
        }
        else {
            return {
                logo: '',
                theme: '',
                styles: '',
            };
        }
    },
    getIdleState: state => key_name => {
        key_name;
        return state.is_idle;
    },
    getInactiveCounter: state => key_name => {
        key_name;
        return state.inactive_counter;
    },
    getNavName: state => key_name => {
        key_name, state;
        // check if page refreshed and user logged in
        if(typeof localStorage.pod_sess_data !== 'undefined') {
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            return pod_data.nav_name;
        }
        return "";
    },
    getQRRefreshState: state => key_name => {
        key_name;
        return state.qr_refresh;
    },
    getQRCounterState: state => key_name => {
        key_name;
        return state.qr_counter;
    },
    getQRUrlState: state => key_name => {
        key_name;
        return state.qr_url;
    },
    getFocusedState: state => key_name => {
        key_name;
        return state.focused_element;
    },
    getFocusedFormState: state => key_name => {
        key_name;
        return state.focused_element_form;
    },
    getPodScreenButtonsState: state => key_name => {
        key_name;
        return state.is_pod_screen_buttons;
    },
    getAppTransitionState: state => key_name => {
        key_name;
        return {'type' : state.app_transition_type, 'value': state.is_app_transition};
    },
};
