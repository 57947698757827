<template>
    <div>
        <div class="main-contenttt" :class="[ user_data.role_id != 1 ? 'main-content' : '' ]">
            <h2 class="page-heading animate__animated animate__fadeIn animate__delay-1s saveseat-heading">Orientation</h2>
            <h3 class="page-subheading mb-1 animate__animated animate__fadeIn animate__delay-1s saveseat-subheading">Welcome {{ ((user_data !== null && Object.prototype.hasOwnProperty.call(user_data, 'name')) ? (user_data.name !== '' ? (user_data.name[0].toUpperCase() + user_data.name.slice(1) + '!') : '') : ((user_data !== null && Object.prototype.hasOwnProperty.call(user_data, 'user_name')) ? user_data.user_name : '!')) }}</h3>

            <!-- start of orientation section -->
            <!-- <div class="orientation-section" :style="{'background': 'url('+require(`../assets/images/orientation_free.png`)+')'}"> -->
            <!-- <div class="orientation-section" :style="{'background': 'url('+require(`../assets/images/orientation_free.png`)+')'}"></div> -->
            <div class="orientation-section">
                <div class="orientation-section-bg">
                    <img src="../assets/images/orientation_free.png" alt="" v-if="otype == 'free'">
                    <img src="../assets/images/orientation_pay.png" alt="" v-if="otype == 'paid'">
                </div>
            </div>

            <!-- <div class="dashboard-buttons-container col-md-3 animate__animated animate__fadeIn animate__delay-2s">
                <div class="dashboard-buttons">
                    <button class="dashboard-button" @click="handleOrientationRequest" style="max-width: 250px;margin: auto;">
                        I Understand
                    </button>
                </div>
            </div> -->
            <!-- end of login section -->
        </div>
        <button class="orientation-button" @click="handleOrientationRequest" style="max-width: 250px;margin: auto;">
            I Understand
        </button>
    </div>
</template>
  
<script>
import { userOrientation } from "../services/HomepageService";
export default {
    name: "OrientationWidget-view",
    data() {
        return {
            user_data: null,
            sess_data: null,
        };
    },
    props: ['otype'],
    computed: {
    },

    methods: {
        setUserData() {
            this.user_data = JSON.parse(localStorage.user_sess_data);
            if(!Object.prototype.hasOwnProperty.call(this.user_data, 'role_id')) {
                this.$router.push("/");
            }
            this.sess_data = JSON.parse(localStorage.pod_sess_data);
            if(!Object.prototype.hasOwnProperty.call(this.sess_data, 'pod_id_key')) {
                this.$router.push("/");
            }
        },
        handleOrientationRequest() {
            let params = {
                session_id_key: this.sess_data.session_id_key, 
                session_id: this.sess_data.session_id_key,
                token: this.user_data.token,
                type: this.otype
            };
            userOrientation(params).then((response) => {
                response;
                this.$emit('orientationComplete', false);
            });
        },
    },

    mounted() {
    },
    beforeDestroy () {
    },
    created () {
        this.setUserData();
    }
};
</script>
<style scoped>
    .main-layout-section .main-content {
        width: 100%;
        min-height: 67.5vh;
    }
    div.timer {
        position: relative;
        margin: auto;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        /* overflow: hidden; */
        color: #d8d8d8;
        border-radius: 50%;
        font-weight: 700;
        font-family: 'Open Sans';
        animation: 60s 0.875s cubic-bezier(0.9, 0, 0.1);
    }

    div.timer_with_kb {
        width: 150px;
        height: 150px;
        font-size: 70px;
        border: 2px solid var(--client-primary);
        margin-top: 25px;
    }

    @keyframes timer_beat {
        40%,
        80% {
            transform: none; }
        50% {
            transform: scale(1.125);
        }
    }
    div.timer:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -100;
        width: 100%;
        height: 100%;
        animation: 60s 1s linear forwards timer_indicator;
    }

    @keyframes timer_indicator {
        100% {
            transform: translateY(100%);
        }
    }
    .saveseat-username {
        font-size: 20px;
        font-family: 'Open Sans';
        padding-bottom: 10px;
    }
    .saveseat-heading {
        font-size: 30px;
        font-family: 'Open Sans';
        font-weight: 600;
    }
    .saveseat-subheading {
        font-size: 16px;
        font-weight: normal;
        font-family: 'Open Sans';
    }
    .timer-unit {
        position: absolute;
        left: 130px;
        font-size: 14px;
        bottom: 0px;
        font-family: 'Open Sans';
    }

    .orientation-section {
        width: 100%;
        display: flex;
        flex-direction: row;
        height: 55vh;
        justify-content: center;
    }
    .orientation-section .orientation-section-bg {
        width: 100%;
        position: relative;
        height: 100%;
        text-align: center;
    }
    .orientation-section .orientation-section-bg img {
        width: 100%;
        position: relative;
        top: -40%;
        margin: auto;
        height: 150%;
        object-fit: contain;
    }
    .main-contenttt {
        z-index: 100;
        position: relative;
    }
    .orientation-button {
        border: 2px solid var(--client-primary);
        background-color: transparent;
        background: transparent;
        padding: 15px 10px;
        border-radius: 8px;
        display: inline-block;
        text-decoration: none;
        color: #fff;
        font-family: sans-serif;
        font-size: 16px;
        outline: none;
        max-width: 250px;
        width: 250px;
        position: relative;
        z-index: 101;
    }
    .orientation-button:hover {
        border: 2px solid var(--client-primary);
        background: var(--client-primary);
    }
</style>