const axios = require('axios');
import { flask_api_routes } from '../routes/flask_api_routes';
let flaskAPI = process.env.VUE_APP_FLASK_API;

export async function getPodId() {
    return await axios.get(`${flaskAPI}${flask_api_routes.GET_POD_ID}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
    })
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {"pod_id" : "IHmH3jXl49kHXQqUOBe373X14Fqluc"}};
        // return {"error" : error, "success" : true, data: {"pod_id" : "fkgfkgj8485748jgfj"}};
        return error.response;
    });
}

export async function getXdTypes() {
    return await axios.get(`${flaskAPI}${flask_api_routes.GET_XD_TYPE}`)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {"xd1" : "ps", "xd2" : "xb", "xb_disabled": false, "ps_disabled": false, "sw_disabled": false, "pc_disabled": false, "pod_disabled": false}};
        return error.response;
    });
}

export async function getMacAddress() {
    return await axios.get(`${flaskAPI}${flask_api_routes.GET_MAC_ADDRESS}`)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {"mac_address" : "fkgfkgj8485748jgfj"}};
        return error.response;
    });
}

export async function getPodLogoutReason() {
    return await axios.get(`${flaskAPI}${flask_api_routes.CHECK_LOGOUT_POD}`)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {"logout" : 0, "logout_reason" : "Button"}};
        return error.response;
    });
}

export async function getPodLogoutAcknowledge() {
    return await axios.get(`${flaskAPI}${flask_api_routes.LOGOUT_POD_ACKNOWLEDGE}`)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {}};
        return error.response;
    });
}

export async function postPodLogoutWeb(params) {
    return await axios.post(`${flaskAPI}${flask_api_routes.SET_LOGOUT_WEB}`, params)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {}};
        return error.response;
    });
}

export async function getPodVolume() {
    return await axios.get(`${flaskAPI}${flask_api_routes.GET_POD_VOLUME}`)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {'vol_now' : ''}};
        return error.response;
    });
}

export async function setFlaskPodVolume(params) {
    return await axios.post(`${flaskAPI}${flask_api_routes.SET_POD_VOLUME}`, params)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {}};
        return error.response;
    });
}

export async function playFlaskGame(params) {
    return await axios.post(`${flaskAPI}${flask_api_routes.SET_GAME_START}`, params)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {}};
        return error.response;
    });
}

export async function writeFlaskPodId(params) {
    return await axios.post(`${flaskAPI}${flask_api_routes.WRITE_POD_ID}`, params)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {}};
        return error.response;
    });
}

export async function getPodType() {
    return await axios.get(`${flaskAPI}${flask_api_routes.GET_POD_TYPE}`)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {'pod_type' : 'gp_reg'}};
        return error.response;
    });
}

export async function setPodType(params) {
    return await axios.post(`${flaskAPI}${flask_api_routes.SET_POD_TYPE}`, params)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {'pod_type' : 'gp_reg'}};
        return error.response;
    });
}

export async function setFlaskStreaming(params, type='') {
    let url = flaskAPI;
    url += (type == 'default' ? flask_api_routes.SET_STREAM_DEFAULT : flask_api_routes.SET_STREAM);
    return await axios.post(`${url}`, params)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {}};
        return error.response;
    });
}

export async function getFlaskGameStarted() {
    return await axios.get(`${flaskAPI}${flask_api_routes.CHECK_GAME_STARTED}`)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {'game_started' : true}};
        return error.response;
    });
}

export async function setFlaskLED1(params, type='') {
    let url = flaskAPI;
    url += (type == 'default' ? flask_api_routes.SET_LED1_DEFAULT : (type == 'lock' ? flask_api_routes.SET_LED1_LOCK : flask_api_routes.SET_LED1_CHANGE));
    return await axios.post(`${url}`, params)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {'led1_change': params.led1_change, 'led1_default': params.led1_default}};
        return error.response;
    });
}

export async function setFlaskLED2(params, type='') {
    let url = flaskAPI;
    url += (type == 'default' ? flask_api_routes.SET_LED2_DEFAULT : (type == 'lock' ? flask_api_routes.SET_LED2_LOCK : flask_api_routes.SET_LED2_CHANGE));
    return await axios.post(`${url}`, params)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {'led2_change': params.led2_change, 'led2_default': params.led2_default}};
        return error.response;
    });
}

export async function setFlaskLoginPod(params) {
    return await axios.post(`${flaskAPI}${flask_api_routes.SET_LOGIN_POD}`, params)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {"user_name" : params.user_name}};
        return error.response;
    });
}

export async function setFlaskResetPod() {
    return await axios.post(`${flaskAPI}${flask_api_routes.RESET_COMMAND}`)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true};
        return error.response;
    });
}

export async function postReadyWeb() {
    return await axios.get(`${flaskAPI}${flask_api_routes.CHECK_READY_WEB}`)
    .then(response => response.data)
    .catch(error => {
        return {"error" : error, "success" : true, data: {}};
        // return error.response;
    });
}

export async function postReadyPod() {
    return await axios.get(`${flaskAPI}${flask_api_routes.CHECK_READY_POD}`)
    .then(response => response.data)
    .catch(error => {
        return {"error" : error, "success" : true, data: {}};
        // return error.response;
    });
}

export async function setFlaskPodDisabled(params) {
    return await axios.post(`${flaskAPI}${flask_api_routes.SET_POD_DISABLED}`, params)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {}};
        return error.response;
    });
}

export async function checkFlaskPodIdle() {
    return await axios.get(`${flaskAPI}${flask_api_routes.CHECK_POD_IDLE}`)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {}};
        return error.response;
    });
}

export async function getFlaskGamePlaying() {
    return await axios.get(`${flaskAPI}${flask_api_routes.CHECK_GAME_PLAYING}`)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {platform: "ps", select_type: "cloud", search_title: ""}};
        return error.response;
    });
}

export async function setFlaskSessionActivity(params) {
    return await axios.post(`${flaskAPI}${flask_api_routes.SET_SESSION_ACTIVITY}`, params)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {}};
        return error.response;
    });
}

export async function checkFlaskPodAsleep() {
    return await axios.get(`${flaskAPI}${flask_api_routes.CHECK_POD_ASLEEP}`)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {}};
        return error.response;
    });
}

export async function setFlaskPodSchedule(params) {
    return await axios.post(`${flaskAPI}${flask_api_routes.SET_POD_SCHEDULE}`, params)
    .then(response => response.data)
    .catch(error => {
        return {"error" : error, "success" : true, data: {}};
        // return error.response;
    });
}

export async function checkFlaskPodPushMenu() {
    return await axios.get(`${flaskAPI}${flask_api_routes.CHECK_POD_PUSHMENU}`)
    .then(response => response.data)
    .catch(error => {
        // let is_disabled = localStorage.getItem('pod_pmenu');
        // if(typeof is_disabled !== 'undefined' && is_disabled !== null) {
        //     return {"error" : error, "success" : JSON.parse(is_disabled).value, data: {}};
        // }
        // return {"error" : error, "success" : false, data: {}};
        return error.response;
    });
}

export async function setFlaskPodWebTime(params) {
    return await axios.post(`${flaskAPI}${flask_api_routes.SET_POD_WEB_TIME}`, params)
    .then(response => response.data)
    .catch(error => {
        return {"error" : error, "success" : true, data: {}};
        // return error.response;
    });
}

export async function setFlaskRebootPod() {
    return await axios.post(`${flaskAPI}${flask_api_routes.REBOOT_COMMAND}`)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true};
        return error.response;
    });
}

export async function setFlaskPodXdDisabled(params) {
    return await axios.post(`${flaskAPI}${flask_api_routes.SET_XD_DISABLED}`, params)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {}};
        return error.response;
    });
}

export async function setFlaskPodEmergency(params) {
    return await axios.post(`${flaskAPI}${flask_api_routes.SET_POD_EMERGENCY}`, params)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {}};
        return error.response;
    });
}

export async function checkFlaskPodTouch() {
    return await axios.get(`${flaskAPI}${flask_api_routes.GET_POD_TOUCH}`)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {}};
        return error.response;
    });
}

export async function setFlaskPodreserved() {
    return await axios.get(`${flaskAPI}${flask_api_routes.SET_POD_RESERVED}`)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {}};
        return error.response;
    });
}

export async function setFlaskSSOLogout() {
    return await axios.get(`${flaskAPI}${flask_api_routes.SET_SSO_LOGOUT}`)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {}};
        return error.response;
    });
}

export async function setFlaskPodWebSSO() {
    return await axios.get(`${flaskAPI}${flask_api_routes.SET_POD_WEB_SSO}`)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {}};
        return error.response;
    });
}

export async function setFlaskPodWebReset() {
    return await axios.get(`${flaskAPI}${flask_api_routes.SET_POD_WEB_RESET}`)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {}};
        return error.response;
    });
}

export async function checkFlaskPodKB() {
    return await axios.get(`${flaskAPI}${flask_api_routes.GET_POD_KB}`)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {}};
        return error.response;
    });
}

export async function createFlaskPodPushMenu() {
    return await axios.get(`${flaskAPI}${flask_api_routes.CREATE_POD_PUSHMENU}`)
    .then(response => response.data)
    .catch(error => {
        return error.response;
    });
}

export async function createFlaskPodPushEnd() {
    return await axios.get(`${flaskAPI}${flask_api_routes.CREATE_POD_PUSHEND}`)
    .then(response => response.data)
    .catch(error => {
        return error.response;
    });
}

export async function checkFlaskPodScreenButtons() {
    return await axios.get(`${flaskAPI}${flask_api_routes.CHECK_POD_SCREEN_BUTTONS}`)
    .then(response => response.data)
    .catch(error => {
        return error.response;
    });
}

export async function checkFlaskPodScreenBlank() {
    return await axios.get(`${flaskAPI}${flask_api_routes.CHECK_POD_BLANK_SCREEN}`)
    .then(response => response.data)
    .catch(error => {
        // return {"error" : error, "success" : true, data: {}};
        return error.response;
    });
}

export async function setFlaskPodUser(params) {
    return await axios.post(`${flaskAPI}${flask_api_routes.SET_POD_USER}`, params)
    .then(response => response.data)
    .catch(error => {
        return error.response;
    });
}

export async function checkFlaskPodUser() {
    return await axios.get(`${flaskAPI}${flask_api_routes.CHECK_POD_USER}`)
    .then(response => response.data)
    .catch(error => {
        return error.response;
    });
}

export async function deleteFlaskPodUser() {
    return await axios.get(`${flaskAPI}${flask_api_routes.DELETE_POD_USER}`)
    .then(response => response.data)
    .catch(error => {
        return error.response;
    });
}

export async function setFlaskPodWebSocket(params) {
    return await axios.post(`${flaskAPI}${flask_api_routes.SET_POD_WEB_SOCKET}`, params)
    .then(response => response.data)
    .catch(error => {
        return error.response;
    });
}