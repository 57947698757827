export const getters = {
    getFeedAlertState: state => key_name => {
        key_name;
        return state.is_feed_alert;
    },
    getFeedAlertData: state => key_name => {
        key_name, state;
        // check if page refreshed and user logged in
        return state.alert_data;
    }
};
