import { getSystemMessagesFE } from "../../../services/HomepageService";
import { getters } from "./getters.js";

export default {
    state() {
        return {
            list: [],
        };
    },
    getters,
    mutations: {
        getMessagesJson(state) {
            getSystemMessagesFE().then((response) => {
                state.list = response.data.messages; 
            });
        },
    },
};
