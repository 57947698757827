<template>
  <section class="black admin-portal">
    <div class="w-logo">
      <img
        src="../../assets/images/gamelab-logo.svg"
        alt="logo"
        class="
          w-input-fields
          animate__animated animate__fadeInUp animate__delay-1s
        "
      />
    </div>
    <div class="w-form-block">
      <!-- login form section -->
      <form action="" class="" @submit.stop.prevent>
        <div
          class="
            w-form-control w-input-fields
            animate__animated animate__fadeInUp animate__delay-2s
          "
        >
          <span><i class="fa-solid fa-user"></i></span>
          <input
            type="text"
            name="email"
            value=""
            v-model="username"
            placeholder="Please Enter your Email"
            autofocus
          />
        </div>
        <div
          class="
            w-form-control w-input-fields
            animate__animated animate__fadeInUp animate__delay-2s
          "
        >
          <span><i class="fa-solid fa-lock"></i></span>
          <input
            type="password"
            name="password"
            value=""
            v-model="password"
            placeholder="Password"
          />
        </div>
        <div class="main-action-box w-login-hide">
          <div
            class="
              w-form-control
              animate__animated animate__fadeInUp animate__delay-3s
            "
          >
            <a
              href="javascript:void(0)"
              @click="handleLoginReq"
              class="flex-fill"
              >Login</a
            >
          </div>
        </div>
        <div class="w-login-show">
          <div class="notification-block"></div>
        </div>
      </form>

      <!-- error container -->
      <div
        class="w-login-show animate__animated animate__fadeInUp"
        v-if="login_class !== '' && login_message !== ''"
      >
        <div class="notification-block" :class="login_class">
          <div class="notification-message">
            <h5>{{ login_message }}</h5>
            <p>{{ login_message }}</p>
          </div>
          <div class="icon-success" v-if="login_class == 'success'">
            <i class="fa-solid fa-circle-check"></i>
          </div>
          <div class="icon-error" v-if="login_class == 'error'">
            <i class="fa-solid fa-times-circle"></i>
          </div>
          <div class="icon-warning" v-if="login_class == 'warning'">
            <i class="fa-solid fa-exclamation-circle"></i>
          </div>
        </div>
      </div>
    </div>
    <AdminFooter type="" />
  </section>
</template>

<script>
import store from "@/store";
import { adminLogin } from "../../services/AdminService";
import AdminFooter from "@/views/admin/AdminFooter.vue";

export default {
  name: "adminmobilelogin-view",
  components: { AdminFooter },

  data() {
    return {
      username: "",
      password: "",
      login_message: "",
      login_class: "",
    };
  },

  computed: {
    getMessage() {
      return store.getters.getMessage;
    },
  },

  methods: {
    handleLoginReq() {
      if (this.password == "" || this.username == "")
        this.makeToast("error", "Please fill all required fields");
      else {
        adminLogin(this.username, this.password, 'mobile').then(response => {
          if(response.status){
            this.username = "";
            this.password = "";
            this.makeToast("success", response.message);
          } else {
            this.makeToast("error", response.message);
          }
        })
      }
    },

    makeToast(variant = "default", message) {
        //   this.$toast.open({
        //     message: message,
        //     type: variant,
        //     position: 'top-right',
        //     duration: 3000,
        //     dismissible: true
        //   });
        this.login_class = variant;
        this.login_message = message;
        setTimeout(() => {
            this.login_class = "";
            this.login_message = "";
        }, 5000);
    },
  },

  mounted() {
  },
};
</script>