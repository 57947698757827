const axios = require('axios');
//import { flask_api_routes } from '../routes/flask_api_routes.js';
import { cloud_api_routes } from '../routes/cloud_api_routes.js';
//let flaskAPI = process.env.VUE_APP_FLASK_API;
let cloudAPI = process.env.VUE_APP_CLOUD_API;

export async function sendPasswordMail(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.PASS_RESET_MAIL}`, requestOptions)
    .then(response => response.data)
    .catch(error => {
        console.log(error.response);
        return error.response;
    });
}

export async function verifyPasswordCode(params) {
    const requestOptions = params;

    return await axios.post(`${cloudAPI}${cloud_api_routes.PASS_RESET_CODE}`, requestOptions)
    .then(response => response.data)
    .catch(error => {
        console.log(error.response);
        return error.response;
    });
}

export async function changePassword(params) {
    const requestOptions = params;

    return await axios.post(`${cloudAPI}${cloud_api_routes.PASS_RESET_CHANGE}`, requestOptions)
    .then(response => response.data)
    .catch(error => {
        console.log(error.response);
        return error.response;
    });
}
