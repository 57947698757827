<template>
    <div class="main-header main-header-player" id="main-header-player">
      <AllTheme />
      <b-navbar toggleable="lg" type="dark" variant="info" class="mb-4 header-bg d-none">
        <b-navbar-brand class="mx-2" v-if="logo_file == '' || logo_file == null">Game Lab</b-navbar-brand>
        <b-navbar-brand class="mx-2 nav_logo" v-if="logo_file !== '' && logo_file !== null"><img :src="logo_file" /></b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="float-right w-100" align="right">
            <b-navbar-brand @click="handleLogout" class="main-header-link">Log out</b-navbar-brand>
          </b-navbar-nav>

        </b-collapse>
      </b-navbar>

        <div class="notification-block-outer w-alert-340">
            <div class="notification-block animate__animated animate__fadeInUp w-alert-340" :class="toast_type" v-if="toast_type !== '' && toast_message !== ''">
            <div class="notification-message w-alert-290">
                <h5>{{ toast_message }}</h5>
                <p v-if="toast_message1 !== ''">{{ toast_message1 }}</p>
            </div>
            <div class="icon-success" v-if="toast_type == 'success'">
                <i class="fa-solid fa-circle-check"></i>
            </div>
            <div class="icon-error" v-if="toast_type == 'error'">
                <i class="fa-solid fa-times-circle"></i>
            </div>
            <div class="icon-warning" v-if="toast_type == 'warning'">
                <i class="fa-solid fa-exclamation-circle"></i>
            </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { userLogout, sessionActivitySave } from "../../services/HomepageService";
import { global_config } from "@/config/config.js";
import AllTheme from "@/components/themes/AllTheme.vue";
import { postPodLogoutWeb } from "../../services/FlaskService";

export default {
    name: "FacHeader",
    components: { AllTheme },
    data() {
        return {
          theme_file:'',
          logo_file:'',
          theme_classes: global_config.THEME_CLASSES,
          toast_type: '',
          toast_message: '',
          toast_message1: '',
        };
    },
    methods: {
        handleLogout() {
          let user_data = JSON.parse(localStorage.user_sess_data);
          let pod_data = JSON.parse(localStorage.pod_sess_data);
          let params = {
              session_id_key : pod_data.session_id_key,
              session_id : pod_data.session_id_key,
              token: user_data.token
          };
          userLogout(params).then(response => {
              if(response.status || response.message == "Token is Expired" || response.message == "Token is Invalid"){
                // localStorage.removeItem('qr_sess_data');
                // localStorage.removeItem('user_sess_data');
                // localStorage.removeItem('pod_sess_data');
                // this.makeToast("success", response.message);
                // // remove existing classes
                // for(let i = 0; i < this.theme_classes.length; i++) {
                //   document.body.classList.remove(this.theme_classes[i]);
                // }
                // this.$router.push("/");
                // init logout on pod as well
                if(typeof localStorage.user_sess_data !== 'undefined' && typeof localStorage.pod_sess_data !== 'undefined') {
                    let user_data = JSON.parse(localStorage.user_sess_data);
                    let pod_data = JSON.parse(localStorage.pod_sess_data);
                    let params = {
                        session_id : pod_data.session_id_key,
                        user_id : user_data.user_id,
                        pod_id_key: pod_data.pod_id_key,
                        logout_reason: 'web'
                    };
                    postPodLogoutWeb(params).then(log_web => {
                        console.log("WEB LOGOUT API CALLED", log_web);
                    });
                }
                this.$router.push({ name: "Player Logout", params: {'reason': 'you clicked the onscreen logout button'}});
              }
              else {
                this.makeToast(response.message, "error", response.message);
              }
          });
        },

        // themeLogoDetails() {
        //   let user_data = JSON.parse(localStorage.user_sess_data);
        //   let pod_data = JSON.parse(localStorage.pod_sess_data);
        //   let params = {
        //       session_id_key : pod_data.session_id_key,
        //       token: user_data.token
        //   };
        //   getThemeLogoDetails(params).then(response => {
        //     if(response.status) {
        //       this.theme_file = response.data.themelogo.theme;
        //       // remove existing classes
        //       for(let i = 0; i < this.theme_classes.length; i++) {
        //         document.body.classList.remove(this.theme_classes[i]);
        //       }
        //       if(this.theme_file !== "" && this.theme_file !== null) {
        //         let class_name = this.theme_file+'Theme';
        //         document.body.classList.add(class_name);
        //       }

        //       // check for logo
        //       if(response.data.themelogo.logo_url !== '' && response.data.themelogo.logo_url !== null) {
        //         this.logo_file = response.data.themelogo.logo_url;
        //         this.$emit('setPodData', {'key' : 'logo_file', 'value' : this.logo_file});
        //       }
        //     }
        //   });
        // },
        makeToast(message_key, variant = "default", message, message1="") {
            document.getElementById('main-header-player').scrollIntoView();
            // search for key
            let smessage = this.$store.state.system_messages.list.find(o => o.msg_key === message_key);
            if(smessage !== null && typeof smessage !== 'undefined' && Object.prototype.hasOwnProperty.call(smessage, 'text_head')) {
                this.toast_type = smessage.type == 'failure' ? 'error' : smessage.type;
                this.toast_message = smessage.text_head;
                this.toast_message1 = smessage.text_sub;
            }
            else {
                this.toast_type = variant;
                this.toast_message = message;
                this.toast_message1 = message1;
            }
            // hide error div
            setTimeout(() => {
                this.toast_type = "";
                this.toast_message = "";
                this.toast_message1 = "";
            }, 5000);
        },
    },
    mounted() {
        this.$root.$on('toast_message', (message) => {
            if (Object.prototype.hasOwnProperty.call(message, "message1")) {
                this.makeToast(message.message, message.type, message.message, message.message1);
            }
            else {
                this.makeToast(message.message, message.type, message.message);
            }
        });
        // call activity record api
        sessionActivitySave({'activity': global_config.ACTIVITIES.UI_HOST}).then((response) => {
            console.log("ACTIVITY API CALLED : ", response);
        });
    }
};
</script>

<style scoped>
.main-header .main-header-link {
  cursor: pointer;
}
.header-bg {
  background-color: #dd550c !important;
}
.main-header .nav_logo img {
  min-width: 100px;
  max-width: 150px;
  max-height: 57px;
}
.main-header .notification-block-outer {
    width: 100%;
    position: absolute;
    left: 0;
}
.main-header .notification-block-outer .notification-block {
    position: relative;
    width: max-content;
    margin: auto;
    z-index: 999999;
}
.main-header .notification-block-outer .notification-block.success {
    background: #00a152;
}
.main-header .notification-block-outer .notification-block.error {
    background: #ff1744b3;
}
.main-header .notification-block-outer .notification-block.warning {
    background: #ffea005e;
}
</style>