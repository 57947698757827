export const getters = {
    getReservationStartState: state => key_name => {
        key_name;
        return state.is_reservation_start;
    },
    getReservationEndState: state => key_name => {
        key_name;
        return state.is_reservation_end;
    },
    getReservationAlertData: state => key_name => {
        key_name, state;
        // check if page refreshed and user logged in
        return state.reservation_data;
    }
};
