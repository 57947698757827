<template>
  <div class="custom-alerts">
    <h2 class="page-heading animate__animated animate__fadeInUp animate__delay-1s">Important Alerts</h2>
    <h3 class="page-subheading mb-3 animate__animated animate__fadeInUp animate__delay-1s">Read & check each to continue.</h3>

    <div v-for="(alert, index) in alerts_data" :key="alert.id" class="alert-generic animate__animated animate__fadeInUp m-auto  animate__delay-1s" :class="[alert.type == 'success' ? 'success' : '', alert.type == 'failure' ? 'error' : '', alert.type == 'warning' ? 'warning' : '', (index == -1 && first_load) ? 'active' : '', index == alerts.length ? '' : 'mb-3', index > 6 ? 'd-none'  : '']" @click="markAlertAsRead(index)" tabindex="0" :id="'alert-generic-'+index" @keyup.enter="markAlertAsRead(index)">
        <div class="alert-generic-message">
            <h5>{{ alert.text_head }}</h5>
            <p v-if="alert.text_sub !== ''">{{ alert.text_sub }}</p>
        </div>
        <div class="alert-generic-icon d-none" :class="['alert-icon-success-'+index]">
            <i class="fa-solid fa-circle-check"></i>
        </div>
        <div class="alert-generic-icon" :class="['alert-icon-error-'+index]">
            <i class="far fa-times-circle"></i>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: "AlertsWidget",
  
    data() {
        return {
            alerts_data: this.alerts,
            first_load: true,
            is_clicked: false,
        };
    },
    props: ['alerts'],
    methods: {
        markAlertAsRead(index) {
            if(!this.is_clicked) {
                this.is_clicked = true;
                document.getElementsByClassName('alert-icon-error-'+index)[0].classList.add('d-none');
                document.getElementsByClassName('alert-icon-success-'+index)[0].classList.remove('d-none');
                setTimeout(() => {
                    document.getElementById('alert-generic-'+index).classList.remove('animate__delay-1s');
                    document.getElementById('alert-generic-'+index).classList.add('animate__fadeOut');
                }, 500);
                setTimeout(() => {
                    this.first_load = false;
                    this.alerts_data.splice(index, 1);
                    this.is_clicked = false;
                    if(this.alerts_data.length <= 0) {
                        this.$emit('alertsComplete', true);
                    }
                }, 1000);
            }
        },
    }
}
</script>
<style>
.custom-alerts {
    overflow: hidden;
    height: 62.5vh;
}
</style>