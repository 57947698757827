<template>
    <section class="w-spalsh-screen animate__animated animate__delay-2s blank" :class="fade_class" v-if="app_transitioning && trans_type == 'blank'">  
    </section>
    <section class="w-spalsh-screen animate__animated animate__delay-2s" :class="fade_class" v-else-if="app_transitioning && trans_type == 'logo'">  
        <div class="w-logo animate__animated animate__delay-1s animate__fadeIn">
            <object type="image/svg+xml" tabindex="-1" style="width:300px;" :data="require(`../assets/images/gamelab.svg`)"></object>
        </div>
    </section>
</template>

<script>
import store from "@/store";
export default {
    name: "AppTransitionWidget-view",
    data() {
        return {
            fade_class: '',
            app_transitioning: false,
            trans_type: '',
        };
    },

    computed: {
        is_app_transition() {
            return store.getters.getAppTransitionState();
        }
    },

    watch: {
        is_app_transition(new_value) {
            if(new_value.value) {
                this.app_transitioning = true;
                this.fade_class = 'animate__fadeIn';
                this.trans_type = new_value.type;
            }
            else {
                this.fade_class = 'animate__fadeOut';
                setTimeout(() => {
                    this.app_transitioning = false;
                    this.trans_type = '';
                }, 3000);
            }
        }
    },

    methods: {
    },
};
</script>
<style scoped>
.w-spalsh-screen {
    z-index: 100000000000000000;
}
.w-spalsh-screen.blank {
    background: #101010;
}
</style>