<template>
    <div class="main-layout-section admin-portal" :class="[user_data.role_id == 4 ? '' : 'secondary']">
        <AdminHeader @setPodData="setPodData($event)" v-if="user_data.role_id == 3" />
        <SuperAdminHeader v-if="user_data.role_id == 4" />
        <div class="w-logo mb-3">
            <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="logo_file !== '' && logo_file !== null && user_data.role_id !== 4"/>
            <img src="../../assets/images/gamelab-logo.svg" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="user_data.role_id == 4"/>
        </div>

        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeInUp animate__delay-1s">Create Report</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">
                    
                    <div class="row">
                        <div class="admin-general-section-buttons col-md-12">
                            <router-link :to="dashboard_link" class="admin-general-section-button">
                                Back To Dashboard
                            </router-link>
                        </div>
                        <div class="admin-general-section-buttons col-md-12 mb-3">
                            <router-link :to="dashboard_link" class="alerts-breadcrumb">
                                Dashboard
                            </router-link>
                            <span class="alerts-breadcrumb">></span>
                            <span class="alerts-breadcrumb">Create New Report</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 col-sm-12 alerts-section-heading first d-none">
                            <h5>Create New Report</h5>
                        </div>
                        <div class="col-sm-12">
                            <div class="reports-menu-header">
                                <router-link class="report-menu-header-button" :to="`/common/reports/status`">Status</router-link>
                                <router-link class="report-menu-header-button active-report-menu-header-button" :to="`/common/reports/summary`">Activity</router-link>
                                <router-link class="report-menu-header-button" :to="`/common/reports/logs`">Logs</router-link>
                            </div>
                            <hr class="alerts-border mt-2 mb-3">
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="overflow-auto p-0 m-0">
                                <table id="reports-table" role="table" aria-busy="false" aria-colcount="6" class="table b-table table-striped">
                                    <tbody role="rowgroup">
                                        <tr role="row" class="reports-table-header-row">
                                            <td aria-colindex="1" role="cell" class="report-td-button">
                                                <div class="report-buttons">
                                                    <button class="report-button" @click="setIntervalType('today')" :class="[report_form.interval == 'today' ? 'active-report-button' : '', buttons_class]">Today</button>
                                                    <button class="report-button" @click="setIntervalType('lastweek')" :class="[report_form.interval == 'lastweek' ? 'active-report-button' : '', buttons_class]">Last 7 Days</button>
                                                    <button class="report-button" @click="setIntervalType('lastmonth')" :class="[report_form.interval == 'lastmonth' ? 'active-report-button' : '', buttons_class]">Last Month</button>
                                                    <button class="report-button" @click="setIntervalType('custom')" :class="[report_form.interval == 'custom' ? 'active-report-button' : '', buttons_class]">Custom</button>
                                                </div>
                                            </td>
                                            <td aria-colindex="5" role="cell" class="report-td-client" v-if="user_data.role_id == 4">
                                                <div class="report-items">
                                                    <span class="report-item-client">
                                                        <select v-model="report_form.client_id" @change="labsByClient" :class="buttons_class">
                                                            <option 
                                                            v-for="(selectOption, indexOpt) in clients" 
                                                            :key="indexOpt"
                                                            :value="selectOption.id"
                                                            >
                                                                {{ selectOption.value }}
                                                            </option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td aria-colindex="4" role="cell" class="report-td-location">
                                                <div class="report-items report-items-location">
                                                    <div class="report-item-location">
                                                        <select v-model="report_form.lab_id"  :class="buttons_class" @change="handleReportReq">
                                                            <option 
                                                            v-for="(selectOption, indexOpt) in labs" 
                                                            :key="indexOpt"
                                                            :value="selectOption.id"
                                                            >
                                                                {{ selectOption.value }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </td>

                                            <!-- start custom section -->
                                            <template v-if="report_form.interval == 'custom'">
                                                <td aria-colindex="2" role="cell" class="report-td-singleday">
                                                    <div class="report-items">
                                                        <div class="report-item-label" :class="is_date_range ? 'disabled-opacity' : ''">
                                                            Single Day
                                                        </div>
                                                        <div class="report-item-switch">
                                                            <label class="lockout-switch">
                                                                <input type="checkbox" 
                                                                :ref="'handle_single_day'" 
                                                                v-model="is_date_range"
                                                                >
                                                                <span class="lockout-slider lockout-round"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td aria-colindex="2" role="cell" class="report-td-label single">
                                                    <div class="report-items">
                                                        <div class="report-item-label" :class="!is_date_range ? 'disabled-opacity' : ''">
                                                            Date Range
                                                        </div>
                                                    </div>
                                                </td>

                                                <!-- start single day section -->
                                                <template v-if="!is_date_range">
                                                    <td aria-colindex="2" role="cell" class="report-td-datetime single">
                                                        <div class="report-items datetime-new-design">
                                                            <span class="report-item-date">
                                                                <input
                                                                    type="text"
                                                                    value=""
                                                                    placeholder="MM/DD/YYYY"
                                                                    v-model="report_form.active_start_date" 
                                                                    v-mask="date_mask"
                                                                />
                                                            </span>
                                                            <span class="report-item-time">
                                                                <input
                                                                    type="text"
                                                                    value=""
                                                                    placeholder="00:00"
                                                                    v-model="report_form.active_start_time" 
                                                                    v-mask="time_mask"
                                                                />
                                                            </span>
                                                            <span class="report-item-ampm">
                                                                <select name="" id="" v-model="report_form.active_start_ampm">
                                                                    <option value="AM">AM</option>
                                                                    <option value="PM">PM</option>
                                                                </select>
                                                            </span>
                                                            <span class="report-item-seperator">-</span>
                                                            <span class="report-item-time">
                                                                <input
                                                                    type="text"
                                                                    value=""
                                                                    placeholder="00:00"
                                                                    v-model="report_form.active_end_time" 
                                                                    v-mask="time_mask"
                                                                />
                                                            </span>
                                                            <span class="report-item-ampm">
                                                                <select v-model="report_form.active_end_ampm">
                                                                    <option value="AM">AM</option>
                                                                    <option value="PM">PM</option>
                                                                </select>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </template>
                                                <!-- end single day section -->

                                                <!-- start date range section -->
                                                <template v-if="is_date_range">
                                                    <td aria-colindex="2" role="cell" class="report-td-datetime datetime-range">
                                                        <div class="report-items datetime-new-design">
                                                            <span class="report-item-date">
                                                                <input
                                                                    type="text"
                                                                    value=""
                                                                    placeholder="MM/DD/YYYY"
                                                                    v-model="report_form.active_start_date" 
                                                                    v-mask="date_mask"
                                                                />
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td aria-colindex="2" role="cell" class="report-td-label" style="width: 20px;max-width: 20px;min-width: 20px;">
                                                        <div class="report-items">
                                                            <div class="report-item-label">-</div>
                                                        </div>
                                                    </td>
                                                    <td aria-colindex="2" role="cell" class="report-td-datetime datetime-range single">
                                                        <div class="report-items datetime-new-design">
                                                            <span class="report-item-date">
                                                                <input
                                                                    type="text"
                                                                    value=""
                                                                    placeholder="MM/DD/YYYY"
                                                                    v-model="report_form.active_end_date" 
                                                                    v-mask="date_mask"
                                                                />
                                                            </span>
                                                        </div>
                                                    </td>
                                                </template>
                                                <!-- end date range section -->
                                            </template>
                                            <!-- end custom section -->

                                            <td aria-colindex="6" role="cell" class="report-td-action">
                                                <div class="report-action-button">
                                                    <button class="btn-sm" :class="buttons_class" @click="handleReportReq">
                                                        Create Report
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr class="alerts-border mt-3">
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-12 col-sm-12">
                            <b-overlay :show="api_state" rounded="sm" variant="dark" opacity="0.75">
                                <div class="report-container">

                                    <!-- start of stats panel -->
                                    <div class="report-stats-activities">
                                        <div class="report-panel">
                                            <div class="report-panel-header">
                                                Statistics
                                            </div>
                                            <div class="report-panel-body">
                                                <ul class="report-panel-body-list" v-if="Object.prototype.hasOwnProperty.call(report_data, 'stats')">
                                                    <li class="report-panel-body-list-item" v-for="(item, index) in report_data.stats" :key="index">
                                                        <div class="report-panel-body-list-item-text" v-html="item.key"></div>
                                                        <div class="report-panel-body-list-item-value">
                                                            {{ item.value }}
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="report-panel">
                                            <div class="report-panel-body">
                                                <ul class="report-panel-body-list">
                                                    <li class="report-panel-body-list-item item-header">
                                                        <div class="report-panel-body-list-item-text">
                                                            Activities
                                                        </div>
                                                        <div class="report-panel-body-list-item-value">
                                                            Tot Hrs
                                                        </div>
                                                        <div class="report-panel-body-list-item-value">
                                                            Avg/Use
                                                        </div>
                                                    </li>
                                                    <template v-if="Object.prototype.hasOwnProperty.call(report_data, 'activities')">
                                                        <li class="report-panel-body-list-item" v-for="(item, index) in report_data.activities" :key="index">
                                                            <div class="report-panel-body-list-item-text">
                                                                {{ item.key }}
                                                            </div>
                                                            <div class="report-panel-body-list-item-value">
                                                                {{ item.value }}
                                                            </div>
                                                            <div class="report-panel-body-list-item-value">
                                                                {{ item.avg }}
                                                            </div>
                                                        </li>
                                                    </template>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end of stats panel -->

                                    <!-- start of games/feedback panel -->
                                    <div class="report-gameplay-feedback-wrapper">
                                        <div class="report-gameplay-feedback">
                                            <div class="report-gameplay">
                                                <div class="report-panel">
                                                    <div class="report-panel-body">
                                                        <ul class="report-panel-body-list">
                                                            <li class="report-panel-body-list-item item-header">
                                                                <div class="report-panel-body-list-item-text">
                                                                    Gameplay
                                                                </div>
                                                                <div class="report-panel-body-list-item-value">
                                                                    Plat
                                                                </div>
                                                                <div class="report-panel-body-list-item-value">
                                                                    Tot Hrs
                                                                </div>
                                                                <div class="report-panel-body-list-item-value">
                                                                    Avg/User
                                                                </div>
                                                            </li>
                                                            <template v-if="Object.prototype.hasOwnProperty.call(report_data, 'games')">
                                                                <li class="report-panel-body-list-item" v-for="(item, key, index) in report_data.games" :key="key">
                                                                    <div class="report-panel-body-list-item-text">
                                                                        {{ index+1 }} {{ item.key }}
                                                                    </div>
                                                                    <div class="report-panel-body-list-item-value">
                                                                        <span v-for="(pt, index) in item.platform" :key="index">
                                                                            {{ (pt !== "" && Object.prototype.hasOwnProperty.call(xds_icons, pt)) ? xds_icons[pt].text.split(" ")[1] : "" }}
                                                                        </span>
                                                                        <!-- {{ (item.platform !== "" && Object.prototype.hasOwnProperty.call(xds_icons, item.platform)) ? xds_icons[item.platform].text.split(" ")[1] : "" }} -->
                                                                    </div>
                                                                    <div class="report-panel-body-list-item-value">
                                                                        {{ item.value }}
                                                                    </div>
                                                                    <div class="report-panel-body-list-item-value">
                                                                        {{ item.avg }}
                                                                    </div>
                                                                </li>
                                                            </template>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="report-feedback">
                                                <div class="report-feedback-section">
                                                    <div class="report-panel">
                                                        <div class="report-panel-body">
                                                            <ul class="report-panel-body-list">
                                                                <li class="report-panel-body-list-item item-header">
                                                                    <div class="report-panel-body-list-item-text">
                                                                        Feedback
                                                                    </div>
                                                                    <div class="report-panel-body-list-item-value">
                                                                        Avg Rating
                                                                    </div>
                                                                </li>
                                                                <template v-if="Object.prototype.hasOwnProperty.call(report_data, 'feedback')">
                                                                    <li class="report-panel-body-list-item" v-for="(item, index) in report_data.feedback" :key="index">
                                                                        <div class="report-panel-body-list-item-text">
                                                                            {{ item.key }}
                                                                        </div>
                                                                        <div class="report-panel-body-list-item-value">
                                                                            {{ item.avg }}
                                                                        </div>
                                                                    </li>
                                                                </template>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="report-upcoming-games">
                                                    <div class="report-panel">
                                                        <div class="report-panel-body">
                                                            <ul class="report-panel-body-list">
                                                                <li class="report-panel-body-list-item item-header">
                                                                    <div class="report-panel-body-list-item-text">
                                                                        Upcoming Games
                                                                    </div>
                                                                    <div class="report-panel-body-list-item-value">
                                                                        Votes
                                                                    </div>
                                                                    <div class="report-panel-body-list-item-value">
                                                                        % of Users
                                                                    </div>
                                                                </li>
                                                                <template v-if="Object.prototype.hasOwnProperty.call(report_data, 'upcoming')">
                                                                    <li class="report-panel-body-list-item" v-for="(item, index) in report_data.upcoming" :key="index">
                                                                        <div class="report-panel-body-list-item-text">
                                                                            {{ item.key }}
                                                                        </div>
                                                                        <div class="report-panel-body-list-item-value">
                                                                            {{ item.count }}
                                                                        </div>
                                                                        <div class="report-panel-body-list-item-value">
                                                                            {{ (item.avg * 100).toFixed(1) }}%
                                                                        </div>
                                                                    </li>
                                                                </template>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="report-revenue-section">
                                            <div class="report-panel">
                                                <div class="report-panel-header">
                                                    Revenue
                                                </div>
                                                <div class="report-panel-body">
                                                    <ul class="report-panel-body-list">
                                                        <li class="report-panel-body-list-item">
                                                            <div class="report-panel-body-list-item-value">
                                                                ${{ report_revenue }}
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end of games/feedback panel -->

                                </div>
                            </b-overlay>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <AdminFooter type="inner" v-if="user_data.role_id == 3" />
            <SuperAdminFooter type="inner" v-if="user_data.role_id == 4" />
        </div>
        <!-- end of footer section -->
    </div>
</template>

<script>
import AdminHeader from "@/views/admin/AdminHeader.vue";
import { adminGetClientsList, adminGetLabsList, adminGetReportSummary } from "../../services/AdminService";
import AdminFooter from "@/views/admin/AdminFooter.vue";
import SuperAdminHeader from "@/views/superadmin/SuperAdminHeader.vue";
import SuperAdminFooter from "@/views/superadmin/SuperAdminFooter.vue";
import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import { required, requiredIf } from 'vuelidate/lib/validators';
import moment from 'moment';
import moment_timezone from 'moment-timezone';
import { global_config } from "@/config/config.js";

export default {
    name: "SummaryReport-view",
    components: { AdminHeader, AdminFooter, SuperAdminHeader, SuperAdminFooter },
    data() {
        return {
            api_state: false,
            sort_by: 'active_start',
            sort_type: 'desc',
            search_client_id: '',
            user_data: null,
            dashboard_link: '',
            clients: [],
            labs:[{'id' : '', 'value' : 'All Labs'}],
            report_form: {
                interval:'today',
                active_start_date: '',
                active_start_time: '',
                active_start_ampm: 'AM',
                active_end_date: '',
                active_end_time: '',
                active_end_ampm: 'AM',
                client_id: '',
                lab_id:'',
            },
            date_mask: '##/##/####',
            time_mask: '##:##',
            user_timezone: moment.tz.guess(),
            mtz: moment_timezone,
            report_data : {},
            xds_icons: global_config.XD_ICONS,
            report_revenue: 0,
            buttons_class: '',
            is_date_range: false,
        };
    },

    validations: {
        report_form : {
            client_id:{required:false},
            lab_id:{required:false},
            interval:{required},
            active_start_date:{required: requiredIf(function(){
                if(this.report_form.interval == 'custom') {
                    return true;
                }
                return false; 
            })},
            active_start_time:{required: requiredIf(function(){
                if(this.report_form.interval == 'custom' && !this.is_date_range) {
                    return true;
                }
                return false; 
            })},
            active_start_ampm:{required: requiredIf(function(){
                if(this.report_form.interval == 'custom' && !this.is_date_range) {
                    return true;
                }
                return false; 
            })},
            active_end_date:{required: requiredIf(function(){
                if(this.report_form.interval == 'custom' && this.is_date_range) {
                    return true;
                }
                return false; 
            })},
            active_end_time:{required: requiredIf(function(){
                if(this.report_form.interval == 'custom' && !this.is_date_range) {
                    return true;
                }
                return false; 
            })},
            active_end_ampm:{required: requiredIf(function(){
                if(this.report_form.interval == 'custom' && !this.is_date_range) {
                    return true;
                }
                return false; 
            })},
        }
    },
    computed: {
        logo_file() {
            return this.$store.getters.getThemeLogo().logo;
        }
    },

    methods: {
        setIntervalType(type) {
            this.report_form.interval = type;
            if(type !== 'custom') {
                this.report_form.active_start_date = "";
                this.report_form.active_start_time = "";
                this.report_form.active_end_date = "";
                this.report_form.active_end_time = "";
                this.handleReportReq();
            }
        },
        labsList() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                client_id: (this.report_form.client_id !== '') ? this.report_form.client_id : user_data.client_id
            };
            adminGetLabsList(params).then(response => {
                if(response.status) {
                    this.labs = response.data.labs;
                    // add empty option as placeholder
                    this.labs.splice(0, 0, {'id' : '', 'value' : 'All Labs'});
                }
                else {
                    this.labs = [{'id' : '', 'value' : 'All Labs'}];
                }
            });
        },
        makeToast(variant = "default", message, message1='') {
            this.$root.$emit("toast_message", {'type' : variant, 'message' : message, 'message1': message1});
        },
        setPodData(obj) {
            this[obj.key] = obj.value;
        },
        setUserData() {
            this.user_data = JSON.parse(localStorage.user_sess_data);
            if(!Object.prototype.hasOwnProperty.call(this.user_data, 'role_id')) {
                this.$router.push("/admin/login");
            }
            this.dashboard_link = this.user_data.role_id == 2 ? '/facilitator/dashboard' : (this.user_data.role_id == 3 ? '/admin/dashboard' : '/superadmin/dashboard');
            if(this.user_data.role_id == 4) {
                this.clientsList();
            }
        },
        clientsList() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token
            };
            adminGetClientsList(params).then(response => {
                this.clients = response.data.clients;
                // add empty option as placeholder
                this.clients.splice(0, 0, {'id' : '', 'value' : 'All clients'});
            });
        },
        labsByClient() {
            if(this.report_form.client_id == "") {
                this.labs = [{'id' : '', 'value' : 'All Labs'}];
            }
            else {
                this.labsList();
            }
            this.handleReportReq();
        },
        handleReportReq() {
            // fill in client id if user is not superadmin
            if(this.user_data.role_id !== 4) {
                this.report_form.client_id = this.user_data.client_id;
            }
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.makeToast("error", "Please fill all required fields");
            } 
            else {
                this.buttons_class = "disabled-opacity";
                this.api_state = true;
                let user_data = JSON.parse(localStorage.user_sess_data);
                let data = this.report_form;
                data.token = user_data.token;
                data.start_date = "";
                data.end_date = "";

                // add checks for date range and single day
                if(data.interval == 'custom') {
                    if(!this.is_date_range && data.active_start_date !== "" && data.active_start_time !== "") {
                        data.start_date = data.active_start_date + " " + data.active_start_time + " " + data.active_start_ampm;
                        data.end_date = data.active_start_date + " " + data.active_end_time + " " + data.active_end_ampm;    
                    }
                    else if(this.is_date_range && data.active_start_date !== "" && data.active_end_date !== "") {
                        data.start_date = data.active_start_date + " 12:00 AM";
                        data.end_date = data.active_end_date + " 11:59 PM";    
                    }
                }
                data.user_timezone = this.user_timezone;
                adminGetReportSummary(data).then(response => {
                    this.api_state = false;
                    this.buttons_class = "";
                    if(response.status) {
                        this.report_data = response.data.report;
                        this.report_revenue = response.data.revenue;
                    }
                    else {
                        this.makeToast("error", response.message);
                    }
                });
            }
        },
    },

    mounted() {
        this.labsList();
        this.handleReportReq();
    },
    created() {
        this.setUserData();
    }
};
</script>
<style scoped>
.disabled-opacity {
    pointer-events: none !important;
    opacity: 0.6;
}
#reports-table > tbody > tr > td.report-td-datetime, #reports-table > thead > tr > th.report-td-datetime {
    width: 320px;
    max-width: 320px;
    min-width: 320px;
    padding: 0px;
}
#reports-table > tbody > tr > td.report-td-singleday {
    width: 160px;
    max-width: 160px;
    min-width: 160px;
    padding: 0;
    padding-right: 7px;
    padding-left: 17px;
}
#reports-table > tbody > tr > td.report-td-singleday > .report-items {
    column-gap: 10px;
    border: unset;
}
#reports-table > tbody > tr > td.report-td-label {
    width: 85px;
    max-width: 85px;
    min-width: 85px;
    padding: 0;
    padding-left: 10px;
    padding-right: 30px;
}
#reports-table > tbody > tr > td.report-td-label > .report-items {
    border: unset;
}
/* date time new design */
#reports-table > tbody > tr.reports-table-header-row {
    display: table;
}
#reports-table > tbody > tr > td.report-td-datetime > .report-items.datetime-new-design, 
#reports-table > thead > tr > th.report-td-datetime > .report-items.datetime-new-design {
    border-radius: 8px !important;
    border-right: 2px solid var(--client-primary) !important;
    position: relative;
}
#reports-table > tbody > tr > td > .report-items.datetime-new-design .report-item-date {
    width: 140px !important;
}
#reports-table > tbody > tr > td > .report-items.datetime-new-design .report-item-time {
    border: 0 !important;
    width: 55px !important;
    padding-right: 0px;
}
#reports-table > tbody > tr > td > .report-items.datetime-new-design .report-item-ampm {
    padding-left: 0px !important;
    width: 30px !important;
}
#reports-table > tbody > tr > td.report-td-datetime.datetime-range {
    width: 147px;
    max-width: 147px;
    min-width: 147px;
}
#reports-table > tbody > tr > td.report-td-datetime.datetime-range > .report-items.datetime-new-design .report-item-date {
    border: unset !important;
}
#reports-table > tbody > tr > td.report-td-button {
    width: 415px;
    max-width: 415px;
    min-width: 415px;
}
#reports-table > tbody > tr > td.report-td-datetime:nth-child(5) .report-items {
    border-right: unset;
    border-radius: 8px 0px 0px 8px;
}
#reports-table > tbody > tr > td.report-td-datetime:nth-child(6) .report-items {
    border-radius: 0px 8px 8px 0px;
}
#reports-table > tbody > tr > td.report-td-client {
    width: 200px;
    max-width: 200px;
    min-width: 200px;
    padding: 0px 0px 0px 5px;
}
.lockout-switch input:checked + .lockout-slider:before {
    background-color: var(--client-primary) !important;
}
.lockout-slider:before {
    content: "" !important;
}
#reports-table > tbody > tr > td.report-td-button .report-buttons::after {
    content: "";
    width: 3px;
    display: block;
    background-color: var(--client-primary) !important;
    margin-left: 13px;
    height: 40px;
    margin-top: 8px;
}
#reports-table > tbody > tr > td.report-td-singleday .report-items::before {
    content: "";
    width: 1px;
    display: block;
    background-color: var(--client-primary) !important;
    height: 40px;
    border-left: 3px solid var(--client-primary);
}
#reports-table > tbody > tr > td.report-td-label.single > .report-items:after {
    content: "";
    width: 1px;
    display: block;
    background-color: var(--client-primary) !important;
    height: 40px;
    border-left: 3px solid var(--client-primary);
    margin-left: 8px;
}
#reports-table > tbody > tr > td.report-td-action > .report-action-button::before {
    content: "";
    width: 1px;
    display: block;
    background-color: var(--client-primary) !important;
    height: 40px;
    border-left: 3px solid var(--client-primary);
    margin-top: 8px;
    margin-right: 4px;
}
#reports-table > tbody > tr > td.report-td-action {
    padding: 0px 0px 0px 15px !important;
}
/* end date time new design */
</style>