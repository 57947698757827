<template>
    <div class="main-layout-section admin-portal">
        <SuperAdminHeader />
        
        <div class="w-logo mb-3">
            <img src="../../assets/images/gamelab-logo.svg" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" />
        </div>

        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeInUp animate__delay-1s">Manage DB Backups</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">

                    <div class="admin-general-section-buttons">
                        <router-link to="/superadmin/dashboard" class="admin-general-section-button">
                            Back To Dashboard
                        </router-link>
                    </div>

                    <b-overlay :show="api_state" rounded="sm" variant="light" opacity="0.75" class="overlay-bg">
                    <div class="col-sm-12">
                        <div class="overflow-auto p-0 m-0">
                            <b-table
                            id="admin-general-table"
                            :striped="true"
                            :hover="false"
                            :dark="false"
                            :items="backups"
                            :fields="fields"
                            show-empty
                            :emptyText="emptyText"
                            >
                                <template #cell(action)="data">
                                    <div class="icon-action-buttons-container d-flex flex-row">
                                        <a :href="data.item.DownloadUrl" target="_blank" class="btn-sm icon-button-actions">
                                            <span class="icon fa fa-download"></span>
                                            Download
                                        </a>
                                        <a 
                                        class="btn-sm icon-button-actions"
                                        @click="confirmBackupDelete(data.item.id)" 
                                        tabindex="0"
                                        >
                                            <span class="icon fa fa-trash"></span>
                                            Delete
                                        </a>
                                    </div>
                                </template>
                                <template #empty="scope">
                                    <p class="text-center p-0 m-0">{{ scope.emptyText }}</p>
                                </template>
                            </b-table>
                        </div>
                        <b-pagination
                        v-model="current_page"
                        :total-rows="total_rows"
                        :per-page="per_page" 
                        limit="3"
                        aria-controls="admin-general-table" 
                        align="right"
                        ></b-pagination>
                        <p class="admin-general-table-status-text">{{ status_text }}</p>
                    </div>
                    </b-overlay>
                </div>
                
            </div>

        </div>

        <!-- start of delete confirm modal -->
        <b-modal id="delete-backup-modal"  dialog-class="admin-portal-modal" header-class="admin-portal-bg px-3 py-2" hide-footer>
            <template #modal-title>
                Confirm
                <b-icon 
                class="icon" 
                icon="question-circle-fill"
                >
                </b-icon> 
            </template>
            <b-overlay :show="false" rounded="lg">
                <div class="d-flex">
                    <p>Are you sure you want to delete selected DB Backup ?</p>
                </div>
                <div class="d-flex text-center">
                    <button
                        type="button"
                        class="modal-button px-5 py-2"
                        @click="deleteBackup"
                    >
                        Yes
                    </button>
                    <button
                        type="button"
                        class="modal-button px-5 py-2 mx-2"
                        @click="$bvModal.hide('delete-backup-modal')"
                    >
                        No
                    </button>
                </div>
            </b-overlay>
        </b-modal>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <SuperAdminFooter type="inner" />
        </div>
        <!-- end of footer section -->
    </div>
</template>

<script>
import SuperAdminHeader from "@/views/superadmin/SuperAdminHeader.vue";
import SuperAdminFooter from "@/views/superadmin/SuperAdminFooter.vue";
import { adminGetDbBackupsList, adminDeleteDbBackup } from "../../services/AdminService";

export default {
    name: "SuperAdminDbBackups-view",
    components: { SuperAdminHeader, SuperAdminFooter },
    data() {
        return {
            status_text: '',
            per_page: 10,
            current_page: 1,
            total_rows: 0,
            emptyText:"No db backups found at the moment!",
            fields: [
            {'key' : 'id', 'label' : 'ID'}, 
            {'key' : 'file_name', 'label' : 'File Name'},
            {'key' : 'action', 'label' : 'Action'}
            ],
            backups: [],
            api_state: false,
            delete_backup_id: "",
        };
    },

    computed: {
    },

    watch: {
        current_page: {
            handler: function(value) {
                this.current_page = value;
                this.backupsList();
            }
        }
    },

    methods: {
        backupsList() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                length: this.per_page,
                page: this.current_page,
            };
            this.api_state = true;
            adminGetDbBackupsList(params).then(response => {
                this.api_state = false;
                this.backups = response.data.backups;
                this.current_page = response.data.current_page;
                this.total_rows = response.data.total_rows;
                this.status_text = response.data.status_text;
            });
        },

        makeToast(variant = "default", message) {
            this.$root.$emit("toast_message", {'type' : variant, 'message' : message});
        },

        confirmBackupDelete(id) {
            this.delete_backup_id = id;
            this.$bvModal.show('delete-backup-modal');
        },

        deleteBackup() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                id: this.delete_backup_id
            };
            this.$bvModal.hide('delete-backup-modal');
            this.api_state = true;
            adminDeleteDbBackup(params).then(response => {
                this.api_state = false;
                if(response.status) {
                    this.makeToast("success", response.message);
                    this.backupsList();
                }
                else {
                    this.makeToast("error", response.message);
                }
            });
        },

    },

    mounted() {
        this.backupsList();
    }

};
</script>