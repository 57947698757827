<template>
    <div class="main-layout-section admin-portal" :class="[user_data.role_id == 4 ? '' : 'secondary']">
        <AdminHeader @setPodData="setPodData($event)" v-if="user_data.role_id == 3" />
        <SuperAdminHeader v-if="user_data.role_id == 4" />
        <div class="w-logo mb-3">
            <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="logo_file !== '' && logo_file !== null && user_data.role_id !== 4"/>
            <img src="../../assets/images/gamelab-logo.svg" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="user_data.role_id == 4"/>
        </div>

        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeInUp animate__delay-1s">Create Report</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">
                    
                    <div class="row">
                        <div class="admin-general-section-buttons col-md-12">
                            <router-link :to="dashboard_link" class="admin-general-section-button">
                                Back To Dashboard
                            </router-link>
                        </div>
                        <div class="admin-general-section-buttons col-md-12 mb-3">
                            <router-link :to="dashboard_link" class="alerts-breadcrumb">
                                Dashboard
                            </router-link>
                            <span class="alerts-breadcrumb">></span>
                            <span class="alerts-breadcrumb">Create New Report</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 col-sm-12 alerts-section-heading first d-none">
                            <h5>Create New Report</h5>
                        </div>
                        <div class="col-sm-12">
                            <div class="reports-menu-header">
                                <router-link class="report-menu-header-button" :to="`/common/reports/status`">Status</router-link>
                                <router-link class="report-menu-header-button" :to="`/common/reports/summary`">Activity</router-link>
                                <router-link class="report-menu-header-button active-report-menu-header-button" :to="`/common/reports/logs`">Logs</router-link>
                            </div>
                            <hr class="alerts-border mt-2 mb-3">
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="overflow-auto p-0 m-0">
                                <table id="reports-table" role="table" aria-busy="false" aria-colcount="6" class="table b-table table-striped">
                                    <tbody role="rowgroup">
                                        <tr role="row" class="">
                                            <td aria-colindex="1" role="cell" class="report-td-location">
                                                <div class="report-items report-items-location">
                                                    <div class="report-item-location">
                                                        <input v-model="report_form.search_email" placeholder="Type an email to search" />
                                                    </div>
                                                </div>
                                            </td>
                                            <td aria-colindex="2" role="cell" class="report-td-client" v-if="user_data.role_id == 4">
                                                <div class="report-items">
                                                    <span class="report-item-client">
                                                        <select v-model="report_form.client_id" @change="labsByClient">
                                                            <option 
                                                            v-for="(selectOption, indexOpt) in clients" 
                                                            :key="indexOpt"
                                                            :value="selectOption.id"
                                                            >
                                                                {{ selectOption.value }}
                                                            </option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td aria-colindex="4" role="cell" class="report-td-location">
                                                <div class="report-items report-items-location">
                                                    <div class="report-item-location">
                                                        <select v-model="report_form.lab_id" @change="podsByLab">
                                                            <option 
                                                            v-for="(selectOption, indexOpt) in labs" 
                                                            :key="indexOpt"
                                                            :value="selectOption.id"
                                                            >
                                                                {{ selectOption.value }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </td>
                                            <td aria-colindex="5" role="cell" class="report-td-client">
                                                <div class="report-items">
                                                    <span class="report-item-client">
                                                        <select v-model="report_form.pod_id">
                                                            <option 
                                                            v-for="(selectOption, indexOpt) in pods" 
                                                            :key="indexOpt"
                                                            :value="selectOption.id"
                                                            >
                                                                {{ selectOption.value }}
                                                            </option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td aria-colindex="6" role="cell" class="report-td-action">
                                                <div class="report-action-button">
                                                    <button class="btn-sm" @click="handleReportReq(false)">
                                                        Create Report
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr class="alerts-border mt-3">
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-12 col-sm-12">
                            <b-overlay :show="api_state" rounded="sm" variant="dark" opacity="0.75">
                                <div class="overflow-auto p-0 m-0">
                                    <b-table
                                    id="admin-general-table" 
                                    class="logs-table"
                                    :striped="false"
                                    :hover="false"
                                    :dark="false"
                                    :items="logs"
                                    :fields="fields"
                                    show-empty
                                    :emptyText="emptyText" 
                                    >
                                        <template #empty="scope">
                                            <p class="text-center p-0 m-0">{{ scope.emptyText }}</p>
                                        </template>
                                    </b-table>
                                </div>
                                <hr class="alerts-border mb-3">
                                <b-pagination
                                v-model="current_page"
                                :total-rows="total_rows"
                                :per-page="per_page" 
                                limit="3"
                                aria-controls="admin-general-table" 
                                align="right"
                                ></b-pagination>
                                <p class="admin-general-table-status-text">{{ status_text }}</p>
                            </b-overlay>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <AdminFooter type="inner" v-if="user_data.role_id == 3" />
            <SuperAdminFooter type="inner" v-if="user_data.role_id == 4" />
        </div>
        <!-- end of footer section -->
    </div>
</template>

<script>
import AdminHeader from "@/views/admin/AdminHeader.vue";
import { adminGetClientsList, adminGetLabsList, adminGetLogsReport, adminGetPodsList } from "../../services/AdminService";
import AdminFooter from "@/views/admin/AdminFooter.vue";
import SuperAdminHeader from "@/views/superadmin/SuperAdminHeader.vue";
import SuperAdminFooter from "@/views/superadmin/SuperAdminFooter.vue";
import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import moment from 'moment';
import moment_timezone from 'moment-timezone';
import { global_config } from "@/config/config.js";

export default {
    name: "ActivityLogReport-view",
    components: { AdminHeader, AdminFooter, SuperAdminHeader, SuperAdminFooter },
    data() {
        return {
            api_state: false,
            search_client_id: '',
            search_email: '',
            user_data: null,
            dashboard_link: '',
            root_link: '',
            status_text: '',
            per_page: 10,
            current_page: 1,
            total_rows: 0,
            clients: [],
            labs:[{'id' : '', 'value' : 'All Labs'}],
            pods:[{'id' : '', 'value' : 'All Pods'}],
            emptyText:"No users found at the moment!",
            emptyHtml:"No users found at the moment!",
            fields: [
                {'key' : 'activity_time_formatted', 'label' : 'Time'}, 
                {'key' : 'user_name', 'label' : 'Username'},
                {'key' : 'role_name', 'label' : 'Role'},
                {'key' : 'activity_title', 'label' : 'Location'},
                {'key' : 'activity', 'label' : 'Activity'}
            ],
            report_form: {
                client_id: '',
                lab_id:'',
                search_email: '',
                pod_id: ''
            },
            user_timezone: moment.tz.guess(),
            mtz: moment_timezone,
            logs : [],
            xds_icons: global_config.XD_ICONS,
        };
    },

    validations: {
        report_form : {
            client_id:{required:false},
            lab_id:{required:false}
        }
    },
    computed: {
        logo_file() {
            return this.$store.getters.getThemeLogo().logo;
        }
    },
    watch: {
        current_page: {
            handler: function(value) {
                this.current_page = value;
                this.handleReportReq();
            }
        }
    },

    methods: {
        labsList() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                client_id: (this.report_form.client_id !== '') ? this.report_form.client_id : user_data.client_id
            };
            adminGetLabsList(params).then(response => {
                if(response.status) {
                    this.labs = response.data.labs;
                    // add empty option as placeholder
                    this.labs.splice(0, 0, {'id' : '', 'value' : 'All Labs'});
                }
                else {
                    this.labs = [{'id' : '', 'value' : 'All Labs'}];
                }
            });
        },
        makeToast(variant = "default", message, message1='') {
            this.$root.$emit("toast_message", {'type' : variant, 'message' : message, 'message1': message1});
        },
        setPodData(obj) {
            this[obj.key] = obj.value;
        },
        setUserData() {
            this.user_data = JSON.parse(localStorage.user_sess_data);
            if(!Object.prototype.hasOwnProperty.call(this.user_data, 'role_id')) {
                this.$router.push("/admin/login");
            }
            this.dashboard_link = this.user_data.role_id == 3 ? '/admin/dashboard' : '/superadmin/dashboard';
            this.root_link = this.user_data.role_id == 3 ? '/admin/' : '/superadmin/';
            if(this.user_data.role_id == 4) {
                this.clientsList();
            }
        },
        clientsList() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token
            };
            adminGetClientsList(params).then(response => {
                this.clients = response.data.clients;
                // add empty option as placeholder
                this.clients.splice(0, 0, {'id' : '', 'value' : 'All clients'});
            });
        },
        labsByClient() {
            if(this.report_form.client_id == "") {
                this.labs = [{'id' : '', 'value' : 'All Labs'}];
                this.pods = [{'id' : '', 'value' : 'All Pods'}];
                this.report_form.lab_id = '';
                this.report_form.pod_id = '';
            }
            else {
                this.labsList();
            }
        },
        podsByLab() {
            let _self = this;
            if(this.report_form.lab_id == "") {
                this.pods = [{'id' : '', 'value' : 'All Pods'}];
                this.report_form.pod_id = '';
            }
            else {
                let user_data = JSON.parse(localStorage.user_sess_data);
                let params = {
                    token: user_data.token,
                    client_id: (this.user_data.role_id == 4 ? this.report_form.client_id : user_data.client_id),
                    lab_id: this.report_form.lab_id
                };
                adminGetPodsList(params).then(response => {
                    if(response.status) {
                        // add empty option as placeholder
                        this.pods = [{'id' : '', 'value' : 'All Pods'}];
                        response.data.pods.forEach((item) => {
                            _self.pods.push({'id': item.id, 'value' : item.license});
                        });
                    }
                    else {
                        this.pods = [{'id' : '', 'value' : 'All Pods'}];
                    }
                });
            }
        },
        handleReportReq(is_loop=false) {
            // fill in client id if user is not superadmin
            if(this.user_data.role_id !== 4) {
                this.report_form.client_id = this.user_data.client_id;
            }
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.makeToast("error", "Please fill all required fields");
            } 
            else {
                this.api_state = is_loop ? false : true;
                let user_data = JSON.parse(localStorage.user_sess_data);
                let data = this.report_form;
                data.token = user_data.token;
                data.user_timezone = this.user_timezone;
                data.length = this.per_page;
                data.page = this.current_page;
                adminGetLogsReport(data).then(response => {
                    this.api_state = false;
                    if(response.status) {
                        this.logs = response.data.logs;
                        this.current_page = response.data.current_page;
                        this.total_rows = response.data.total_rows;
                        this.status_text = response.data.status_text;
                    }
                    else {
                        this.makeToast("error", response.message);
                    }
                });
            }
        },
    },

    mounted() {
        this.labsList();
        this.handleReportReq();
    },
    created() {
        this.setUserData();
    }
};
</script>
<style scoped>
#reports-table {
    width: auto;
}
#reports-table > tbody > tr > td.report-td-button {
    width: auto;
    min-width: auto;
    max-width: max-content;
}
.client-lab-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 47px;
}
.report-client-name {
    font-size: 18px;
    font-family: 'Open Sans';
    width: fit-content !important;
    margin-right: 15px;
}
.report-lab-name {
    font-size: 14px;
    font-family: 'Open Sans';
    width: fit-content !important;
}
.report-anchor-link {
    color: inherit;
    text-decoration: none;
}
#reports-table > tbody > tr > td > .report-items input::placeholder {
    color: #d8d8d8;
    opacity: 0.5;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
}
</style>