<template>
    <div class="main-layout-section admin-portal">
        <SuperAdminHeader />
        
        <div class="w-logo mb-3">
            <img src="../../assets/images/gamelab-logo.svg" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" />
        </div>

        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeInUp animate__delay-1s">Client Details</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">

                    <div class="admin-general-section-buttons">
                        <router-link to="/superadmin/clients" class="admin-general-section-button">
                            Back To Clients Management
                        </router-link>
                    </div>
                    
                    <div class="col-sm-12">
                        <div class="d-flex col-md-12 m-auto p-0 my-3">
                            <h4 class="page-heading animate__animated animate__fadeInUp animate__delay-1s text-left">Client</h4>
                        </div>
                        
                        <div class="overflow-auto p-0 m-0">
                            <b-form inline @submit.stop.prevent class="p-0 m-0">
                                <table class="table b-table table-striped" id="admin-client-table">
                                    <tbody>
                                        <tr>
                                            <th width="20%">Name</th>
                                            <td width="80%" colspan="3">
                                                <b-form-input
                                                    v-model="form.name"
                                                    type="text"
                                                    placeholder="Enter name"
                                                    id="name"
                                                    aria-describedby="name-help-block"
                                                    class="client-edit-input"
                                                    :class="{ 'is-invalid': $v.form.name.$error }"
                                                ></b-form-input>
                                            </td>
                                            <!-- <th width="20%">Last Name</th>
                                            <td width="30%">{{ client.last_name }}</td> -->
                                        </tr>
                                        <tr>
                                            <th width="20%">Email</th>
                                            <td width="30%">
                                                <b-form-input
                                                    v-model="form.email"
                                                    type="text"
                                                    placeholder="Enter email"
                                                    id="email"
                                                    aria-describedby="email-help-block"
                                                    class="client-edit-input"
                                                    :class="{ 'is-invalid': $v.form.email.$error }"
                                                ></b-form-input>
                                            </td>
                                            <th width="20%">Phone#</th>
                                            <td width="30%">
                                                <b-form-input
                                                    v-model="form.phone"
                                                    type="text"
                                                    placeholder="Enter phone"
                                                    id="phone"
                                                    aria-describedby="phone-help-block"
                                                    class="client-edit-input"
                                                    :class="{ 'is-invalid': $v.form.phone.$error }"
                                                ></b-form-input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th width="20%">City</th>
                                            <td width="30%">
                                                <b-form-input
                                                    v-model="form.city"
                                                    type="text"
                                                    placeholder="Enter city"
                                                    id="city"
                                                    aria-describedby="name-help-block"
                                                    class="client-edit-input"
                                                    :class="{ 'is-invalid': $v.form.city.$error }"
                                                ></b-form-input>
                                            </td>
                                            <th width="20%">State</th>
                                            <td width="30%">
                                                <b-form-input
                                                    v-model="form.state"
                                                    type="text"
                                                    placeholder="Enter state"
                                                    id="state"
                                                    aria-describedby="state-help-block"
                                                    class="client-edit-input"
                                                    :class="{ 'is-invalid': $v.form.state.$error }"
                                                ></b-form-input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th width="20%">Address 1</th>
                                            <td width="30%">
                                                <b-form-input
                                                    v-model="form.address1"
                                                    type="text"
                                                    placeholder="Enter address line 1"
                                                    id="address1"
                                                    aria-describedby="address1-help-block"
                                                    class="client-edit-input"
                                                    :class="{ 'is-invalid': $v.form.address1.$error }"
                                                ></b-form-input>
                                            </td>
                                            <th width="20%">Address 2</th>
                                            <td width="30%">
                                                <b-form-input
                                                    v-model="form.address2"
                                                    type="text"
                                                    placeholder="Enter address line 2"
                                                    id="address2"
                                                    aria-describedby="address2-help-block"
                                                    class="client-edit-input"
                                                    :class="{ 'is-invalid': $v.form.address2.$error }"
                                                ></b-form-input>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-form>
                        </div>
                        
                        <div
                        class="
                        client-edit-button w-form-control m-0 p-0
                        "
                        >
                            <a
                            href="javascript:void(0)"
                            @click="handleClientUpdateReq"
                            class=""
                            >Save</a
                            >
                        </div>

                    </div>

                    <div class="col-sm-12">
                        <div class="d-flex col-md-12 m-auto p-0 my-3">
                            <h4 class="page-heading animate__animated animate__fadeInUp animate__delay-1s text-left">SSO Settings</h4>
                        </div>
                        
                        <div class="overflow-auto p-0 m-0">
                            <b-form inline @submit.stop.prevent class="p-0 m-0">
                                <table class="table b-table table-striped" id="admin-client-table">
                                    <tbody>
                                        <tr>
                                            <th width="20%">Active</th>
                                            <td width="80%">
                                                <div class="d-flex flex-column switch-container">
                                                    <switches 
                                                    v-model="sso_form.active"
                                                    class="active-switch"
                                                    theme="bootstrap" 
                                                    color="primary"
                                                    type-bold="true"
                                                    >
                                                    </switches>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="sso_form.active">
                                            <th width="20%">Entity ID</th>
                                            <td width="80%" colspan="3">
                                                <b-form-input
                                                    v-model="sso_form.entity"
                                                    type="text"
                                                    placeholder="Enter entity id from metadata"
                                                    id="name"
                                                    aria-describedby="name-help-block"
                                                    class="client-edit-input"
                                                    :class="{ 'is-invalid': $v.sso_form.entity.$error }"
                                                ></b-form-input>
                                            </td>
                                        </tr>
                                        <tr v-if="sso_form.active">
                                            <th width="20%">Login URL</th>
                                            <td width="80%" colspan="3">
                                                <b-form-input
                                                    v-model="sso_form.login"
                                                    type="text"
                                                    placeholder="Enter login url from metadata"
                                                    id="name"
                                                    aria-describedby="name-help-block"
                                                    class="client-edit-input"
                                                    :class="{ 'is-invalid': $v.sso_form.login.$error }"
                                                ></b-form-input>
                                            </td>
                                        </tr>
                                        <tr v-if="sso_form.active">
                                            <th width="20%">Logout URL</th>
                                            <td width="80%" colspan="3">
                                                <b-form-input
                                                    v-model="sso_form.logout"
                                                    type="text"
                                                    placeholder="Enter logout url from metadata"
                                                    id="name"
                                                    aria-describedby="name-help-block"
                                                    class="client-edit-input"
                                                    :class="{ 'is-invalid': $v.sso_form.logout.$error }"
                                                ></b-form-input>
                                            </td>
                                        </tr>
                                        <tr v-if="sso_form.active">
                                            <th width="20%" style="vertical-align:baseline;">X509 Certificates</th>
                                            <td width="80%">
                                                <b-form-textarea
                                                    v-model="sso_form.certs"
                                                    id="metadata"
                                                    class="client-edit-input"
                                                    :class="{ 'is-invalid': $v.sso_form.certs.$error }"
                                                    placeholder="Enter x509 certificates here..."
                                                    no-resize 
                                                    style="background:#d8d8d8;height:100px;"
                                                ></b-form-textarea>
                                            </td>
                                        </tr>
                                        <tr v-if="sso_form.active && Object.prototype.hasOwnProperty.call(sso_urls, 'metadata')">
                                            <th width="20%">Your Metadata Link</th>
                                            <td width="80%">
                                                <p class="m-0">{{ sso_urls.metadata }}</p>
                                                <small style="font-size:12px;color:red;">You have to provide that link to your Identity Provider to whitelist our server so sso could work.</small>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-form>
                        </div>
                        
                        <div
                        class="
                        client-edit-button w-form-control m-0 p-0
                        "
                        >
                            <a
                            href="javascript:void(0)"
                            @click="handleClientSsoReq"
                            class=""
                            >Save</a
                            >
                        </div>

                    </div>

                    <div class="col-sm-12">
                        <div class="d-flex col-md-12 m-auto p-0 my-3">
                            <h4 class="page-heading animate__animated animate__fadeInUp animate__delay-1s text-left">Labs</h4>
                        </div>
                        
                        <div class="overflow-auto p-0 m-0">
                            <b-table
                            id="admin-general-table"
                            :striped="true"
                            :hover="false"
                            :dark="false"
                            :items="labs"
                            :fields="labfields"
                            show-empty
                            :emptyText="emptyLabs"
                            >
                                <template #cell(action)="data">
                                    <router-link :to="`/superadmin/clients/${client_id}/pods/${data.item.id}`">
                                        <b-icon class="icon" :icon="data.value"></b-icon>
                                        View Pods
                                    </router-link>
                                </template>
                                <template #empty="scope">
                                    <p class="text-center p-0 m-0">{{ scope.emptyText }}</p>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <SuperAdminFooter type="inner" />
        </div>
        <!-- end of footer section -->
    </div>
</template>

<script>
import SuperAdminHeader from "@/views/superadmin/SuperAdminHeader.vue";
import SuperAdminFooter from "@/views/superadmin/SuperAdminFooter.vue";
import { adminGetLabsList, adminGetClientDetails, adminUpdateClient, adminSaveSSOSetting } from "../../services/AdminService";
import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import { required, requiredIf } from 'vuelidate/lib/validators';
import Switches from 'vue-switches';

export default {
  name: "SuperAdminClientsDetails-view",
  components: { SuperAdminHeader, SuperAdminFooter,Switches },
  data() {
    return {
      client_id: this.$route.params.client_id,
      emptyLabs:"No labs found for client!",
      labfields: [
        {'key' : 'id', 'label' : 'ID'},
        {'key' : 'value', 'label' : 'Name'},
        {'key' : 'action', 'label' : 'Action'}
      ],
      labs: [],
      emptyPods:"No pods found for client!",
      podfields: [
        {'key' : 'id', 'label' : 'ID'}, 
        {'key' : 'value', 'label' : 'Key'},
        {'key' : 'nav_id', 'label' : 'Nav ID'}, 
        {'key' : 'seat', 'label' : 'Seat'}, 
        {'key' : 'status', 'label' : 'Status'}
      ],
      client: [],
      form: {
        name : '',
        email : '',
        phone : '',
        address1 : '',
        address2 : '',
        city : '',
        state : ''
      },
      sso_form: {
        active: 0,
        entity: '',
        login: '',
        logout: '',
        certs: ''
      },
      sso_urls: []
    };
  },

  validations: {
    form : {
      name : {required},
      email : {required},
      phone : {required},
      address1 : {required:false},
      address2 : {required:false},
      city : {required:false},
      state : {required:false},
    },
    sso_form: {
        active: {required},
        entity: {
            required: requiredIf(function (params) {
                params;
                if(!this.sso_form.active) {
                    return false;
                }
                return true;  
            })
        },
        login: {
            required: requiredIf(function (params) {
                params;
                if(!this.sso_form.active) {
                    return false;
                }
                return true;  
            })
        },
        logout: {
            required: requiredIf(function (params) {
                params;
                if(!this.sso_form.active) {
                    return false;
                }
                return true;  
            })
        },
        certs: {
            required: requiredIf(function (params) {
                params;
                if(!this.sso_form.active) {
                    return false;
                }
                return true;  
            })
        }
    }
  },

  computed: {
  },

  methods: {
    labsList() {
      let user_data = JSON.parse(localStorage.user_sess_data);
      let params = {
        token: user_data.token,
        client_id: this.client_id
      };
      adminGetLabsList(params).then(response => {
        this.labs = response.data.labs;
        if(this.labs.length > 0) {
          this.labs.map(function(cl){
            return cl.action='eye-fill';
          });
        }
      });
    },

    clientDetails() {
      let user_data = JSON.parse(localStorage.user_sess_data);
      let params = {
        token: user_data.token,
        client_id: this.client_id
      };
      adminGetClientDetails(params).then(response => {
        this.client = response.data.clients[0];
        this.form.name = this.client.first_name;
        this.form.email = this.client.email;
        this.form.phone = this.client.phone;
        this.form.address1 = this.client.address1;
        this.form.address2 = this.client.address2;
        this.form.city = this.client.city;
        this.form.state = this.client.state;
        this.sso_form.active = this.client.sso_active;
        this.sso_urls = this.client.sso_tenant_urls;
        if(typeof response.data.sso_details == 'object' && Object.prototype.hasOwnProperty.call(response.data.sso_details, 'entity')) {
            this.sso_form.entity = response.data.sso_details.entity;
            this.sso_form.login = response.data.sso_details.login;
            this.sso_form.logout = response.data.sso_details.logout;
            this.sso_form.certs = response.data.sso_details.certs;
        }
      });
    },

    makeToast(variant = "default", message) {
        // this.$toast.open({
        // message: message,
        // type: variant,
        // position: 'top-right',
        // duration: 3000,
        // dismissible: true
        // });
        this.$root.$emit("toast_message", {'type' : variant, 'message' : message});
    },

    handleClientUpdateReq() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.makeToast("error", "generic-required-message");
      } else {
        let user_data = JSON.parse(localStorage.user_sess_data);
        let data = this.form;
        data.token = user_data.token;
        data.edit_id = this.client_id;
        adminUpdateClient(data).then(response => {
          if(response.status){
            this.makeToast("success", response.message);
            this.sso_urls = response.data.client.sso_tenant_urls;
          } else {
            this.makeToast("error", response.message);
          }
        }); 
      }
    },

    handleClientSsoReq() {
        this.$v.sso_form.$touch();
        if (this.$v.sso_form.$invalid) {
            this.makeToast("error", "generic-required-message");
        } else {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let data = this.sso_form;
            data.client_id = this.client_id;
            data.token = user_data.token;
            data.active = data.active ? 1 : 0;
            adminSaveSSOSetting(data).then(response => {
                if(response.status){
                    this.makeToast("success", response.message);
                } else {
                    this.makeToast("error", response.message);
                }
            });
        }
    },

  },

  mounted() {
    this.clientDetails();
    this.labsList();
  }

};
</script>
<style scoped>
.admin-portal #admin-client-table > thead > tr > th, 
.admin-portal #admin-client-table > tbody > tr > th {
    vertical-align: middle;
}
.client-edit-input,
.client-edit-input:focus {
    width: 100%;
    background: transparent;
    border: unset;
    outline: unset;
    box-shadow: unset;
    padding: 5px 0px;
}
.client-edit-button {
    justify-content: right;
    display: flex;
}
.client-edit-button a {
    min-width: 125px;
}
</style>