<template>
  <!-- new view -->
  <section class="blue forgot-page">
    <MainKeyboard :main_element="current_component" />  
    <div class="smaller-screen-header">
        <div class="smaller-screen-header-logo">
            <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s"
                v-if="logo_file !== '' && logo_file !== null"
            />
        </div>
        <div v-if="nav_name !== ''" class="w-form-control pod-info-nav animate__animated animate__fadeInUp animate__delay-1s smaller-screen-header-nav">
            <span class="pod-info-nav-name">
                {{ nav_name }}
            </span>
        </div>
    </div>

    <div class="w-logo larger-screen-header">
        <img
            :src="logo_file"
            alt="logo"
            class="
            w-input-fields
            animate__animated animate__fadeInUp animate__delay-1s
            "
            v-if="logo_file !== '' && logo_file !== null"
        />
        <img
            src="../../assets/images/header-auburn.svg"
            alt="logo"
            class="
            w-input-fields
            animate__animated animate__fadeInUp animate__delay-1s
            "
            v-if="logo_file == '' || logo_file == null"
        />
    </div>
    <div class="w-form-block h-60 mt-5">
        <!-- email form section -->
        <form action="" class="" @submit.stop.prevent v-if="is_email">
          <div class="main-action-box w-login-hide">
            <div class="w-form-control animate__animated animate__fadeInUp animate__delay-2s">
                <p>Enter your login email so we can send you a reset code.</p>
            </div>
          </div>
          <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s" :class="{ 'is_invalid': $v.mailform.username.$error }">
              <span><i class="fa-solid fa-user"></i></span>
              <input 
              type="text" 
              name="email" 
              value="" 
              v-model="mailform.username" 
              ref="emailInput"
              placeholder="user@auburn.edu"
              :class="[ ($v.mailform.username.$error ? 'is-invalid' : ''), ($v.mailform.username.$dirty && !$v.mailform.username.$error ? 'is-valid' : '') ]" 
              @keyup.enter="handleEnterPress('email')" 
              @focus="setFocusedElement($event, 'username', 'mailform')"
              />
          </div>
          <div class="main-action-box w-login-hide">
            <div class="w-form-control animate__animated animate__fadeInUp animate__delay-3s">
                <template v-if="remote_id !== ''">
                    <router-link :to="`/mobile-login/${remote_id}`" class="flex-fill" v-if="remote_id !== ''">Back</router-link>
                    <button
                    type="button"
                    @click="handleMailReq"
                    :disabled="$v.mailform.$invalid" 
                    v-if="remote_verified" 
                    tabindex="0" 
                    class="flex-fill"
                    >Send Code</button>
                    <button 
                    class="flex-fill" 
                    disabled="disabled" 
                    v-if="!remote_verified"
                    >Send Code</button>
                </template>
                <template v-else>
                    <router-link to="/" class="flex-fill">Back</router-link>
                    <button
                    type="button"
                    @click="handleMailReq"
                    :disabled="$v.mailform.$invalid" 
                    tabindex="0" 
                    class="flex-fill"
                    >Send Code</button>
                </template>
            </div>
          </div>
          <div class="w-login-show">
            <div class="notification-block">
            </div>
          </div>
        </form>

        <!-- code enter form section -->
        <form action="" class="" @submit.stop.prevent v-if="is_code" @paste="onPaste">
          <div class="main-action-box w-login-hide">
            <div class="w-form-control animate__animated animate__fadeInUp animate__delay-2s">
                <p>Enter the 6-digit reset code from your email below.</p>
            </div>
          </div>
          <!-- <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s" :class="{ 'is_invalid': $v.verifyform.code.$error }">
              <span><i class="fa-solid fa-calculator"></i></span>
              <input 
              type="text" 
              name="code" 
              value="" 
              v-model="verifyform.code" 
              ref="codeInput"
              placeholder="Please enter code"
              :class="[ ($v.verifyform.code.$error ? 'is-invalid' : ''), ($v.verifyform.code.$dirty && !$v.verifyform.code.$error ? 'is-valid' : '') ]"
              />
          </div> -->
          <div class="animate__animated animate__fadeInUp animate__delay-2s">
            <CodeInput :loading="false" :autoFocus="true" v-on:change="onCodeChange" v-on:complete="onCodeComplete" ref="inputboxes" />
          </div>
          <div class="main-action-box w-login-hide" style="max-width:368px !important;">
            <div class="w-form-control animate__animated animate__fadeInUp animate__delay-2s" style="max-width:368px !important;">
                <button
                type="button"
                @click="handleBackStep('code')"
                class="flex-fill"
                >Back</button>
                <button
                type="button"
                @click="handleVerifyReq"
                :disabled="$v.verifyform.$invalid" 
                class="flex-fill"
                >Submit</button>
            </div>
          </div>
          <div class="w-login-show">
            <div class="notification-block">
            </div>
          </div>
        </form>

        <!-- password form section -->
        <form action="" class="" @submit.stop.prevent v-if="is_password">
          <div class="main-action-box w-login-hide">
            <div class="w-form-control animate__animated animate__fadeInUp animate__delay-2s">
                <p>Enter and repeat your new password below.</p>
            </div>
          </div>
          <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s" :class="{ 'is_invalid': $v.passform.password.$error }">
              <span><i class="fa-solid fa-lock"></i></span>
              <input 
              type="password" 
              name="password" 
              value="" 
              v-model="passform.password" 
              ref="passInput"
              placeholder="s3cretp@ssword"
              :class="[ ($v.passform.password.$error ? 'is-invalid' : ''), ($v.passform.password.$dirty && !$v.passform.password.$error ? 'is-valid' : '') ]" 
              @focus="setFocusedElement($event, 'password', 'passform')"
              />
          </div>
          <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s" :class="{ 'is_invalid': $v.passform.confirm_password.$error }">
              <span><i class="fa-solid fa-lock"></i></span>
              <input 
              type="password" 
              name="confirm_password" 
              value="" 
              v-model="passform.confirm_password" 
              placeholder="s3cretp@ssword"
              :class="[ ($v.passform.confirm_password.$error ? 'is-invalid' : ''), ($v.passform.confirm_password.$dirty && !$v.passform.confirm_password.$error ? 'is-valid' : '') ]" 
              @keyup.enter="handleEnterPress('pass')" 
              @focus="setFocusedElement($event, 'confirm_password', 'passform')"
              />
          </div>
          <div class="main-action-box w-login-hide">
            <div class="w-form-control animate__animated animate__fadeInUp animate__delay-3s">
                <button
                type="button"
                @click="handleBackStep('pass')"
                class="flex-fill"
                >Back</button>
                <button
                type="button"
                @click="handlePassReq" 
                class="flex-fill"
                >Submit</button>
            </div>
          </div>
          <div class="w-login-show">
            <div class="notification-block">
            </div>
          </div>
        </form>
        <!-- error container -->
        <div class="w-login-show notification-container w-alert-340 mx-auto move-to-header">
            <div class="w-login-show animate__animated animate__fadeInUp w-alert-340" v-if="noti_class !== '' && noti_message !== ''">
                <div class="notification-block" :class="noti_class">
                    <div class="notification-message w-alert-290">
                        <h5>{{ noti_message }}</h5>
                        <p>{{ noti_message1 !== '' ? noti_message1 : noti_message }}</p>
                    </div>                
                    <div class="icon-success" v-if="noti_class == 'success'"><i class="fa-solid fa-circle-check"></i></div>
                    <div class="icon-error" v-if="noti_class == 'error'"><i class="fa-solid fa-times-circle"></i></div>
                    <div class="icon-warning" v-if="noti_class == 'warning'"><i class="fa-solid fa-exclamation-circle"></i></div>
                </div>
            </div>
            <div class="notification-block error animate__animated animate__fadeInUp w-alert-340" v-if="is_password && ($v.passform.password.$error && !$v.passform.password.required)">
                <div class="notification-message w-alert-290">
                    <h5>{{ messages.required_message }}</h5>
                    <p>{{ messages.register_error_message }}</p>
                </div>                
                <div class="icon-error"><i class="fa-solid fa-times-circle"></i></div>
            </div>
        </div>

    </div>
    <PlayerFooter type="" :visib="''" class="animate__animated animate__fadeIn animate__delay-3s outer-footer"/>
  </section>
</template>

<script>
import store from "@/store";
import { global_config } from "@/config/config.js";
import { sendPasswordMail, verifyPasswordCode, changePassword } from "../../services/ResetPasswordService";
import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import { required, sameAs, minLength, maxLength, email } from 'vuelidate/lib/validators';
import { ToastPlugin } from 'bootstrap-vue';
Vue.use(ToastPlugin);
import PlayerFooter from "@/views/player/PlayerFooter.vue";
import { getPodId, checkFlaskPodUser } from "../../services/FlaskService";
import { validateKioskPodId, validateRemoteQR, sessionActivitySave } from "../../services/HomepageService";
import CodeInput from "vue-verification-code-input";
import { handlePushMenuPolling } from "../../services/Guard";

export default {
    name: "forgotpass-view",
    components: { PlayerFooter, CodeInput },
    data() {
        return {
            mailform: {
                username: ""
            },
            verifyform: {
                code: "",
                token: "",
            },
            passform: {
                token: "",
                password: "",
                confirm_password : ""
            },
            is_email: true,
            reset_token: "",
            is_code: false,
            is_password: false,
            messages: global_config.MESSAGES,
            noti_class: "",
            noti_message: "",
            noti_message1: "",
            logo_file: "",
            pod_id_key: "",
            remote_id: "",
            remote_verified: false,
            idle_polling: null,
            menu_polling: null,
            current_component: this,
        };
    },

    validations: {
        mailform : {
            username: {required, email}
        },
        verifyform : {
            code: {required, minLength: minLength(6), maxLength: maxLength(6)}
        },
        passform : {
            password: {required, minLength: minLength(8)},
            confirm_password: {sameAsPassword: sameAs('password')}
        }
    },

    computed: {
        getMessage() {
            return store.getters.getMessage;
        },
        remote_session_expired_error() {
            return this.$store.getters.getMessageDetail('remote-session-expired');
        },
        nav_name() {
            return this.$store.getters.getNavName();
        }
    },

    methods: {
        handleMailReq() {
            this.$v.mailform.$touch();
            if (this.$v.mailform.$invalid) {
                this.makeToast('generic-required-message');
            } else {
                let data = this.mailform;
                sendPasswordMail(data).then(response => {
                    if(response.status){
                        this.makeToast(response.message, this.messages.success_class, response.data.message, response.data.message1);
                        setTimeout(() => {
                            this.is_email = false;
                            this.is_code = true;
                            this.captureCodeFocusEvents();
                            this.reset_token = response.data.token;
                            this.resetForm('mail');
                            this.noti_class = "";
                            this.noti_message = "";
                            this.noti_message1 = "";
                        }, 5000);
                        setTimeout(() => {
                            document.getElementsByClassName('react-code-input')[0].getElementsByTagName('input')[0].focus();
                        }, 7000);
                    } else {
                        if(response.message == 'both') {
                            this.makeToast(response.message, this.messages.error_class, response.data.message, response.data.message1);
                        }
                        else {
                            this.makeToast(response.message, this.messages.error_class, response.message);
                        }
                    }
                }); 
            }
        },

        handleVerifyReq() {
            this.$v.verifyform.$touch();
            if (this.$v.verifyform.$invalid) {
                this.makeToast('generic-required-message');
            } else {
                let data = this.verifyform;
                data.token = this.reset_token;
                verifyPasswordCode(data).then(response => {
                    if(response.status){
                        this.makeToast(response.message, this.messages.success_class, response.data.message, response.data.message1);
                        setTimeout(() => {
                            this.is_email = false;
                            this.is_code = false;
                            this.is_password = true;
                            this.resetForm('verify');
                            this.noti_class = "";
                            this.noti_message = "";
                            this.noti_message1 = "";
                        }, 4000);
                        setTimeout(() => {
                            this.$refs.passInput.focus();
                        }, 6000);
                    } else {
                        if(response.message == 'both') {
                            this.makeToast(response.message, this.messages.error_class, response.data.message, response.data.message1);
                        }
                        else {
                            this.makeToast(response.message, this.messages.error_class, response.message);
                        }
                    }
                }); 
            }
        },

        handlePassReq() {
            this.$v.passform.$touch();
            if (this.$v.passform.$invalid) {
                if(this.$v.passform.password.$error && !this.$v.passform.password.minLength) {
                    this.makeToast('forgotpass-weak-pass-error');
                }
                else if(this.$v.passform.confirm_password.$error && !this.$v.passform.confirm_password.sameAs) {
                    this.makeToast('forgotpass-pass-not-match-error');
                }
            } else {
                let data = this.passform;
                data.token = this.reset_token;
                changePassword(data).then(response => {
                    if(response.status){
                        this.makeToast(response.message, this.messages.success_class, response.data.message, response.data.message1);
                        this.$v.$reset();
                        this.resetForm('pass');
                        setTimeout(() => {
                            if(this.remote_id !== '') {
                                this.$router.push('/mobile-login/'+this.remote_id);
                            }
                            else {
                                this.$router.push('/');
                            }
                        }, 4000);
                    } else {
                        this.makeToast(response.message, this.messages.error_class, response.message);
                    }
                }); 
            }
        },

        handleBackStep(step) {
            if(step == "code") {
                this.is_code = false;
                this.is_password = false;
                this.is_email = true;
            }
            else if(step == "pass") {
                this.is_password = false;
                this.is_code = true;
                this.captureCodeFocusEvents();
                this.is_email = false;
                setTimeout(() => {
                    document.getElementsByClassName('react-code-input')[0].getElementsByTagName('input')[0].focus();
                }, 2000);
            }
        },

        makeToast(message_key = '', variant = "default", message, message1="") {
            // search for key
            let smessage = this.$store.state.system_messages.list.find(o => o.msg_key === message_key);
            if(smessage !== null && typeof smessage !== 'undefined' && Object.prototype.hasOwnProperty.call(smessage, 'text_head')) {
                this.noti_class = smessage.type == 'failure' ? 'error' : smessage.type;
                this.noti_message = smessage.text_head;
                this.noti_message1 = smessage.text_sub;
            }
            else {
                this.noti_class = variant;
                this.noti_message = message;
                this.noti_message1 = message1;
            }
            // hide error div
            setTimeout(() => {
                this.noti_class = "";
                this.noti_message = "";
                this.noti_message1 = "";
            }, 5000);
        },

        resetForm(type = "mail") {
            var self = this;
            //Iterate through each object field, key is name of the object field`
            if(type == "mail") {
            Object.keys(this.mailform).forEach(function(key) {
                self.mailform[key] = '';
            });
            }
            else if(type == "verify") {
            Object.keys(this.verifyform).forEach(function(key) {
                self.verifyform[key] = '';
            });
            }
            else if(type == "pass") {
            Object.keys(this.passform).forEach(function(key) {
                self.passform[key] = '';
            });
            }
        },
        validateKioskPodId() {
            validateKioskPodId(this.pod_id_key).then((response) => {
                if (response.status) {
                    localStorage.setItem('pod_sess_data', JSON.stringify(response.data));
                    this.logo_file = response.data.logo;
                    this.$store.commit("setThemeLogo", response.data);
                    // call activity record api
                    sessionActivitySave({'activity': global_config.ACTIVITIES.UI_PASSWORD}).then((response) => {
                        console.log("ACTIVITY API CALLED : ", response);
                    });
                }
            });
        },
        getPodIdFromFlask() {
            getPodId().then((pod) => {
                if (
                    Object.prototype.hasOwnProperty.call(pod, "success") &&
                    pod.success &&
                    Object.prototype.hasOwnProperty.call(pod, "data") &&
                    Object.prototype.hasOwnProperty.call(pod.data, "pod_id") &&
                    pod.data.pod_id.length > 0
                ) {
                    this.pod_id_key = pod.data.pod_id;
                    checkFlaskPodUser().then((pu) => {
                        if (
                            Object.prototype.hasOwnProperty.call(pu, "success") &&
                            pu.success &&
                            Object.prototype.hasOwnProperty.call(pu, "data") &&
                            Object.prototype.hasOwnProperty.call(pu.data, "user_name")
                        ) {
                            this.$router.push('/');
                        } 
                        else {
                            this.validateKioskPodId();
                            this.pollPodIdle();
                            this.menu_polling = handlePushMenuPolling(this);
                        }
                    });
                }
            });
        },
        onCodeChange(v) {
            this.verifyform.code = v;
        },
        onCodeComplete(v) {
            this.verifyform.code = v;
        },
        onPaste(event) {
            let pastevalue = event.clipboardData.getData('text');
            if(pastevalue != '') {
                pastevalue = pastevalue.split("");
                let elems = document.getElementsByClassName('react-code-input')[0].getElementsByTagName('input');
                if(pastevalue.length == elems.length) {
                    setTimeout(() => {
                        for (let index = 0; index < elems.length; index++) {
                            elems[index].focus();
                            elems[index].value = pastevalue[index];
                        }
                        this.$refs.inputboxes.triggerChange(pastevalue);
                    }, 200);
                }
            }
        },
        validateQRRemoteId() {
            validateRemoteQR(this.remote_id, 'forgot').then(response => {
                this.remote_requested = true;
                if(!response.status) {
                    this.remote_verified = false;
                    this.noti_class = this.messages.error_class;
                    this.noti_message = (this.remote_session_expired_error.is_exist) ? this.remote_session_expired_error.text_head : "This remote session has expired.";
                    this.noti_message1 = (this.remote_session_expired_error.is_exist) ? this.remote_session_expired_error.text_sub : "Please scan the QR code again with your camera.";
                }
                else {
                    this.remote_verified = true;
                    if(response.message == 'remote_init_reg_forgot') {
                        this.makeToast(this.messages.success_class, "Starting remote session with "+response.data.nav_name+".", "Please wait while the connection is established...");
                    }
                    else {
                        this.makeToast(response.message, this.messages.success_class, response.message);
                    }
                    localStorage.setItem('pod_sess_data', JSON.stringify(response.data));
                    // set theme file and logo file for pod
                    this.logo_file = response.data.logo;
                }
            })
        },
        pollPodIdle () {
            this.idle_polling = setInterval(() => {
                // // get xd types for buttons
                // checkFlaskPodIdle().then(idle => {
                //     if(idle.success !== 'undefined') {
                //         if(idle.success) {
                //             clearInterval(this.idle_polling);
                //             this.$router.push('/');
                //         }
                //     }
                // });
            
                let pod_idle = localStorage.getItem('pod_idle');
                // check if idle already recorded
                if(typeof pod_idle !== 'undefined' && pod_idle == 'true') {
                    clearInterval(this.idle_polling);
                    this.$router.push('/');
                }
            }, 3000);
            this.$store.commit("setIdleFlagUpater", true);
        },
        handleEnterPress(type) {
            if(type == 'email') {
                this.handleMailReq();
            }
            else if(type == 'pass') {
                this.handlePassReq();
            }
        },
        setFocusedElement(event, ref, form) {
            this.$store.commit("setFocusedElement", ref);
            this.$store.commit("setFocusedElementForm", {'form': form, 'target' : event});
        },
        captureCodeFocusEvents() {
            setTimeout(() => {
                let elems = document.getElementsByClassName('react-code-input')[0].getElementsByTagName('input');
                if(elems.length > 0) {
                    for (let index = 0; index < elems.length; index++) {
                        elems[index].addEventListener("focus", (event) => {
                            this.setFocusedElement(event, 'code', 'verifyform');
                        });
                    }
                }
            }, 1000);
        }
    },
    mounted() {
        if(typeof this.$route.params.qr_code == 'undefined') {
            this.getPodIdFromFlask();
        }
        else {
            this.remote_id = this.$route.params.qr_code;
            this.noti_class = this.messages.warning_class;
            this.noti_message = this.messages.remote_verifying;
            this.validateQRRemoteId();
        }
        setTimeout(() => {
            this.$refs.emailInput.focus();
        }, 2000);
        // check if info recieved from login page
        let params = this.$route.params;
        if(Object.prototype.hasOwnProperty.call(params, "uname") && params.uname !== '') {
            this.mailform.username = params.uname;
        }
    },
    beforeDestroy() {
        clearInterval(this.idle_polling);
        clearInterval(this.menu_polling);
    }
};
</script>