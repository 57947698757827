import Vue from "vue";
import Vuex from "vuex";

// import all Vuex module here
import auth from "@/store/modules/auth/index.js";
import system_messages from "@/store/modules/system_messages/index.js";
import theme_logo from "@/store/modules/theme_logo/index.js";
import rss_feed from "@/store/modules/rss_feed/index.js";
import reservation_feed from "@/store/modules/reservation_alert/index.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    system_messages,
    theme_logo,
    rss_feed,
    reservation_feed
  },
});
