<template>
    <div class="main-contenttt" v-if="remaining_time <= 15 && remaining_time >= 0">
        <div class="overlay"></div>
        <h2 class="page-heading animate__animated animate__fadeIn">Still there...?</h2>
        <h3 class="page-subheading mb-1 animate__animated animate__fadeIn">You will be logged out due to inactivity in...</h3>

        <!-- start timer section -->
        <div
            class="w-form-control timer animate__animated animate__fadeInUp timer_with_kb" 
        >
            <div class="timer-counter">{{ remaining_time }}</div>
        </div>
        <!-- end timer section -->
    </div>

</template>

<script>
export default {
    name: "LogoutWarningWidget-view",
    data() {
        return {
            remaining_time: -1
        };
    },

    computed: {
        pod_inactive() {
            return this.$store.getters.getInactiveCounter();
        }
    },

    watch: {
        pod_inactive(new_val) {
            let difference = process.env.VUE_APP_LOGOUT_INACTIVITY_DURATION - new_val;
            if(new_val != -1 && difference <= 15000) {
                this.setRemainingTime(Math.ceil(difference/1000));
            }
            else {
                this.setRemainingTime(60);
            }
        }
    },

    methods: {
        setRemainingTime(val) {
            this.remaining_time = val;
        }
    },
};
</script>
<style scoped>
.main-contenttt {
    width: 100%;
    height: 100vh;
    z-index: 1000;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    backdrop-filter: blur(30px);
    background: transparent;
}
div.timer {
    position: relative;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    /* overflow: hidden; */
    color: #d8d8d8;
    border-radius: 50%;
    font-weight: 700;
    font-family: 'Open Sans';
    animation: 60s 0.875s cubic-bezier(0.9, 0, 0.1);
}

div.timer_with_kb {
    width: 150px;
    height: 150px;
    font-size: 70px;
    border: 2px solid var(--client-primary);
    margin-top: 25px;
}

@keyframes timer_beat {
    40%,
    80% {
        transform: none; }
    50% {
        transform: scale(1.125);
    }
}
div.timer:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -100;
    width: 100%;
    height: 100%;
    animation: 60s 1s linear forwards timer_indicator;
}

@keyframes timer_indicator {
    100% {
        transform: translateY(100%);
    }
}
.main-contenttt .overlay {
    position: absolute;
    background: black;
    width: 100%;
    left: 0;
    height: 100%;
    opacity: 0.65;
}
</style>