<template>
  <div></div>
</template>

<script>
export default {};
</script>
<style>
/* purple theme */
/* .PurpleTheme .bg-labPrimary,
.PurpleTheme .main-header .header-bg,
.PurpleTheme #volume-modal .mute-icons .icon,
.PurpleTheme section.blue,
.PurpleTheme .bg-labPrimary .admin-portal,
.PurpleTheme .admin-portal-bg {
  background-color: purple !important;
  background-image: linear-gradient(34deg, purple, #3f3f3f) !important;
} */

/* blue theme */
/* .BlueTheme .bg-labPrimary,
.BlueTheme .main-header .header-bg,
.BlueTheme #volume-modal .mute-icons .icon,
.BlueTheme section.blue,
.BlueTheme .bg-labPrimary .admin-portal,
.BlueTheme .admin-portal-bg {
  background-color: blue !important;
  background-image: linear-gradient(34deg, blue, #3f3f3f) !important;
} */

/* green theme */
/* .GreenTheme .bg-labPrimary,
.GreenTheme .main-header .header-bg,
.GreenTheme #volume-modal .mute-icons .icon,
.GreenTheme section.blue,
.GreenTheme .bg-labPrimary .admin-portal,
.GreenTheme .admin-portal-bg {
  background-color: green !important;
  background-image: linear-gradient(34deg, green, #3f3f3f) !important;
} */

/* lime theme */
/* .LimeTheme .bg-labPrimary,
.LimeTheme .main-header .header-bg,
.LimeTheme #volume-modal .mute-icons .icon,
.LimeTheme section.blue,
.LimeTheme .bg-labPrimary .admin-portal,
.LimeTheme .admin-portal-bg {
  background-color: lime !important;
  background-image: linear-gradient(34deg, lime, #3f3f3f) !important;
} */

/* orange theme */
/* .OrangeTheme .bg-labPrimary,
.OrangeTheme .main-header .header-bg,
.OrangeTheme #volume-modal .mute-icons .icon,
.OrangeTheme section.blue,
.OrangeTheme .bg-labPrimary .admin-portal,
.OrangeTheme .admin-portal-bg {
  background-color: orange !important;
  background-image: linear-gradient(34deg, #EC9F05, #3f3f3f) !important;
} */

/* pink theme */
/* .PinkTheme .bg-labPrimary,
.PinkTheme .main-header .header-bg,
.PinkTheme #volume-modal .mute-icons .icon,
.PinkTheme section.blue,
.PinkTheme .bg-labPrimary .admin-portal,
.PinkTheme .admin-portal-bg {
  background-color: pink !important;
  background-image: linear-gradient(34deg, pink, #3f3f3f) !important;
} */

/* red theme */
/* .RedTheme .bg-labPrimary,
.RedTheme .main-header .header-bg,
.RedTheme #volume-modal .mute-icons .icon,
.RedTheme section.blue,
.RedTheme .bg-labPrimary .admin-portal,
.RedTheme .admin-portal-bg {
  background-color: red !important;
  background-image: linear-gradient(34deg, red, #3f3f3f) !important;
} */

/* teal theme */
/* .TealTheme .bg-labPrimary,
.TealTheme .main-header .header-bg,
.TealTheme #volume-modal .mute-icons .icon,
.TealTheme section.blue,
.TealTheme .bg-labPrimary .admin-portal,
.TealTheme .admin-portal-bg {
  background-color: teal !important;
  background-image: linear-gradient(34deg, teal, #3f3f3f) !important;
} */

/* yellow theme */
/* .YellowTheme .bg-labPrimary,
.YellowTheme .main-header .header-bg,
.YellowTheme #volume-modal .mute-icons .icon,
.YellowTheme section.blue,
.YellowTheme .bg-labPrimary .admin-portal,
.YellowTheme .admin-portal-bg {
  background-color: yellow !important;
  background-image: linear-gradient(34deg, #EF5734, #3f3f3f) !important;
} */
</style>